import { AccountingPlanTabComponent } from './accounting-plan-tab/accounting-plan-tab.component';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
} from '@angular/core';
import { Company } from '../../shared/_models/Company';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { OnUser } from '../../shared/_models/OnUser';
import { CompanyService } from '../../shared/stores/company/company.service';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { EmployeesTabComponent } from './employees-tab/employees-tab.component';
import { HierarchyTabComponent } from './hierarchy-tab/hierarchy-tab.component';
import { CompanyCustomFieldsTabComponent } from './company-custom-fields-tab/company-custom-fields-tab.component';
import { CompanyState } from '../../shared/stores/company/company.state';
import { Select, Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { CompanyAction } from '../../shared/stores/company/company.action';
import { UserState } from '../../shared/stores/user/user.state';
import { UserActions } from '../../shared/stores/user/user.actions';
import { SettingsTabComponent } from './settings-tab/settings-tab.component';

@Component({
  selector: 'on-my-company-page',
  templateUrl: './my-company-page.component.html',
  styleUrls: ['./my-company-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MyCompanyPageComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  wantCreateCompany = false;

  company: Company = null;
  companies: Company[];

  //connectedUserSubscription: Subscription;
  @Select(UserState.currentUser) currentUser$: Observable<OnUser>;
  @Select(CompanyState.current) company$: Observable<Company>;
  destroy$ = new Subject<void>();

  @ViewChild(NgbNav, { static: false }) ngbNav: NgbNav;
  @ViewChild('EmployeesPage', { static: false })
  employeesPage: EmployeesTabComponent;
  @ViewChild('HierarchyPage', { static: false })
  hierarchyPage: HierarchyTabComponent;
  @ViewChild('CustomFieldsPage', { static: false })
  customFieldsPage: CompanyCustomFieldsTabComponent;
  @ViewChild('AccountingPlanPage', { static: false })
  accountingPlanPage: AccountingPlanTabComponent;
  @ViewChild('SettingsPage', { static: false })
  settingsPage: SettingsTabComponent;

  constructor(
    private companyService: CompanyService,
    private router: Router,
    private store: Store,
  ) { }
  ngOnInit() {
    this.isLoading = true;
    this.store.dispatch(new CompanyAction.LoadCompaniesIfNeeded());
    this.currentUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => this.connectedUserChanged(user));
  }

  ngOnDestroy() {
    //this.connectedUserSubscription.unsubscribe();

    this.destroy$.next();
    this.destroy$.complete();
  }

  connectedUserChanged(user: OnUser) {
    if (!user) {
      return;
    }

    if (user.CompanyId) {
      // Check if the user can be on this page
      if (user.Role !== 'ADMIN') {
        this.router.navigate(['dashboard']);
      }
      this.companyService.SetCompanySelected(user.CompanyId);
    }

    this.company$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      if (response) {
        this.company = response;
        this.isLoading = false;
      } else if (!user.CompanyId) {
        this.isLoading = false;
      }
    });
  }

  createNewCompany() {
    this.wantCreateCompany = true;
  }

  cancelCreationCompany() {
    this.wantCreateCompany = false;
  }

  onCompanyCreated() {
    this.isLoading = true;
    this.store.dispatch(new UserActions.GetCurrentUser()).subscribe(() => {
      this.store.dispatch(new CompanyAction.LoadCompanies());
    });

    //this.accountService.loadConnectedOnUser();
    //
    //this.accountService.GetUser().subscribe(response => {
    //  if (response.IsSuccess) {
    //    this.company = response.Result.Company;
    //    this.isLoading = false;
    //  } else {
    //    window.location.reload();
    //  }
    //});
  }

  reload() {
    const id = this.ngbNav.activeId;

    if (id === 'employees') {
      this.employeesPage.reload();
    } else if (id === 'hierarchy') {
      this.hierarchyPage.reload();
    } else if (id === 'customFields') {
      this.customFieldsPage.reload();
    } else if (id === 'organisation') {
      this.accountingPlanPage.reload();
    } else if (id === 'settings') {
      this.settingsPage.reload();
    }
  }
}
