import {
  sequence,
  trigger,
  stagger,
  animate,
  style,
  group,
  query as q,
  transition,
  keyframes,
  animateChild,
} from '@angular/animations';


export function query(style, animate, optional = { optional: true }) {
  return q(style, animate, optional);
}

export const screenSizes = {
  xs: 207,
  sm: 691,
  md: 1091,
  lg: 1331,
  xl: 1500
};

export function fade(marginSize?: number) {
  return [
    query(':enter, :leave', style({ position: 'absolute', width: `100%`})),
    query(':enter', [style({ opacity: 0 })]),
    group([
      query(':leave', [animate('0.4s ease-out', style({ opacity: 0 }))]),
      query(':enter', [
        style({ opacity: 0 }),
        animate('0.4s ease-out', style({ opacity: 1 })),
      ]),
    ]),
  ];
}

export function fadeInFromDirection(direction) {
  return [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
    group([
      query(':enter', [
        style({
          transform: `translateX(${direction === 'backward' ? '-12%' : '100%'})`,
          opacity: 0,
        }),
        animate(
          `0.${direction === 'backward' ? '7' : '4'}s ease-out`,
          style({ transform: 'translateX(0%)', opacity: 1 }),
        ),
      ]),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate(`0.${direction === 'backward' ? '4' : '7'}s ease-out`, style({ opacity: 0 })),
      ]),
    ]),
  ];
}

export const routerTransition = trigger('routerTransition', [
  transition('* => none', []),
  transition('* => xs-fade', fade(20)),
  transition('* => xs-inital', fade(20)),
  transition('* => xs-section', fade(20)),
  transition('* => xs-forward', fade(20)),
  transition('* => xs-backward', fade(20)),
  transition('* => sm-fade', fade(36)),
  transition('* => sm-inital', fade(36)),
  transition('* => sm-section', fade(36)),
  transition('* => sm-forward', fade(36)),
  transition('* => sm-backward', fade(36)),
  transition('* => md-fade', fade(36)),
  transition('* => md-inital', fade(36)),
  transition('* => md-section', fade(36)),
  transition('* => md-forward', fade(36)),
  transition('* => md-backward', fade(36)),
  transition('* => lg-fade', fade(36)),
  transition('* => lg-inital', fade(36)),
  transition('* => lg-section', fade(36)),
  transition('* => lg-forward', fade(6)),
  transition('* => lg-backward', fade(36)),
  transition('* => xl-fade', fade(43)),
  transition('* => xl-inital', fade(43)),
  transition('* => xl-section', fade(43)),
  transition('* => xl-forward', fade(43)),
  transition('* => xl-backward', fade(43)),
  transition('* => fade', fade()),
  transition('* => inital', fade()),
  transition('* => section', fade()),
  transition('* => forward', fade()),
  transition('* => backward', fade()),
]);
