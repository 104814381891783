import { TranslateModule } from '@ngx-translate/core';
import { CompanyRoleSelectComponent } from './_components/company-role-select/company-role-select.component';
import { DatepickerStringAdapter } from './misc/datepicker-string-adapter';
import {
  CustomDatepickerFormatter,
  CustomDatepickerI18n,
} from './i18n/Datepicker.i18n';
import {
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDateAdapter,
  NgbNavModule
} from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TotalGridcellComponent } from './grid-cell/total-gridcell/total-gridcell.component';
import { KickGridcellComponent } from './grid-cell/kick-gridcell/kick-gridcell.component';
import { CheckboxFloatingFilterComponent } from './grid-cell/checkbox-floating-filter/checkbox-floating-filter.component';
import { DateGridcellComponent } from './grid-cell/date-gridcell/date-gridcell.component';
import {
  NgModule,
  MissingTranslationStrategy,
  TRANSLATIONS_FORMAT,
  LOCALE_ID
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {
  NbButtonModule,
  NbPopoverModule,
  NbIconModule,
  NbInputModule,
} from '@nebular/theme';
import { GlobalSettingsComponent } from './_components/global-settings/global-settings.component';
import { CheckboxGridcellComponent } from './grid-cell/checkbox-gridcell/checkbox-gridcell.component';
import { DeleteRowGridcellComponent } from './grid-cell/delete-row-gridcell/delete-row-gridcell.component';
import { OnCommonModule } from 'on-common/on-common.module';
import { OnAuthModule } from 'on-auth/on-auth.module';
import { EmployeeCategorySelectComponent } from './_components/employee-category-select/employee-category-select.component';
import { SelectEmployeesComponent } from './_components/select-employees/select-employees.component';

declare const require;

registerLocaleData(localeFr, 'fr');

@NgModule({
    declarations: [
        CheckboxFloatingFilterComponent,
        DateGridcellComponent,
        KickGridcellComponent,
        TotalGridcellComponent,
        GlobalSettingsComponent,
        CompanyRoleSelectComponent,
        CheckboxGridcellComponent,
        DeleteRowGridcellComponent,
        EmployeeCategorySelectComponent,
        SelectEmployeesComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        NbButtonModule,
        NbPopoverModule,
        NbButtonModule,
        NbIconModule,
        NbInputModule,
        NgbNavModule,
        AgGridModule,
        OnCommonModule,
        OnAuthModule,
        TranslateModule,
    ],
    exports: [
        // Export all component
        CommonModule,
        FormsModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        NbButtonModule,
        NbPopoverModule,
        NbButtonModule,
        NbIconModule,
        NbInputModule,
        NgbNavModule,
        OnCommonModule,
        OnAuthModule,
        TranslateModule,
        // Export local component
        CheckboxFloatingFilterComponent,
        DateGridcellComponent,
        KickGridcellComponent,
        TotalGridcellComponent,
        GlobalSettingsComponent,
        CompanyRoleSelectComponent,
        CheckboxGridcellComponent,
        DeleteRowGridcellComponent,
        EmployeeCategorySelectComponent,
        SelectEmployeesComponent
    ],
    providers: [
        { provide: NgbDateAdapter, useClass: DatepickerStringAdapter },
        { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
        { provide: NgbDateParserFormatter, useClass: CustomDatepickerFormatter },
        { provide: LOCALE_ID, useValue: 'fr' },
        // format of translations that you use
        { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' },
        // locale id that you're using (default en-US)
        { provide: LOCALE_ID, useValue: 'fr' }
    ],
    schemas: []
})
export class SharedModule { }
