<div class="d-inline-block" ngbDropdown *ngFor="let action of actions" #myDrop="ngbDropdown"
>
  <span *ngIf="action.canDisplayCondition === undefined || action.canDisplayCondition()"
  >
    <span *ngIf="!!action.tooltipMessage"
      [nbTooltip]="action.tooltipMessage? action.tooltipMessage: null"
      [nbTooltipStatus]="action.tooltipMessage? action.tooltipStatus : null"
    >
    <button [hidden]="!(action.children && action.children.length > 0)" [ngClass]="action.customClass"
      class="btn mr-2"
      ngbDropdownAnchor (click)="dropdownClick()">
      <span *ngFor="let icon of action.icons" style="margin: 2px">
        <nb-icon [icon]="icon"></nb-icon>
      </span>
      <nb-icon *ngIf="action.icon" [icon]="action.icon"></nb-icon>
      {{action.text}}
    </button>
    <button *ngIf="!action.children || action.children.length == 0" [ngClass]="action.customClass"
      class="btn mr-2" (click)="action.actionOnClick()">
      <span *ngFor="let icon of action.icons" style="margin: 2px">
        <nb-icon [icon]="icon"></nb-icon>
      </span>
      <nb-icon *ngIf="action.icon" [icon]="action.icon"></nb-icon>
      {{action.text}}
    </button>
    <div ngbDropdownMenu>
      <div *ngFor="let subAction of action.children">
        <hr *ngIf="subAction.isSeparation">
        <button *ngIf="!subAction.isSeparation" class="dropdown-item" (click)="subAction.actionOnClick()">
          <span *ngFor="let icon of subAction.icons" style="margin: 2px">
            <nb-icon [ngClass]="['fas', icon]"></nb-icon>
          </span>
          <nb-icon *ngIf="subAction.icon" [icon]="subAction.icon"></nb-icon>
          {{subAction.text}}
        </button>
      </div>
    </div>
    </span>

    <span *ngIf="!action.tooltipMessage">
      <button [hidden]="!(action.children && action.children.length > 0)" [ngClass]="action.customClass"
        class="btn mr-2"
        ngbDropdownAnchor (click)="dropdownClick()">
        <span *ngFor="let icon of action.icons" style="margin: 2px">
          <nb-icon [icon]="icon"></nb-icon>
        </span>
        <nb-icon *ngIf="action.icon" [icon]="action.icon"></nb-icon>
        {{action.text}}
      </button>
      <button *ngIf="!action.children || action.children.length == 0" [ngClass]="action.customClass"
        class="btn mr-2" (click)="action.actionOnClick()">
        <span *ngFor="let icon of action.icons" style="margin: 2px">
          <nb-icon [icon]="icon"></nb-icon>
        </span>
        <nb-icon *ngIf="action.icon" [icon]=" action.icon"></nb-icon>
        {{action.text}}
      </button>
      <div ngbDropdownMenu>
        <div *ngFor="let subAction of action.children">
          <hr *ngIf="subAction.isSeparation">
          <button *ngIf="!subAction.isSeparation" class="dropdown-item" (click)="subAction.actionOnClick()">
            <span *ngFor="let icon of subAction.icons" style="margin: 2px">
              <nb-icon [icon]="icon"></nb-icon>
            </span>
            <nb-icon *ngIf="subAction.icon" [icon]="subAction.icon"></nb-icon>
            {{subAction.text}}
          </button>
        </div>
      </div>
    </span>


  </span>

</div>
