import { Expense } from '../../_models/Expense';

// tslint:disable-next-line: no-namespace
export namespace ExpenseAction {

  export class LoadExpenses {
    public static readonly type = `[Expense] load the expenses of the current user`;

    constructor() { }
  }

  export class LoadExpensesIfNeeded {
    public static readonly type = `[Expense] load the expenses of the current user if needed`;

    constructor() { }
  }

  export class LoadExpenseById {
    public static readonly type = `[Expense] load one expense by its id`;

    constructor(public expenseId: number) { }
  }

  export class RemoveExpenses {
    public static readonly type = `[Expense] remove`;

    constructor(public idsToRemove: number[]) { }
  }

  export class CreateManualExpense {
    public static readonly type = `[Expense] create a manual expense`;

    constructor(public expense: Expense) { }
  }

  export class EditExpense {
    public static readonly type = `[Expense] edit an expense`;

    constructor(public expense: Expense, public callback: () => void) { }
  }

  export class LoadExpenseTypes {
    public static readonly type = `[Expense] load the expense types`;

    constructor() { }
  }

  export class LoadExpenseTypesIfNeeded {
    public static readonly type = `[Expense] load the expense types if needed`;

    constructor() { }
  }

  export class ValidateExpense {
    public static readonly type = `[Expense] validate an expense`;

    constructor(public expense: Expense, public callback: () => void) { }
  }

  export class RejectExpense {
    public static readonly type = `[Expense] reject an expense`;

    constructor(public expense: Expense, public callback: () => void) { }
  }

  export class MustReloadExpenses {
    public static readonly type = `[Expense] must reload the expenses`;

    constructor() { }
  }

  export class DuplicateExpense {
    public static readonly type = `[Expense] duplicate`;

    constructor(public expenseId: number) { }
  }
}
