import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';

@Component({
  selector: 'on-expense-manager-validation-gridcell',
  templateUrl: './expense-manager-validation-gridcell.component.html',
  styleUrls: ['./expense-manager-validation-gridcell.component.scss']
})
export class ExpenseManagerValidationGridcellComponent implements ICellRendererAngularComp {

  params: any;

  constructor() { }

  refresh(params: any): boolean {
    return false;
  }
  agInit(params: import('ag-grid-community').ICellRendererParams): void {
    this.params = params;
  }
  afterGuiAttached?(params?: import('ag-grid-community').IAfterGuiAttachedParams): void {
  }

}
