<on-loading *ngIf="isLoading"></on-loading>

<div class="row" *ngIf="!currentSubscription && !isLoading">
    <p class="col-12">
        <br>{{'payment.stripe-vp-payment.to-activate-choose' | translate}}</p>
    <div class="col-12 d-flex justify-content-around">
        <div *ngFor="let price of prices" class="card text-center" [class.selected]="selectedPrice === price"
            [class.shadow-sm]="selectedPrice !== price" [class.shadow]="selectedPrice === price"
            (click)="selectPrice(price)">
            <h4 class="plan-title">{{'payment.stripe-vp-payment.plan' | translate}} {{price.Name}} - {{price.Price}} € /
                {{'month' | translate}}</h4>
            <p>{{price.Description}}<br>
                {{'payment.stripe-vp-payment.price-per-extra-receipt' | translate}}</p>
        </div>
    </div>
    <div class="col-12 d-flex justify-content-center">
        <div class="card text-center shadow-sm higher-plan" [class.selected]="quoteSelected"
            (click)="selectPrice(null, true)">
            <h4 class="plan-title">{{'payment.stripe-vp-payment.plan-quote' | translate}}</h4>
            <p>{{'payment.stripe-vp-payment.more-per-month' | translate}}</p>
        </div>
    </div>
    <div class="col-12" *ngIf="selectedPrice">
        <br><br>
        <p
            [innerHTML]="'payment.stripe-vp-payment.summary' | translate:{selectedPriceName: selectedPrice.Name, selectedPricePrice: selectedPrice.Price, selectedPriceDescription: selectedPrice.Description}">
        </p>
        <br>
        <div class="text-center">
            <button class="btn btn-primary" (click)="buyPlan()">{{'payment.stripe-vp-payment.confirm' | translate}}
                {{selectedPrice.Name}}</button>
        </div>
    </div>
    <div class="col-12" *ngIf="quoteSelected">
        <br><br>
        <div class="text-center">
            <button class="btn btn-primary"
                [routerLink]="['/support']">{{'payment.stripe-vp-payment.contact-sales' | translate}}</button>
        </div>
    </div>
</div>

<div class="row" *ngIf="currentSubscription && !isLoading">
    <p class="col-12">{{'payment.stripe-vp-payment.your-current-subscription' | translate}}</p>

    <div class="col-12 d-flex justify-content-around">
        <div *ngFor="let price of prices" class="card text-center" [class.disabled]="selectedPrice !== price"
            [class.selected]="selectedPrice === price" [class.shadow]="selectedPrice === price">
            <h4 class="plan-title">{{'payment.stripe-vp-payment.plan' | translate}} {{price.Name}} - {{price.Price}} € /
                {{'month' | translate}}</h4>
            <p>{{price.Description}}<br>
                {{'payment.stripe-vp-payment.price-per-extra-receipt' | translate}}</p>
        </div>
    </div>

    <p class="col-12">
        <br>{{'payment.stripe-vp-payment.contact-sales' | translate}} <a
            [routerLink]="['/support']">{{'payment.stripe-vp-payment.clic-to-access' | translate}}</a>
    </p>
</div>