import { DomainName } from './../../_models/DomainName';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Company } from '../../_models/Company';
import { CompanyEmployeesData } from '../../_models/CompanyEmployeesData';
import { OnUser } from '../../_models/OnUser';
import { CustomField } from '../../_models/CustomField';
import { CompanyInvitation } from '../../_models/CompanyInvitation';
import { map, tap } from 'rxjs/operators';
import { ManagerInfos } from '../../_models/ManagerInfos';
import { Subscription } from '../../_models/Subscription';
import { VPConsumption } from '../../_models/VPConsumption';
import { API_V2_URL } from 'on-common/constants';
import { DialogService } from 'on-common/_services/dialog.service';
import { IApiActionResult, IApiResult } from 'on-common/_models/ApiResult';
import { Rule } from 'on-shared/_models/Rule';
import { EmployeeCategory } from 'on-shared/_models/EmployeeCategory';
import { InvitedUser } from 'onexpense/expense/_models/InvitedUser';

const COMPANY_URL = API_V2_URL + '/company';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  rolesObservable: BehaviorSubject<string[]>;

  constructor(
    private http: HttpClient,
    private dialogService: DialogService
  ) { }

  GetCompany(): Observable<IApiResult<Company>> {
    return this.http.post<IApiResult<Company>>(COMPANY_URL + '/infos', null);
  }

  GetCompanies(): Observable<IApiResult<Company[]>> {
    return this.http.post<IApiResult<Company[]>>(COMPANY_URL + '/infos/all', null);
  }

  GetRoles(): Observable<string[]> {
    if (!this.rolesObservable) {
      this.rolesObservable = new BehaviorSubject<string[]>(null);
      this.rolesObservable.next([
        'EMP',
        'ACC',
        'ADMIN'
      ]);
    }

    return this.rolesObservable;
  }

  SaveAuthorizationsChanges(company: Company): Observable<IApiActionResult> {
    return this.http.post<IApiActionResult>(COMPANY_URL + '/save/authorizations', company);
  }

  Create(toCreate: Company): Observable<IApiActionResult> {
    return this.http.post<IApiActionResult>(COMPANY_URL + '/create', toCreate);
  }

  UpdateMileageSettings(toUpdate: Company): Observable<IApiResult<Company>> {
    return this.http.post<IApiResult<Company>>(COMPANY_URL + '/edit/mileage/settings', toUpdate);
  }

  Delete() {
    this.dialogService.ShowLoading();

    return this.http.delete<IApiActionResult>(COMPANY_URL).pipe(
      tap(() => { this.dialogService.CloseLoading(); },
        error => { this.dialogService.CloseLoading(); },
        () => { this.dialogService.CloseLoading(); }));
  }

  GetEmployees(): Observable<IApiResult<CompanyEmployeesData>> {
    return this.http.get<IApiResult<CompanyEmployeesData>>(COMPANY_URL + '/employees');
  }

  AddEmployees(emails: string[]): Observable<IApiActionResult> {
    return this.http.post<IApiActionResult>(COMPANY_URL + '/addemployees', emails);
  }

  CancelInvitation(email: string): Observable<IApiActionResult> {
    return this.http.get<IApiActionResult>(COMPANY_URL + '/invitation/delete/' + email);
  }

  GetInvitation(token: string): Observable<IApiResult<CompanyInvitation>> {
    return this.http.get<IApiResult<CompanyInvitation>>(COMPANY_URL + '/invitation/' + token);
  }

  /**
   * Return company's Rule[]
   */
  GetRules(): Observable<IApiResult<Rule[]>> {
    return this.http.get<IApiResult<Rule[]>>(`${COMPANY_URL}/rules`);
  }

  /**
   * Add the given Rule (ruleToAdd) to the refered Company (companyId)
   * Return if the rule is added
   * @param ruleToAdd
   */
  AddRule(ruleToAdd: Rule): Observable<IApiActionResult> {
    return this.http.put<IApiActionResult>(`${COMPANY_URL}/rules`, ruleToAdd);
  }

  /**
   * Update the given Rule (ruleToUpdate) to the refered Company (companyId)
   * Return if the rule is updated
   * @param ruleToUpdate
   */
  UpdateRule(ruleToUpdate: Rule): Observable<IApiActionResult> {
    return this.http.post<IApiActionResult>(`${COMPANY_URL}/rules`, ruleToUpdate);
  }

  /**
   * Delete the refered Rule (ruleToDeleteNumber) of the refered Company (companyId)
   * Return if the rule is deleted
   * @param ruleToDeleteNumber
   */
  DeleteRule(ruleToDeleteNumber: number) {
    return this.http.delete<IApiActionResult>(`${COMPANY_URL}/rules/${ruleToDeleteNumber}`);
  }

  AcceptInvitation(token: string): Observable<IApiActionResult> {
    this.dialogService.ShowLoading();
    return this.http.get<IApiActionResult>(COMPANY_URL + '/invitation/accept/' + token).pipe(
      tap(() => { this.dialogService.CloseLoading(); },
        error => { this.dialogService.CloseLoading(); },
        () => { this.dialogService.CloseLoading(); }));
  }

  KickEmployee(employee: OnUser, newManagerId?: number): Observable<IApiActionResult> {
    if (!newManagerId) {
      newManagerId = 0;
    }
    const url = COMPANY_URL + '/kick/' + employee.Id + '/' + newManagerId;
    return this.http.get<IApiActionResult>(url);
  }

  ApplySubscription(userId: number, shouldApply: boolean): Observable<IApiActionResult> {
    const url = COMPANY_URL + (shouldApply ? '/applysubscription' : '/removesubscription');
    return this.http.post<IApiActionResult>(url, userId);
  }

  ChangeEmployeeRole(companyId: number, employeeId: number, role: string): Observable<IApiActionResult> {
    return this.http.post<IApiActionResult>(`${COMPANY_URL}/changerole`,
      {
        CompanyId: companyId,
        UserId: employeeId,
        Role: role
      });
  }

  UpdateEmployee(employee: OnUser): Observable<IApiResult<OnUser>> {
    return this.http.post<IApiResult<OnUser>>(`${COMPANY_URL}/editemployee`, employee);
  }

  GetHierarchy(): Observable<IApiResult<OnUser[]>> {
    return this.http.get<IApiResult<OnUser[]>>(COMPANY_URL + '/hierarchy');
  }

  UpdateHierarchy(employeeId: number, managerId: number, companyId: number) {
    return this.http.post<IApiActionResult>(COMPANY_URL + '/hierarchy/one/' + employeeId, {
      ManagerId: managerId,
      UserId: employeeId,
      CompanyId: companyId
    });
  }

  SaveHierarchy(allUsers: OnUser[]): Observable<IApiActionResult> {
    const associations = [];
    for (const user of allUsers) {
      associations.push({
        ManagerId: user.ManagerId,
        UserId: user.Id
      });
    }
    return this.http.post<IApiActionResult>(COMPANY_URL + '/hierarchy', associations);
  }

  GetCustomFields(): Observable<IApiResult<CustomField[]>> {
    return this.http.get<IApiResult<CustomField[]>>(COMPANY_URL + '/customfields');
  }

  SaveCustomFields(fieldsToSave: CustomField[]): Observable<IApiActionResult> {
    return this.http.post<IApiActionResult>(COMPANY_URL + '/savecustomfields', fieldsToSave);
  }

  GetManagersInfos(): Observable<IApiResult<ManagerInfos[]>> {
    return this.http.get<IApiResult<ManagerInfos[]>>(`${COMPANY_URL}/managersinfos`);
  }

  SetCompanySelected(companyId?: number) {
    return this.http.get<IApiActionResult>(`${COMPANY_URL}/changecompany/${companyId}`);
  }

  GetSubscriptions(companyId: number): Observable<IApiResult<Subscription[]>> {
    return this.http.get<IApiResult<Subscription[]>>(COMPANY_URL + '/' + companyId + '/subscriptions');
  }

  GetVPConsumption(companyId: number): Observable<IApiResult<VPConsumption[]>> {
    return this.http.get<IApiResult<VPConsumption[]>>(`${COMPANY_URL}/vpConsumption/${companyId}`);
  }

  RequestDomainName(domainName: string): Observable<IApiResult<DomainName[]>> {
    return this.http.post<IApiResult<DomainName[]>>(`${COMPANY_URL}/domains`, {
      DomainName: domainName
    });
  }

  GetDomainNames(): Observable<IApiResult<DomainName[]>> {
    return this.http.get<IApiResult<DomainName[]>>(`${COMPANY_URL}/domains`);
  }


  /**
   * Return all CompanyCategories[] the refered Company (companyId)
   * Return full EmployeeCategory list
   * @param companyId: number
   */
  GetEmployeeCategories(companyId: number): Observable<IApiResult<EmployeeCategory[]>> {
    return this.http.get<IApiResult<EmployeeCategory[]>>(`${COMPANY_URL}/${companyId}/categories`);
  }

  /**
   * Add the given EmployeeCategory (categoryToAdd) to the refered Company (companyId)
   * Return full EmployeeCategory list
   * @param companyId: number
   * @param categoryToAdd: EmployeeCategory
   */
  AddEmployeeCategory(companyId: number, categoryToAdd: EmployeeCategory): Observable<IApiResult<EmployeeCategory[]>> {
    return this.http.post<IApiResult<EmployeeCategory[]>>(`${COMPANY_URL}/${companyId}/categories`, categoryToAdd);
  }

  /**
   * Update the given EmployeeCategory (updatedCategory) of the refered Company (companyId)
   * Return full EmployeeCategory list
   * @param companyId: number
   * @param updatedCategory: EmployeeCategory
   */
  UpdateEmployeeCategory(companyId: number, updatedCategory: EmployeeCategory): Observable<IApiResult<EmployeeCategory[]>> {
    return this.http.patch<IApiResult<EmployeeCategory[]>>(`${COMPANY_URL}/${companyId}/categories`, updatedCategory);
  }

  /**
   * Delete the refered EmployeeCategory (categoryIdToRemove) of the refered Company (companyId)
   * Return full EmployeeCategory list
   * @param companyId: number
   * @param categoryIdToRemove: number
   */
  DeleteEmployeeCategory(companyId: number, categoryIdToRemove: number): Observable<IApiResult<EmployeeCategory[]>> {
    return this.http.delete<IApiResult<EmployeeCategory[]>>(`${COMPANY_URL}/${companyId}/categories/${categoryIdToRemove}`);
  }

  SearchInvitableUsers(term: string): Observable<InvitedUser[]> {
    return this.http.get<IApiResult<InvitedUser[]>>(`${COMPANY_URL}/invitableusers/${term}`)
      .pipe(map(res => res.Result));
  }

  /**
   * Update the configuration for Restaurant ticket
   * @param shouldActivate: whether the company has restaurant ticket
   * @param value: The value taken in charge by the company
   */
  UpdateRestaurantTicketSettings(shouldActivate: boolean, value: number): Observable<IApiResult<Company>> {
    return this.http.post<IApiResult<Company>>(`${COMPANY_URL}/restaurant-ticket`, {
      ActivateRestaurantTicket: shouldActivate,
      RestaurantTicketValue: +value
    });
  }

  GenerateStatisticsFile(startDate: Date, endDate: Date) {
    return this.http.post<IApiActionResult>(`${COMPANY_URL}/statistics`, {
      StartDate: startDate.toISOString(),
      EndDate: endDate.toISOString()
    });
  }
}
