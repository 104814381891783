<div>
  <!-- Inputs -->
  <div class="map-inputs">
    <div class="form-group mb-0 dropdown-content map-input" style="z-index: 2;">
      <div class="input-group h-100">
        <span class="input-group-text" (click)="onClickAddMarker(1)" [ngClass]="{'color-red': isMarkingStart}">
          <nb-icon icon="fa-map-marker-alt"></nb-icon>
        </span>
        <input type="text" class="form-control h-100" placeholder="Départ..." [(ngModel)]="startPlaceName"
          (keyup)="onSearch(1)">
      </div>
      <a *ngFor="let searchResult of searchResultsStart" (click)="onSelectSearchResult(searchResult, 1)">{{
        searchResult.address.freeformAddress }} ({{ searchResult.address.countrySubdivision }})</a>
    </div>
    <div class="form-group mb-0 dropdown-content map-input" style="z-index: 1; top: 40px">
      <div class="input-group h-100">
        <span class="input-group-text" (click)="onClickAddMarker(2)" [ngClass]="{'color-red': isMarkingEnd}">
          <nb-icon icon="fa-map-marker-alt"></nb-icon>
        </span>
        <input type="text" class="form-control h-100" placeholder="Arrivé..." [(ngModel)]="endPlaceName"
          (keyup)="onSearch(2)">
      </div>
      <a *ngFor="let searchResult of searchResultsEnd" (click)="onSelectSearchResult(searchResult, 2)">{{
        searchResult.address.freeformAddress }} ({{ searchResult.address.countrySubdivision }})</a>
    </div>
  </div>
  <!---->

  <!-- Infos & Validation -->
  <div class="row infos m-0">
    <div class="col-6 d-flex justify-content-end align-items-center">
      Distance :&nbsp; <b> {{ distance ? distance * 0.001 : 0 }} </b> km
    </div>
    <div class="col-6 d-flex justify-content-start align-items-center">
      <button class="btn btn-primary" (click)="valid()">{{ 'map.btn-valid' | translate }}</button>
    </div>
  </div>

  <div id="map" class="map" [ngClass]="{'crosshair-cursor': isMarkingStart || isMarkingEnd}">
  </div>

  <!-- Popups -->
  <div id="popupRoute1" class="ol-popup popup-selected" [class.invisible]="!routesResponse"
    (click)="onClickPopupRoute(1)">
    <div id="popupContentRoute1"></div>
  </div>
</div>