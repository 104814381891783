import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StripeProduct } from '../_models/StripeProduct';
import { StripeCustomer } from '../_models/StripeCustomer';
import { StripeSubscription } from '../_models/StripeSubscription';
import { StripePrice } from '../_models/StripePrice';
import { API_V2_URL } from 'on-common/constants';
import { IApiResult } from 'on-common/_models/ApiResult';

const PAYMENT_URL = API_V2_URL + '/payments';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) {
  }

  public GetAllProducts(): Observable<IApiResult<StripeProduct[]>> {
    return this.http.get<IApiResult<StripeProduct[]>>(PAYMENT_URL + '/stripe/products');
  }

  public GetPriceDetails(priceId: string): Observable<IApiResult<StripePrice>> {
    return this.http.get<IApiResult<StripePrice>>(PAYMENT_URL + '/stripe/price/' + priceId);
  }

  public GetSessionId(planId: string, quantity: number): Observable<IApiResult<string>> {
    return this.http.post<IApiResult<string>>(PAYMENT_URL + '/stripe/licensesession/' + planId + '/' + quantity, null);
  }

  public GetStripeCustomer(): Observable<IApiResult<StripeCustomer>> {
    return this.http.get<IApiResult<StripeCustomer>>(PAYMENT_URL + '/stripe/customer');
  }

  public CreateOrUpdateStripeCustomer(customer: StripeCustomer): Observable<IApiResult<StripeCustomer>> {
    return this.http.post<IApiResult<StripeCustomer>>(PAYMENT_URL + '/stripe/customer', customer);
  }

  public GetStripeSubscription(subscriptionId: string): Observable<IApiResult<StripeSubscription>> {
    return this.http.get<IApiResult<StripeSubscription>>(PAYMENT_URL + '/stripe/subscription/' + subscriptionId);
  }

  public GetStripePortalLink(): Observable<IApiResult<string>> {
    return this.http.get<IApiResult<string>>(PAYMENT_URL + '/stripe/portalsession');
  }

  public GetUpdateProration(newPriceId: string, newQuantity: number): Observable<IApiResult<number>> {
    return this.http.post<IApiResult<number>>(PAYMENT_URL + '/stripe/proration', {
      PriceId: newPriceId,
      NbOfLicenses: newQuantity
    });
  }

  public UpdateSubscription(newPriceId: string, newQuantity: number): Observable<IApiResult<number>> {
    return this.http.post<IApiResult<number>>(PAYMENT_URL + '/stripe/subscription/update', {
      PriceId: newPriceId,
      NbOfLicenses: newQuantity
    });
  }

  public CreateNewStripeSubscription(
    paymentMethodId: string,
    priceId: string,
    nbOfLicenses: number): Observable<IApiResult<StripeSubscription>> {
    return this.http.post<IApiResult<StripeSubscription>>(PAYMENT_URL + '/stripe/subscription', {
      PaymentMethodId: paymentMethodId,
      PriceId: priceId,
      NbOfLicenses: nbOfLicenses
    });
  }

  public CreateNewVpSubscription(priceId: string) {
    return this.http.post<IApiResult<StripeSubscription>>(PAYMENT_URL + '/stripe/vpsubscription', {
      PriceId: priceId
    });
  }

  public CreateSetupIntent(): Observable<IApiResult<string>> {
    return this.http.get<IApiResult<string>>(PAYMENT_URL + '/stripe/setup');
  }
}
