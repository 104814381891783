// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  windowsLogin: true,
  applicationinsights_instrumentationkey: '19c1ba9b-07f9-4a36-b50d-bd31d8656fc0',
  stripePKey: 'pk_test_2ZtytRXHm67I1qNs1WFo3u3V00kv6Cz2iH',
  appName: 'ONexpense',
  defaultLanguage: 'fr',
  defaultCountry: 'FRA',
  defaultCurrency: 'EUR'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
