import { BannerNotification } from './../_models/BannerNotification';
import { NotificationBannerService } from './../_services/notification-banner.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SideBarService } from '../_services/side-bar.service';
import { RouterOutlet } from '@angular/router';
import { routerTransition, screenSizes } from '../router.animations';
import { AccountService } from 'on-auth/_services/account.service';

@Component({
  selector: 'on-sidebar-page',
  templateUrl: './sidebar-page.component.html',
  styleUrls: ['./sidebar-page.component.scss'],
  animations: [routerTransition]
})
export class SidebarPageComponent implements OnInit, OnDestroy {

  isSideBarOpened: boolean;
  isBannerClosed: boolean;

  sidebarSub: Subscription;
  notificationBannerSub: Subscription;
  closeNotificationBanner: Subscription;
  notification: BannerNotification = null;

  private previousPath = '';
  private previousTransition = '';


  constructor(
    private sidebarService: SideBarService,
    private notificationBannerService: NotificationBannerService,
    private accountService: AccountService
  ) { }

  ngOnInit() {
    this.accountService.loadConnectedUser();

    this.sidebarSub = this.sidebarService.isSideBarOpened.subscribe(isOpened => {
      this.isSideBarOpened = isOpened;
    });

    this.notificationBannerSub = this.notificationBannerService.notificationToDisplay.subscribe(notificationToDisplay => {
      if (notificationToDisplay) {
        this.notification = notificationToDisplay;
      }
    });
    this.closeNotificationBanner = this.notificationBannerService.closeBanner.subscribe(isClose => {
      if (isClose) {
        this.isBannerClosed = isClose;
      }
    });
  }

  ngOnDestroy() {
    this.sidebarSub.unsubscribe();
    this.notificationBannerSub.unsubscribe();
    this.closeNotificationBanner.unsubscribe();
  }

  onBannerClose(notification: BannerNotification) {
    this.notification = notification;
  }


  getPageTransition(routerOutlet: RouterOutlet) {
    const activatedRoute = routerOutlet.activatedRoute;
    const fullPath = (activatedRoute.snapshot.parent && activatedRoute.snapshot.parent.routeConfig) ?
      `${activatedRoute.snapshot.parent.routeConfig.path}/${activatedRoute.routeConfig.path}` : activatedRoute.routeConfig.path;

    if (routerOutlet.isActivated) {
      let transitionName = 'section';
      const isSame = this.previousPath === fullPath;

      const isBackward = this.previousPath.startsWith(fullPath);
      const isForward = fullPath.startsWith(this.previousPath);

      if (isSame) {
        transitionName = this.previousTransition;
      } else if (isBackward && isForward) {
        transitionName = 'initial';
      } else if (isBackward) {
        transitionName = 'backward';
      } else if (isForward) {
        transitionName = 'forward';
      }

      if (!isSame && this.previousTransition.indexOf(transitionName) > -1) {
        transitionName = transitionName === 'section' ? 'fade' : (transitionName === 'initial' ? 'section' : 'fade');
      }
      if (!isSame) {
        const container = document.getElementById('content');
        switch (container.clientWidth) {
          case screenSizes.xl:
            transitionName = `xl-${transitionName}`;
            break;
          case screenSizes.lg:
            transitionName = `lg-${transitionName}`;
            break;
          case screenSizes.md:
            transitionName = `md-${transitionName}`;
            break;
          case screenSizes.sm:
            transitionName = `sm-${transitionName}`;
            break;
          case screenSizes.xs:
            transitionName = `xs-${transitionName}`;
            break;
          default:
            transitionName = `${transitionName}`;
            break;
        }
      }

      this.previousPath = fullPath;
      this.previousTransition = transitionName;

      return transitionName;
    }

  }
}
