<on-page-title [title]="'expense.send-new-receipt' | translate" icon="fa-upload">
</on-page-title>

<p class="alert alert-warning" *ngIf="isUploading">
    <nb-icon icon="fa-exclamation-triangle"></nb-icon>
    {{'expense.send-wait-finished' | translate}}
</p>
<div class="bordered-drop">
    <image-upload (uploadFinished)="uploadFinished($event)" [maxFileSize]="4000000"
        [buttonCaption]="'expense.send-receipt-button' | translate"
        [dropBoxMessage]="'expense.drop-expense-here' | translate" [url]="imageUploadURL"
        [clearButtonCaption]="'expense.send-receipt-clear-button' | translate" partName="imageFile"
        [extensions]="['image/jpeg','image/png','application/pdf']" (uploadStateChanged)="uploadStateChanged($event)">
    </image-upload>
</div>