export class AccountingCode {
  CompanyId: number;
  ExpenseTypeId: number;
  ExpenseTypeName: string;
  Code: string;

  constructor(model?: AccountingCode) {
    if (model) {
      this.CompanyId = model.CompanyId;
      this.ExpenseTypeId = model.ExpenseTypeId;
      this.ExpenseTypeName = model.ExpenseTypeName;
      this.Code = model.Code;
    } else {
      this.CompanyId = null;
      this.ExpenseTypeId = null;
      this.ExpenseTypeName = null;
      this.Code = null;
    }
  }
}
