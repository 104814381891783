import { OnUser } from '../../_models/OnUser';

export namespace UserActions {
  export class GetCurrentUser {
    public static readonly type = `[User] load current user `;
    constructor() { }
  }

  export class GetSelectedUser {
    public static readonly type = `[User] load user by th given id`;
    constructor(public id: number) { }
  }

  export class SaveUserSettings {
    public static readonly type = `[User] load user by th given id`;
    constructor(public userToSave: OnUser) { }
  }

  export class ChangePassword {
    public static readonly type = `[User] change current user password`;
    constructor(
      public oldPassword: string,
      public newPassword: string,
      public confirmPassword: string,
    ) { }
  }

  export class DeleteAccount {
    public static readonly type = `[User] delete account of the current user `;
    constructor() { }
  }


  export class Logout {
    public static readonly type = `[User] log out from the app `;
    constructor() { }
  }

  export class ExternalLogin {
    public static readonly type = `[User] log in from external provider`;
    constructor(
      public jwtToken: string
    ) { }
  }
}
