import { Injectable } from '@angular/core';
import { Selector, Action, StateContext, State, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AccountantExportService } from './accountant-export.service';
import { AccountantExportStateModel } from './accountant-export.model';
import { AccountantExportAction } from './accountant-export.actions';
import { TranslateService } from '@ngx-translate/core';
import { AccountantExport } from '../../../company/_models/AccountantExport';
import { patch, removeItem, insertItem, updateItem } from '@ngxs/store/operators';
import { Router } from '@angular/router';
import { AccountingCode } from '../../../company/accountant/_models/AccountingCode';
import { ReportsActions } from '../reports/reports.actions';
import { BadgesAction } from '../badges/badges.action';
import { ToastService } from 'on-common/_services/toast.service';

@State<AccountantExportStateModel>({
  name: `accountantExport`,
  defaults: new AccountantExportStateModel()
})
@Injectable()
export class AccountantExportState {
  constructor(
    private accountantExportService: AccountantExportService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private router: Router,
    private store: Store,
  ) { }


  @Selector()
  static exports(state: AccountantExportStateModel) {
    return state.exports;
  }

  @Selector()
  static exportsTreated(state: AccountantExportStateModel) {
    return state.exportsTreated;
  }

  @Selector()
  static getEditingExport(state: AccountantExportStateModel) {
    return state.editingExport;
  }

  @Selector()
  static accountingCodes(state: AccountantExportStateModel) {
    return state.accountingCodes;
  }

  @Action(AccountantExportAction.LoadAccountingCodes)
  public loadAccountingCodes(ctx: StateContext<AccountantExportStateModel>, { companyId }: AccountantExportAction.LoadAccountingCodes) {
    return this.accountantExportService.GetAccountingCodes(companyId).pipe(tap(response => {
      if (response.IsSuccess) {
        ctx.patchState({
          accountingCodes: response.Result,
          shouldReloadAccountingCodes: false
        });
      } else {
        this.toastService.error(this.translateService.instant('company.accountant-export.load-accounting-codes-error'));
      }
    }, error => {
      this.toastService.error(this.translateService.instant('company.accountant-export.load-accounting-codes-error'));
    }));
  }

  @Action(AccountantExportAction.LoadAccountingCodesIfNeeded)
  public loadAccountingCodesIfNeeded(
    ctx: StateContext<AccountantExportStateModel>,
    { companyId }: AccountantExportAction.LoadAccountingCodesIfNeeded) {
    // if (ctx.getState().shouldReloadAccountingCodes) {
    // Always reload data for now
    return this.loadAccountingCodes(ctx, new AccountantExportAction.LoadAccountingCodes(companyId));
    // }
  }

  @Action(AccountantExportAction.SaveAccountingCodes)
  public saveAccountingCodes(
    ctx: StateContext<AccountantExportStateModel>,
    { companyId, accountingCodes }: AccountantExportAction.SaveAccountingCodes) {
    return this.accountantExportService.SaveAccountingCodes(companyId, accountingCodes).pipe(tap(response => {
      if (response.IsSuccess) {
        ctx.patchState({
          accountingCodes: response.Result.map((c) => new AccountingCode(c))
        });
        this.toastService.success(this.translateService.instant('company.accountant-export.save-accounting-codes-success'));
      } else {
        this.toastService.error(this.translateService.instant('company.accountant-export.save-accounting-codes-error'));
      }
    }, error => {
      this.toastService.error(this.translateService.instant('company.accountant-export.save-accounting-codes-error'));
    }));
  }

  @Action(AccountantExportAction.LoadAccountantExports)
  public loadAccountantExports(ctx: StateContext<AccountantExportStateModel>) {
    return this.accountantExportService.GetCompanyExports().pipe(tap(response => {
      if (response.IsSuccess) {
        ctx.patchState({
          exports: response.Result.map((c) => new AccountantExport(c)),
          shouldReloadExports: false
        });
      } else {
        this.toastService.error(this.translateService.instant('company.accountant-export.load-exports-error'));
      }
    }, error => {
      this.toastService.error(this.translateService.instant('company.accountant-export.load-exports-error'));
    }));
  }

  @Action(AccountantExportAction.LoadAccountantExportsIfNeeded)
  public loadAccountantExportsIfNeeded(ctx: StateContext<AccountantExportStateModel>) {
    // if (ctx.getState().shouldReloadExports) {
    // Always reload data for now
    return this.loadAccountantExports(ctx);
    // }
  }

  @Action(AccountantExportAction.LoadTreatedAccountantExports)
  public loadTreatedAccountantExports(ctx: StateContext<AccountantExportStateModel>) {
    return this.accountantExportService.GetTreatedCompanyExports().pipe(tap(response => {
      if (response.IsSuccess) {
        ctx.patchState({
          exportsTreated: response.Result.map((c) => new AccountantExport(c)),
          shouldReloadTreatedExports: false
        });
      } else {
        this.toastService.error(this.translateService.instant('company.accountant-export.load-exports-error'));
      }
    }, error => {
      this.toastService.error(this.translateService.instant('company.accountant-export.load-exports-error'));
    }));
  }

  @Action(AccountantExportAction.LoadTreatedAccountantExportsIfNeeded)
  public loadTreatedAccountantExportsIfNeeded(ctx: StateContext<AccountantExportStateModel>) {
    // if (ctx.getState().shouldReloadTreatedExports) {
    // Always reload data for now
    return this.loadTreatedAccountantExports(ctx);
    // }
  }

  @Action(AccountantExportAction.LoadAccountantExportById)
  public loadAccountantExportById(ctx: StateContext<AccountantExportStateModel>,
    { exportId }: AccountantExportAction.LoadAccountantExportById) {
    ctx.patchState({
      editingExport: null
    });
    return this.accountantExportService.GetAccountantExport(exportId).pipe(tap(response => {
      if (response.IsSuccess) {
        ctx.patchState({
          editingExport: response.Result
        });
      } else {
        this.toastService.error(this.translateService.instant('company.accountant-export.load-exports-error'));
      }
    }, error => {
      this.toastService.error(this.translateService.instant('company.accountant-export.load-exports-error'));
    }));
  }

  @Action(AccountantExportAction.CreateAccountantExport)
  public createAccountantExport(
    ctx: StateContext<AccountantExportStateModel>,
    { exportToCreate, callback }: AccountantExportAction.CreateAccountantExport) {
    // Dates
    const userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
    exportToCreate.PeriodBeginDate = new Date(new Date(exportToCreate.PeriodBeginDate).getTime() - userTimezoneOffset);
    exportToCreate.PeriodEndDate = new Date(new Date(exportToCreate.PeriodEndDate).getTime() - userTimezoneOffset);

    exportToCreate.NbOfReports = exportToCreate.Reports.length;

    return this.accountantExportService.Create(exportToCreate).pipe(tap(response => {
      if (response.IsSuccess) {
        ctx.setState(patch<AccountantExportStateModel>({
          exports: insertItem<AccountantExport>(new AccountantExport(response.Result), 0)
        }));

        this.toastService.success(
          this.translateService.instant('company.accountant-export.created-success'),
          this.translateService.instant('company.accountant-export.created-success-status'));

        if (callback) {
          callback(response.Result.Id);
        } else {
          this.router.navigate(['company', 'exports', response.Result.Id]);
        }

        // Reload badges
        this.store.dispatch(new BadgesAction.LoadBadges());

      } else {
        this.toastService.error(this.translateService.instant('company.accountant-export.created-error'));
      }
    }, error => {
      this.toastService.error(this.translateService.instant('company.accountant-export.created-error'));
    }));
  }

  @Action(AccountantExportAction.UpdateAccountantExport)
  public updateAccountantExport(
    ctx: StateContext<AccountantExportStateModel>,
    { exportToUpdate }: AccountantExportAction.UpdateAccountantExport
  ) {

    const userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
    exportToUpdate.PeriodBeginDate = new Date(new Date(exportToUpdate.PeriodBeginDate).getTime() - userTimezoneOffset);
    exportToUpdate.PeriodEndDate = new Date(new Date(exportToUpdate.PeriodEndDate).getTime() - userTimezoneOffset);

    exportToUpdate.NbOfReports = exportToUpdate.Reports.length;

    return this.accountantExportService.Update(exportToUpdate).pipe(tap(response => {
      if (response.IsSuccess) {
        ctx.setState(patch<AccountantExportStateModel>({
          exports: updateItem<AccountantExport>(exp => exp.Id === exportToUpdate.Id, new AccountantExport(response.Result))
        }));

        this.store.dispatch(new ReportsActions.MustReloadValidatedReportsForAccountant());

        this.toastService.success(
          this.translateService.instant('company.accountant-export.updated-success'),
          this.translateService.instant('company.accountant-export.updated-success-status'));
      } else {
        this.toastService.error(
          this.translateService.instant('company.accountant-export.updated-error'),
          this.translateService.instant('company.accountant-export.updated-error-status'));
      }
    }, error => {
      this.toastService.error(
        this.translateService.instant('company.accountant-export.updated-error'),
        this.translateService.instant('company.accountant-export.updated-error-status'));
    }));
  }

  @Action(AccountantExportAction.DeleteAccountantExport)
  public deleteAccountantExport(
    ctx: StateContext<AccountantExportStateModel>,
    { exportId }: AccountantExportAction.DeleteAccountantExport) {
    return this.accountantExportService.Delete(exportId).pipe(tap(response => {
      if (response.IsSuccess) {
        ctx.setState(patch<AccountantExportStateModel>({
          exports: removeItem<AccountantExport>(exp => +exp.Id === +exportId)
        }));
        this.toastService.success(
          this.translateService.instant('company.accountant-export.delete-success'));
      } else {
        this.toastService.error(
          this.translateService.instant('company.accountant-export.delete-error'));
      }
    }, error => {
      this.toastService.error(
        this.translateService.instant('company.accountant-export.delete-error'));
    }));
  }

  @Action(AccountantExportAction.ArchivedInProbativeValue)
  public archivedInProbativeValue(
    ctx: StateContext<AccountantExportStateModel>,
    { exportToArchived }: AccountantExportAction.ArchivedInProbativeValue) {
    // Dates
    const userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
    exportToArchived.PeriodBeginDate = new Date(exportToArchived.PeriodBeginDate.getTime() - userTimezoneOffset);
    exportToArchived.PeriodEndDate = new Date(exportToArchived.PeriodEndDate.getTime() - userTimezoneOffset);

    exportToArchived.NbOfReports = exportToArchived.Reports.length;

    // Update & Archive
    return this.accountantExportService.Update(exportToArchived).subscribe(response => {
      if (response.IsSuccess) {
        this.accountantExportService.ArchivedInProbativeValue(exportToArchived.Id).subscribe(res => {
          if (res.IsSuccess) {
            const newData = new AccountantExport(res.Result);

            ctx.setState(patch<AccountantExportStateModel>({
              exports: removeItem<AccountantExport>(exp => exp.Id === newData.Id),
              exportsTreated: insertItem<AccountantExport>(newData, 0),
              editingExport: newData
            }));

            this.toastService.success(this.translateService.instant('company.accountant-export.archived-success'),
              this.translateService.instant('company.accountant-export.archived'));

            // Reload badges
            this.store.dispatch(new BadgesAction.LoadBadges());
          } else {
            this.toastService.error(this.translateService.instant('company.accountant-export.archived-error'),
              this.translateService.instant('company.accountant-export.error'));
          }
        });
      } else {
        this.toastService.error(this.translateService.instant('company.accountant-export.error-save'),
          this.translateService.instant('company.accountant-export.error'));
      }
    });
  }

  @Action(AccountantExportAction.MarkAsTreated)
  public markAsTreated(
    ctx: StateContext<AccountantExportStateModel>,
    { exportToTreat }: AccountantExportAction.MarkAsTreated
  ) {
    this.accountantExportService.Update(exportToTreat).subscribe(response => {
      if (response.IsSuccess) {
        this.accountantExportService.MarkAsTreated(exportToTreat.Id).subscribe(archiveResponse => {
          if (archiveResponse.IsSuccess) {

            const newData = new AccountantExport(archiveResponse.Result);

            ctx.setState(patch<AccountantExportStateModel>({
              exports: removeItem<AccountantExport>(exp => exp.Id === newData.Id),
              exportsTreated: insertItem<AccountantExport>(newData, 0),
              editingExport: newData
            }));

            this.store.dispatch(new BadgesAction.LoadBadges());

            this.toastService.success(this.translateService.instant('company.accountant-export.treated-success'),
              this.translateService.instant('company.accountant-export.treated'));
          } else {
            this.toastService.error(this.translateService.instant('company.accountant-export.treated-error'),
              this.translateService.instant('company.accountant-export.error'));
          }
        });
      } else {
        this.toastService.error(this.translateService.instant('company.accountant-export.error-save'),
          this.translateService.instant('company.accountant-export.error'));
      }
    });
  }

  @Action(AccountantExportAction.MustReloadAccountantExports)
  public mustReloadAccountantExports(ctx: StateContext<AccountantExportStateModel>) {
    ctx.patchState({
      shouldReloadExports: true
    });
  }
}
