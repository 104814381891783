/**
 * Inspired by Nebular (https://akveo.github.io/nebular/)
 **/

import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Location } from '@angular/common';
import { MenuItem } from '../../_models/MenuItem';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'on-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() items: MenuItem[];

  currentItem: MenuItem;

  constructor(private location: Location,
              private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateItemSelected();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateItemSelected();
  }

  updateItemSelected() {
    const link = this.location.path();

    for (const item of this.items) {
      item.selected = false;

      if (item.link === link) {
        this.currentItem = item;
        this.currentItem.selected = true;
      }

      if (item.children) {
        for (const children of item.children) {
          children.selected = false;

          if (children.link === link) {
            this.currentItem = item;
            this.currentItem.selected = true;
          }
        }
      }
    }
  }

}
