import { BannerNotification } from './../../_models/BannerNotification';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'on-notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrls: ['./notification-banner.component.scss']
})
export class NotificationBannerComponent implements OnInit, OnDestroy {

  @Input() notification: BannerNotification = null;
  @Output() closeNotificationBanner = new EventEmitter<BannerNotification>();

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  closeBanner() {
    this.notification.LastClosedDate = new Date();
    this.notification.Closed = true;
    localStorage.setItem(this.notification.Title, JSON.stringify(this.notification));
    this.closeNotificationBanner.emit(this.notification);
  }
}
