import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_V2_URL } from 'on-common/constants';
import { AutocompleteResponse } from '../_models/AutocompleteResponse';
import { ItineraryResponse } from '../_models/ItineraryResponse';
import { IApiResult } from 'on-common/_models/ApiResult';

const ITINERARY_URL = API_V2_URL + '/itinerary';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(private http: HttpClient) { }

  Search(terms: string): Observable<IApiResult<AutocompleteResponse>> {
    const fullUrl = ITINERARY_URL + '/autocomplete?text=' + terms;

    return this.http.get<IApiResult<AutocompleteResponse>>(fullUrl);
  }

  GetRoute(lat1: number, long1: number, lat2: number, long2: number): Observable<IApiResult<ItineraryResponse>> {
    const fullUrl = ITINERARY_URL + `?lat1=${lat1}&long1=${long1}&lat2=${lat2}&long2=${long2}`;

    return this.http.get<IApiResult<ItineraryResponse>>(fullUrl);
  }
}
