import { IApiToken } from './../_models/ApiToken';
import { IRegisterModel } from './../_models/RegisterModel';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Observable,
  BehaviorSubject
} from 'rxjs';
import {
  tap,
  map,
  share
} from 'rxjs/operators';
import * as moment_ from 'moment';
import {
  API_V2_URL,
  TOKEN_EXPIRATION_STORAGE_KEY,
  TOKEN_STORAGE_KEY,
  TOKEN_STORAGE_KEY_CONNECTED_AS,
  USERNAME_STORAGE_KEY_CONNECTED_AS
} from 'on-common/constants';
import { OnUser } from 'on-shared/_models/OnUser';
import { IApiActionResult, IApiResult } from 'on-common/_models/ApiResult';
import { DialogService } from 'on-common/_services/dialog.service';
import { Country } from 'on-common/_models/Country';
import { Currency } from 'on-common/_models/Currency';

const moment = moment_;
const ACCOUNT_URL = API_V2_URL + '/account';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  connectedUser: BehaviorSubject<OnUser> = new BehaviorSubject<OnUser>(undefined);

  private getConnectedUser$: Observable<IApiResult<OnUser>>;

  constructor(private http: HttpClient, private dialogService: DialogService) { }

  Register(
    email: string,
    password: string,
    country: Country,
    currency: Currency,
    languageCode: string,
    invitationToken: string,
  ): Observable<IApiActionResult> {
    const registermodel: IRegisterModel = {
      UserName: email,
      Password: password,
      ConfirmPassword: password,
      CountryId: country.Id,
      CurrencyId: currency.Id,
      LanguageCode: languageCode,
      InvitationToken: invitationToken,
    };
    this.dialogService.ShowLoading('Enregistrement en cours...');
    return this.http
      .post<IApiActionResult>(`${ACCOUNT_URL}/register`, registermodel)
      .pipe(
        tap(null, null, () => {
          this.dialogService.CloseLoading();
        }),
      );
  }

  LogIn(username: string, password: string): Observable<IApiToken> {
    this.dialogService.ShowLoading('Connexion en cours...');
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return this.http
      .post<IApiToken>(
        '/Token',
        'username=' +
        username +
        '&password=' +
        encodeURIComponent(password) +
        '&grant_type=password',
        {
          headers,
        },
      )
      .pipe(
        tap(this.setSession),
        tap(
          (response) => {
            this.dialogService.CloseLoading();
            this.loadConnectedUser();
          },
          () => this.dialogService.CloseLoading(),
          () => this.dialogService.CloseLoading(),
        ),
      );
  }

  GetUser(userId?: number): Observable<IApiResult<OnUser>> {
    if (userId !== undefined && userId !== null) {
      return this.http.get<IApiResult<OnUser>>(`${ACCOUNT_URL}/${userId}`);
    } else {
      if (!this.getConnectedUser$) {
        this.getConnectedUser$ = this.http
          .get<IApiResult<OnUser>>(`${ACCOUNT_URL}/me`)
          .pipe(
            tap({
              next: (response) => {
                if (response.IsSuccess) {
                  this.connectedUser.next(response.Result);
                }
              },
              complete: () => {
                this.getConnectedUser$ = null;
              },
            }),
            share(),
          );
      }

      return this.getConnectedUser$;
    }
  }

  ForgotPassword(email: string): Observable<IApiActionResult> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<IApiActionResult>(
      `${ACCOUNT_URL}/forgotpassword`,
      JSON.stringify(email),
      {
        headers,
      },
    );
  }

  ResetPassword(
    email: string,
    token: string,
    password: string,
    confirmationPassword: string,
  ): Observable<IApiActionResult> {
    return this.http.post<IApiActionResult>(`${ACCOUNT_URL}/resetpassword`, {
      Email: email,
      Token: token,
      NewPassword: password,
      ConfirmationPassword: confirmationPassword,
    });
  }

  ChangePassword(
    oldPassword,
    newPassword,
    confirmationPassword,
  ): Observable<IApiActionResult> {
    return this.http.post<IApiActionResult>(ACCOUNT_URL + '/changepassword', {
      OldPassword: oldPassword,
      NewPassword: newPassword,
      ConfirmPassword: confirmationPassword,
    });
  }

  GetStoredToken(): Observable<IApiToken> {
    return this.http.get<string>(`${ACCOUNT_URL}/storedToken`).pipe(
      map((response) => {
        return JSON.parse(response) as IApiToken;
      }),
      tap((response) => {
        this.setSession(response);
      }),
    );
  }

  GetAvailableAuthTypes(email: string): Observable<string[]> {
    return this.http.post<string[]>(`${ACCOUNT_URL}/availableauth`, { Email: email });
  }

  public SetJwtToken(jwt: string): void {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 2);

    this.setSession({
      ExpireDate: expiryDate.toISOString(),
      access_token: jwt,
      Username: null
    });
  }

  setSession(authResult: IApiToken) {
    localStorage.setItem(TOKEN_STORAGE_KEY, authResult.access_token);
    localStorage.setItem(
      TOKEN_EXPIRATION_STORAGE_KEY,
      JSON.stringify(moment(authResult.ExpireDate).valueOf()),
    );
  }

  logout() {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    localStorage.removeItem(TOKEN_EXPIRATION_STORAGE_KEY);
    localStorage.removeItem(TOKEN_STORAGE_KEY_CONNECTED_AS);
    localStorage.removeItem(USERNAME_STORAGE_KEY_CONNECTED_AS);
  }

  public isLoggedIn() {
    return (
      localStorage.getItem(TOKEN_STORAGE_KEY) &&
      moment().isBefore(this.getExpiration())
    );
  }

  getExpiration() {
    const expiration = localStorage.getItem(TOKEN_EXPIRATION_STORAGE_KEY);
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  getToken() {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);
    return token;
  }

  passwordRecover(email: string): Observable<object> {
    return this.http.post(ACCOUNT_URL, `'${email}'`);
  }

  loadConnectedUser(): void {
    this.GetUser().subscribe();
  }

}
