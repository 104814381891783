import { Currency } from '../_models/Currency';
import { Injectable } from '@angular/core';
import { Country } from '../_models/Country';
import { API_V2_URL } from '../constants';
import { IApiResult } from '../_models/ApiResult';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

const COUNTRY_URL = API_V2_URL + '/country';
const CURRENCY_URL = API_V2_URL + '/currency';
const RATIO_URL = API_V2_URL + '/conversionrate';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  // Variables used as cache
  private countries: Country[];
  private currencies: Currency[];

  constructor(private http: HttpClient) { }


  getCountries(): Observable<Country[]> {
    if (this.countries) {
      return of(this.countries);
    } else {
      return this.http.get<IApiResult<Country[]>>(COUNTRY_URL)
        .pipe(map(response => response.Result),
          tap(countries => this.countries = countries));
    }
  }

  getCurrencies(): Observable<Currency[]> {
    if (this.currencies) {
      return of(this.currencies);
    } else {
      return this.http.get<IApiResult<Currency[]>>(CURRENCY_URL)
        .pipe(map(response => response.Result),
          tap(currencies => this.currencies = currencies));
    }
  }

  getConversionRatio(sourceCurrency: string, targetCurrency: string): Observable<IApiResult<number>> {
    return this.http.get<IApiResult<number>>(`${RATIO_URL}/${sourceCurrency}/${targetCurrency}`);
  }

  getConversionRatioAt(sourceCurrency: string, targetCurrency: string, date: Date | string): Observable<IApiResult<number>> {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    return this.http.get<IApiResult<number>>(`${RATIO_URL}/${sourceCurrency}/${targetCurrency}/${date.toISOString()}`);
  }
}
