<div class="card">
  <div class="card-body">
    <h4 class="card-title">Réinitialiser votre mot de passe</h4>
    <div *ngIf="!isLoading && email && token">
      <div class="form-group">
        <label for="">Votre e-mail :</label>
        <input type="text" [(ngModel)]="email" disabled class="form-control">
      </div>
      <div class="form-group">
        <label>Nouveau mot de passe :</label>
        <input type="password" class="form-control" [(ngModel)]="password">
      </div>
      <div class="form-group">
        <label>Confirmation du mot de passe :</label>
        <input type="password" class="form-control" [(ngModel)]="confirmation">
      </div>
      <div>
        <button (click)="resetPassword()" class="btn btn-primary">Changer mon mot de passe</button>
      </div>

    </div>
    <div *ngIf="!isLoading && (!email || !token)">
      Lien de réinitialisation du mot de passe incorrect.
    </div>
    <on-loading *ngIf="isLoading"></on-loading>
  </div>
</div>