import { AccountingPlanState } from 'on-shared/stores/accounting-plan/accounting-plan.state';
import { AccountantExpertModule } from './accountant-expert/accountant-expert.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import {
  NbMenuModule,
  NbThemeModule,
  NbSidebarModule,
  NbDialogModule,
} from '@nebular/theme';
import { SnotifireService, NgxSnotifireModule } from 'ngx-snotifire';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';

import { AppComponent } from './app.component';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpBackend,
} from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

// import ngx-translate and the http loader
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { environment } from '../environments/environment';
import { NgxsModule } from '@ngxs/store';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { StatisticState } from './shared/stores/statistic/statistic.state';
import { ReportsState } from './shared/stores/reports/reports.state';
import { ExpenseState } from './shared/stores/expense/expense.state';
import { AccountantExpertState } from './shared/stores/accountant-expert/accountant-expert.state';
import { AccountantExportState } from './shared/stores/accountant-export/accountant-export.state';
import { CompanyState } from './shared/stores/company/company.state';
import { UserState } from './shared/stores/user/user.state';
import { BadgesState } from './shared/stores/badges/badges.state';
import { VehiclesState } from './shared/stores/vehicles/vehicles.state';

import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { SubscriptionState } from './shared/stores/subscription/subscription.state';
import { LANGUAGE_STORAGE_KEY } from 'on-common/constants';
import { OnLayoutModule } from 'on-layout/on-layout.module';
import { HttpTokenInterceptorService } from 'on-auth/_services/http-token-interceptor.service';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { ConnectedAsStatusComponent } from './accountant-expert/_shared/connected-as-status/connected-as-status.component';
import { StatisticsExportPageComponent } from './statistics/statistics-export-page/statistics-export-page.component';
import { MissingTranslationDebugHandler } from './locale/MissingTranslationDebugHandler';

export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector,
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null),
      );
      locationInitialized.then(() => {
        translate.setDefaultLang('fr');

        let langToSet = localStorage.getItem(LANGUAGE_STORAGE_KEY);
        if (!langToSet) {
          langToSet = 'fr';
        }

        translate.use(langToSet).subscribe({
          complete: () => resolve(null),
        });
      });
    });
}

@NgModule({
  declarations: [AppComponent, ConnectedAsStatusComponent, StatisticsExportPageComponent],
  imports: [
    AppRoutingModule,
    SharedModule,
    OnLayoutModule,
    NbDialogModule.forRoot(),

    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpBackend]
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: MissingTranslationDebugHandler,
        },
        useDefaultLang: true,
        isolate: true
      }),

    NgxSnotifireModule,
    NbThemeModule.forRoot({ name: 'onexpense' }),
    NbMenuModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbDialogModule.forRoot({ context: true }),
    AccountantExpertModule,

    NgxsModule.forRoot([], {
      developmentMode: !environment.production,
    }),
    NgxsModule.forFeature([
      // add store states
      StatisticState,
      ReportsState,
      ExpenseState,
      AccountantExpertState,
      AccountantExportState,
      AccountingPlanState,
      CompanyState,
      UserState,
      BadgesState,
      SubscriptionState,
      VehiclesState,
    ]),
    NgxsResetPluginModule.forRoot(),
    // Must be last
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production })
  ],
  providers: [
    SnotifireService,
    NgbActiveModal,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

// AoT requires an exported function for factories
export function HttpLoaderFactory(_httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(_httpBackend, ['/assets/i18n/', '/assets/i18n/extensions/']);
}
