import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { BadgesStateModel } from './badges.model';
import { BadgesService } from './badges.service';
import { BadgesAction } from './badges.action';
import { tap } from 'rxjs/operators';
import { BadgesData } from '../../_models/BadgesData';
import { timer, Subscription } from 'rxjs';
import { ExpenseAction } from '../expense/expense.actions';
import { ReportsActions } from '../reports/reports.actions';
import { AccountantExportAction } from '../accountant-export/accountant-export.actions';

@State<BadgesStateModel>({
  name: 'badges',
  defaults: new BadgesStateModel()
})
@Injectable()
export class BadgesState {

  reloadSubscription: Subscription;

  constructor(
    private badgesService: BadgesService,
    private store: Store
  ) { }

  @Selector()
  static badgesData(state: BadgesStateModel) {
    return state.badgesData;
  }

  @Action(BadgesAction.LoadBadges)
  public loadBadges(ctx: StateContext<BadgesStateModel>) {
    return this.badgesService.GetBadgesData().pipe(tap(response => {
      if (response.IsSuccess) {
        const currentData = ctx.getState().badgesData;

        if (!this.equals(currentData, response.Result)) {
          ctx.patchState({
            badgesData: new BadgesData(response.Result)
          });
        }
      } else {
        // TODO : Error message ?
      }
    }, error => {
      // TODO : Error message ?
    }));
  }

  @Action(BadgesAction.AutoReloadingBadges)
  public autoReloadingBadges(ctx: StateContext<BadgesStateModel>) {

    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe();
      this.reloadSubscription = null;
    }

    const time = ctx.getState().reloadTime;

    const source = timer(0, time * 1000);
    this.reloadSubscription = source.subscribe(() => {
      if (document.hasFocus()) {
        this.store.dispatch(new BadgesAction.LoadBadges());
      }
    });
  }

  @Action(BadgesAction.StopReloadingBadges)
  public stopReloadingBadges(ctx: StateContext<BadgesStateModel>) {
    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe();
    }
  }

  private equals(item1: BadgesData, item2: BadgesData): boolean {
    if (!item1 && !item2) {
      return true;
    }

    if (!item1 && item2) {
      return false;
    }

    if (item1 && !item2) {
      return false;
    }

    let isEquals = true;

    if (item1.ExpensesToBeVerified !== item2.ExpensesToBeVerified) {
      isEquals = false;
      this.store.dispatch(new ExpenseAction.MustReloadExpenses());
    }
    if (item1.ReportsRefused !== item2.ReportsRefused) {
      isEquals = false;
      this.store.dispatch(new ReportsActions.MustReloadReports());
    }
    if (item1.ReportsToSign !== item2.ReportsToSign) {
      isEquals = false;
      this.store.dispatch(new ReportsActions.MustReloadReportsToSign());
    }
    if (item1.ReportsToBeExported !== item2.ReportsToBeExported) {
      isEquals = false;
      this.store.dispatch(new ReportsActions.MustReloadValidatedReportsForAccountant());
    }
    if (item1.AccountantExportsNotTreated !== item2.AccountantExportsNotTreated) {
      isEquals = false;
      this.store.dispatch(new AccountantExportAction.MustReloadAccountantExports());
    }

    return isEquals;
  }
}
