<div class="row">
  <div class="col-12">
    <form [formGroup]="loginForm">

      <div class="row">
        <div class="col input-group">
          <input placeholder="Email" type="text" id="username" class="form-control" formControlName="username"
            (change)="resetPasswordField()">
          <div class="invalid-feedback"
            *ngIf="!loginForm.controls['username'].valid && (loginForm.controls['username'].touched)">
            <span [hidden]="!loginForm.controls['username'].errors.required">
              Nom d'utilisateur requis
            </span>
            <span [hidden]="!loginForm.controls['username'].errors.email">
              Email incorrect
            </span>
          </div>
        </div>
      </div>
      <on-loading *ngIf="isLoadingAuthTypes"></on-loading>
      <div class="row password-row" *ngIf="showPassword">
        <div class="col-12 input-group">
          <input placeholder="Mot de passe" type="password" id="password" class="form-control"
            formControlName="password">
          <div class="invalid-feedback"
            *ngIf="!loginForm.controls['password'].valid && (loginForm.controls['password'].touched)">
            <div [hidden]="!loginForm.controls['password'].errors.required">
              Mot de passe requis !
            </div>
          </div>
        </div>
        <div class="col text-center">
          <a class="forgotten-password-link" [routerLink]="['/lostpassword']">Mot de passe oublié ?</a>
        </div>
      </div>

      <div class="row" *ngIf="showMicrosoft">
        <div class="col">
          <br>
          <a class="microsoft-button"
            [href]="'/api/v2/account/connectmicrosoft?login='+loginForm.controls['username'].value">
            <img src="/assets/images/ms-logo.png" />
            Se connecter avec un compte Microsoft</a>
        </div>
      </div>

      <div class="row" *ngIf="showPassword || !showMicrosoft">
        <div class="w-100"></div>
        <div class="col text-center">
          <br>
          <button class="btn btn-primary" (click)="Login()">Se connecter</button>
        </div>
      </div>
    </form>
  </div>
</div>
<br>
<div class="row justify-content-center" *ngIf="!showMicrosoft">
  <div class="col text-center">
    <span>Pas encore de compte ?</span>
  </div>
  <div class="w-100"></div>
  <div class="col text-center">
    <button class="btn" routerLink="/register" [queryParams]="route.snapshot.queryParams">Créer un compte</button>
  </div>
</div>