import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompanyService } from 'on-shared/stores/company/company.service';
import { InvitedUser } from 'onexpense/expense/_models/InvitedUser';
import { asyncScheduler, concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'on-select-employees',
  templateUrl: './select-employees.component.html',
  styleUrls: ['./select-employees.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectEmployeesComponent),
    multi: true
  }]
})
export class SelectEmployeesComponent implements OnInit, ControlValueAccessor {

  invitees$: Observable<InvitedUser[]>;
  loading = false;
  inviteeInput$ = new Subject<string>();

  selectedInvitees: InvitedUser[] = [];

  isDisabled = false;

  onChange: (selectedEmployees: InvitedUser[]) => void;
  onTouch: () => void;

  constructor(private companyService: CompanyService) {
  }

  writeValue(obj: any): void {
    this.selectedInvitees = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngOnInit(): void {
    this.loadInvitees();
  }

  trackByFn(item: InvitedUser) {
    return item.Id;
  }

  selectionChanged() {
    if (this.onChange) {
      this.onChange(this.selectedInvitees);
    }
  }

  private loadInvitees() {
    this.invitees$ = concat(
      of([]),
      this.inviteeInput$.pipe(
        distinctUntilChanged(),
        throttleTime(600, asyncScheduler, { leading: true, trailing: true }),
        tap(() => this.loading = true),
        switchMap(term => this.companyService.SearchInvitableUsers(term).pipe(
          catchError(() => of([])),
          tap(() => this.loading = false)
        ))
      )
    )
  }
}
