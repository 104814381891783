<div class="row">
  <div class="col" *ngIf="!minimalist">
    <div class="row">
      <div class="col-sm-12 action-buttons-section">
        <div class="row justify-content-between">
          <div class="col">
            <on-action-buttons [actions]="leftActions"></on-action-buttons>
          </div>
          <div class="col d-flex justify-content-center">
            <on-action-buttons [actions]="centerActions"></on-action-buttons>
          </div>
          <div class="col d-flex justify-content-end">
            <on-action-buttons [actions]="rightActions"></on-action-buttons>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12" style="color: teal" *ngIf='!minimalist && imgAnalized'
    [innerHTML]="'expense.images-analized' | translate: {number: imgAnalized}">

  </div>
  <!--Expenses grid-->
  <div class="col-12" [hidden]="!expenses || !expenses.length">
    <div *ngIf="foundDuplicates" class="alert alert-info">
      <p>
        <nb-icon icon="fa-info-circle"></nb-icon>
        {{'expense.duplicates-found' | translate}}
      </p>
      <p class="text-center" *ngIf="!displayDuplicates"><button (click)="showDuplicatesColors()"
          class="btn btn-secondary">{{"expense.show-duplicates" | translate}}</button></p>
      <p class="text-center" *ngIf="displayDuplicates"><button (click)="showDuplicatesColors()"
          class="btn btn-secondary">{{"expense.hide-duplicates" | translate}}</button></p>
    </div>
    <div class="row justify-content-end align-items-center" *ngIf="canAddColumns && additionalColumns?.length || gridStateKey">
      <div class="col-4">
        <ng-select [items]="additionalColumns" groupBy="Type" [multiple]="true" [closeOnSelect]="false"
          [hideSelected]="true" bindLabel="Name" [placeholder]="'table.display-more-columns' | translate"
          appendTo="body" (add)="columnAdded($event)" (remove)="columnRemoved($event.Name)" (clear)="columnCleared()"
          [(ngModel)]="selectedAdditionalColumns">
        </ng-select>
      </div>
      <div class="col-auto" *ngIf="gridStateKey">
        <button class="btn btn-sm btn-outline-dark" (click)="resetGridState()">
          {{'expense.grid-reset' | translate}}
        </button>
      </div>
    </div>
    <!-- <p-table styleClass="p-datatable-sm" #expenseTable selectionMode="multiple" [rowHover]="true" [value]="expenses"
      [paginator]="showPagination" [rowsPerPageOptions]="[10,25,50,100]" [rows]="25"
      [globalFilterFields]="['ExpenseType.Name','Emitter','Comments']">
      <ng-template pTemplate="caption" *ngIf="!minimalist">
        <div class="flex">
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="expenseTable.filterGlobal($event.target.value, 'contains')"
              placeholder="Search keyword" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 40pt" *ngIf="canSelect">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th style="min-width: 150pt" pSortableColumn="ExpenseType.Name">
            <div class="flex justify-content-center align-items-center">
              {{'expense.expense-type' | translate}}
              <p-sortIcon field="ExpenseType.Name"></p-sortIcon>
              <p-columnFilter type="text" field="ExpenseType.Name" display="menu"></p-columnFilter>
            </div>
          </th>
          <th style="min-width: 100pt" pSortableColumn="GlobalAmount.Ttc">
            {{'expense.total' | translate}}
            <p-sortIcon field="GlobalAmount.Ttc"></p-sortIcon>
            <p-columnFilter type="numeric" field="GlobalAmount.Ttc" display="menu"></p-columnFilter>
          </th>
          <th style="min-width: 100pt" pSortableColumn="DateOnExpense">
            {{'expense.date' | translate}}
            <p-sortIcon field="DateOnExpense"></p-sortIcon>
            <p-columnFilter type="date" field="DateOnExpense" display="menu"></p-columnFilter>
          </th>
          <th style="min-width: 100pt">
            {{'expense.emitter' | translate}}
            <p-columnFilter type="text" field="Emitter" display="menu" matchMode="contains"></p-columnFilter>
          </th>
          <th style="min-width: 150pt" *ngIf="!minimalist">{{'expense.comments' | translate}}
            <p-columnFilter type="text" field="Comments" display="menu" matchMode="contains"></p-columnFilter>
          </th>
          <th style="min-width: 80pt" pSortableColumn="IsValidated"
            *ngIf="!minimalist && this.report && this.report.ManagerId">
            {{'expense.status' | translate}}</th>
          <th style="min-width: 80pt" pSortableColumn="ExpenseState.Code"
            *ngIf="!minimalist && !(this.report && this.report.ManagerId)">
            {{'expense.verified' | translate}}
            <p-sortIcon field="ExpenseState.Code"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-expense>
        <tr class="p-selectable-row" (onRowSelect)="openEdit($event.Id)" (mouseover)="showImage($event, expense)"
          (mouseleave)="hideImage(expense)">
          <td *ngIf="canSelect">
            <p-tableCheckbox [value]="expense"></p-tableCheckbox>
          </td>
          <td>
            <div class="icon-type">
              <nb-icon [icon]="'fa-' + expense.ExpenseType.Icon"></nb-icon>
            </div> {{'db.expense-types.' + expense.ExpenseType.Code | translate}}
          </td>
          <td>
            {{ expense.GlobalAmount.Ttc | currency:expense.Currency.ISOCode}}
          </td>
          <td>{{expense.DateOnExpense | date:'dd/MM/yyyy'}}</td>
          <td>{{expense.Emitter}}</td>
          <td *ngIf="!minimalist">{{expense.Comments}}</td>
          <td *ngIf="!minimalist && this.report && this.report.ManagerId">
            <span *ngIf="expense.IsValidated === false"
              [nbTooltip]="'expense.reject-tooltip' | translate: { comments : expense.ValidationComment }"
              nbTooltipStatus="danger" nbTooltipPlacement="right">
              <nb-icon icon="fa-times" class="icon-danger"></nb-icon>
            </span>

            <span *ngIf="expense.IsValidated === true" [nbTooltip]="'expense.validated' | translate"
              nbTooltipStatus="success" nbTooltipPlacement="right">
              <nb-icon icon="fa-check" class="icon-success"></nb-icon>
            </span>

            <span *ngIf="expense.IsValidated === null" [nbTooltip]="'expense.not-verified' | translate"
              nbTooltipStatus="warning" nbTooltipPlacement="right">
              <nb-icon icon="fa-question" class="icon-warning"></nb-icon>
            </span>
          </td>
          <td *ngIf="!minimalist && !(this.report && this.report.ManagerId)">
            <div class="flex align-content-center justify-content-center">
              <span class="text-success" *ngIf="expense.ExpenseState.Code === 'VERIF'">
                <nb-icon size="2x" icon="fa-eye"></nb-icon>
              </span>
              <span class="text-warning" *ngIf="expense.ExpenseState.Code === 'DONE'">
                <nb-icon size="2x" icon="fa-eye-slash"></nb-icon>
              </span>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table> -->
    <ag-grid-angular (cellMouseOver)="showImage($any($event.event), $event.data)" (cellMouseOut)="hideImage($event)"
      #agGridExpenses class="ag-theme-material" (stateUpdated)="gridStateUpdated($event)">
    </ag-grid-angular>
    <div [style.top]="imageHoverTop + 'px'" [style.left]="imageHoverLeft + 'px'" class="thumbnail text-center"
      [class.hidden]="!shouldShowImage">
      <img *ngIf="imgUrl" [src]="imgUrl" class="thumb-image">
      <div *ngIf="!imgUrl">
        <h6>{{'expense.no-image' | translate}}</h6><br>
        <nb-icon size="4x" icon="fa-file-image"></nb-icon>
      </div>
    </div>
  </div>
</div>

<on-empty-list *ngIf="expenses && !expenses.length"></on-empty-list>