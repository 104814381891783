import { Expense } from 'on-shared/_models/Expense';
import { TranslateService } from '@ngx-translate/core';
import { Report } from 'on-shared/_models/Report';
import { ExpenseManagerValidationGridcellComponent } from './../grid-cell/expense-manager-validation-gridcell/expense-manager-validation-gridcell.component';
import { DateGridcellComponent } from 'on-shared/grid-cell/date-gridcell/date-gridcell.component';
import { TotalGridcellComponent } from 'on-shared/grid-cell/total-gridcell/total-gridcell.component';
import { VerifGridcellComponent } from './../grid-cell/verif-gridcell/verif-gridcell.component';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  NgZone,
  HostListener,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { formatDate } from '@angular/common';
import {
  CellMouseOutEvent,
  ColDef,
  RowClickedEvent,
  StateUpdatedEvent
} from 'ag-grid-community';
import { EditPageComponent } from '../../my-expenses-page/edit-page/edit-page.component';
import { ExpenseTypeGridCellComponent } from '../grid-cell/expense-type-grid-cell/expense-type-grid-cell.component';
import { ExpenseType } from 'on-shared/_models/ExpenseType';
import { ActionButton } from 'on-common/_models/ActionButton';
import { AccountService } from 'on-auth/_services/account.service';
import { AGGRID_TRANSLATIONS } from 'on-common/constants';

interface AdditionalColumn {
  Name: string;
  Type: string;
}

@Component({
  selector: 'on-expense-list',
  templateUrl: './expense-list.component.html',
  styleUrls: ['./expense-list.component.scss'],
})
export class ExpenseListComponent implements OnInit, OnChanges {
  @Input() expenses: Expense[];
  @Input() showPagination = true;
  @Input() pageSize = 20;
  @Input() leftActions: ActionButton[] = [];
  @Input() centerActions: ActionButton[] = [];
  @Input() rightActions: ActionButton[] = [];
  @Input() canSelect = true;
  @Input() canEdit = true;
  @Input() minimalist = false;
  @Input() canAddColumns = false;
  @Input() showDuplicates = true;

  @Input() report?: Report;

  @Input() gridStateKey: string | null = null;

  @Output() changesDone = new EventEmitter();

  allExpensesSelected = false;
  displayMode: 'list' | 'thumb' = 'list';

  imgAnalized = 0;

  imgUrl: string;
  shouldShowImage = false;
  imageHoverTop = 0;
  imageHoverLeft = 0;

  additionalColumns: AdditionalColumn[] = [];
  selectedAdditionalColumns: AdditionalColumn[] = [];

  expenseDuplicateNumber: { [key: number]: number } = {};
  foundDuplicates = false;
  displayDuplicates = false;

  colors = ['#cedde2', '#ffb2ae', '#b4ecb4', '#ffd394', '#fefec8'];

  // Grid Expenses
  @ViewChild('agGridExpenses', { static: true }) gridExpenses: AgGridAngular;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const timeout = setTimeout((_) => {
      this.gridExpenses.api.sizeColumnsToFit();
      clearTimeout(timeout);
    }, 200);
  }

  constructor(
    private modalService: NgbModal,
    public accountService: AccountService,
    private zone: NgZone,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    if (this.expenses) {
      this.kickImgAnalized();
      this.detectDuplicates();
    }

    this.createAdditionalColumns();

    const columnDefsExpenses: ColDef[] = [
      {
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        headerCheckboxSelectionCurrentPageOnly: true,
        colId: 'selectionCol',
        width: 30,
        lockVisible: true,
        resizable: false,
        suppressMovable: true,
        pinned: 'left',
        singleClickEdit: false
      },
      {
        headerName: 'D',
        valueGetter: function (params) {
          return params.context.parent.expenseDuplicateNumber[params.data.Id];
        },
        hide: !this.displayDuplicates,
        sortable: true,
        colId: 'duplicateCol',
        cellClass: 'd-flex align-items-center justify-content-center',
        width: 85,
        cellStyle: function (params) {
          const parent = params.context.parent as ExpenseListComponent;
          if (!parent.expenseDuplicateNumber[params.data.Id])
            return {};

          return {
            backgroundColor: parent.colors[parent.expenseDuplicateNumber[params.data.Id] % parent.colors.length],
          };
        },
        comparator: (a, b, nodeA, nodeB, isInverted) => {
          if (a === b) {
            return 0;
          }
          // for null
          else if (!a) {
            return isInverted ? -1 : 1;
          }
          else if (!b) {
            return isInverted ? 1 : -1;
          }
          else {
            return +a - +b;
          }
        }
      },
      {
        headerName: this.translate.instant('expense.expense-type'),
        field: 'ExpenseType',
        hide: this.minimalist,
        sortable: true,
        filter: 'agTextColumnFilter',
        resizable: true,
        width: 125,
        filterValueGetter: params => {
          return this.translate.instant('db.expense-types.' + params.data.ExpenseType.Code);
        },
        comparator: (typeA: ExpenseType, typeB: ExpenseType) => {
          const textA = this.translate.instant('db.expense-types.' + typeA.Code);
          const textB = this.translate.instant('db.expense-types.' + typeB.Code);

          if (textA < textB) {
            return 1;
          } else if (textA === textB) {
            return 0;
          } else {
            return -1;
          }
        },
        cellRenderer: ExpenseTypeGridCellComponent,
      },
      {
        headerName: this.translate.instant('expense.total'),
        field: 'GlobalAmount.Ttc',
        cellRenderer: TotalGridcellComponent,
        cellRendererParams: {
          for: 'expenses',
        },
        sortable: true,
        filter: 'agNumberColumnFilter',
        resizable: true,
        cellClass: 'd-flex align-items-center justify-content-end',
        width: 90,
      },
      {
        headerName: this.translate.instant('expense.date'),
        field: 'DateOnExpense',
        cellRenderer: DateGridcellComponent,
        cellRendererParams: {
          for: 'expenses',
        },
        sortable: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          browserDatePicker: true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            const dateAsString = formatDate(cellValue, 'dd/MM/yyyy', 'fr');

            if (dateAsString == null) {
              return 0;
            }

            const dateParts = dateAsString.split('/');
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(day, month, year);

            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
        },
        resizable: true,
        cellClass: 'd-flex align-items-center justify-content-end',
        width: 60
      },
      {
        headerName: this.translate.instant('expense.emitter'),
        field: 'Emitter',
        sortable: true,
        filter: 'agTextColumnFilter',
        resizable: true,
        tooltipField: 'Emitter',
      },
      {
        headerName: this.translate.instant('expense.comments'),
        field: 'Comments',
        hide: this.minimalist,
        sortable: true,
        filter: 'agTextColumnFilter',
        resizable: true,
        tooltipField: 'Comments',
      },
      {
        headerName:
          this.report && this.report.ManagerId
            ? this.translate.instant('expense.status')
            : this.translate.instant('expense.verified'),
        field: 'ExpenseState.Name',
        hide: this.minimalist,
        cellRenderer:
          this.report && this.report.ManagerId
            ? ExpenseManagerValidationGridcellComponent
            : VerifGridcellComponent,
        sortable: true,
        resizable: true,
        cellClass: 'd-flex align-items-center justify-content-center',
        width: 85
      },
      ...this.additionalColumns.map(c => {
        return this.generateAdditionalColDef(c, false)
      })
    ];

    const initialState = this.gridStateKey != null ? localStorage.getItem(this.gridStateKey) : null;
    const hasInitialState = initialState !== null;

    // Grid Expenses Options
    this.gridExpenses.gridOptions = {
      columnDefs: columnDefsExpenses,

      rowData: this.expenses,

      rowSelection: 'multiple',
      // floatingFilter: true,
      domLayout: 'autoHeight',

      pagination: this.showPagination,
      paginationPageSize: this.pageSize, // Nb items displayed per page - default
      paginationPageSizeSelector: [10, 20, 50, 100],

      rowHeight: 38,

      suppressCellFocus: true,
      suppressRowClickSelection: true,

      onRowClicked: this.onRowClicked,

      onGridReady: function (params) {
        params.api.sizeColumnsToFit();

        this.context.parent.createAdditionalColumns();
        this.selectedAdditionalColumns = this.context.parent.additionalColumns.filter(addCol => this.context.parent.gridExpenses.api.getColumn(addCol.Name).isVisible());
      },

      onRowDataUpdated: function (params) {
        this.context.parent.detectDuplicates();
      },

      // For get 'this' in child component
      context: { parent: this },

      initialState: hasInitialState ? JSON.parse(initialState) : null,

      // Translate
      localeText: AGGRID_TRANSLATIONS,
    };

    this.gridExpenses.defaultColDef = {
      cellClass: 'd-flex align-items-center',
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.gridExpenses.api &&
      changes.expenses &&
      this.expenses
    ) {
      this.kickImgAnalized();
      this.gridExpenses.api.setGridOption('rowData', this.expenses);

      this.createAdditionalColumns();
      if (this.additionalColumns?.length) {
        this.selectedAdditionalColumns = this.additionalColumns.filter(addCol => this.gridExpenses.api.getColumn(addCol.Name).isVisible());
      } else {
        this.selectedAdditionalColumns = [];
      }

      this.detectDuplicates();
    }
  }

  onRowClicked(event: RowClickedEvent) {
    event.context.parent.openEdit(event.data.Id, event.rowIndex);
  }

  setData(data: any) {
    this.gridExpenses.api.setRowData(data);
  }

  checkAllExpenses() {
    for (const exp of this.expenses) {
      exp.IsSelected = this.allExpensesSelected;
    }
  }

  openEdit(expenseId?: number, rowIndex?: number) {
    if (!this.canEdit) {
      return;
    }
    const rowModel = this.gridExpenses.api.getModel();
    const modalRef = this.modalService.open(EditPageComponent, {
      size: 'lg',
      windowClass: 'full-width',
    });
    modalRef.componentInstance.report = this.report;
    modalRef.result
      .then((result) => {
        this.changesDone.emit();
      })
      .catch(() => {
        this.changesDone.emit();
      });
    modalRef.componentInstance.expenseId = expenseId;

    modalRef.componentInstance.editionFinished.subscribe((shouldContinue) => {
      if (rowIndex < rowModel.getRowCount() - 1) {
        expenseId = rowModel.getRow(++rowIndex).data.Id;
      } else {
        expenseId = 0;
      }
      if (shouldContinue && expenseId > 0) {
        modalRef.componentInstance.expenseId = expenseId;
        modalRef.componentInstance.loadExpense();
      } else {
        this.changesDone.emit();
        modalRef.close();
      }
    });
  }

  kickImgAnalized() {
    this.imgAnalized = 0;
    for (const exp of this.expenses) {
      if (exp.ExpenseState.Code === 'ACQ') {
        this.imgAnalized++;
      }
    }
    this.expenses = this.expenses.filter(
      (exp) => exp.ExpenseState.Code !== 'ACQ',
    );
  }

  setPaginationSize(pageSize: number) {
    this.pageSize = pageSize;
    this.gridExpenses.api.setGridOption('paginationPageSize', pageSize);
    this.gridExpenses.api.paginationGoToFirstPage(); // Go to first page
  }

  getSelectedRows(): Expense[] {
    const selectedNodes = this.gridExpenses.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    return selectedData;
  }

  getUnselectedRows() {
    const selectedRows = this.getSelectedRows();
    const unselectedRows: Expense[] = [];
    for (const data of this.expenses) {
      let isSelected = false;
      for (const tmp of selectedRows) {
        if (data.Id === tmp.Id) {
          isSelected = true;
        }
      }
      if (!isSelected) {
        unselectedRows.push(data);
      }
    }
    return unselectedRows;
  }

  getSelectedRowsIDs() {
    const selectedNodes = this.gridExpenses.api.getSelectedNodes();
    const selectedIds = selectedNodes.map((node) => node.data.Id);
    return selectedIds;
  }

  showImage($event: MouseEvent, expense: Expense) {
    this.zone.run(() => {
      this.shouldShowImage = true;
      this.imgUrl = expense.ThumbUrl;
      this.imageHoverLeft = window.innerWidth / 2 - 800;
      if (this.imageHoverLeft <= 100) {
        this.imageHoverLeft = 100;
      }
      this.imageHoverTop = $event.clientY - 100;
    });
  }

  hideImage($event: CellMouseOutEvent) {
    this.zone.run(() => {
      this.shouldShowImage = false;
    });
  }

  createAdditionalColumns() {
    if (!this.canAddColumns) {
      return;
    }

    if (this.expenses && this.expenses.length > 0 && this.expenses[0].DynamicFieldsValues) {
      this.additionalColumns = [];
      for (const field of this.expenses[0].DynamicFieldsValues) {
        this.additionalColumns.push({
          Name: field.Field.Name,
          Type: field.Field.ExpenseType?.Name || this.translate.instant('expense.all-expense-type')
        } as AdditionalColumn);
      }

      // Reorder
      const tmp = [];
      if (this.additionalColumns.length > 0) {
        for (const col of this.additionalColumns) {
          if (col.Type === this.translate.instant('expense.all-expense-type')) {
            tmp.push(col);
          } else {
            tmp.splice(0, 0, col);
          }
        }
      }
      this.additionalColumns = tmp;
    }
  }

  columnAdded(event: AdditionalColumn) {
    this.gridExpenses.api.setColumnVisible(event.Name, true);
    this.gridExpenses.api.sizeColumnsToFit();
  }

  generateAdditionalColDef(event: AdditionalColumn, visible: boolean) {
    return {
      colId: event.Name,
      headerName: event.Name,
      valueGetter: params => {
        const expense = params.data as Expense;

        if (event.Type === this.translate.instant('expense.all-expense-type')) {
          for (const field of expense.DynamicFieldsValues) {
            if (field.Field.Name === event.Name && !field.Field.ExpenseType) {
              return field.Value;
            }
          }
        }

        if (expense.ExpenseType.Name === event.Type) {
          for (const field of expense.DynamicFieldsValues) {
            if (field.Field.Name === event.Name) {
              return field.Value;
            }
          }
        }

        return null;
      },
      sortable: true,
      resizable: true,
      width: 120,
      filter: 'agTextColumnFilter',
      hide: !visible
    } as ColDef
  }

  columnRemoved(name) {
    this.gridExpenses.api.setColumnVisible(name, false);
    this.gridExpenses.api.sizeColumnsToFit();
  }

  columnCleared() {
    for (const addCol of this.selectedAdditionalColumns) {
      this.columnRemoved(addCol.Name);
    }
  }

  detectDuplicates() {
    this.foundDuplicates = false;
    if (!this.showDuplicates)
      return;

    const duplicationGroups: { [key: string]: { index: number, expenses: Expense[] } } = {};

    let duplicateIndex = 1;
    for (const expense of this.expenses) {
      const group = expense.GlobalAmount?.Ttc + '-' + expense.DateOnExpense.toDateString() + expense.ExpenseType.Id;
      if (!duplicationGroups[group]) {
        duplicationGroups[group] = {
          expenses: [],
          index: null
        };
      } else if (duplicationGroups[group] && !duplicationGroups[group].index) {
        duplicationGroups[group].index = duplicateIndex++;
      }

      duplicationGroups[group].expenses.push(expense);
    }
    this.expenseDuplicateNumber = {};
    for (const group in duplicationGroups) {
      if (duplicationGroups[group].expenses.length > 1) {
        this.foundDuplicates = true;
        for (const expense of duplicationGroups[group].expenses) {
          this.expenseDuplicateNumber[expense.Id] = duplicationGroups[group].index;
        }
      }
    }

    if (this.displayDuplicates) {
      this.gridExpenses.api.redrawRows();
    }
  }

  showDuplicatesColors() {
    this.displayDuplicates = !this.displayDuplicates;
    this.gridExpenses.api.setColumnVisible('duplicateCol', this.displayDuplicates);
    if (this.displayDuplicates) {
      this.gridExpenses.api.applyColumnState({
        state: [
          {
            colId: 'duplicateCol',
            sort: 'asc'
          }
        ]
      });
    } else {
      this.gridExpenses.api.applyColumnState({
        state: []
      });
    }
    this.gridExpenses.api.sizeColumnsToFit();
  }

  gridStateUpdated(event: StateUpdatedEvent) {
    if (this.gridStateKey) {
      localStorage.setItem(this.gridStateKey, JSON.stringify(event.state));
    }

    this.selectedAdditionalColumns = this.additionalColumns.filter(addCol => !event.state.columnVisibility.hiddenColIds.includes(addCol.Name));
  }

  resetGridState() {
    localStorage.removeItem(this.gridStateKey);
    this.gridExpenses.api.resetColumnState();
    this.gridExpenses.api.setFilterModel(null);
    this.gridExpenses.api.sizeColumnsToFit();
    this.selectedAdditionalColumns = [];
  }
}
