export enum CurrencyGroupEnum {
  PRIMARY = 'Monaies principales',
  LIST = 'Autre monaies à disposition',
  OTHER = 'Autre',
}

export class Currency {
  Id: number;
  ISOCode: string;
  Symbol: string;
  Name: string;
  group?: CurrencyGroupEnum;

  constructor(model?: Currency) {
    if (model) {
      this.Id = model.Id;
      this.ISOCode = model.ISOCode;
      this.Symbol = model.Symbol;
      this.Name = model.Name;
      this.group = model.group;
    } else {
      this.Id = 0;
      this.ISOCode = null;
      this.Symbol = null;
      this.Name = null;
      this.group = CurrencyGroupEnum.PRIMARY;
    }
  }
}


