<on-page-title icon="fa-check" [title]="'company.accountant-export.title' | translate" [showReloadButton]="true"
  (reload)="reload()"></on-page-title>

<ul ngbNav #accountantNav="ngbNav" class="nav-tabs">
  <li ngbNavItem="exports">
    <a ngbNavLink>{{'company.accountant-export.exports-not-treated-tab-title' | translate}}</a>
    <ng-template ngbNavContent>
      <on-accountant-exports #AccountantExports></on-accountant-exports>
    </ng-template>
  </li>
  <li ngbNavItem="exports-treated">
    <a ngbNavLink>{{'company.accountant-export.exports-treated-tab-title' | translate}}</a>
    <ng-template ngbNavContent>
      <on-accountant-exports #AccountantTreatedExports [showOnlyTreatedExports]="true"></on-accountant-exports>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="accountantNav" class="mt-3"></div>