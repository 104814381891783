import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AccountantExport } from 'onexpense/company/_models/AccountantExport';
import { Component } from '@angular/core';
import swal from 'sweetalert2';
import { ToastService } from 'on-common/_services/toast.service';
import { AccountantExportService } from 'on-shared/stores/accountant-export/accountant-export.service';
import { AccountService } from 'onexpense/authentication/_services/account.service';

@Component({
  selector: 'on-export-format-gridcell',
  templateUrl: './export-format-gridcell.component.html',
  styleUrls: ['./export-format-gridcell.component.scss']
})
export class ExportFormatGridcellComponent implements ICellRendererAngularComp {

  public params: ICellRendererParams;
  accountantExportForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private accountantExportService: AccountantExportService,
    private accountService: AccountService,
    private toast: ToastService,
    private translateService: TranslateService
  ) { }

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.accountantExportForm = this.fb.group({ ExportFromSelect: ['placeholder'] });
  }

  afterGuiAttached?(params?: import('ag-grid-community').IAfterGuiAttachedParams): void {
  }

  generate() {
    const choice = this.accountantExportForm.getRawValue().ExportFromSelect; // get selection
    switch (choice) {
      case 'accountPayments':
        this.generateAccountPayments(this.params.data);
        break;
      case 'accounting':
        this.generateAccounting(this.params.data);
        break;
      case 'accountingSoftware':
        this.generateAccoutingSoftware(this.params.data);
        break;
      default:
        this.toast.error(this.translateService.instant('company.accountant-export.grid-cell.not-supported-export'));
        break;
    }
  }

  generateAccountPayments(accountantExport: AccountantExport) {
    this.accountantExportService.GenerateAccountPayments(accountantExport.Id).subscribe(response => {
      if (response.IsSuccess) {
        this.showDownloadableLink(response.Result);
      }
    });
  }

  generateAccounting(accountantExport: AccountantExport) {
    this.accountantExportService.GenerateAccounting(accountantExport.Id).subscribe(response => {
      if (response.IsSuccess) {
        this.showDownloadableLink(response.Result);
      }
    });
  }

  generateAccoutingSoftware(accountantExport: AccountantExport) {
    this.accountantExportService.GenerateAccountingSoftware(accountantExport.Id).subscribe(response => {
      if (response.IsSuccess) {
        this.showDownloadableLink(response.Result);
      }
    });
  }

  showDownloadableLink(linkToDownload) {
    const downloadLink = `${linkToDownload}?token=${this.accountService.getToken()}`;
    swal.fire({
      title: this.translateService.instant('company.accountant-export.grid-cell.swarl-title'),
      html: this.translateService.instant('company.accountant-export.grid-cell.swarl-text', { link: downloadLink }),
      showCloseButton: true
    });
  }
}
