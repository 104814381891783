<on-page-title [title]="'company.signature.title' | translate" icon="fa-file-signature" [showReloadButton]="true"
  (reload)="reload()"></on-page-title>
<ul ngbNav #mySignaturesNav="ngbNav" class="nav-tabs">
  <li ngbNavItem="toSign">
    <a ngbNavLink> {{'company.signature.to-sign' | translate}}</a>
    <ng-template ngbNavContent>
      <on-reports-to-sign #ReportsToSignPage></on-reports-to-sign>
    </ng-template>
  </li>
  <li ngbNavItem="signed">
    <a ngbNavLink> {{'company.signature.signed' | translate}}</a>
    <ng-template ngbNavContent>
      <on-reports-signed #ReportsSignedPage></on-reports-signed>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="mySignaturesNav" class="mt-3"></div>