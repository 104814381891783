import { OnUser } from 'on-shared/_models/OnUser';
import { Subscription as rxjsSubscription, Observable, Subject } from 'rxjs';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { UserState } from 'on-shared/stores/user/user.state';
import { takeUntil } from 'rxjs/operators';
import { CompanyState } from 'on-shared/stores/company/company.state';
import { Company } from 'on-shared/_models/Company';
import { Subscription } from 'on-shared/_models/Subscription';
import { SubscriptionState } from 'on-shared/stores/subscription/subscription.state';
import { SubscriptionActions } from 'on-shared/stores/subscription/subscription.actions';
import { StripeSubscription } from 'on-payment/_models/StripeSubscription';
import { PaymentService } from 'on-payment/_services/payment.service';
import { CompanyAction } from 'on-shared/stores/company/company.action';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { ExternalPaymentProviderEnum } from 'on-common/_models/Enums';
import { environment } from 'projects/onexpense/src/environments/environment';

@Component({
  selector: 'on-licenses-page',
  templateUrl: './licenses-page.component.html',
  styleUrls: ['./licenses-page.component.scss'],
})
export class LicensesPageComponent implements OnInit, OnDestroy {
  private user: OnUser;
  companyId: number;
  allLicenses: Subscription[];
  hasStripeSubscription: boolean;
  hasFloatingLicenses: boolean;
  hasVpSubscription: boolean;

  // Stripe subscription already subscribed
  currentLicenseStripeSubscription: Subscription;
  currentVpStripeSubscription: Subscription;

  stripeSubscription: StripeSubscription;
  lastInvoiceWasPaid: boolean;
  stripeVpSubscription: StripeSubscription;

  refreshLicencesSubscription: rxjsSubscription = null;

  hasConsumption = false;
  @Select(UserState.currentUser) currentUser$: Observable<OnUser>;
  @Select(CompanyState.current) currentCompany$: Observable<Company>;

  @Select(SubscriptionState.companySubscriptions) subscriptions$: Observable<Subscription[]>;

  @ViewChild('threeDPlaceholder', { static: true }) threeDPlaceholder: ElementRef;

  destroy$ = new Subject<void>();

  isLoading = true;

  appName = environment.appName;

  constructor(
    private store: Store,
    private paymentService: PaymentService,
    private t: TranslateService
  ) { }

  ngOnInit() {
    this.currentUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: OnUser) => {
        this.user = user;
        this.companyId = user.CompanyId;

        this.store.dispatch(new SubscriptionActions.LoadSubscriptions(user.CompanyId));
      });

    this.currentCompany$
      .pipe(takeUntil(this.destroy$))
      .subscribe(company => {
        if (company) {
          this.hasConsumption = company.IsVP;
          this.hasFloatingLicenses = company.HasFloatingLicense;
        } else {
          this.hasConsumption = false;
        }
      });

    this.subscriptions$.pipe(takeUntil(this.destroy$))
      .subscribe(subscriptions => {
        if (!subscriptions) {
          this.currentLicenseStripeSubscription = null;
          return;
        }

        this.currentLicenseStripeSubscription = subscriptions.find(s => s.ExternalProvider === ExternalPaymentProviderEnum.Stripe
          && s.ProductType.IsLicense);
        this.currentVpStripeSubscription = subscriptions.find(s => s.ExternalProvider === ExternalPaymentProviderEnum.Stripe
          && s.ProductType.Code === 'VP');

        this.hasStripeSubscription = this.currentLicenseStripeSubscription != null;
        this.hasVpSubscription = this.currentVpStripeSubscription != null;

        if (this.hasStripeSubscription) {
          this.paymentService.GetStripeSubscription(this.currentLicenseStripeSubscription.ExternalId).subscribe(response => {
            if (response.IsSuccess) {
              this.stripeSubscription = response.Result;
              this.lastInvoiceWasPaid = this.stripeSubscription.LatestInvoice.Status === 'paid';
            }
            this.isLoading = false;
          });
        }
        if (this.hasVpSubscription) {
          this.paymentService.GetStripeSubscription(this.currentVpStripeSubscription.ExternalId).subscribe(response => {
            if (response.IsSuccess) {
              this.stripeVpSubscription = response.Result;
            }
          });
        }

        if (!this.hasStripeSubscription) {
          this.isLoading = false;
        }
      });
  }

  openStripePortal() {
    Swal.fire({
      text: this.t.instant('company.licenses.access-invoicing-generation'),
      showConfirmButton: false,
      showCloseButton: false
    });
    this.paymentService.GetStripePortalLink().subscribe((response) => {
      if (response.IsSuccess) {
        Swal.fire({
          html: `<a href="${response.Result}" target="_blank">${this.t.instant('company.licenses.access-invoicing')}`,
          showConfirmButton: false,
          showCloseButton: false
        });
      }
    });
  }

  finalize3DSecure() {
    const iframe = document.createElement('iframe');
    iframe.src = this.stripeSubscription.LatestInvoice.PaymentIntent.FinalizationUrl;
    iframe.width = '600';
    iframe.height = '400';
    this.threeDPlaceholder.nativeElement.appendChild(iframe);
  }

  ngOnDestroy() {
    if (this.refreshLicencesSubscription) {
      this.refreshLicencesSubscription.unsubscribe();
    }
    if (this.destroy$) {
      this.destroy$.next();
      this.destroy$.complete();
    }
  }

  updateSubscriptions() {
    this.store.dispatch(new SubscriptionActions.LoadSubscriptions(this.user.CompanyId));
    this.store.dispatch(new CompanyAction.LoadCompanies());
  }

  isNaN(n: number) {
    return isNaN(n);
  }
}
