import { VPConsumption } from './../../../shared/_models/VPConsumption';
import { Component, OnInit, Input } from '@angular/core';
import { MonthlyConsumption, ExportConsumption } from './structured-consumption';
import { CompanyService } from '../../../shared/stores/company/company.service';

@Component({
  selector: 'on-vp-consumption',
  templateUrl: './vp-consumption.component.html',
  styleUrls: ['./vp-consumption.component.scss']
})
export class VpConsumptionComponent implements OnInit {

  @Input()
  companyId: number;

  isLoading = false;

  allConsumptions: VPConsumption[];
  monthlyConsumptions: MonthlyConsumption[];
  consumptionKeys: string[];

  constructor(private companyService: CompanyService) { }

  ngOnInit() {
    if (this.companyId) {
      this.isLoading = true;
      this.companyService.GetVPConsumption(this.companyId)
        .subscribe(result => {
          this.isLoading = false;
          if (result.IsSuccess) {
            this.allConsumptions = result.Result.sort((a, b) => {
              const date1 = new Date(a.StorageDate).getDate();
              const date2 = new Date(b.StorageDate).getDate();
              if (date1 === date2) {
                return 0;
              } else if (date1 < date2) {
                return -1;
              } else {
                return 1;
              }
            });

            this.monthlyConsumptions = this.createMonthlyConsumption(this.allConsumptions);
          }
        });
    }
  }

  createMonthlyConsumption(consumptions: VPConsumption[]): MonthlyConsumption[] {
    const monthlyConsumptions: MonthlyConsumption[] = [];
    const monthlyConsumptionDictionary: { [key: string]: MonthlyConsumption } = {};

    for (const consumption of consumptions) {
      const currentDate = new Date(consumption.StorageDate);
      // Create the string to represent the month this way 202001 for Jan. 2020 (month starts at 0 in javascript)
      const currentMonth = currentDate.getFullYear().toString() + (currentDate.getMonth() + 1).toString();

      let currentMonthlyConsumption: MonthlyConsumption;
      let currentExportConsumption: ExportConsumption;
      if (monthlyConsumptionDictionary[currentMonth]) {
        currentMonthlyConsumption = monthlyConsumptionDictionary[currentMonth];

        currentExportConsumption = currentMonthlyConsumption.ConsumptionPerExport
          .find(cpe => cpe.ExportId === consumption.AccountantExportId);
        if (!currentExportConsumption) {
          currentExportConsumption = {
            Consumptions: [],
            ExportId: consumption.AccountantExportId,
            ExportName: consumption.AccountantExportName,
            nbOfPages: 0
          };
          currentMonthlyConsumption.ConsumptionPerExport.push(currentExportConsumption);
        }

      } else {
        currentExportConsumption = {
          Consumptions: [],
          ExportId: consumption.AccountantExportId,
          ExportName: consumption.AccountantExportName,
          nbOfPages: 0
        };

        currentMonthlyConsumption = {
          ConsumptionPerExport: [currentExportConsumption],
          DateMonth: new Date(currentDate.getFullYear(), currentDate.getMonth()),
          nbOfPages: 0
        };

        monthlyConsumptionDictionary[currentMonth] = currentMonthlyConsumption;
        monthlyConsumptions.push(currentMonthlyConsumption);
      }

      // Once we created missing data if needed, we push and add the consumption data
      currentExportConsumption.Consumptions.push(consumption);
      currentMonthlyConsumption.nbOfPages += consumption.NbOfPages;
      currentExportConsumption.nbOfPages += consumption.NbOfPages;
    }

    return monthlyConsumptions;
  }
}
