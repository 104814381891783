<h2>{{"company.custom-fields.title" | translate}}</h2>
{{"company.custom-fields-description" | translate}}
<br>
<table class="table table-sm">
  <thead>
    <tr>
      <th>{{"company.custom-fields.name" | translate}}</th>
      <th>{{"company.custom-fields.description" | translate}}</th>
      <th>{{"company.custom-fields.expense-type" | translate}}</th>
      <th>{{"company.custom-fields.field-type" | translate}}</th>
      <th>{{"company.custom-fields.predefined-value" | translate}}</th>
      <th>{{"company.custom-fields.required" | translate}}</th>
      <th>#</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let field of customFields; let i = index">
      <td>
        <input type="text" class="form-control" [(ngModel)]="field.Name">
      </td>
      <td>
        <input type="text" class="form-control" [(ngModel)]="field.Description">
      </td>
      <td>
        <on-expense-type-select [allowAll]="true" [(ngModel)]="field.ExpenseType"></on-expense-type-select>
      </td>
      <td>
        <select class="form-control" [(ngModel)]="field.Type">
          <option value="string">{{"company.custom-fields.free-field" | translate}}</option>
          <option value="list">{{"company.custom-fields.predefined-list" | translate}}</option>
        </select>
      </td>
      <td style="max-width: 400px;">
        <tag-input [modelAsStrings]="true" [secondaryPlaceholder]="'company.custom-fields.enter-choices' | translate" placeholder="+ Choix" *ngIf="field.Type == 'list'" [(ngModel)]='field.PossibleValues' [editable]='true'></tag-input>
      </td>
      <td class="text-center">
        <input id="required-cb" type="checkbox" [(ngModel)]="field.IsRequired">
      </td>
      <td>
        <button class="btn btn-danger btn-sm" (click)="deleteCustomField(i)">
          <nb-icon icon="fa-times"></nb-icon>
        </button>
      </td>
    </tr>
    <tr>
      <td colspan="10" class="text-center">
        <button class="btn btn-outline-secondary" id="add-field-button" (click)="addCustomField()">
          <nb-icon icon="fa-plus"></nb-icon>
          {{"company.custom-fields.add" | translate}}
        </button>
      </td>
    </tr>
  </tbody>
</table>
<br>
<hr>
<br>
<div class="d-inline-block">
  <button class="btn btn-primary" (click)="Save()">
    <nb-icon icon="fa-save"></nb-icon>
    {{"company.custom-fields.save" | translate}}
  </button>
</div>
