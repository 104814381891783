<ng-select #ngSelect [(ngModel)]="selectedId" (blur)="selectTouched()" (change)="selectedValueChanged()">
  <ng-option [value]="'null'">{{'company.hierarchy.select-none' | translate}}</ng-option>
  <ng-option [value]="manager.Id" *ngFor="let manager of allEmployees">{{manager.FirstName}} {{manager.LastName}}
    ({{manager.UserName}})</ng-option>
</ng-select>

<!-- <select #ngSelect class="form-control-sm" [(ngModel)]="selectedId" (blur)="selectTouched()" (change)="selectedValueChanged()">
  <option [value]="'null'">Aucun</option>
  <option [value]="manager.Id" *ngFor="let manager of allEmployees">{{manager.FirstName}} {{manager.LastName}}
    ({{manager.UserName}})</option>
</select> -->
