<div class="modal-header">
  <h2 class="modal-title" id="modal-basic-title">{{'company.employees.manage-employee.employee' | translate: {firstname: User.FirstName, lastname: User.LastName} }}</h2>
  <button type="button" class="btn btn-light close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <on-loading *ngIf="isLoading" ></on-loading>
  <on-global-settings *ngIf="!isLoading" [user]="User" [disabled]="disable"></on-global-settings>
</div>
