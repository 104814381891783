<div class="card ps-4 pe-4">
  <div class="row">
    <div class="col col-sm-4 settings-description">
      <h2>{{title}}</h2>
      <p *ngIf="description">{{description}}</p>
      <ng-content *ngIf="!description" select="[description]"></ng-content>
    </div>

    <div class="col col-sm-8 content">
      <ng-content select="[content]"></ng-content>
    </div>
  </div>
</div>
