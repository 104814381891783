import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Store, Select } from '@ngxs/store';
import { AccountantExpertAction } from '../../shared/stores/accountant-expert/accountant-expert.action';
import { UserState } from '../../shared/stores/user/user.state';
import { TranslateService } from '@ngx-translate/core';
import { OnUser } from '../../shared/_models/OnUser';
import { Observable } from 'rxjs';
import { environment } from 'projects/onexpense/src/environments/environment';
import { ToastService } from 'on-common/_services/toast.service';

@Component({
  selector: 'on-create-company-page',
  templateUrl: './create-company-page.component.html',
  styleUrls: ['./create-company-page.component.scss'],
})
export class CreateCompanyPageComponent implements OnInit {
  @Select(UserState.currentUser) currentUser$: Observable<OnUser>;

  @Output() companyCreated = new EventEmitter();

  companyForm: UntypedFormGroup;
  addressForm: UntypedFormGroup;

  isLoading = false;

  constructor(
    private fb: UntypedFormBuilder,
    private toastService: ToastService,
    private translateService: TranslateService,
    private store: Store,
  ) { }

  ngOnInit() {
    this.companyForm = this.fb.group({
      Name: ['', Validators.required],
      AdminEmail: ['', Validators.required],
      AdminPhone: ['', Validators.required],
      SIRET: ['', Validators.required],
      Country: [
        {
          ISOCode: environment.defaultCountry
        },
        Validators.required,
      ],
      Currency: [
        { ISOCode: environment.defaultCurrency },
        Validators.required,
      ],
    });

    this.addressForm = this.fb.group({
      Street1: [''],
      Street2: [''],
      City: [''],
      Zip: [''],
    });
  }

  createCompany() {
    for (const controlKey in this.companyForm.controls) {
      if (this.companyForm.controls[controlKey]) {
        this.companyForm.controls[controlKey].markAsTouched();
      }
    }

    if (this.companyForm.valid && this.addressForm.valid) {
      const company = this.companyForm.value;
      company.Address = this.addressForm.value;

      this.isLoading = true;

      // Create company
      this.store.dispatch(new AccountantExpertAction.CreateCompany(company));
    } else {
      this.toastService.warning(
        this.translateService.instant(
          'accountant.create-company-missing-fields',
        ),
      );
    }
  }
}
