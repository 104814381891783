export class ExpenseType {
  Id: number;
  Code: string;
  Name: string;
  CategoryId?: number;
  CategoryName?: string;
  CategoryCode?: string;
  Icon?: string;

  constructor(model?: ExpenseType) {
    if (model) {
      this.Id = model.Id;
      this.Code = model.Code;
      this.Name = model.Name;
      this.CategoryId = model.CategoryId;
      this.CategoryName = model.CategoryName;
      this.CategoryCode = model.CategoryCode;
      this.Icon = model.Icon;
    } else {
      this.Id = null;
      this.Code = null;
      this.Name = null;
      this.CategoryId = null;
      this.CategoryName = null;
      this.CategoryCode = null;
      this.Icon = null;
    }
  }
}
