import { EmployeeCategory } from 'on-shared/_models/EmployeeCategory';
import { ToastService } from 'on-common/_services/toast.service';
import { ExpenseType } from 'on-shared/_models/ExpenseType';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Rule } from 'projects/onexpense/src/app/shared/_models/Rule';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { CompanyService } from 'on-shared/stores/company/company.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'on-limit-rule-form',
  templateUrl: './limit-rule-form.component.html',
  styleUrls: ['./limit-rule-form.component.scss']
})
export class LimitRuleFormComponent implements OnInit, OnChanges {
  @Input() rule: Rule;
  @Input() expenseTypes: ExpenseType[];
  @Input() employeeCategories: EmployeeCategory[];
  @Input() new: boolean;

  @Output() closeModalEmitter?: EventEmitter<boolean> = new EventEmitter(undefined);
  @Output() reloadListEmitter?: EventEmitter<boolean> = new EventEmitter(undefined);
  @Output() deleteRuleEmitter?: EventEmitter<Rule> = new EventEmitter(undefined);

  ruleForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private companyService: CompanyService,
    private toastService: ToastService,
    private t: TranslateService) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.initRuleForm();
  }

  initRuleForm(): void {
    this.ruleForm = this.fb.group({
      Name: this.rule.Name ? this.rule.Name : '',
      Type: this.rule.Type ? this.rule.Type : '',
      ExpenseType: this.rule.ExpenseType,
      Value: this.rule.Value ? this.rule.Value : 0,
      EmployeeCategory: this.rule.EmployeeCategory,
    });
  }

  createRule(): void {
    const ruleToCreate: Rule = this.buildRuleObject();

    this.companyService.AddRule(ruleToCreate).subscribe(
      result => {
        this.toastService.success(this.t.instant('expense-rule.rule-created'));
        this.closeModalEmitter.emit(true)
      },
      error => this.toastService.error(`Impossible d'ajouter cette règle`, `Erreur`)
    );
  }

  updateRule(): void {
    const ruleToUpdate: Rule = this.buildRuleObject();

    this.companyService.UpdateRule(ruleToUpdate).subscribe(
      response => {
        if (response.IsSuccess) {
          this.toastService.success(this.t.instant('expense-rule.rule-updated'));
          this.reloadListEmitter.emit(true)
        }
      },
      error => this.toastService.error(`Impossible d'éditer la règle`, `Erreur`)
    );
  }

  buildRuleObject(): Rule {
    let expenseTypeId: number = null;
    if (this.ruleForm.value.ExpenseType) {
      expenseTypeId = this.ruleForm.value.ExpenseType.Id;
    }

    let categoryId: number = null;
    if (this.ruleForm.value.EmployeeCategory) {
      categoryId = this.ruleForm.value.EmployeeCategory.Id;
    }

    return {
      Id: this.rule.Id ?? 0,
      Name: this.ruleForm.value.Name,
      Type: this.ruleForm.value.Type,
      ExpenseTypeId: expenseTypeId,
      Value: this.ruleForm.value.Value,
      EmployeeCategoryId: categoryId,
      ExpenseType: this.ruleForm.value.ExpenseType,
      EmployeeCategory: this.ruleForm.value.EmployeeCategory
    };
  }

  deleteRule(): void {
    this.deleteRuleEmitter.emit(this.rule);
  }

  isDisabled(): boolean {
    return !(this.ruleForm.value.Type && this.ruleForm.value.Name);
  }
}
