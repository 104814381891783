import { OnUser } from 'on-shared/_models/OnUser';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Select } from '@ngxs/store';
import { UserState } from 'on-shared/stores/user/user.state';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'on-user-settings-page',
  templateUrl: './user-settings-page.component.html',
  styleUrls: ['./user-settings-page.component.scss'],
})
export class UserSettingsPageComponent implements OnInit, OnDestroy {
  @Select(UserState.currentUser) currentUser$: Observable<OnUser>;
  currentUser: OnUser;
  isLoading = false;

  selectedTabId = 'settings-tab';
  destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.currentUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: OnUser) => {
        this.currentUser = user;
        this.isLoading = false;
      });

    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        this.selectedTabId = this.route.snapshot.fragment;
      } else {
        this.selectedTabId = 'settings-tab';
      }
    });

    if (this.route.snapshot.fragment) {
      this.selectedTabId = this.route.snapshot.fragment;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
