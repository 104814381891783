import { Login } from '../../_models/Login';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountService } from 'on-auth/_services/account.service';

@Component({
  selector: 'on-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  @Output() loginRequested = new EventEmitter<Login>();

  isLoadingAuthTypes = false;
  showPassword = false;
  showMicrosoft = false;

  constructor(
    private fb: UntypedFormBuilder,
    public route: ActivatedRoute,
    private accountService: AccountService) { }

  loginForm: UntypedFormGroup;

  ngOnInit() {
    this.loginForm = this.fb.group(
      {
        username: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required]
      }
    );

    this.loginForm.get('username').valueChanges.subscribe(newValue => {
      this.resetPasswordField();
    });
  }

  resetPasswordField() {
    this.showPassword = false;
    this.showMicrosoft = false;
  }

  Login() {
    if (!this.loginForm.controls.username.valid) {
      return;
    }

    if (!this.showPassword && !this.showMicrosoft) {
      this.isLoadingAuthTypes = true;
      this.accountService.GetAvailableAuthTypes(this.loginForm.value.username).subscribe({
        next: authTypes => {
          if (authTypes.indexOf('AZUREAD') > -1) {
            this.showMicrosoft = true;
            this.showPassword = false;
          } else {
            this.showMicrosoft = false;
            this.showPassword = true;
          }
          this.isLoadingAuthTypes = false;
        },
        error: err => {
          this.showMicrosoft = false;
          this.showPassword = true;
          this.isLoadingAuthTypes = false;
        }
      });
    }

    if (this.loginForm.valid) {
      this.loginRequested.emit(this.loginForm.value);
    }
  }
}
