import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ActionButton } from './../../_models/ActionButton';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'on-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss'],
})
export class ActionButtonsComponent {
  @Input() actions: ActionButton[];

  @ViewChild('myDrop') myDrop: NgbDropdown;
  constructor() {}

  dropdownClick() {
    this.myDrop.toggle();
  }
}
