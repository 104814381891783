import { TranslateService } from '@ngx-translate/core';
import { OnUser } from 'projects/onexpense/src/app/shared/_models/OnUser';
import { Report } from 'on-shared/_models/Report';
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { ReportsCriteria } from '../_models/ReportsCriteria';
import { ReportListComponent } from '../_shared/report-list/report-list.component';
import { Store, Select } from '@ngxs/store';
import { ReportsState } from 'on-shared/stores/reports/reports.state';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { ReportsActions } from 'on-shared/stores/reports/reports.actions';
import { CompanyState } from 'on-shared/stores/company/company.state';
import { Company } from 'on-shared/_models/Company';
import { UserState } from 'on-shared/stores/user/user.state';
import { ToastService } from 'on-common/_services/toast.service';

@Component({
  selector: 'on-my-reports-page',
  templateUrl: './my-reports-page.component.html',
  styleUrls: ['./my-reports-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyReportsPageComponent implements OnInit, OnDestroy {
  reportsToShow: Report[];
  onGoingReports: Report[];
  validatedReports: Report[];

  currentUser: OnUser;

  allAreChecked = false;

  filterCriteria: ReportsCriteria = {
    MinTotal: 0,
    MaxTotal: 5000,
    CreationDateRange: null,
  };
  pageNumber = 1;
  nbPerPages = 9999;
  totalItems = 0;

  pageSize = 10;
  customPageSize: number;

  freeAccount = true;

  @ViewChild('reportsList') reportsList: ReportListComponent;

  @Select(ReportsState.getCurrentUserReportList) readonly reports$: Observable<Report[]>;
  destroy$ = new Subject<void>();

  @Select(CompanyState.current) company$: Observable<Company>;
  @Select(UserState.currentUser) currentUser$: Observable<OnUser>;
  @Select(ReportsState.isLoadingReports) isLoadingReports$: Observable<boolean>;

  constructor(
    private toastService: ToastService,
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef,
    private store: Store,
  ) { }

  ngOnInit() {
    this.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        this.currentUser = user;
        this.freeAccount = user.License ? false : true;
      }
    });

    this.store.dispatch(new ReportsActions.IfCurrentUserReportListNeeded());
    this.reports$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res) {
        this.reportsToShow = res.map(r => new Report(r));
        this.onGoingReports = res.filter(r => r.State.Code !== 'VAL' && r.State.Code !== 'ARCHIVED');
        this.validatedReports = res.filter(r => r.State.Code === 'VAL' || r.State.Code === 'ARCHIVED');
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  DeleteReports() {
    // const ids = this.FindSelectedReportIds();
    const ids = this.reportsList.getSelectedRowsIds(); // For ag grid

    if (ids === null || ids.length === 0) {
      this.toastService.warning(
        this.translate.instant('report.delete-warning-select-report'),
      );
    }

    return this.store
      .dispatch(new ReportsActions.Delete(ids));
  }

  FindSelectedReportIds(): number[] {
    if (this.reportsToShow == null) {
      return null;
    }

    return this.reportsToShow.reduce(
      (
        selectedIds: number[],
        expense: Report,
        currentIndex: number,
        allExpenses: Report[],
      ) => {
        if (expense.IsSelected) {
          selectedIds.push(expense.Id);
        }
        return selectedIds;
      },
      [],
    );
  }

  CreateEmptyReport() {
    this.store
      .dispatch(new ReportsActions.CreateEmpty());
  }

  CreateSinceLastReport() {
    this.store
      .dispatch(new ReportsActions.CreateFromLast());
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.reportsList.setPaginationSize(pageSize);

    if (pageSize === 10 || pageSize === 20 || pageSize === 50) {
      this.customPageSize = null;
    }
  }

  // Call when choice with input
  onChangePageSize() {
    if (this.customPageSize) {
      this.changePageSize(this.customPageSize);
    }
  }

  reload() {
    this.store.dispatch(new ReportsActions.GetCurrentUserReports());
  }
}
