import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { VPState } from 'onexpense/company/_models/AccountantExport';

@Component({
  selector: 'on-export-archived-gridcell',
  templateUrl: './export-archived-gridcell.component.html',
  styleUrls: ['./export-archived-gridcell.component.scss']
})
export class ExportArchivedGridcellComponent implements ICellRendererAngularComp {

  isNew: boolean;
  isSent: boolean;
  isError: boolean;
  isArchived: boolean;

  constructor() { }

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: import('ag-grid-community').ICellRendererParams): void {
    this.isArchived = params.value === VPState.StoredInVP;
    this.isSent = params.value === VPState.SentToVP;
    this.isError = params.value === VPState.ErrorInVP;
    this.isNew = params.value === VPState.Created;
  }

  afterGuiAttached?(params?: import('ag-grid-community').IAfterGuiAttachedParams): void {
  }

}
