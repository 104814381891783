import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import Map from 'ol/Map';
import View from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import { Icon, Style, Stroke } from 'ol/style';
import OSM from 'ol/source/OSM';
import * as olProj from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import Feature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString';
import Overlay from 'ol/Overlay';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MapService } from '../../../_services/map.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteResult } from '../../../_models/AutocompleteResult';
import { ItineraryResponse } from '../../../_models/ItineraryResponse';
import { ItineraryRoute } from '../../../_models/ItineraryRoute';
import Geometry from 'ol/geom/Geometry';

@Component({
  selector: 'on-map-modal',
  templateUrl: './map-modal.component.html',
  styleUrls: ['./map-modal.component.scss']
})
export class MapModalComponent implements OnInit, OnDestroy {

  @Input() startPlaceName: string;
  @Input() endPlaceName: string;

  startPlace: AutocompleteResult;
  endPlace: AutocompleteResult;

  latitude = 47.215923;
  longitude = -1.700700;

  latitude2 = 47.215284;
  longitude2 = -1.642083;

  map: Map;
  layerMarkers: VectorLayer<VectorSource<Feature<Geometry>>>;
  layerRoutes: VectorLayer<VectorSource<Feature<Geometry>>>;

  searchTermsStart: string;
  searchTermsEnd: string;
  searchResultsStart: AutocompleteResult[];
  searchResultsEnd: AutocompleteResult[];

  resultSearch: Observable<any> = null;
  subjectSearch = new Subject<number>();

  isMarkingStart = false;
  isMarkingEnd = false;

  selectedRouteStyle: Style;
  unselectedRouteStyle: Style;

  routesResponse: ItineraryResponse;
  routesFeatures: Feature[];
  selectedRoute: ItineraryRoute;
  distance: number;

  constructor(
    private mapService: MapService,
    private modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.initMap();
    this.initRouteStyles();
  }

  ngOnDestroy() {
    this.subjectSearch.complete();
  }

  initMap() {
    this.map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSM()
        })
      ],
      view: new View({
        center: olProj.fromLonLat([2.468996, 46.679019]), // Center of France
        zoom: 6
      })
    });


    this.map.on('click', event => {
      this.onClick(event);
    });
  }

  centerOn(lon: number, lat: number) {
    let view = this.map.getView();

    view.setCenter(olProj.fromLonLat([lon, lat]));
    view.setZoom(12);
  }

  onSearch(target: number) {
    const searchTerms = target === 1 ? this.startPlaceName : this.endPlaceName;

    if (!searchTerms || searchTerms.length < 4) {
      this.searchResultsStart = null;
      this.searchResultsEnd = null;
      return;
    }

    if (!this.resultSearch) {
      this.resultSearch = this.subjectSearch.pipe(debounceTime(500));
      this.resultSearch.subscribe(target2 => {
        this.search(target2);
      });
    }

    this.subjectSearch.next(target);
  }

  search(target: number) {
    const searchTerms = target === 1 ? this.startPlaceName : this.endPlaceName;

    this.mapService.Search(searchTerms).subscribe(response => {
      if (target === 1) {
        this.searchResultsStart = response.Result.results;
      } else {
        this.searchResultsEnd = response.Result.results;
      }
    });
  }

  onSelectSearchResult(result: AutocompleteResult, target: number) {
    const latitude = result.position.lat;
    const longitude = result.position.lon;

    if (target === 1) {
      this.searchResultsStart = null;

      this.startPlaceName = result.address.freeformAddress + ` (${result.address.countrySubdivision})`;
      this.startPlace = result;
    } else {
      this.searchResultsEnd = null;

      this.endPlaceName = result.address.freeformAddress + ` (${result.address.countrySubdivision})`;
      this.endPlace = result;
    }

    this.removeMarker(target);
    this.addMarker(longitude, latitude, target);

    this.centerOn(longitude, latitude);
  }

  addMarker(lon: number, lat: number, id: number) {
    // If no markers layer
    if (!this.layerMarkers) {
      this.layerMarkers = new VectorLayer({
        source: new VectorSource(),
        zIndex: 10
      });

      this.map.addLayer(this.layerMarkers);
    }

    // Create marker
    const icon = new Feature({
      geometry: new Point(olProj.fromLonLat([lon, lat])),
      id: id
    });

    // Set marker style
    icon.setStyle(
      new Style({
        image: new Icon({
          crossOrigin: 'anonymous',
          src: 'assets/images/marker-red.png',
          scale: 0.04,
          anchor: [0.5, 1]
        })
      })
    );

    // Add marker in the layer
    this.layerMarkers.getSource().addFeature(icon);

    this.removeRoutes();

    if (this.startPlace && this.endPlace) {
      this.getRoute();
    }
  }

  removeMarker(id: number) {
    if (!this.layerMarkers) {
      return;
    }

    for (const feature of this.layerMarkers.getSource().getFeatures()) {
      if (feature.getId() === id) {
        this.layerMarkers.getSource().removeFeature(feature);
        return;
      }
    }
  }

  getRoute() {
    if (!this.startPlace || !this.endPlace) {
      return;
    }

    this.mapService.GetRoute(
      this.startPlace.position.lat,
      this.startPlace.position.lon,
      this.endPlace.position.lat,
      this.endPlace.position.lon)
      .subscribe(response => {
        this.routesResponse = response.Result;

        this.routesFeatures = [];
        this.selectedRoute = this.routesResponse.routes[0];
        this.distance = this.selectedRoute.summary.lengthInMeters;
        this.drawRoute(this.selectedRoute, 0, true);
      });
  }

  drawRoute(route: ItineraryRoute, index = null, selected = false) {
    // If no markers layer
    if (!this.layerRoutes) {
      this.layerRoutes = new VectorLayer({
        source: new VectorSource()
      });

      this.map.addLayer(this.layerRoutes);
    }

    const lineString = new LineString(route.legs[0].points.map(p => [p.longitude, p.latitude]));

    const line = new Feature(lineString)
    line.getGeometry().transform('EPSG:4326', 'EPSG:3857');
    line.setStyle(this.selectedRouteStyle);

    this.routesFeatures.push(line);

    this.layerRoutes.getSource().addFeature(line);

    // Add popup
    let popupPosition;

    switch (index) {
      case 0: popupPosition = 0.25; break;
      case 1: popupPosition = 0.40; break;
      case 2: popupPosition = 0.60; break;
      case 3: popupPosition = 0.75; break;
    }

    this.addOverlay(
      [(this.startPlace.position.lon + this.endPlace.position.lon) / 2,
      (this.startPlace.position.lat + this.endPlace.position.lat) / 2],
      (+route.summary.lengthInMeters * 0.001).toFixed(2) + '', index);
  }

  changeSelectedRoute(routeFeature: Feature) {
    for (const feature of this.routesFeatures) {
      feature.setStyle(this.unselectedRouteStyle);
    }
    routeFeature.setStyle(this.selectedRouteStyle);

    this.selectedRoute = this.routesResponse[routeFeature.getId()];
  }

  removeRoutes() {
    if (!this.layerRoutes) {
      return;
    }

    this.routesResponse = null;
    this.routesFeatures = [];
    this.selectedRoute = null;

    this.layerRoutes.getSource().clear();

    this.hideOverlay(0);
  }

  addOverlay(coords: any, text: string, index: number) {
    const container = document.getElementById('popupRoute' + (index + 1));
    const content = document.getElementById('popupContentRoute' + (index + 1));

    const overlay = new Overlay({
      element: container
    });

    content.innerHTML = '<b>' + text + 'km</b>';

    container.style.visibility = 'visible';

    overlay.setPosition(olProj.fromLonLat(coords));

    this.map.addOverlay(overlay);
  }

  hideOverlay(index: number) {
    const element = document.getElementById('popupRoute' + (index + 1));
    element.style.visibility = 'hidden';
  }

  onClick(event) {
    // const coords = this.map.getEventCoordinate(event.originalEvent);

    // if (this.isMarkingStart || this.isMarkingEnd) {
    //   const lonLat = olProj.toLonLat(coords);

    //   // Get adress
    //   this.mapService.ReverseSearch(lonLat[0], lonLat[1]).subscribe(response => {
    //     if (response.features.length < 1) {
    //       return;
    //     }

    //     const longitude = response.features[0].geometry.coordinates[0];
    //     const latitude = response.features[0].geometry.coordinates[1];

    //     const display_name = this.placePropertiesToString(response.features[0].properties);

    //     let target: number;

    //     if (this.isMarkingEnd) {
    //       this.endPlaceName = display_name;
    //       this.endPlace = {
    //         FullName: display_name,
    //         longitude: longitude,
    //         latitude: latitude
    //       } as Place;

    //       target = 2;
    //     } else {
    //       this.startPlaceName = display_name;
    //       this.startPlace = {
    //         FullName: display_name,
    //         longitude: longitude,
    //         latitude: latitude
    //       } as Place;

    //       target = 1;
    //     }

    //     this.removeMarker(target);
    //     this.addMarker(lonLat[0], lonLat[1], target);

    //     this.isMarkingStart = false;
    //     this.isMarkingEnd = false;
    //   });
    // }

    // // If routes
    // if (this.layerRoutes) {
    //   this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
    //     this.changeSelectedRoute(feature);
    //   });
    // }
  }

  onClickAddMarker(target: number) {
    if (target === 1) {
      this.isMarkingStart = !this.isMarkingStart;
      this.isMarkingEnd = false;
    } else {
      this.isMarkingEnd = !this.isMarkingEnd;
      this.isMarkingStart = false;
    }
  }

  onClickPopupRoute(routeId: number) {
    if (this.routesFeatures.length < routeId - 1) {
      return;
    }

    this.changeSelectedRoute(this.routesFeatures[routeId - 1]);
  }

  initRouteStyles() {
    this.selectedRouteStyle = new Style({
      stroke: new Stroke({
        width: 9,
        color: [47, 129, 255, 1],
      })
    });

    this.unselectedRouteStyle = new Style({
      stroke: new Stroke({
        width: 6,
        color: [47, 129, 255, 0.5],
      })
    });
  }

  valid() {
    const distance = this.selectedRoute ? (+this.selectedRoute.summary.lengthInMeters * 0.001).toFixed(2) : 0;
    this.modal.close({
      distance: +distance,
      start: this.startPlaceName,
      end: this.endPlaceName
    });
  }
}
