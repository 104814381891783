import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Company } from 'on-shared/_models/Company';
import { OnUser } from 'on-shared/_models/OnUser';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { CompanyState } from 'on-shared/stores/company/company.state';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompanyStateModel } from 'on-shared/stores/company/company.model';
import { CompanyAction } from 'on-shared/stores/company/company.action';
import { UserState } from 'on-shared/stores/user/user.state';
import { ToastService } from 'on-common/_services/toast.service';

@Component({
  selector: 'on-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectCompanyComponent implements OnInit, OnDestroy {
  showAction = false;

  user: OnUser;
  companies: Company[];
  companySelected: Company;

  @Select(UserState.currentUser) $currentUser: Observable<OnUser>;
  @Select(CompanyState) $companyState: Observable<CompanyStateModel>;
  destroy$ = new Subject<void>();

  constructor(
    private toastService: ToastService,
    private translateService: TranslateService,
    private store: Store,
  ) { }

  ngOnInit() {
    this.$currentUser.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
      this.store.dispatch(new CompanyAction.LoadCompaniesIfNeeded());
      this.loadCompanies();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadCompanies() {
    this.$companyState.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      if (!response.shouldReloadCompanies) {
        this.companies = response.companies;

        if (this.companies.length > 1) {
          this.companySelected = response.current;
          this.showAction = true;
        }
      }
    });
  }

  onChangeCompany() {
    this.store.dispatch(
      new CompanyAction.SetCompanySelected(this.companySelected.Id),
    );
  }
}
