import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { SupportPageComponent } from './support-page/support-page.component';



@NgModule({
  declarations: [
    SupportPageComponent
  ],
  imports: [
    SharedModule
  ]
})
export class SupportModule { }
