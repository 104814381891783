import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PaymentService } from '../_services/payment.service';
import { StripeSubscription } from '../_models/StripeSubscription';
import { StripePrice } from '../_models/StripePrice';
import { StripeProduct } from '../_models/StripeProduct';

@Component({
  selector: 'on-stripe-vp-payment',
  templateUrl: './stripe-vp-payment.component.html',
  styleUrls: ['./stripe-vp-payment.component.scss']
})
export class StripeVpPaymentComponent implements OnInit {

  @Input() currentSubscription: StripeSubscription;
  @Output() subscriptionChanged = new EventEmitter<void>();

  prices: StripePrice[];
  selectedPrice: StripePrice;
  quoteSelected: boolean;
  vpProduct: StripeProduct;

  isLoading: boolean;

  constructor(
    private paymentService: PaymentService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.paymentService.GetAllProducts().subscribe(response => {
      if (response.IsSuccess) {
        this.vpProduct = response.Result.find(p => p.IsVP);
        // Order prices by their price
        this.prices = this.vpProduct.Prices.sort((a, b) => a.Price - b.Price);

        if (!this.currentSubscription) {
          this.selectedPrice = null;
        } else {
          this.selectedPrice = this.prices.find(price => price.Id === this.currentSubscription.RecurringPrice.Id);
        }
      }
      this.isLoading = false;
    });
  }

  selectPrice(price: StripePrice, isQuote: boolean = false) {
    if (!isQuote && price) {
      this.selectedPrice = price;
      this.quoteSelected = false;
    }

    if (isQuote) {
      this.quoteSelected = true;
      this.selectedPrice = null;
    }
  }

  buyPlan() {
    if (this.currentSubscription || !this.selectedPrice) {
      return;
    }

    this.isLoading = true;
    this.paymentService.CreateNewVpSubscription(this.selectedPrice.Id).subscribe(response => {
      if (response.IsSuccess) {
        this.currentSubscription = response.Result;
        this.subscriptionChanged.emit();
      }
      this.isLoading = false;
    });
  }
}
