import { NbMenuModule, NbIconModule, NbSidebarModule, NbLayoutModule, NbButtonModule } from '@nebular/theme';
import { FooterComponent } from './sidebar-page/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NoSidebarPageComponent } from './no-sidebar-page/no-sidebar-page.component';
import { NgModule } from '@angular/core';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { TopbarComponent } from './topbar/topbar.component';
import { SidebarPageComponent } from './sidebar-page/sidebar-page.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NotificationBannerComponent } from './sidebar-page/notification-banner/notification-banner.component';
import { MenuComponent } from './components/menu/menu.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { OnCommonModule } from 'on-common/on-common.module';
import { OnAuthModule } from 'on-auth/on-auth.module';



@NgModule({
  declarations: [
    SideMenuComponent,
    TopbarComponent,
    NoSidebarPageComponent,
    SidebarPageComponent,
    FooterComponent,
    NotificationBannerComponent,
    MenuComponent,
    MenuItemComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NbMenuModule,
    NbIconModule,
    NbLayoutModule,
    NbSidebarModule,
    NbButtonModule,

    RouterModule,
    OnCommonModule,
    OnAuthModule
  ],
  exports: [
    NoSidebarPageComponent,
    SidebarPageComponent
  ]
})
export class OnLayoutModule {
}
