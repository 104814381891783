import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OnUser } from '../../_models/OnUser';
import { map, tap } from 'rxjs/operators';
import { API_V2_URL } from 'on-common/constants';
import { AccountService } from 'on-auth/_services/account.service';
import { IApiActionResult, IApiResult } from 'on-common/_models/ApiResult';

const ACCOUNT_URL = API_V2_URL + '/account';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private http: HttpClient,
    private accountService: AccountService,
  ) { }

  SaveUserSettings(userToSave: OnUser): Observable<IApiResult<OnUser>> {
    return this.http.post<IApiResult<OnUser>>(
      `${ACCOUNT_URL}/saveSettings`,
      userToSave,
    );
  }

  DeleteAccount(): Observable<IApiActionResult> {
    return this.http.delete<IApiActionResult>(ACCOUNT_URL).pipe(tap(result => {
      if (result.IsSuccess) {
        this.accountService.logout();
      }
    }));
  }

  GetUser(userId?: number): Observable<IApiResult<OnUser>> {
    return this.accountService
      .GetUser(userId)
      .pipe(map((u) => u as IApiResult<OnUser>));
  }

  ChangePassword(
    oldPassword: string,
    newPassword: string,
    confirmationPassword: string,
  ): Observable<IApiActionResult> {
    return this.accountService.ChangePassword(
      oldPassword,
      newPassword,
      confirmationPassword,
    );
  }
}
