import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'on-date-gridcell',
  templateUrl: './date-gridcell.component.html',
  styleUrls: ['./date-gridcell.component.scss']
})
export class DateGridcellComponent implements ICellRendererAngularComp {

  params: any;
  date: any;
  dateFormat: string;

  constructor(private translateService: TranslateService) {
    this.dateFormat = translateService.instant('date.format-number');
  }

  refresh(params: any): boolean {
    return false;
  }
  agInit(params): void {
    this.params = params;

    // Get good attribut
    if (params.for === 'invitations') {
      this.date = this.params.data.ExpirationDate;
    } else if (params.for === 'expenses') {
      this.date = this.params.data.DateOnExpense;
    } else if (params.for === 'dateCreaReport') {
      this.date = this.params.data.CreationDate;
    } else if (params.for === 'dateValidReport') {
      this.date = this.params.data.ValidationDate;
    }

  }

  afterGuiAttached?(params?: import('ag-grid-community').IAfterGuiAttachedParams): void {
  }

}
