// tslint:disable-next-line:no-namespace
export namespace SubscriptionActions {

    export class LoadSubscriptionsIfNeeded {
        public static readonly type = `[Subscription] load the subscriptions only if needed`;

        constructor(public companyId: number) { }
    }

    export class LoadSubscriptions {
        public static readonly type = `[Subscription] force reloading the subscriptions`;

        constructor(public companyId: number) { }
    }
}
