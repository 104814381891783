<div class="modal-header" [ngClass]="" >
  <h4 class="modal-title" [innerHTML]="title"></h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(modalSouce)">
    <nb-icon icon="fa-times"></nb-icon>
  </button>
</div>
<div class="modal-body">
  <div class="col-12">
    <div *ngIf="!!class"  class="col-12" align="center">
      <div *ngIf="class === 'danger'"  class="swal2-icon swal2-error"  style="display: flex;"><span class="swal2-x-mark"><span class="swal2-x-mark-line-left"></span><span class="swal2-x-mark-line-right"></span></span></div>
      <div *ngIf="class === 'warning'" class="swal2-icon swal2-warning swal2-animate-warning-icon"  style="display: flex;"></div>
      <div *ngIf="class === 'info'" class="swal2-icon swal2-info"  style="display: flex;"></div>
    </div>
    <div class="col-12 message">
      <p *ngIf="!routerLink && !href" [innerHTML]="message"></p>
      <a *ngIf="!!routerLink" [routerLink]="routerLink" [innerHTML]="message" (click)="activeModal.dismiss(modalSouce)"></a>
      <a *ngIf="!!href" [href]="href" target="_blank" [innerHTML]="message" (click)="activeModal.dismiss(modalSouce)"></a>
    </div>
  </div>
</div>
