<form>
  <div class="row">
    <div class="col form-group">
      <label class="label">Dans Emetteur :</label>
      <input type="text" [(ngModel)]="filter.Emitter" [ngModelOptions]="{standalone: true}"
        class="form-control form-control-sm">
    </div>
    <div class="col form-group">
      <label class="label">Dans Commentaires :</label>
      <input type="text" [(ngModel)]="filter.Comments" [ngModelOptions]="{standalone: true}"
        class="form-control form-control-sm">
    </div>
  </div>

  <div class="more-filters" [ngbCollapse]="!displayAllFilters">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="receiptType">Types de reçu</label>
          <on-expense-type-select [isMultiple]="true" [(ngModel)]="filter.TypeIds"
            [ngModelOptions]="{standalone: true}"></on-expense-type-select>
        </div>
      </div>
      <div class="col">
        <div class="form-checkbox">
          <label for="check">Dans une note de frais</label>
          <br>
          <input type="checkbox" [(ngModel)]="filter.AreInReport" [ngModelOptions]="{standalone: true}"
            class="form-checkt" id="checkbox">
        </div>
      </div>

      <!-- STAUT -->
      <!-- <div class="col form-group">
        <label for="receiptType">Statut</label>
        <select class="form-control" id="receiptType">
          <option *ngFor="let statut of status">{{statut}}</option>
        </select>
      </div> -->
    </div>

    <!-- DATE -->
    <div class="row">
      <div class="col form-group">
        <label for="">Date Minimale</label>
        <div class="input-group">
          <button class="btn btn-outline-secondary" (click)="min.toggle()">
            <nb-icon icon="fa-calendar"></nb-icon>
          </button>
          <input class="form-control" placeholder="jj/mm/aaaa" name="minDate" (change)="expenseMinDateChanged()"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="filter.MinDateOnExpense" ngbDatepicker
            #min="ngbDatepicker" container="body">
        </div>
      </div>
      <div class="col form-group">
        <label for="">Date Maximale</label>
        <div class="input-group">
          <button class="btn btn-outline-secondary" (click)="max.toggle()">
            <nb-icon icon="fa-calendar"></nb-icon>
          </button>
          <input class="form-control" placeholder="jj/mm/aaaa" name="MaxDate" (change)="expenseMaxDateChanged()"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="filter.MaxDateOnExpense" ngbDatepicker
            #max="ngbDatepicker">
        </div>
      </div>
    </div>

    <!-- COUNTRY -->
    <div class="row">
      <!-- <div class="col form-group">
        <label for="country">Pays</label>
        <on-country-select></on-country-select>
      </div> -->

      <!-- CURRENCIES -->
      <!-- <div class="col form-group">
        <label for="country">Devise</label>
        <on-currency-select></on-currency-select>
      </div> -->

      <!-- CHECKBOX -->

    </div>
  </div>

  <div class="row">
    <div class="col">
      <button type="button" (click)="displayAllFilters = !displayAllFilters"
        class="btn btn-outline-warning float-left">{{displayAllFilters
        ? 'Moins' : 'Plus'}} de filtres</button>
    </div>
    <div class="col">
      <button type="button" (click)="applyFilters()"
        class="btn btn-primary waves-light float-right float-bottom">Appliquer
        les filtres</button>
    </div>
  </div>
</form>