<on-page-title [title]="'statistics.export-title' | translate" icon="fa-chart-line"></on-page-title>

<p class="alert alert-info">
    <i class="fa fa-info-circle"></i>
    {{'statistics.export-disclaimer' | translate}}
</p>

<on-two-columns-display [title]="'statistics.request-title' | translate"
    [description]="'statistics.request-description' | translate">
    <ng-container content>
        <div class="row align-content-center justify-content-around">
            <div class="col text-center">
                Date de début : <strong>{{startDate | date:'dd/MM/yyyy'}}</strong><br><br>
                <ngb-datepicker [(ngModel)]="startDate" #startPicker></ngb-datepicker>
            </div>
            <div class="col text-center">
                Date de fin : <strong>{{endDate | date:'dd/MM/yyyy'}}</strong><br><br>
                <ngb-datepicker [(ngModel)]="endDate" #endPicker></ngb-datepicker>
            </div>
        </div>
        <div class="row">
            <div class="col text-center">
                <br><br>
                <button class="btn btn-primary" (click)="sendRequest()">{{'statistics.send-creation-request' | translate}}</button>
            </div>
        </div>
    </ng-container>
</on-two-columns-display>