import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Expense } from '../../_models/Expense';
import { ExpenseType } from '../../_models/ExpenseType';
import { API_V3_URL } from 'on-common/constants';
import { IApiActionResult, IApiResult } from 'on-common/_models/ApiResult';

const EXPENSE_URL = API_V3_URL + '/expense';

@Injectable({ providedIn: 'root' })
export class ExpenseService {
  constructor(private http: HttpClient) { }

  GetExpensesOfCurrentUser(): Observable<IApiResult<Expense[]>> {
    return this.http.get<IApiResult<Expense[]>>(EXPENSE_URL + '/currentuser');
  }

  GetExpenseDetails(id: number): Observable<IApiResult<Expense>> {
    return this.http.get<IApiResult<Expense>>(EXPENSE_URL + '/' + id);
  }

  RemoveExpenses(ids: number[]): Observable<IApiActionResult> {
    return this.http.post<IApiActionResult>(EXPENSE_URL + '/delete', ids);
  }

  CreateManualExpense(expenseToCreate: Expense, tempImageFileName: string): Observable<IApiResult<Expense>> {
    return this.http.post<IApiResult<Expense>>(EXPENSE_URL + '/create', {
      ExpenseToCreate: expenseToCreate,
      TempImageName: tempImageFileName
    });
  }

  EditExpense(expenseToEdit: Expense): Observable<IApiResult<Expense>> {
    return this.http.post<IApiResult<Expense>>(EXPENSE_URL + '/edit', expenseToEdit);
  }

  GetExpenseTypes(): Observable<IApiResult<ExpenseType[]>> {
    return this.http.get<IApiResult<ExpenseType[]>>(EXPENSE_URL + '/types');
  }

  ValidExpense(expenseToValid: Expense): Observable<IApiResult<Expense>> {
    return this.http.post<IApiResult<Expense>>(EXPENSE_URL + '/validate', {
      ExpenseId: expenseToValid.Id,
      AmountToBeReimbursed: expenseToValid.AmountToBeReimbursed
    });
  }

  RejectExpense(expenseToReject: Expense): Observable<IApiResult<Expense>> {
    return this.http.post<IApiResult<Expense>>(EXPENSE_URL + '/refuse', {
      ExpenseId: expenseToReject.Id,
      Comment: expenseToReject.ValidationComment
    });
  }

  DuplicateExpense(expenseId: number): Observable<IApiResult<Expense>> {
    return this.http.post<IApiResult<Expense>>(EXPENSE_URL + '/duplicate/' + expenseId, null);
  }

  GetTotalMileageExpense(expense: Expense): Observable<IApiResult<number>> {
    return this.http.post<IApiResult<number>>(EXPENSE_URL + '/mileage/total', expense);
  }
}
