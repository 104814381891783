import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'on-report-validation-gridcell',
  templateUrl: './report-validation-gridcell.component.html',
  styleUrls: ['./report-validation-gridcell.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportValidationGridcellComponent implements ICellRendererAngularComp {

  params: any;
  date: any;

  constructor() { }

  refresh(params: any): boolean {
    return false;
  }

  agInit(params): void {
    this.params = params;
  }

  afterGuiAttached?(params?: import('ag-grid-community').IAfterGuiAttachedParams): void {
  }


  sendToValidation() {
    this.params.context.parent.sendToValidation(this.params.data);
  }

}
