import { AccountService } from './../_services/account.service';
import { Login } from '../_models/Login';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'on-common/_services/toast.service';

@Component({
  selector: 'on-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  msLoginSub;
  showMicrosoftLogin: boolean; // environment.windowsLogin;

  constructor(
    private accountService: AccountService,
    private toastService: ToastService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.msLoginSub = this.activatedRoute.data.subscribe(
      (d) => (this.showMicrosoftLogin = d.msExternalLogin),
    );
  }

  ngOnDestroy() {
    this.msLoginSub.unsubscribe();
  }

  Login(loginmodel?: Login) {
    this.accountService
      .LogIn(loginmodel.username, loginmodel.password)
      .subscribe(
        (response) => {
          if (response.access_token) {
            this.toastService.success('Connexion réussie');
            if (this.activatedRoute.snapshot.queryParams['returnUrl']) {
              this.router.navigate([
                '/' + this.activatedRoute.snapshot.queryParams['returnUrl'],
              ]);
            } else {
              // TODO replace to default
              this.router.navigate(['/expenses']);
            }
          } else {
            this.toastService.error(
              'Une erreur est intervenue lors de la connexion, merci de réessayer ultérieurement',
              'Echec de connexion',
            );
          }
        },
        (error) => {
          if (error.error.indexOf('invalid_grant') > -1) {
            this.toastService.error(
              'Identifiants incorrects !',
              'Echec de connexion',
            );
          } else {
            this.toastService.error(
              'Une erreur inconnue est survenue, merci de réessayer ultérieurement.',
              'Echec de connexion',
            );
          }
        },
      );
  }
}
