import { Component, OnInit } from '@angular/core';

import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
// import { ShareButtons } from 'ngx-sharebuttons/buttons';

@Component({
  selector: 'on-sharebuttons',
  templateUrl: './sharebuttons.component.html',
  styleUrls: ['./sharebuttons.component.scss'],
})
export class SharebuttonsComponent implements OnInit {
  // fbIcon = faFacebookSquare;
  // pinIcon = faPinterest;
  // tweetIcon = faTwitterSquare;

  constructor() {}

  facebook = faFacebookF;
  twitter = faTwitter;
  linkedin = faLinkedinIn;

  ngOnInit() {}
}
