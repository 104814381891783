import { TranslateService } from '@ngx-translate/core';
import { ExpenseListComponent } from './../_shared/expense-list/expense-list.component';
import { Expense } from 'on-shared/_models/Expense';

import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';

import { Router } from '@angular/router';
import swal from 'sweetalert2';
// import { constants } from 'fs';
import { ExpensesCriteria } from 'on-shared/_models/ExpenseCriteria';
import { ReportsActions } from 'on-shared/stores/reports/reports.actions';
import { Select, Store } from '@ngxs/store';
import { ExpenseState } from 'on-shared/stores/expense/expense.state';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExpenseAction } from 'on-shared/stores/expense/expense.actions';
import { UserState } from 'on-shared/stores/user/user.state';
import { OnUser } from 'on-shared/_models/OnUser';
import { ActionButton } from 'on-common/_models/ActionButton';
import { ToastService } from 'on-common/_services/toast.service';
import { DialogService } from 'on-common/_services/dialog.service';

@Component({
  selector: 'on-my-expenses-page',
  templateUrl: './my-expenses-page.component.html',
  styleUrls: ['./my-expenses-page.component.scss'],
})
export class MyExpensesPageComponent implements OnInit, OnDestroy {
  expensesToShow: Expense[];
  totalExpenses: number;
  page = 1;
  pageSize = 20;
  customPageSize: number;
  isLoading: boolean;

  freeAccount: boolean;

  filter: ExpensesCriteria;

  addActions: ActionButton[];

  reportActions: ActionButton[];

  deleteActions: ActionButton[];

  @ViewChild('expensesList') expensesList: ExpenseListComponent;

  @Select(ExpenseState.expenses) expenses$: Observable<Expense[]>;
  @Select(UserState.currentUser) currentUser$: Observable<OnUser>;
  destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private toastService: ToastService,
    private translate: TranslateService,
    private store: Store,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        this.freeAccount = user.License ? false : true;
      }

      this.initActionButtons();
    });

    this.store.dispatch(new ExpenseAction.LoadExpensesIfNeeded());
    this.loadMyExpenses();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initActionButtons() {
    // If already initialized, return
    if (this.addActions && this.addActions.length) {
      return;
    }

    this.addActions = [
      {
        text: this.translate.instant('expense.add'),
        icon: 'fa-plus',
        customClass: 'btn-primary',
        children: [
          {
            text: this.translate.instant('expense.manual-expense'),
            icon: 'fa-edit',
            actionOnClick: () => {
              this.router.navigate(['expenses', 'create']);
            },
          },
          {
            text: this.translate.instant('expense.kilometric-costs'),
            icon: 'fa-road',
            actionOnClick: () => {
              this.router.navigate(['expenses', 'create'], {
                queryParams: { type: 'km' },
              });
            },
          },
          {
            text: this.translate.instant('expense.duplicate'),
            icon: 'fa-copy',
            actionOnClick: () => {
              this.duplicateExpense();
            }
          },
          {
            isSeparation: true,
          },
          {
            text: this.translate.instant('expense.analyze-images'),
            icon: 'fa-camera',
            actionOnClick: () => {
              this.router.navigate(['expenses', 'send']);
            },
          },
        ],
      },
    ];

    this.deleteActions = [
      {
        text: '',
        icon: 'fa-trash',
        customClass: 'btn-danger',
        actionOnClick: () => {
          this.removeSelected();
        },
      },
    ];

    this.reportActions = [
      {
        text: this.translate.instant('expense.reports'),
        icon: 'fa-folder-open',
        customClass: 'btn-info',
        children: [
          {
            text: this.translate.instant('expense.report-empty'),
            icon: 'fa-folder-open',
            actionOnClick: () => {
              this.createReportEmpty();
            },
          },
          {
            text: this.translate.instant(
              'expense.report-with-selected-expenses',
            ),
            icon: 'fa-copy',
            actionOnClick: () => {
              this.createReportWithSelected();
            },
          },
          {
            isSeparation: true,
          },
          {
            text: this.translate.instant('expense.show-reports'),
            icon: 'fa-link',
            actionOnClick: () => {
              this.router.navigate(['reports']);
            },
          },
        ],
      },
    ];
  }

  loadMyExpenses() {
    this.isLoading = true;
    this.expenses$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      if (response) {
        this.expensesToShow = response.map((c) => new Expense(c));
        this.isLoading = false;
      }
    });
  }

  createReportEmpty() {
    this.store
      .dispatch(new ReportsActions.CreateEmpty())
      .subscribe((_) => this.loadMyExpenses());
  }

  createReportWithSelected() {
    // const idsToInclude = this.getSelectedIDs();
    const idsToInclude = this.expensesList.getSelectedRowsIDs();
    if (!idsToInclude) {
      return;
    }
    this.store.dispatch(new ReportsActions.CreateWithSelected(idsToInclude));
  }

  getSelectedIDs() {
    const idsToDelete = this.expensesToShow
      .filter((exp) => exp.IsSelected)
      .map((exp) => exp.Id);

    if (idsToDelete.length === 0) {
      this.toastService.warning(
        this.translate.instant('expense.no-selected-expense'),
      );
      return undefined;
    }

    return idsToDelete;
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.expensesList.setPaginationSize(pageSize);

    if (pageSize === 10 || pageSize === 20 || pageSize === 50) {
      this.customPageSize = null;
    }
  }

  // Call when choice with input
  onChangePageSize() {
    if (this.customPageSize) {
      this.changePageSize(this.customPageSize);
    }
  }

  pageChanged() {
    setTimeout(() => {
      this.loadMyExpenses();
    }, this.pageSize);
  }

  removeSelected() {
    // const idsToDelete = this.getSelectedIDs();
    const idsToDelete = this.expensesList.getSelectedRowsIDs();

    if (!idsToDelete) {
      return;
    }
    swal
      .fire({
        title: this.translate.instant('expense.delete-expenses-title'),
        text: this.translate.instant('expense.delete-expenses-message', {
          numberOfExpenses: idsToDelete.length,
        }),
        icon: 'question',
        showConfirmButton: true,
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value !== undefined) {
          this.dialogService.ShowLoading(this.translate.instant('expense.deleting'));
          this.store.dispatch(new ExpenseAction.RemoveExpenses(idsToDelete)).subscribe({
            next: () => {
              this.dialogService.CloseLoading();
            },
            error: () => {
              this.dialogService.CloseLoading();
            }
          });
        }
      });
  }

  filterChanged(newFilters: ExpensesCriteria) {
    this.filter = newFilters;
    this.loadMyExpenses();
  }

  resetFilter() {
    this.filter = {
      AreInReport: false,
      // MinTotal: 0,
      // MaxTotal: 10000,
      MinCreationDate: null,
      MaxCreationDate: null,
      MinDateOnExpense: null,
      MaxDateOnExpense: null,
    };
    this.loadMyExpenses();
  }

  reload() {
    this.isLoading = true;
    this.store.dispatch(new ExpenseAction.LoadExpenses());
  }

  duplicateExpense() {
    const selectedRows = this.expensesList.getSelectedRows();
    if (!selectedRows?.length) {
      this.toastService.warning(
        this.translate.instant('expense.no-selected-expense')
      );

      return;
    }

    if (selectedRows.length > 1) {
      this.toastService.warning(
        this.translate.instant('expense.more-one-selected-expense')
      );

      return;
    }
    this.dialogService.ShowLoading(this.translate.instant('expense.duplicating'));
    this.store.dispatch(new ExpenseAction.DuplicateExpense(selectedRows[0].Id)).subscribe({
      next: () => this.dialogService.CloseLoading(),
      error: () => this.dialogService.CloseLoading()
    });
  }
}
