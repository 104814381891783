import { EmployeeRole } from 'on-shared/_models/EmployeeRole';
import { ExpertedCompany } from 'onexpense/accountant-expert/_models/ExpertedCompany';

export class AccountantExpertStateModel {
  companies: ExpertedCompany[];
  selectedCompanyEmployees: EmployeeRole[];
  shouldReload: boolean;

  constructor(model?: AccountantExpertStateModel) {
    if (model) {
      this.companies = (model.companies || []).map((c) => new ExpertedCompany(c));
      this.selectedCompanyEmployees = model.selectedCompanyEmployees.map(emp => new EmployeeRole(emp));
      this.shouldReload = model.shouldReload;
    } else {
      this.companies = null;
      this.shouldReload = true;
      this.selectedCompanyEmployees = null;
    }
  }
}
