import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Report } from '../../_models/Report';
import { API_V2_URL } from 'on-common/constants';
import { IApiActionResult, IApiResult } from 'on-common/_models/ApiResult';

const REPORT_URL = API_V2_URL + '/report';
const GENERATION_URL = API_V2_URL + '/generation';

@Injectable({ providedIn: 'root' })
export class ReportsService {
  constructor(private http: HttpClient) { }

  // Api calls
  GetReportsOfCurrentUser(): Observable<IApiResult<Report[]>> {
    return this.http.get<IApiResult<Report[]>>(REPORT_URL + '/currentuser');
  }

  Create(reportToCreate: Report): Observable<IApiResult<Report>> {
    return this.http.post<IApiResult<Report>>(
      REPORT_URL + '/create',
      reportToCreate,
    );
  }

  CreateFromLast(reportToCreate: Report): Observable<IApiResult<Report>> {
    return this.http.post<IApiResult<Report>>(
      REPORT_URL + '/createfromlast',
      reportToCreate,
    );
  }

  RemoveReports(ids: number[]): Observable<IApiActionResult> {
    return this.http.post<IApiActionResult>(REPORT_URL + '/delete', ids);
  }

  GetReport(reportId: number): Observable<IApiResult<Report>> {
    return this.http.get<IApiResult<Report>>(REPORT_URL + '/' + reportId);
  }

  UpdateReport(reportToUpdate: Report): Observable<IApiResult<Report>> {
    return this.http.post<IApiResult<Report>>(
      REPORT_URL + '/edit',
      reportToUpdate,
    );
  }

  Validate(reportId: number): Observable<IApiResult<Report>> {
    return this.http.post<IApiResult<Report>>(
      REPORT_URL + '/validate',
      reportId,
    );
  }

  Refuse(reportId: number, comment: string): Observable<IApiResult<Report>> {
    return this.http.post<IApiResult<Report>>(REPORT_URL + '/refuse', {
      ReportId: reportId,
      Comment: comment,
    });
  }

  ExportCSV(reportId: number): Observable<IApiResult<string>> {
    return this.http.get<IApiResult<string>>(
      GENERATION_URL + '/csv/' + reportId,
    );
  }

  ExportExcel(reportId: number): Observable<IApiResult<string>> {
    return this.http.get<IApiResult<string>>(
      GENERATION_URL + '/excel/' + reportId,
    );
  }

  ExportPDF(reportId: number): Observable<IApiResult<string>> {
    return this.http.get<IApiResult<string>>(
      GENERATION_URL + '/pdf/' + reportId,
    );
  }

  ExportZip(reportId: number): Observable<IApiResult<string>> {
    return this.http.get<IApiResult<string>>(
      GENERATION_URL + '/zip/' + reportId,
    );
  }

  SendByMail(reportId: number): Observable<IApiActionResult> {
    return this.http.post<IApiActionResult>(REPORT_URL + '/email', [reportId]);
  }

  SendToValidation(
    reportId: number,
    companyId: number,
    managerId: number,
  ): Observable<IApiResult<Report>> {
    return this.http.get<IApiResult<Report>>(
      REPORT_URL +
      '/sendValidation/' +
      companyId +
      '/' +
      managerId +
      '/' +
      reportId,
    );
  }

  GetToSign(): Observable<IApiResult<Report[]>> {
    return this.http.get<IApiResult<Report[]>>(REPORT_URL + '/toSign');
  }

  GetSigned(): Observable<IApiResult<Report[]>> {
    return this.http.get<IApiResult<Report[]>>(REPORT_URL + '/signed');
  }

  GetValidatedReportsForAccountant(): Observable<IApiResult<Report[]>> {
    return this.http.get<IApiResult<Report[]>>(
      REPORT_URL + '/readyforaccountant',
    );
  }
}
