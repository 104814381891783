import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ReportListComponent } from '../../report-list/report-list.component';

@Component({
  selector: 'on-export-gridcell',
  templateUrl: './export-gridcell.component.html',
  styleUrls: ['./export-gridcell.component.scss'],
})
export class ExportGridcellComponent implements ICellRendererAngularComp {
  params: any;
  reportId: number;

  form: UntypedFormGroup;
  isInVp = false;

  get parent(): ReportListComponent {
    return this.params.context.parent as ReportListComponent
  }

  constructor(private fb: UntypedFormBuilder) { }

  refresh(params: any): boolean {
    return false;
  }
  agInit(params: any): void {
    this.params = params;
    this.reportId = params.data.Id;
    this.isInVp = this.parent.isInVP;

    this.form = this.fb.group({ select: ['placeholder'] });
  }

  afterGuiAttached?(
    params?: import('ag-grid-community').IAfterGuiAttachedParams,
  ): void { }

  selectionChanged() {
    const choice = this.form.getRawValue().select; // get selection
    // If 'placeholder' => return
    if (choice === 'placeholder') {
      return;
    }

    // Export
    if (choice === 'csv') {
      this.exportCSV();
    } else if (choice === 'excel') {
      this.exportExcel();
    } else if (choice === 'zip') {
      this.exportZip();
    } else if (choice === 'pdf') {
      this.exportPdf();
    } else if (choice === 'excelAndArchive') {
      this.sendByMail();
    }
    this.form.get('select').setValue('placeholder'); // Set selected value
  }

  exportCSV() {
    this.parent.exportCSV(this.reportId);
  }

  exportExcel() {
    this.parent.exportExcel(this.reportId);
  }

  exportZip() {
    this.parent.exportZip(this.reportId);
  }

  exportPdf() {
    this.parent.exportPdf(this.reportId);
  }

  sendByMail() {
    this.parent.sendByMail(this.reportId);
  }
}
