<!-- With link -->
<a
  class="row m-0"
  [routerLink]="item.link"
  [queryParams]="item.queryParams"
  [class.active]="item.selected"
  (click)="onItemClick()"
  *ngIf="!item.children && item.link">
  <div class="col-11 p-0 d-flex align-items-center">
    <nb-icon [icon]="item.icon" class="item-icon" *ngIf="item.icon"></nb-icon>
    <span>
      {{ item.title }}
    </span>
  </div>
  <div class="col-1 p-0" *ngIf="item.badges"><span class="custom-badge" [ngStyle]="{'background-color': item.badgeColor }">{{ item.badges }}</span></div>
</a>
<!---->

<!-- With url -->
<a
  class="row m-0"
  [href]="item.url"
  [target]="item.target"
  [class.active]="item.selected"
  *ngIf="!item.children && item.url">
  <div class="col-11 p-0 d-flex align-items-center">
    <nb-icon [icon]="item.icon" class="item-icon" *ngIf="item.icon"></nb-icon>
    <span>
      {{ item.title }}
    </span>
  </div>
  <div class="col-1 p-0" *ngIf="item.badges"><span class="custom-badge" [ngStyle]="{'background-color': item.badgeColor }">{{ item.badges }}</span></div>
</a>
<!---->

<!-- With children -->
<a
  [routerLink]="item.link"
  [queryParams]="item.queryParams"
  [class.active]="item.selected"
  (click)="displayChildren()"
  *ngIf="item.children">
  <div class="d-flex align-items-center">
    <nb-icon [icon]="item.icon" class="item-icon" *ngIf="item.icon"></nb-icon>
    <span>
      {{ item.title }}
    </span>
  </div>
  <div class="chevron">
    <nb-icon icon="fa-chevron-down" *ngIf="item.expanded"></nb-icon>
    <nb-icon icon="fa-chevron-left" *ngIf="!item.expanded"></nb-icon>
  </div>
</a>
<ul class="list-children" [@toggle]="toggleState">
  <ng-container *ngFor="let children of item.children">
    <li *ngIf="!children.hidden">
      <!-- Link -->
      <a
      class="col pe-0"
      [routerLink]="children.link"
      [queryParams]="children.queryParams"
      [class.active]="children.selected"
      (click)="onItemClick()"
      *ngIf="children.link">
        <div class="col-11 p-0 d-flex align-items-center">
          <nb-icon [icon]="children.icon" class="item-icon" *ngIf="children.icon"></nb-icon>
          <span>
            {{ children.title }}
          </span>
        </div>
        <div class="col-1 p-0" *ngIf="children.badges"><span class="custom-badge" [ngStyle]="{'background-color': children.badgeColor }">{{ children.badges }}</span></div>
      </a>
      <!---->
      <!-- Url -->
      <a
      class="col pe-0"
      [href]="children.url"
      [target]="children.target"
      [class.active]="children.selected"
      *ngIf="children.url">
        <div class="col-11 p-0 d-flex align-items-center">
          <nb-icon [icon]="children.icon" class="item-icon" *ngIf="children.icon"></nb-icon>
          <span>
            {{ children.title }}
          </span>
        </div>
        <div class="col-1 p-0" *ngIf="children.badges"><span class="custom-badge" [ngStyle]="{'background-color': children.badgeColor }">{{ children.badges }}</span></div>
      </a>
    </li>
  </ng-container>
</ul>
<!---->

