import { AccountingCode } from '../../../company/accountant/_models/AccountingCode';
import { AccountantExport } from '../../../company/_models/AccountantExport';

// tslint:disable-next-line: no-namespace
export namespace AccountantExportAction {

  export class LoadAccountingCodes {
    public static readonly type = `[AccountantExport] load the accounting codes`;

    constructor(public companyId: number) { }
  }

  export class LoadAccountingCodesIfNeeded {
    public static readonly type = `[AccountantExport] load the accounting codes if needed`;

    constructor(public companyId: number) { }
  }

  export class SaveAccountingCodes {
    public static readonly type = `[AccountantExport] save the accounting codes`;

    constructor(public companyId: number, public accountingCodes: AccountingCode[]) { }
  }

  export class LoadAccountantExports {
    public static readonly type = `[AccountantExport] load accountant exports`;

    constructor() { }
  }

  export class LoadAccountantExportsIfNeeded {
    public static readonly type = `[AccountantExport] load accountant exports if needed`;

    constructor() { }
  }

  export class LoadTreatedAccountantExports {
    public static readonly type = `[AccoutantExport] load treated accountant export`;
    constructor() { }
  }

  export class LoadTreatedAccountantExportsIfNeeded {
    public static readonly type = `[AccountantExport] load treated accountant exports if needed`;

    constructor() { }
  }

  export class LoadAccountantExportById {
    public static readonly type = `[AccountantExport] load accountant export by id`;

    constructor(public exportId: number) { }
  }

  export class CreateAccountantExport {
    public static readonly type = `[AccountantExport] create accountant export`;

    constructor(public exportToCreate: AccountantExport, public callback?: (exportId: number) => void) { }
  }

  export class UpdateAccountantExport {
    public static readonly type = `[AccountantExport] update accountant export`;

    constructor(public exportToUpdate: AccountantExport) { }
  }

  export class DeleteAccountantExport {
    public static readonly type = `[AccountantExport] delete accountant export`;

    constructor(public exportId: number) { }
  }

  export class ArchivedInProbativeValue {
    public static readonly type = `[AccountantExport] archived export in probative value`;

    constructor(public exportToArchived: AccountantExport) { }
  }

  export class MarkAsTreated {
    public static readonly type = `[AccountantExport] mark export as treated`;

    constructor(public exportToTreat: AccountantExport) { }
  }

  export class MustReloadAccountantExports {
    public static readonly type = `[AccountantExport] must reload accountant exports`;

    constructor() { }
  }

}
