import { OnUser } from '../../_models/OnUser';

export class UserStateModel {
  current: OnUser;
  selectedUser?: OnUser;
  isSuccess?: boolean;

  constructor(model?: UserStateModel) {
    if (model) {
      this.current = new OnUser(model.current);
      this.selectedUser = new OnUser(model.selectedUser);
    } else {
      this.current = null;
    }
  }
}
