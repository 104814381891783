<div *ngIf="!isMultiple">
  <ng-select [disabled]="isDisabled" [items]="allTypes" groupBy="CategoryName" [(ngModel)]="selectedType"
    [ngModelOptions]="{standalone: true}" (change)="expenseTypeChanged()" [clearable]="false" appendTo="body"
    [searchFn]="searchInCategoryAndName">
    <ng-template ng-label-tmp let-item="item">
      <div class="d-flex">
        <div class="icon-type d-flex justify-content-left me-2">
          <nb-icon [icon]="'fa-' + item.Icon"></nb-icon>
        </div>
        <div class="d-flex align-items-center p-0"> {{item.Name}} </div>
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div class="d-flex">
        <div class="icon-type d-flex justify-content-left me-2">
          <nb-icon [icon]="'fa-' + item.Icon"></nb-icon>
        </div>
        <div class="d-flex align-items-center p-0"> {{item.Name}} </div>
      </div>
    </ng-template>
    <ng-template ng-optgroup-tmp let-item="item" let-index="index">
      <span class="category-header">{{item.CategoryName}}</span>
    </ng-template>
  </ng-select>
</div>

<div *ngIf="isMultiple">
  <ng-select (change)="expenseTypeChanged()" [items]="allTypes" bindLabel="Name" bindValue="Id" groupBy="CategoryName"
    [multiple]="true" [placeholder]="'expense.select-expense-types' | translate" [(ngModel)]="selectedType"></ng-select>
</div>