import { Component, OnInit } from '@angular/core';
import { AccountService } from 'on-auth/_services/account.service';

@Component({
  selector: 'on-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentYear: number;
  constructor(public accountService: AccountService) { }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }

}
