import { EmployeeRole } from './EmployeeRole';
import { CompanyEmployeesData } from './CompanyEmployeesData';
import { OnUser } from './OnUser';
import { CustomField } from './CustomField';
import { CompanySettings } from './CompanySettings';
import { Country } from 'on-common/_models/Country';
import { Currency } from 'on-common/_models/Currency';
import { EmployeeCategory } from './EmployeeCategory';

export class Company {
  Id: number;
  Name: string;
  AdminEmail?: string;
  AdminPhone?: string;
  CompanyGroupId?: number;
  CompanyGroupName?: string;
  SIRET: string;
  Country?: Country;
  Currency?: Currency;
  ManagerCanEdit: boolean;
  AccountantCanEdit: boolean;
  IsVP: boolean;
  CompanyEmployeesData?: CompanyEmployeesData;
  Hierarchy?: OnUser[];
  CustomFields?: CustomField[];
  IsAccountantExpert: boolean;
  HasFloatingLicense: boolean;
  EmployeeCategories?: EmployeeCategory[];
  UseRestaurantTicket: boolean;
  RestaurantTicketValue: number;

  Settings: CompanySettings;

  Employees: EmployeeRole[]; // Used only for accountant expert

  constructor(model?: Company) {
    if (model) {
      this.Id = model.Id;
      this.Name = model.Name;
      this.AdminEmail = model.AdminEmail;
      this.AdminPhone = model.AdminPhone;
      this.CompanyGroupId = model.CompanyGroupId;
      this.CompanyGroupName = model.CompanyGroupName;
      this.SIRET = model.SIRET;
      this.Country = new Country(model.Country);
      this.Currency = new Currency(model.Currency);
      this.ManagerCanEdit = model.ManagerCanEdit;
      this.AccountantCanEdit = model.AccountantCanEdit;
      this.IsVP = model.IsVP;
      this.Employees = model.Employees;
      this.CompanyEmployeesData = model.CompanyEmployeesData ? new CompanyEmployeesData(model.CompanyEmployeesData) : null;
      this.Hierarchy = model.Hierarchy ? model.Hierarchy.map((c) => new OnUser(c)) : null;
      this.CustomFields = model.CustomFields ? model.CustomFields.map((c) => new CustomField(c)) : null;
      this.EmployeeCategories = model.EmployeeCategories ? model.EmployeeCategories.map((c) => new EmployeeCategory(c)) : null;
      this.Settings = model.Settings ? new CompanySettings(model.Settings) : new CompanySettings();
      this.IsAccountantExpert = model.IsAccountantExpert;
      this.HasFloatingLicense = model.HasFloatingLicense;
      this.UseRestaurantTicket = model.UseRestaurantTicket;
      this.RestaurantTicketValue = model.RestaurantTicketValue;
    } else {
      this.Id = null;
      this.Name = null;
      this.AdminEmail = null;
      this.AdminPhone = null;
      this.CompanyGroupId = null;
      this.CompanyGroupName = null;
      this.SIRET = null;
      this.Country = null;
      this.Currency = null;
      this.ManagerCanEdit = null;
      this.AccountantCanEdit = null;
      this.IsVP = null;
      this.Employees = null;
      this.CompanyEmployeesData = null;
      this.Hierarchy = null;
      this.CustomFields = null;
      this.EmployeeCategories = null;
      this.Settings = null;
      this.IsAccountantExpert = null;
      this.HasFloatingLicense = null;
      this.UseRestaurantTicket = false;
      this.RestaurantTicketValue = null;
    }
  }
}
