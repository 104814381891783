import { CustomField } from './../../../shared/_models/CustomField';

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { CompanyState } from '../../../shared/stores/company/company.state';
import { Observable, Subject } from 'rxjs';
import { Company } from '../../../shared/_models/Company';
import { takeUntil } from 'rxjs/operators';
import { CompanyAction } from '../../../shared/stores/company/company.action';

@Component({
  selector: 'on-company-custom-fields-tab',
  templateUrl: './company-custom-fields-tab.component.html',
  styleUrls: ['./company-custom-fields-tab.component.scss']
})
export class CompanyCustomFieldsTabComponent implements OnInit, OnDestroy {

  @Input() companyId: number;

  companyFields: CustomField[];
  @Select(CompanyState.current) company$: Observable<Company>;
  destroy$ = new Subject<void>();

  isLoading = false;

  constructor(private store: Store) { }

  ngOnInit() {
    this.store.dispatch(new CompanyAction.LoadCustomFieldsIfNeeded());
    this.loadFields();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadFields() {
    this.isLoading = true;

    this.company$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response.CustomFields) {
        this.companyFields = (response.CustomFields || []).map((c) => new CustomField(c));

        this.isLoading = false;
      }
    });
  }

  saveFields() {
    this.store.dispatch(new CompanyAction.SaveCustomFields(this.companyFields));
  }

  reload() {
    this.isLoading = true;
    this.store.dispatch(new CompanyAction.LoadCustomFields());
  }

}
