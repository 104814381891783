import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input } from '@angular/core';
import { OnUser } from 'on-shared/_models/OnUser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'on-shared/stores/user/user.service';
import { ToastService } from 'on-common/_services/toast.service';

@Component({
  selector: 'on-manage-employee-settings-modal',
  templateUrl: './manage-employee-settings-modal.component.html',
  styleUrls: ['./manage-employee-settings-modal.component.scss'],
})
export class ManageEmployeeSettingsModalComponent implements OnInit {
  @Input() User: OnUser;
  @Input() connectedUser: OnUser;

  currentUser: OnUser;
  disable: boolean;

  isLoading = false;

  constructor(
    public modal: NgbActiveModal,
    private toastService: ToastService,
    private translateService: TranslateService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.disable = this.connectedUser.Role !== 'ADMIN';
    this.isLoading = true;
    this.userService.GetUser(this.User.Id).subscribe(
      (response) => {
        this.User = response.Result;
        if (!this.User.FirstName) {
          this.User.FirstName = '';
        }
        if (!this.User.LastName) {
          this.User.LastName = '';
        }
        this.isLoading = false;
      },
      (err) =>
        this.toastService.error(
          this.translateService.instant(
            'company.employees.manage-employee.cannot-update',
          ),
        ),
    );
  }
}
