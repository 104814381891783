import { AccountingPlan } from '../../_models/AccountingPlan';
// tslint:disable-next-line: no-namespace
export namespace AccountingPlanAction {

  export class LoadAccountingPlans {
    public static readonly type = '[Accountant plan] load accounting plans for user\'s company';
    constructor() { }
  }

  export class UpdateAccountingPlans {
    public static readonly type = '[Accountant plan] update accounting plan for company';
    constructor(public plansToUpdate: AccountingPlan[]) { }
  }
}
