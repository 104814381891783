import { AccountantExpertRoutingModule } from './accountant-expert-routing.module';
import { CreateCompanyPageComponent } from './create-company-page/create-company-page.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { SelectEmployeeComponent } from './_shared/select-employee/select-employee.component';
import { ListExpertedCompaniesPageComponent } from './list-experted-companies-page/list-experted-companies-page.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { ConnectAsCellComponent } from './list-experted-companies-page/connect-as-cell/connect-as-cell.component';
import { ChangeActivableLicensesCellComponent } from './list-experted-companies-page/change-activable-licenses-cell/change-activable-licenses-cell.component';
import { ChangeIsVpCellComponent } from './list-experted-companies-page/change-is-vp-cell/change-is-vp-cell.component';



@NgModule({
    declarations: [
        SelectEmployeeComponent,
        CreateCompanyPageComponent,
        ListExpertedCompaniesPageComponent,
        ConnectAsCellComponent,
        ChangeActivableLicensesCellComponent,
        ChangeIsVpCellComponent
    ],
    imports: [
        SharedModule,
        AccountantExpertRoutingModule,
        NgbModalModule,
        AgGridModule
    ],
    exports: [
        SelectEmployeeComponent
    ]
})
export class AccountantExpertModule { }
