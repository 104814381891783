import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'on-resend-invitation-gridcell',
  templateUrl: './resend-invitation-gridcell.component.html',
  styleUrls: ['./resend-invitation-gridcell.component.scss']
})
export class ResendInvitationGridcellComponent implements ICellRendererAngularComp {

  params: any;

  constructor() { }

  resendInvitation(event) {
    this.params.context.parent.resendInvitation(this.params.data);
  }

  refresh(params: any): boolean {
    return false;
  }
  agInit(params: import('ag-grid-community').ICellRendererParams): void {
    this.params = params;
  }
  afterGuiAttached?(params?: import('ag-grid-community').IAfterGuiAttachedParams): void {
  }


}
