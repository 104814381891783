import { ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'on-change-is-vp-cell',
  templateUrl: './change-is-vp-cell.component.html',
  styleUrls: ['./change-is-vp-cell.component.scss']
})
export class ChangeIsVpCellComponent implements ICellRendererAngularComp {

  params: any;
  isChecked: boolean;

  constructor() { }

  refresh(params: any): boolean {
    this.params = params;
    this.isChecked = this.params.value === true;
    return true;
  }

  agInit(params: any): void {
    this.params = params;
    this.isChecked = this.params.value === true;
  }

  afterGuiAttached?(params?: import('ag-grid-community').IAfterGuiAttachedParams): void {
  }

  changeIsVp() {
    this.params.context.parentList.activateVpSetting(this.params.node.data);
  }
}
