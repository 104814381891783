import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'on-form-date-picker',
  templateUrl: './form-date-picker.component.html',
  styleUrls: ['./form-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormDatePickerComponent),
      multi: true
    }
  ]
})
export class FormDatePickerComponent implements OnInit, ControlValueAccessor {

  @Input() disabled = false;

  selectedDate: Date;

  onTouch: any;
  onChange: any;

  constructor() { }

  ngOnInit() {

  }

  dateChanged() {
    if (this.onChange) {
      this.onChange(this.selectedDate);
    }
    if (this.onTouch) {
      this.onTouch();
    }
  }

  dateBlurred() {
    if (this.onTouch) {
      this.onTouch();
    }
  }

  writeValue(obj: any): void {
    this.selectedDate = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
