export class CompanyInvitation {
  CompanyId: number;
  InvitedEmail: string;
  ExpirationDate: Date;
  DoesUserExists?: boolean;
  CompanyName?: string;

  constructor(model?: CompanyInvitation) {
    if (model) {
      this.CompanyId = model.CompanyId;
      this.InvitedEmail = model.InvitedEmail;
      this.ExpirationDate = new Date(model.ExpirationDate);
      this.DoesUserExists = model.DoesUserExists;
      this.CompanyName = model.CompanyName;
    } else {
      this.CompanyId = null;
      this.InvitedEmail = null;
      this.ExpirationDate = new Date();
      this.DoesUserExists = null;
      this.CompanyName = null;
    }
  }
}
