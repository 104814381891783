import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AccountingCode } from '../../../company/accountant/_models/AccountingCode';
import { AccountantExport } from '../../../company/_models/AccountantExport';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { API_V2_URL } from 'on-common/constants';
import { DialogService } from 'on-common/_services/dialog.service';
import { IApiActionResult, IApiResult } from 'on-common/_models/ApiResult';

const ACCOUNTANT_EXPORT_URL = API_V2_URL + '/accountantexport';
const GENERATION_URL = API_V2_URL + '/generation';
const ACCOUNTING_CODE_URL = API_V2_URL + '/accountant/accountingcode';

@Injectable({ providedIn: 'root' })
export class AccountantExportService {
  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) { }

  GetAccountingCodes(companyId: number): Observable<IApiResult<AccountingCode[]>> {
    return this.http.get<IApiResult<AccountingCode[]>>(ACCOUNTING_CODE_URL + '/' + companyId);
  }

  SaveAccountingCodes(companyId: number, accountingCodes: AccountingCode[]): Observable<IApiResult<AccountingCode[]>> {
    return this.http.post<IApiResult<AccountingCode[]>>(ACCOUNTING_CODE_URL + '/' + companyId + '/save', accountingCodes);
  }

  GetAccountingCode(companyId: number, expenseTypeId: number): Observable<IApiResult<string>> {
    return this.http.get<IApiResult<string>>(ACCOUNTING_CODE_URL + '/get' + '/' + companyId + '/' + expenseTypeId);
  }

  GetCompanyExports(): Observable<IApiResult<AccountantExport[]>> {
    return this.http.get<IApiResult<AccountantExport[]>>(ACCOUNTANT_EXPORT_URL);
  }

  GetTreatedCompanyExports(): Observable<IApiResult<AccountantExport[]>> {
    return this.http.get<IApiResult<AccountantExport[]>>(ACCOUNTANT_EXPORT_URL + '/treated');
  }

  GetAccountantExport(exportId: number): Observable<IApiResult<AccountantExport>> {
    return this.http.get<IApiResult<AccountantExport>>(ACCOUNTANT_EXPORT_URL + '/' + exportId);
  }

  Create(exportToCreate: AccountantExport): Observable<IApiResult<AccountantExport>> {
    this.dialogService.ShowLoading(this.translateService.instant('company.create-export'));
    return this.http.post<IApiResult<AccountantExport>>(ACCOUNTANT_EXPORT_URL + '/create', exportToCreate)
      .pipe(tap(response => this.dialogService.CloseLoading(), response => this.dialogService.CloseLoading()));
  }

  Update(exportToUpdate: AccountantExport): Observable<IApiResult<AccountantExport>> {
    this.dialogService.ShowLoading(this.translateService.instant('company.update-export'));
    return this.http.post<IApiResult<AccountantExport>>(ACCOUNTANT_EXPORT_URL + '/update', exportToUpdate)
      .pipe(tap(response => this.dialogService.CloseLoading(), response => this.dialogService.CloseLoading()));
  }

  Delete(id: number): Observable<IApiActionResult> {
    return this.http.delete<IApiResult<AccountantExport>>(`${ACCOUNTANT_EXPORT_URL}/${id}`);
  }

  GenerateAccountPayments(exportId: number): Observable<IApiResult<string>> {
    this.dialogService.ShowLoading(this.translateService.instant('company.generate'));
    return this.http.get<IApiResult<string>>(GENERATION_URL + '/payments/' + exportId)
      .pipe(tap(() => this.dialogService.CloseLoading(), () => this.dialogService.CloseLoading()));
  }

  GenerateAccounting(exportId: number): Observable<IApiResult<string>> {
    this.dialogService.ShowLoading(this.translateService.instant('company.generate'));
    return this.http.get<IApiResult<string>>(GENERATION_URL + '/accounting/' + exportId)
      .pipe(tap(() => this.dialogService.CloseLoading(), () => this.dialogService.CloseLoading()));
  }

  ArchivedInProbativeValue(exportId: number): Observable<IApiResult<AccountantExport>> {
    this.dialogService.ShowLoading(this.translateService.instant('company.archiving'));
    return this.http.post<IApiResult<AccountantExport>>(`${ACCOUNTANT_EXPORT_URL}/archived/${exportId}`, null)
      .pipe(tap(() => this.dialogService.CloseLoading(), () => this.dialogService.CloseLoading()));
  }

  GenerateAccountingSoftware(exportId: number): Observable<IApiResult<string>> {
    this.dialogService.ShowLoading(this.translateService.instant('company.generate'));
    return this.http.get<IApiResult<string>>(GENERATION_URL + '/accounting/software/' + exportId)
      .pipe(tap(() => this.dialogService.CloseLoading(), () => this.dialogService.CloseLoading()));
  }

  MarkAsTreated(exportId: number): Observable<IApiResult<AccountantExport>> {
    this.dialogService.ShowLoading(this.translateService.instant('company.treating'));
    return this.http.post<IApiResult<AccountantExport>>(`${ACCOUNTANT_EXPORT_URL}/treated/${exportId}`, null)
      .pipe(tap(() => this.dialogService.CloseLoading(), () => this.dialogService.CloseLoading()));
  }
}
