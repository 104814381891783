<on-loading *ngIf="isLoading"></on-loading>
<div [hidden]="isLoading">
  <div class="m-1 pt-2" *ngIf="!showOnlyTreatedExports">
    <button class="btn btn-primary" (click)="create()">
      <nb-icon icon="fa-plus"></nb-icon>
      {{'company.accountant-export.new-export-btn' | translate}}
    </button>
  </div>
  <on-accountant-exports-grid [exports]="exports" [showDeleteButton]="!showOnlyTreatedExports"
    [showLockColumn]="canArchived">
  </on-accountant-exports-grid>
</div>