import { AccountingPlanValue } from "./AccountingPlanValue";

export class AccountingPlan {
  Id: number;
  Name: string;
  Order: number;
  PossibleValues: AccountingPlanValue[];

  constructor(model?: AccountingPlan) {
    if (model) {
      this.Id = model.Id;
      this.Name = model.Name;
      this.Order = model.Order;
      this.PossibleValues = model.PossibleValues ? model.PossibleValues.map(pv => new AccountingPlanValue(pv)) : null;
    } else {
      this.Id = 0;
      this.Name = null;
      this.Order = 0;
      this.PossibleValues = [];
    }
  }
}
