import { CreateRuleModalComponent } from './create-rule-modal/create-rule-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnUser } from './../../../shared/_models/OnUser';
import { Observable } from 'rxjs';
import { ToastService } from 'on-common/_services/toast.service';
import { Component, OnInit, Input } from '@angular/core';
import { Rule } from 'on-shared/_models/Rule';
import { ExpenseType } from 'on-shared/_models/ExpenseType';
import { CompanyService } from 'on-shared/stores/company/company.service';
import { ExpenseService } from 'on-shared/stores/expense/expense.service';
import { EmployeeCategory } from 'on-shared/_models/EmployeeCategory';
import { Select } from '@ngxs/store';
import { UserState } from 'on-shared/stores/user/user.state';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'on-rules-tab',
  templateUrl: './rules-tab.component.html',
  styleUrls: ['./rules-tab.component.scss']
})
export class RulesTabComponent implements OnInit {

  @Input() companyId: number;

  @Select(UserState.currentUser)
  connectedOnUser$: Observable<OnUser>;

  isLoading = false;
  ruleTypes = ['limit'];
  rules: Rule[] = [];
  ruleToCreate: Rule;
  expenseTypes: ExpenseType[];
  employeeCategories: EmployeeCategory[];

  constructor(
    private companyService: CompanyService,
    private expenseService: ExpenseService,
    private toastService: ToastService,
    private translate: TranslateService,
    private bsModal: NgbModal
  ) { }

  ngOnInit() {
    this.getRules();
    this.expenseService.GetExpenseTypes().subscribe(
      response => this.expenseTypes = response.Result,
      error => this.toastService.error('Impossible de charger les types de reçu', 'Erreur')
    );

    this.companyService.GetEmployeeCategories(this.companyId).subscribe(
      response => this.employeeCategories = response.Result,
      error => this.toastService.error(`Impossible de charger les catégories d'employés`, 'Erreur')
    );
  }

  selectedRuleTypeChanged(ruleToChange: Rule, event) {
    ruleToChange.Type = event;
  }

  getRules() {
    this.isLoading = true;
    this.companyService.GetRules().subscribe(
      response => {
        this.rules = response.Result;
        this.isLoading = false;
      },
      error => {
        this.toastService.error('Impossible de charger les règles', 'Erreur');
        this.isLoading = false;
      }
    );
  }

  reloadList(event) {
    this.getRules();
  }

  deleteRule(event) {
    this.companyService.DeleteRule(event.Id).subscribe(
      response => {
        this.toastService.success(this.translate.instant('expense-rule.rule-deleted'));
        this.getRules();
      },
      error => this.toastService.error(`Impossible de supprimer la règle`, 'Erreur')
    );
  }

  openCreateRuleModal() {
    const modal = this.bsModal.open(CreateRuleModalComponent);
    modal.componentInstance.expenseTypes = this.expenseTypes;
    modal.componentInstance.employeeCategories = this.employeeCategories;
    modal.componentInstance.companyId = this.companyId;

    modal.result.then(
      (data) => {
        this.getRules();
      },
      (reason) => {
        this.getRules();
      }
    );
  }
}
