import { ExpenseManagerValidationGridcellComponent } from './_shared/grid-cell/expense-manager-validation-gridcell/expense-manager-validation-gridcell.component';
import { ExpenseRoutingModule } from './expense-routing.module';
import { EditFormComponent } from './my-expenses-page/edit-page/edit-form/edit-form.component';
import { FilterExpensesComponent } from './my-expenses-page/filter-expenses/filter-expenses.component';
import { MyExpensesPageComponent } from './my-expenses-page/my-expenses-page.component';
import { AgGridModule } from 'ag-grid-angular';
import { NgChartsModule } from 'ng2-charts';
import { VerifGridcellComponent } from './_shared/grid-cell/verif-gridcell/verif-gridcell.component';
import { ExpensesRepartitionComponent } from './_shared/expenses-repartition/expenses-repartition.component';
import { NgModule } from '@angular/core';
import { ExpenseListComponent } from './_shared/expense-list/expense-list.component';
import { ExpenseTypeSelectComponent } from './_shared/expense-type-select/expense-type-select.component';
import { ExpenseStatusSelectComponent } from './_shared/expense-status-select/expense-status-select.component';
import { SharedModule } from '../shared/shared.module';
import { CreateExpensePageComponent } from './create-expense-page/create-expense-page.component';
import { SendReceiptPageComponent } from './send-receipt-page/send-receipt-page.component';
import { EditPageComponent } from './my-expenses-page/edit-page/edit-page.component';
import { ImageUploadModule } from 'ng6-image-upload';
import { ImageViewerModule } from 'ngx-image-viewer';
import { NbAccordionModule } from '@nebular/theme';
import { ExpenseTypeGridCellComponent } from './_shared/grid-cell/expense-type-grid-cell/expense-type-grid-cell.component';
import { RouterModule } from '@angular/router';
import { MapModalComponent } from './my-expenses-page/edit-page/map-modal/map-modal.component';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';


@NgModule({
  declarations: [
    ExpensesRepartitionComponent,
    ExpenseListComponent,
    ExpenseTypeSelectComponent,
    ExpenseStatusSelectComponent,
    VerifGridcellComponent,
    ExpenseManagerValidationGridcellComponent,
    EditFormComponent,
    EditPageComponent,
    FilterExpensesComponent,
    MyExpensesPageComponent,
    CreateExpensePageComponent,
    SendReceiptPageComponent,
    ExpenseTypeGridCellComponent,
    MapModalComponent,
  ],
  imports: [
    SharedModule,
    ImageViewerModule,
    NgChartsModule,
    AgGridModule,
    ImageUploadModule.forRoot(),
    NbAccordionModule,
    RouterModule,
    TableModule,
    InputTextModule
  ],
  exports: [
    ExpensesRepartitionComponent,
    ExpenseListComponent,
    ExpenseTypeSelectComponent,
    ExpenseStatusSelectComponent,
    MyExpensesPageComponent
  ]
})
export class ExpenseModuleWithoutRouting { }

@NgModule({
  imports: [
    ExpenseModuleWithoutRouting,
    ExpenseRoutingModule,
  ],
  exports: [
    ExpenseModuleWithoutRouting
  ]
})
export class ExpenseModule { }
