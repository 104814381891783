<div>
  <span *ngIf="params.data.State.Code === 'REF'"
    [nbTooltip]="'report.reject-tooltip-message' | translate: {comments: params.data.ValidationComments}"
    nbTooltipStatus="danger" nbTooltipPlacement="right">
    <nb-icon icon="fa-times" class="icon-danger"></nb-icon>
  </span>

  <span *ngIf="params.data.State.Code === 'VAL' || params.data.State.Code === 'ARCHIVED'"
    [nbTooltip]="'report.validated' | translate" nbTooltipStatus="success" nbTooltipPlacement="right">
    <nb-icon icon="fa-check" class="icon-success"></nb-icon>
  </span>

  <div class="loading-indicator">
    <span *ngIf="params.data.State.Code === 'RULEPROCESS'" [nbTooltip]="'report.rules-processing' | translate"
      nbTooltipStatus="warning" nbTooltipPlacement="right" [nbSpinner]="true" nbSpinnerSize="small"
      nbSpinnerStatus="warning">
    </span>
  </div>

  <span *ngIf="params.data.State.Code === 'SENT'" [nbTooltip]="'report.not-verified' | translate"
    nbTooltipStatus="warning" nbTooltipPlacement="right">
    <nb-icon icon="fa-question" class="icon-warning"></nb-icon>
  </span>

  <span class="m-3" *ngIf="params.data.State.Code === 'CREATED' || params.data.State.Code === 'REF'">
    <button (click)="$event.stopPropagation();sendToValidation()" class="btn btn-sm btn-outline-secondary">
      <nb-icon icon="fa-paper-plane"></nb-icon>
    </button>
  </span>
</div>