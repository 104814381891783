export class ICountryTaxe {
  code: string;
  taxes: number[];

  constructor(model?: ICountryTaxe) {
    if (model) {
      this.code = model.code;
      this.taxes = model.taxes;
    } else {
      this.code = null;
      this.taxes = [];
    }
  }
}

export const CountryTaxes: ICountryTaxe[] = [
  {
    code: 'BGR',
    taxes: [20, 9, 0],
  },
  {
    code: 'CZE',
    taxes: [21, 15, 10, 0],
  },
  {
    code: 'DNK',
    taxes: [25, 0],
  },
  {
    code: 'AUT',
    taxes: [20, 13, 10, 0],
  },
  {
    code: 'CHE',
    taxes: [8.1, 7.7, 3.7, 3.8, 2.6, 2.5, 0],
  },
  {
    code: 'DEU',
    taxes: [19, 7, 0],
  },
  {
    code: 'GRC',
    taxes: [24, 13, 6, 0],
  },
  {
    code: 'GBR',
    taxes: [20, 5, 0],
  },
  {
    code: 'IRL',
    taxes: [23, 13.5, 9, 4.8, 0],
  },
  {
    code: 'ESP',
    taxes: [21, 10, 4, 0],
  },
  {
    code: 'EST',
    taxes: [20, 9, 0],
  },
  {
    code: 'FIN',
    taxes: [24, 14, 10, 0],
  },
  {
    code: 'BEL',
    taxes: [21, 12, 6, 0],
  },
  {
    code: 'FRA',
    taxes: [20, 10, 5.5, 2.1, 0],
  },
  {
    code: 'LUX',
    taxes: [16, 13, 7, 3, 0],
  },
  {
    code: 'HRV',
    taxes: [25, 13, 5, 0],
  },
  {
    code: 'HUN',
    taxes: [27, 18, 5, 0],
  },
  {
    code: 'ITA',
    taxes: [22, 10, 5, 4, 0],
  },
  {
    code: 'LTU',
    taxes: [21, 9, 5, 0],
  },
  {
    code: 'MLT',
    taxes: [18, 7, 5, 0],
  },
  {
    code: 'NLD',
    taxes: [21, 9, 0],
  },
  {
    code: 'POL',
    taxes: [23, 8, 5, 0],
  },
  {
    code: 'PRT',
    taxes: [23, 13, 6, 0],
  },
  {
    code: 'ROU',
    taxes: [19, 9, 5, 0],
  },
  {
    code: 'SVK',
    taxes: [20, 10, 0],
  },
  {
    code: 'SVN',
    taxes: [22, 9.5, 5, 0],
  },
  {
    code: 'SWE',
    taxes: [25, 12, 6, 0],
  },
  {
    code: 'OTH',
    taxes: [0]
  },
  {
    code: 'MUS',
    taxes: [15]
  },
  {
    code: 'GLP',
    taxes: [8.5, 2.1]
  },
  {
    code: 'MTQ',
    taxes: [8.5, 2.1]
  },
  {
    code: 'REU',
    taxes: [8.5, 2.1]
  },
  {
    code: 'GUY',
    taxes: [0]
  }
];
