import { DialogComponent } from './../_components/dialog/dialog.component';
import { LoadingComponent } from './../_components/loading/loading.component';
import { Injectable, Component } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private loadingModal: NgbModalRef;
  private modal: NgbModalRef;

  constructor(
    private bsModal: NgbModal,
    ) {

  }

  ShowLoading(message = 'Chargement...') {
    if (this.loadingModal) {
      this.loadingModal.close();
    }
    this.loadingModal = this.bsModal.open(LoadingComponent, {
      backdrop: 'static',
      centered: true,
      size: 'sm',
    });
    this.loadingModal.componentInstance.title = message;
  }

  CloseLoading() {
    if (this.loadingModal) {
      this.loadingModal.close();
      this.loadingModal = null;

    }
  }

  OpenDialog(): NgbModalRef {
    if (this.modal) {
      this.modal.close();
    }
    return this.modal = this.bsModal.open(DialogComponent, {
      windowClass: 'modal-holder',
      centered: true
    });
  }
}


