import { Report } from '../../_models/Report';

export class ReportsModel {
  current: Report;
  list: Report[];
  toSignList: Report[];
  signedList: Report[];
  forAccountantList: Report[];
  shouldReloadCurrent: boolean;
  shouldReloadList: boolean;
  shouldReloadToSignList: boolean;
  shouldReloadSignedList: boolean;
  shouldReloadForAccountantList: boolean;
  isSuccess: boolean;
  isLoadingReports: boolean;

  constructor(model?: ReportsModel) {
    if (model) {
      this.current = new Report(model.current);
      this.list = (model.list || []).map((r) => new Report(r));
      this.toSignList = (model.toSignList || []).map((r) => new Report(r));
      this.signedList = (model.signedList || []).map((r) => new Report(r));
      this.forAccountantList = (model.forAccountantList || []).map(
        (r) => new Report(r),
      );
      this.shouldReloadCurrent = model.shouldReloadCurrent;
      this.shouldReloadList = model.shouldReloadList;
      this.shouldReloadToSignList = model.shouldReloadToSignList;
      this.shouldReloadSignedList = model.shouldReloadSignedList;
      this.shouldReloadForAccountantList = model.shouldReloadForAccountantList;
      this.isSuccess = model.isSuccess;
      this.isLoadingReports = model.isLoadingReports;
    } else {
      this.current = null;
      this.list = [];
      this.toSignList = [];
      this.signedList = [];
      this.forAccountantList = [];
      this.shouldReloadCurrent = false;
      this.shouldReloadList = true;
      this.shouldReloadToSignList = true;
      this.shouldReloadSignedList = true;
      this.shouldReloadForAccountantList = true;
      this.isSuccess = true;
      this.isLoadingReports = false;
    }
  }
}
