import { AccountingPlanAction } from 'on-shared/stores/accounting-plan/accounting-plan.action';
import { OnUser } from '../../_models/OnUser';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { CompanyAction } from '../../stores/company/company.action';
import { UserActions } from '../../stores/user/user.actions';
import { UserState } from '../../stores/user/user.state';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateVehiclesComponent } from '../../../company/_shared/create-vehicles/create-vehicles.component';
import { VehiclesService } from '../../stores/vehicles/vehicles.service';
import { Vehicle } from '../../_models/Vehicle';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { ToastService } from 'on-common/_services/toast.service';
import { environment } from 'projects/onexpense/src/environments/environment';
import { AccountingPlan } from 'on-shared/_models/AccountingPlan';
import { AccountingPlanState } from 'on-shared/stores/accounting-plan/accounting-plan.state';
import { CompanyState } from 'on-shared/stores/company/company.state';
import { Company } from 'on-shared/_models/Company';

@Component({
  selector: 'on-global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.scss'],
})
export class GlobalSettingsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() user: OnUser;
  @Input() disabled: boolean;

  addressForm: UntypedFormGroup;
  personnalForm: UntypedFormGroup;
  employeeCompanyForm: UntypedFormGroup;
  onexpenseForm: UntypedFormGroup;
  analyticsForm: UntypedFormGroup;

  connectedUser: OnUser;

  vehicles: Vehicle[];
  isVehiclesLoading = true;

  appName = environment.appName;

  @Select(UserState.currentUser) currentUser$: Observable<OnUser>;
  @Select(AccountingPlanState.userAccountingPlans) userAccountingPlans$: Observable<AccountingPlan[]>;
  selectedUserAccountingPlans: { [key: number]: number | null };

  @Select(CompanyState.current) currentCompany$: Observable<Company>;

  destroy$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private toastService: ToastService,
    private store: Store,
    private modal: NgbModal,
    private vehiclesService: VehiclesService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.LoadVehicles();

    this.store.dispatch(new AccountingPlanAction.LoadAccountingPlans());

    // subscribe to accounting plans change to recalculate what values are set for this user.
    this.userAccountingPlans$.pipe(takeUntil(this.destroy$)).subscribe(plans => {
      this.selectedUserAccountingPlans = {};
      if (!plans) {
        return;
      }
      for (const p of plans) {
        this.selectedUserAccountingPlans[p.Id] = this.user.AccountingPlanValues?.find(apv => apv.AccountingPlanId === p.Id)?.AccountingPlanValueId;
      }
    });

    this.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      // Copying the data so we do not edit directly the state
      this.connectedUser = new OnUser(user);

      this.addressForm = this.fb.group({
        Street1: [''],
        Street2: [''],
        City: [''],
        Zip: [''],
      });

      this.personnalForm = this.fb.group({
        ContactEmail: [
          '',
          Validators.compose([Validators.required, Validators.email]),
        ],
        FirstName: [''],
        LastName: [''],
        PhoneNumber: [''],
        Facebook: [''],
        LinkedIn: [''],
      });

      this.employeeCompanyForm = this.fb.group({
        Identifier: [''],
        OrganizationUnit: [''],
        CompanyRole: [
          { value: '', disabled: this.connectedUser?.Id === this.user.Id },
        ],
        EmployeeCategory: [''],
        OtherInfo1: [''],
        OtherInfo2: [''],
        OtherInfo3: [''],
        OtherInfo4: [''],
        ThirdPartyAccount: [''],
        HasRestaurantTicket: [true],
        HasPerDiem: [true],
        HasProfessionalCard: [false]
      });

      this.onexpenseForm = this.fb.group({
        Country: ['', Validators.required],
        Currency: ['', Validators.required]
      });

      this.patchValues();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user && this.addressForm) {
      this.patchValues();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  patchValues() {
    // Create a copy to avoid referencing directly the user in the state
    this.user = new OnUser(this.user);
    if (this.user) {
      if (!this.user.Address) {
        this.user.Address = {
          Id: 0,
          Street1: '',
          Street2: '',
          City: '',
          CountryId: undefined,
          Zip: '',
        };
      }
      this.addressForm.patchValue(this.user.Address);
      this.personnalForm.patchValue(this.user);
      this.onexpenseForm.patchValue(this.user);
      this.employeeCompanyForm.patchValue(this.user);
    }
    if (this.disabled) {
      this.addressForm.disable();
      this.personnalForm.disable();
      this.onexpenseForm.disable();
      this.employeeCompanyForm.disable();
    }
  }

  SaveInformations() {
    if (
      this.addressForm.valid &&
      this.onexpenseForm.valid &&
      this.personnalForm.valid &&
      this.employeeCompanyForm.valid
    ) {
      Object.assign(this.user, this.onexpenseForm.value);
      Object.assign(this.user, this.personnalForm.value);
      Object.assign(this.user.Address, this.addressForm.value);
      Object.assign(this.user, this.employeeCompanyForm.value);

      if (this.selectedUserAccountingPlans) {
        this.user.AccountingPlanValues = []
        for (const planId in this.selectedUserAccountingPlans) {
          if (this.selectedUserAccountingPlans[planId]) {
            this.user.AccountingPlanValues.push({
              AccountingPlanId: +planId,
              AccountingPlanValueId: this.selectedUserAccountingPlans[planId]
            });
          }
        }
      }

      // If update the settings of an employee
      if (this.user.CompanyId) {
        this.store.dispatch(new CompanyAction.UpdateEmployee(this.user));
      } else {
        this.store.dispatch(new UserActions.SaveUserSettings(this.user));
      }
    }
  }

  LoadVehicles() {
    this.vehiclesService.GetByUserId(this.user.Id).subscribe(response => {
      if (response.IsSuccess) {
        this.vehicles = response.Result;
      } else {
        this.toastService.warning(
          this.translateService.instant('vehicle.loading-failed'), // "Impossible de charger les véhicules"
          this.translateService.instant('vehicle.loading-failed-title'), // "Problème"
        );
      }
      this.isVehiclesLoading = false;
    });
  }

  AddVehicle() {
    const createModal = this.modal.open(CreateVehiclesComponent, { centered: true });
    createModal.componentInstance.user = this.user;

    createModal.result.then(result => {
      if (result !== 'Cross click') {
        this.isVehiclesLoading = true;
        this.LoadVehicles();
      }
    });
  }

  EditVehicle(vehicle: Vehicle) {
    if (this.disabled) {
      return;
    }

    const createModal = this.modal.open(CreateVehiclesComponent, { centered: true });
    createModal.componentInstance.user = this.user;
    createModal.componentInstance.vehicle = vehicle;

    createModal.result.then(result => {
      if (result !== 'Cross click') {
        this.isVehiclesLoading = true;
        this.LoadVehicles();
      }
    });
  }

  DeleteVehicle(vehicle: Vehicle) {
    if (this.disabled) {
      return;
    }
    this.vehiclesService.CanDelete(vehicle).subscribe(response => {
      if (response.IsSuccess) {
        // If can delete
        if (response.Result) {
          this.DisplayModalDeleteVehicle(vehicle);
        } else {
          this.DisplayModalDisabledVehicle(vehicle);
        }
      }
    });
  }

  DisplayModalDeleteVehicle(vehicle: Vehicle) {
    Swal.fire({
      title: this.translateService.instant('vehicle.swarl-delete-title'),
      text: this.translateService.instant('vehicle.swarl-delete', { name: vehicle.Name }),
      showCancelButton: true,
      cancelButtonText: this.translateService.instant('vehicle.swarl-delete-cancel-btn'),
      confirmButtonText: this.translateService.instant('vehicle.swarl-delete-confirm-btn'),
      confirmButtonColor: '#DC0000'
    }).then(result => {
      if (result.value) {
        this.vehiclesService.Delete(vehicle).subscribe(response => {
          if (response.IsSuccess) {
            this.toastService.success(
              this.translateService.instant('vehicle.deleted-success'),
              this.translateService.instant('vehicle.deleted-success-title'),
            );

            this.isVehiclesLoading = true;
            this.LoadVehicles();

          } else {
            this.toastService.error(
              this.translateService.instant('vehicle.deleted-failed'), // "Impossible de supprimer le véhicule"
              this.translateService.instant('vehicle.deleted-failed-title'), // "Erreur"
            );
          }
        });
      }
    });
  }

  DisplayModalDisabledVehicle(vehicle: Vehicle) {
    Swal.fire({
      title: this.translateService.instant('vehicle.swarl-disabled-title'),
      text: this.translateService.instant('vehicle.swarl-disabled', vehicle.Name),
      showCancelButton: true,
      cancelButtonText: this.translateService.instant('vehicle.swarl-disabled-cancel-btn'),
      confirmButtonText: this.translateService.instant('vehicle.swarl-disabled-confirm-btn'),
    }).then(result => {
      if (result.value) {
        this.vehiclesService.Disabled(vehicle).subscribe(response => {
          if (response.IsSuccess) {
            this.toastService.success(
              this.translateService.instant('vehicle.disabled-success'),
              this.translateService.instant('vehicle.disabled-success-title'), // "Succès"
            );

            this.isVehiclesLoading = true;
            this.LoadVehicles();

          } else {
            this.toastService.error(
              this.translateService.instant('vehicle.disabled-failed'),
              this.translateService.instant('vehicle.disabled-failed-title'),
            );
          }
        });
      }
    });
  }
}
