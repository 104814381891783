export class UserAccountingPlanValue {
  AccountingPlanId: number;
  AccountingPlanValueId: number;

  constructor(model?: UserAccountingPlanValue) {
    if (model) {
      this.AccountingPlanId = model.AccountingPlanId;
      this.AccountingPlanValueId = model.AccountingPlanValueId;
    } else {
      this.AccountingPlanId = 0;
      this.AccountingPlanValueId = 0;
    }
  }
}
