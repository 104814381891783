import { TranslateService } from '@ngx-translate/core';
import { OnUser } from 'on-shared/_models/OnUser';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Router } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { AccountantExpertAction } from 'on-shared/stores/accountant-expert/accountant-expert.action';
import { AccountantExpertService } from 'on-shared/stores/accountant-expert/accountant-expert.service';
import { UserState } from 'on-shared/stores/user/user.state';
import { ToastService } from 'on-common/_services/toast.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountantExpertState } from 'on-shared/stores/accountant-expert/accountant-expert.state';
import { EmployeeRole } from 'on-shared/_models/EmployeeRole';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'on-select-employee',
  templateUrl: './select-employee.component.html',
  styleUrls: ['./select-employee.component.scss'],
})
export class SelectEmployeeComponent implements OnInit, OnDestroy {

  companyId: number = null;
  employeeId: number = null;

  employees: any[] = [];

  isLoading = false;

  @Select(AccountantExpertState.selectedCompanyEmployees) employees$: Observable<EmployeeRole[]>;
  destroy$ = new Subject<void>();

  constructor(
    private accountantExpertService: AccountantExpertService,
    private toastService: ToastService,
    private router: Router,
    private translateService: TranslateService,
    private modalRef: NgbActiveModal
  ) { }

  ngOnInit() {
    this.employees$.pipe(takeUntil(this.destroy$))
      .subscribe(employees => {
        this.employees = employees;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onChangeEmployeeSelected() {
    if (this.employeeId === null) {
      return;
    }
    this.isLoading = true;
    this.accountantExpertService
      .GetTokenByUserId(this.employeeId)
      .subscribe((response) => {
        if (response.access_token) {
          this.accountantExpertService.SetActiveToken(
            response.access_token,
            response.Username,
          );
        } else {
          this.toastService.error(
            this.translateService.instant('accountant.get-token-error'),
          );
          this.isLoading = false;
        }
      });
  }

  closeModal() {
    this.modalRef.dismiss();
  }
}
