import { CompanyInvitation } from './CompanyInvitation';
import { EmployeeRole } from './EmployeeRole';


export class CompanyEmployeesData {
  EmployeeRoles: EmployeeRole[];
  Invitations: CompanyInvitation[];
  TotalSubscriptionNb: number;
  TotalSubscriptionNotAssigned: number;

  constructor(model?: CompanyEmployeesData) {
    if (model) {
      this.EmployeeRoles = (model.EmployeeRoles || []).map((c) => new EmployeeRole(c));
      this.Invitations = (model.Invitations || []).map((c) => new CompanyInvitation(c));
      this.TotalSubscriptionNb = model.TotalSubscriptionNb;
      this.TotalSubscriptionNotAssigned = model.TotalSubscriptionNotAssigned;
    } else {
      this.EmployeeRoles = [];
      this.Invitations = [];
      this.TotalSubscriptionNb = null;
      this.TotalSubscriptionNotAssigned = null;
    }
  }
}
