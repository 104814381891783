import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AccountService } from './../_services/account.service';
import { ToastService } from 'on-common/_services/toast.service';

@Component({
  selector: 'on-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss']
})
export class ResetPasswordPageComponent implements OnInit {

  isLoading = false;

  email: string;
  password: string;
  confirmation: string;

  token: string;

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private toastService: ToastService,
    private router: Router
  ) { }

  ngOnInit() {
    this.email = this.route.snapshot.queryParams['username'];
    this.token = this.route.snapshot.queryParams['token'];
  }

  resetPassword() {
    if (!this.password || this.password !== this.confirmation) {
      this.toastService.warning('Les mots de passe sont différents');
    } else {
      this.accountService.ResetPassword(this.email, this.token, this.password, this.confirmation).subscribe(response => {
        if (response.IsSuccess) {
          this.toastService.success('Votre mot de passe a été modifié');
          this.router.navigate(['/login']);
        } else {
          this.toastService.error(response.Message);
        }
      }, error => {
        this.toastService.error('Une erreur est intervenue à la modification de votre mot de passe');
      });
    }
  }

}
