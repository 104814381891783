import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountingCode } from '../_models/AccountingCode';
import { VatChargeAccount } from '../_models/VatChargeAccount';
import { API_V2_URL } from 'on-common/constants';
import { IApiActionResult, IApiResult } from 'on-common/_models/ApiResult';


const ACCOUNTANT_URL = API_V2_URL + '/accountant';


@Injectable({
  providedIn: 'root'
})
export class AccountantService {

  ACCOUNTING_CODE_URL = ACCOUNTANT_URL + '/accountingcode';
  VAT_CHARGE_URL = ACCOUNTANT_URL + '/vatchargeaccount';

  constructor(private http: HttpClient) { }


  GetAccountingCodes(companyId: number): Observable<IApiResult<AccountingCode[]>> {
    return this.http.get<IApiResult<AccountingCode[]>>(this.ACCOUNTING_CODE_URL + '/' + companyId);
  }

  SaveAccountingCodes(companyId: number, accountingCodes: AccountingCode[]): Observable<IApiActionResult> {
    return this.http.post<IApiActionResult>(this.ACCOUNTING_CODE_URL + '/' + companyId + '/save', accountingCodes);
  }

  GetAccountingCode(companyId: number, expenseTypeId: number): Observable<IApiResult<string>> {
    return this.http.get<IApiResult<string>>(this.ACCOUNTING_CODE_URL + '/get' + '/' + companyId + '/' + expenseTypeId);
  }

  GetVatChargeAccounts(companyId: number): Observable<IApiResult<VatChargeAccount[]>> {
    return this.http.get<IApiResult<VatChargeAccount[]>>(this.VAT_CHARGE_URL + '/' + companyId);
  }

  SaveVatChargeAccounts(companyId: number, vatChargeAccounts: VatChargeAccount[]): Observable<IApiActionResult> {
    return this.http.post<IApiActionResult>(this.VAT_CHARGE_URL + '/' + companyId, vatChargeAccounts);
  }

}
