import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { AccountantExpertAction } from 'on-shared/stores/accountant-expert/accountant-expert.action';
import { AccountantExpertState } from 'on-shared/stores/accountant-expert/accountant-expert.state';
import { CompanyAction } from 'on-shared/stores/company/company.action';
import { CompanyState } from 'on-shared/stores/company/company.state';
import { Company } from 'on-shared/_models/Company';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ExpertedCompany } from '../_models/ExpertedCompany';
import { SelectEmployeeComponent } from '../_shared/select-employee/select-employee.component';
import { ChangeActivableLicensesCellComponent } from './change-activable-licenses-cell/change-activable-licenses-cell.component';
import { ChangeIsVpCellComponent } from './change-is-vp-cell/change-is-vp-cell.component';
import { ConnectAsCellComponent } from './connect-as-cell/connect-as-cell.component';

@Component({
  selector: 'on-list-experted-companies-page',
  templateUrl: './list-experted-companies-page.component.html',
  styleUrls: ['./list-experted-companies-page.component.scss']
})
export class ListExpertedCompaniesPageComponent implements OnInit, OnDestroy {

  @Select(AccountantExpertState.companies) companies$: Observable<ExpertedCompany[]>;
  companies: ExpertedCompany[];

  @Select(CompanyState.current) currentCompany$: Observable<Company>;
  isVp: boolean;

  private destroyed$: ReplaySubject<void> = new ReplaySubject();

  hovers: boolean[] = [];

  // Grid Companies
  @ViewChild('agGridCompanies', { static: true }) gridCompanies: AgGridAngular;
  NbOfActivableLicenses

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const timeout = setTimeout((_) => {
      this.gridCompanies.api.sizeColumnsToFit();
      clearTimeout(timeout);
    }, 200);
  }
  constructor(
    private store: Store,
    private modal: NgbModal,
    private translateService: TranslateService,) { }

  ngOnInit(): void {

    this.companies$.pipe(takeUntil(this.destroyed$))
      .subscribe((companies) => {
        this.companies = companies;
        if (this.gridCompanies.api) {
          this.gridCompanies.api.setRowData(companies);
        }
      });

    this.currentCompany$.pipe(takeUntil(this.destroyed$))
      .subscribe((currentCompany) => {
        if (!currentCompany) {
          this.isVp = false;
        } else {
          this.isVp = currentCompany.IsVP;
        }
        if (this.gridCompanies?.gridOptions?.columnDefs) {
          this.gridCompanies.columnApi.setColumnVisible('IsVP', this.isVp);
          this.gridCompanies.api.sizeColumnsToFit();
        }
      });

    this.store.dispatch(new AccountantExpertAction.LoadCompaniesIfNeeded());
    this.store.dispatch(new CompanyAction.LoadCompaniesIfNeeded());

    const colDefs: ColDef[] = [
      {
        headerName: this.translateService.instant('accountant-expert.company-name'),
        headerTooltip: this.translateService.instant('accountant-expert.company-name'),
        field: 'Name',
        filter: true,
        sortable: true,
        resizable: true,
        filterParams: {
          suppressAndOrCondition: true
        }
      },
      {
        headerName: this.translateService.instant('accountant-expert.nb-exports-not-teated'),
        headerTooltip: this.translateService.instant('accountant-expert.nb-exports-not-teated'),
        field: 'NbOfAccountantExports',
        filter: 'agNumberColumnFilter',
        sortable: true,
        resizable: true,
        filterParams: {
          suppressAndOrCondition: true
        }
      },
      {
        headerName: this.translateService.instant('accountant-expert.nb-validated-reports'),
        headerTooltip: this.translateService.instant('accountant-expert.nb-validated-reports'),
        field: 'NbOfReportsValidated',
        filter: 'agNumberColumnFilter',
        sortable: true,
        resizable: true,
        filterParams: {
          suppressAndOrCondition: true
        }
      },
      {
        headerName: this.translateService.instant('accountant-expert.nb-of-active-licenses'),
        headerTooltip: this.translateService.instant('accountant-expert.nb-of-active-licenses'),
        field: 'NbOfActiveLicenses',
        filter: 'agNumberColumnFilter',
        sortable: true,
        resizable: true,
        filterParams: {
          suppressAndOrCondition: true
        }
      },
      {
        headerName: this.translateService.instant('accountant-expert.nb-of-activable-licenses'),
        headerTooltip: this.translateService.instant('accountant-expert.nb-of-activable-licenses'),
        field: 'NbOfActivableLicenses',
        filter: 'agNumberColumnFilter',
        sortable: true,
        resizable: true,
        width: 225,
        cellRenderer: ChangeActivableLicensesCellComponent,
        filterParams: {
          suppressAndOrCondition: true
        }
      },
      {
        headerName: 'VP',
        headerTooltip: this.translateService.instant('accountant-expert.activate-vp'),
        cellRenderer: ChangeIsVpCellComponent,
        field: "IsVP",
        width: 80,
        hide: !this.isVp
      },
      {
        headerName: this.translateService.instant('accountant-expert.connect-as'),
        headerTooltip: this.translateService.instant('accountant-expert.connect-as'),
        cellRenderer: ConnectAsCellComponent,
        width: 150,
      }
    ];

    this.gridCompanies.gridOptions = {
      columnDefs: colDefs,
      rowData: this.companies,
      rowHeight: 45,
      domLayout: 'autoHeight',

      suppressCellFocus: true,
      suppressRowClickSelection: true,

      context: {
        parentList: this
      },

      onGridReady: function (params) {
        params.api.sizeColumnsToFit();
      },
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openConnectAs(company: ExpertedCompany): void {
    this.store.dispatch(
      new AccountantExpertAction.LoadEmployees(company.Id),
    );
    this.modal.open(SelectEmployeeComponent);
  }

  activateVpSetting(company: ExpertedCompany) {
    if (company.IsVP) {
      return;
    }

    Swal.fire(
      {
        title: this.translateService.instant('accountant-expert.confirm-vp-title'),
        html: this.translateService.instant('accountant-expert.confirm-vp-text'),
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        input: 'checkbox',
        inputPlaceholder: this.translateService.instant('accountant-expert.confirm-vp-checkbox'),
        confirmButtonText: this.translateService.instant('accountant-expert.confirm-vp-button'),
        inputValidator: (result) => {
          return !result && this.translateService.instant('accountant-expert.confirm-vp-checkbox-mandatory')
        }
      })
      .then(response => {
        if (response.value) {
          this.store.dispatch(
            new AccountantExpertAction.ActivateVP(company)
          );
        } else {
          this.store.dispatch(
            new AccountantExpertAction.DeactivateVP(company)
          );
        }
        this.gridCompanies.api.refreshCells();
      });
  }

  updateActivableLicense(company: ExpertedCompany, newNumber: number) {
    this.store.dispatch(
      new AccountantExpertAction.UpdateActivableLicenses(company, newNumber)
    );
  }
}
