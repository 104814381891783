import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'on-no-sidebar-page',
  templateUrl: './no-sidebar-page.component.html',
  styleUrls: ['./no-sidebar-page.component.scss']
})
export class NoSidebarPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
