<on-page-title [title]="'company.licenses.page-title' | translate" icon="fa-euro-sign"></on-page-title>

<ul class="nav-tabs" ngbNav #mainLicenseNav="ngbNav">
  <li ngbNavItem="licenses-subscription-tab">
    <a ngbNavLink>{{'company.licenses.title' | translate}}</a>
    <ng-template ngbNavContent>
      <div class="row" *ngIf="!isLoading">
        <div class="col-12 p-3">
          <div *ngIf="!hasStripeSubscription">
            <p>{{'company.licenses.description' | translate}}</p>
            <on-stripe-payment></on-stripe-payment>
          </div>
          <div class="col-12 existing-subscription" *ngIf="stripeSubscription">
            <ul class="nav-tabs" ngbNav #subLicenseNav="ngbNav">
              <li ngbNavItem="current-subscription-tab">
                <a ngbNavLink>{{'company.licenses.current-title' | translate}}</a>
                <ng-template ngbNavContent>
                  <div *ngIf="stripeSubscription.Status !== 'active'" class="alert alert-warning">
                    <div
                      *ngIf="stripeSubscription.Status === 'incomplete' || stripeSubscription.Status === 'incomplete_expired'"
                      [innerHTML]="'company.licenses.incomplete-payment' | translate">
                    </div>
                    <div *ngIf="stripeSubscription.Status === 'canceled'"
                      [innerHTML]="'company.licenses.canceled-subscription' | translate : {appName:appName}">
                    </div>
                    <div *ngIf="stripeSubscription.Status === 'past_due' || stripeSubscription.Status === 'unpaid'
                  || !lastInvoiceWasPaid" [innerHTML]="'company.licenses.payment-due' | translate">
                    </div>
                  </div>
                  <!-- SEPA payment is processing -->
                  <div class="alert alert-info"
                    *ngIf="stripeSubscription.Status === 'active' && stripeSubscription.LatestInvoice?.PaymentIntent?.Status === 'processing'">
                    <nb-icon icon="fa-info"></nb-icon>
                    {{'company.licenses.payment-processing' | translate}}
                  </div>
                  <br>
                  <div class="row justify-content-center">
                    <div class="col" style="max-width:450pt">
                      <on-stripe-subscription-summary-card [subscription]="stripeSubscription">
                      </on-stripe-subscription-summary-card>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col text-center">
                      <button class="btn btn-secondary"
                        (click)="openStripePortal()">{{'company.licenses.access-invoicing'
                        |
                        translate}}</button>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li ngbNavItem="edit-subscription-tab" *ngIf="!hasFloatingLicenses">
                <a ngbNavLink>{{'company.licenses.edit-subsription-title' | translate}}</a>
                <ng-template ngbNavContent>
                  <p class="alert alert-warning" *ngIf="!lastInvoiceWasPaid || stripeSubscription.Status !== 'active'">
                    {{'company.licenses.invoice-not-paid' | translate}}
                  </p>
                  <on-stripe-payment *ngIf="stripeSubscription.Status === 'active' && lastInvoiceWasPaid"
                    [currentSubscription]="stripeSubscription">
                  </on-stripe-payment>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="subLicenseNav" class="mt-3"></div>
          </div>
        </div>
      </div>
      <on-loading *ngIf="isLoading"></on-loading>
    </ng-template>
  </li>
  <li ngbNavItem="vp-consumption-tab" *ngIf="hasConsumption">
    <a ngbNavLink>{{'company.vp-consumption.title' | translate}}</a>
    <ng-template ngbNavContent>
      <on-vp-consumption [companyId]="companyId"></on-vp-consumption>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="mainLicenseNav" class="mt-3"></div>