import { TranslateService } from '@ngx-translate/core';
import { OnUser } from 'on-shared/_models/OnUser';
import { Expense } from 'on-shared/_models/Expense';
import {
  OnInit,
  EventEmitter,
  Input,
  Component,
  Output,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { Report } from 'on-shared/_models/Report';
import swal from 'sweetalert2';
import { ExpenseListComponent } from '../../../expense/_shared/expense-list/expense-list.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SendValidationComponent } from '../../_shared/report-list/send-validation/send-validation.component';
import { Store, Select } from '@ngxs/store';
import { ExpenseAction } from 'on-shared/stores/expense/expense.actions';
import { ExpenseState } from 'on-shared/stores/expense/expense.state';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExpenseService } from 'on-shared/stores/expense/expense.service';
import { UserState } from 'on-shared/stores/user/user.state';
import { ActionButton } from 'on-common/_models/ActionButton';
import { ToastService } from 'on-common/_services/toast.service';
import { ReportsState } from 'on-shared/stores/reports/reports.state';
import Swal from 'sweetalert2';

@Component({
  selector: 'on-edit-report-form',
  templateUrl: './edit-report-form.component.html',
  styleUrls: ['./edit-report-form.component.scss'],
})
export class EditReportFormComponent implements OnInit, OnDestroy {
  @Input() report: Report;
  @Input() user: OnUser;
  @Output() saveRequested = new EventEmitter();
  @Output() reload = new EventEmitter();
  @Output() managerActionsVisible: EventEmitter<any> = new EventEmitter();

  @ViewChild('expensesInReportList') expensesInReportGrid: ExpenseListComponent;
  @ViewChild('expensesNotInReportList')
  expensesNotInReportGrid: ExpenseListComponent;

  expensesNotInReport: Expense[] = [];
  amountToReimbourse: number;

  @Select(ExpenseState.expenses) expensesNotInReport$: Observable<Expense[]>;
  @Select(UserState.currentUser) currentUser$: Observable<OnUser>;
  destroy$ = new Subject<void>();

  leftActions: ActionButton[] = [
    {
      text: this.translate.instant('report.add-expenses-in-report'),
      icon: 'fa-plus',
      customClass: 'btn-primary',
      actionOnClick: () => this.goToExpenseMode(),
      canDisplayCondition: () => this.report
        && this.report.IsEditable
        && this.report.UserId === this.user.Id,
    },
    {
      text: '',
      icon: 'fa-level-up-alt',
      customClass: 'btn-info',
      tooltipMessage: this.translate.instant(
        'report.remove-expenses-in-report',
      ),
      tooltipStatus: 'info',
      actionOnClick: () => this.excludeSelectedExpenses(),
      canDisplayCondition: () =>
        this.canShowSelectedExpensesActions() &&
        this.report &&
        this.report.IsEditable,
    }
  ];

  rightActions: ActionButton[] = [
    {
      text: '',
      icons: ['fa-check', 'fa-receipt'],
      customClass: 'btn-primary',
      tooltipMessage: this.translate.instant('report.valid-expenses-in-report'),
      tooltipStatus: 'primary',
      actionOnClick: () => this.validSelectedExpenses(),
      canDisplayCondition: () =>
        this.canShowSelectedExpensesActions(true) &&
        this.report &&
        this.report.State.Code === 'SENT',
    },
    {
      text: '',
      icons: ['fa-times', 'fa-receipt'],
      customClass: 'btn-danger',
      tooltipMessage: this.translate.instant(
        'report.reject-expenses-in-report',
      ),
      tooltipStatus: 'danger',
      actionOnClick: () => this.refuseSelectedExpenses(),
      canDisplayCondition: () =>
        this.canShowSelectedExpensesActions(true) &&
        this.report &&
        this.report.State.Code === 'SENT',
    },
  ];

  mode: 'report' | 'expense' = 'report';
  isLoading: boolean;

  constructor(
    private expenseService: ExpenseService,
    private toastService: ToastService,
    private bsModalService: NgbModal,
    private translate: TranslateService,
    private store: Store
  ) { }

  ngOnInit() {
    this.store.dispatch(new ExpenseAction.LoadExpensesIfNeeded());
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  canShowSelectedExpensesActions(tryIfManager?: boolean) {
    let canShow =
      this.expensesInReportGrid &&
      this.expensesInReportGrid.getSelectedRows().length > 0;
    if (tryIfManager) {
      canShow = canShow && this.user.Id === this.report.ManagerId;
    }
    return canShow;
  }

  excludeSelectedExpenses() {
    const expensesAtExclude = this.expensesInReportGrid.getSelectedRows();
    // If no row selected
    if (!expensesAtExclude.length) {
      return;
    }

    Swal.fire({
      title: this.translate.instant('report.remove-expenses-in-report-title'),
      text: this.translate.instant('report.remove-expenses-in-report-text'),
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
    }).then((result) => {
      if (result?.value) {
        const unselectedRows = this.expensesInReportGrid.getUnselectedRows();

        this.report.Expenses = unselectedRows;
        this.expensesInReportGrid.setData(this.report.Expenses);

        this.saveReport();
      }
    });
  }

  async validSelectedExpenses() {
    if (await this.hasReportChangeToSave()) {
      return;
    }

    const expensesToValid = this.expensesInReportGrid.getSelectedRows();
    let countUpdate = 0;
    expensesToValid.map((expenseToValid, index) => {
      this.expenseService.ValidExpense(expenseToValid).subscribe(
        (response) => {
          countUpdate++;
          if (countUpdate === expensesToValid.length) {
            this.toastService.success(
              this.translate.instant('report.valid-expenses-success-message'),
            );
            this.expensesChanged();
          }
        },
        (error) =>
          this.toastService.error(
            this.translate.instant('report.valid-expense-error-message'),
            this.translate.instant(
              'report.valid-expense-error-message-complement',
            ),
          ),
      );
    });
  }

  async refuseSelectedExpenses() {
    if (await this.hasReportChangeToSave()) {
      return;
    }

    const expensesToReject = this.expensesInReportGrid.getSelectedRows();
    swal
      .fire<string>({
        title: this.translate.instant('report.reject-expense-comment-title'),
        // tslint:disable-next-line:max-line-length
        text: this.translate.instant('report.reject-expense-comment-comment'),
        input: 'textarea',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: this.translate.instant(
          'report.reject-expense-confirm-button',
        ),
        cancelButtonText: this.translate.instant(
          'report.reject-expense-cancel-button',
        ),
        icon: 'question',
        preConfirm: () => {
          const inputValue = swal.getInput().value;
          if (!inputValue || inputValue.trim() === '') {
            swal.showValidationMessage(
              this.translate.instant('report.refuse-no-comment-error'),
            );
            return null;
          }
        },
      })
      .then((result) => {
        if (result.value) {
          let countUpdate = 0;
          expensesToReject.map((expenseToValid, index) => {
            expenseToValid.ValidationComment = result.value;
            this.expenseService.RejectExpense(expenseToValid).subscribe(
              (response) => {
                countUpdate++;
                if (countUpdate === expensesToReject.length) {
                  this.toastService.success(
                    this.translate.instant(
                      'report.reject-expenses-success-message',
                    ),
                  );
                  this.expensesChanged();
                }
              },
              (error) =>
                this.toastService.error(
                  this.translate.instant('report.reject-expense-error-message'),
                  this.translate.instant(
                    'report.reject-expense-error-message-complement',
                  ),
                ),
            );
          });
        }
      });
  }

  hasReportChangeToSave() {
    return new Promise<boolean>(resolver => {
      const report = this.store.selectSnapshot(ReportsState.getCurrentReport);
      if (report.Name !== this.report.Name || report.Comments !== this.report.Comments) {
        const result = swal.fire({
          title: this.translate.instant('report.unsaved-report-title'),
          text: this.translate.instant('report.unsaved-report-message'),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.translate.instant('report.unsaved-report-cancel'),
          showConfirmButton: true,
          confirmButtonText: this.translate.instant('report.unsaved-report-confirm'),
        }).then((result) => {
          if (result?.value) {
            resolver(false);
          } else {
            resolver(true);
          }
        });
      } else {
        resolver(false);
      }
    });
  }

  addExpensesToReport() {
    const newExpenses = this.expensesNotInReportGrid.getSelectedRows();
    // this.report.Expenses = this.report.Expenses.concat(this.expensesNotInReport.filter(exp => exp.IsSelected));
    this.report.Expenses = this.report.Expenses.concat(newExpenses);

    this.saveReport();
    this.mode = 'report';
    this.managerActionsVisible.emit({ visible: true });
  }

  goToExpenseMode() {
    this.mode = 'expense';

    this.isLoading = true;

    this.expensesNotInReport$
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.expensesNotInReport = (response || []).map((c) => new Expense(c));
        this.isLoading = false;
      });

    this.managerActionsVisible.emit({ visible: false });
  }

  goToReportMode() {
    this.mode = 'report';
    this.managerActionsVisible.emit({ visible: true });
  }

  saveReport() {
    this.saveRequested.emit();
  }

  expensesChanged() {
    this.reload.emit();
  }

  async sendToValidation(report: Report) {
    const modalInstance = this.bsModalService.open(SendValidationComponent, {});
    modalInstance.componentInstance.report = report;

    modalInstance.result.then((result) => {
      if (result.reload) {
        this.reload.emit();
      }
    });
  }

  getMode(): string {
    return this.mode;
  }
}
