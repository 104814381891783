import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { OnUser } from '../../_models/OnUser';
import { Subscription } from '../../_models/Subscription';
import { CompanyService } from '../company/company.service';
import { DialogService } from 'on-common/_services/dialog.service';
import { BannerNotification } from 'on-layout/_models/BannerNotification';
import { environment } from 'projects/onexpense/src/environments/environment';

marker('shared.renew-sub.banner-title-more');
marker('shared.renew-sub.banner-title-less');

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  oneDayValue = 86400000;
  warningTimeInDays = 45;
  dangerTimeInDays = 7;

  subModalLocalStorageKey = `SubscibtionModalClosed`;
  subBannerLocalStorageKey = `SubscibtionBannerClosed`;

  constructor(
    private dialogService: DialogService,
    public activeModal: NgbActiveModal,
    private companyService: CompanyService,
    private translateService: TranslateService
  ) {
  }

  /**
   * Return if the renew need to be warn and it time delay in days [boolean, number]
   * @param connectedUser: OnUser
   */
  needToRenewSubscriptionFomUser(connectedUser: OnUser): Observable<[boolean, number]> {
    return new Observable<[boolean, number]>(obersver => {
      const isCompanyAndAdmin = this.CheckIfAdminOfCompany(connectedUser);
      // let numberOfDaysBeforeSubscriptionEnd: number;

      if (isCompanyAndAdmin) {
        this.companyService.GetSubscriptions(connectedUser.CompanyId).subscribe(response => {
          const trialLicenseSubscription = response.Result.find(s => s.IsTrial);

          if (!trialLicenseSubscription) {
            obersver.next([false, 0]);
          } else {
            const remainingDays = this.computeRemainingDays(trialLicenseSubscription.NextPaymentDueDate);
            obersver.next([
              this.canSeeRenewNotification(connectedUser, trialLicenseSubscription, remainingDays),
              remainingDays
            ]);
          }
        });
      }
    });
  }

  private CheckIfAdminOfCompany(connectedUser) {
    return connectedUser.CompanyId && connectedUser.Role === `ADMIN`;
  }

  private computeRemainingDays(nextPaymentDueDate: Date) {
    return (
      new Date(nextPaymentDueDate).getTime() - Date.now()
    ) / this.oneDayValue;
  }

  private canSeeRenewNotification(connectedUser: OnUser, subscription: Subscription, numberOfDaysBeforeSubscriptionEnd) {
    return (
      this.CheckIfAdminOfCompany(connectedUser)
      && numberOfDaysBeforeSubscriptionEnd < this.warningTimeInDays
    );
  }

  /**
   * Compute the subscription's notification to display in the banner
   * @param connectedUser
   * @param numberOfDaysBeforeSubscriptionEnd
   */
  getSubscriptionNotificationToDisplay(connectedUser: OnUser, numberOfDaysBeforeSubscriptionEnd: number): BannerNotification {
    const isDanger = this.isRenewUrgent(numberOfDaysBeforeSubscriptionEnd);
    const displayDueTime: number = Math.round(numberOfDaysBeforeSubscriptionEnd);
    // const prefix: string = numberOfDaysBeforeSubscriptionEnd > displayDueTime ? `un peu plus de` : `moins de`;
    // const dayOrDays: string = displayDueTime > 1 ? `jours` : `jour`;

    const title = `SubscibtionBannerClosed`;
    const routerLink = (!!connectedUser.CompanyId && connectedUser.Role === `ADMIN`) ? `/company/licenses` : `/account`;
    const bannerClosed = this.bannerHasBeenClosedToday(connectedUser);

    const subscriptionNotification: BannerNotification = {
      Title: title,
      Message: this.translateService.instant(
        (numberOfDaysBeforeSubscriptionEnd > displayDueTime ? 'shared.renew-sub.banner-title-more' : 'shared.renew-sub.banner-title-less'),
        {
          dueTime: displayDueTime,
          appName: environment.appName
        }),
      // Message: `Votre souscription se termine dans <b>${prefix} ${displayDueTime} ${dayOrDays}</b>, `
      // +`merci de cliquer sur ce lien pour renouveler votre abonnement. Si non renouvellement l'ensemble de vos données seront effacées.`,
      Class: isDanger ? `danger` : `warning`,
      Closed: bannerClosed,
      UserId: connectedUser.Id,
      CanClose: isDanger ? false : true,
      RouterLink: routerLink
    };

    const modalClosed = this.modalHasBeenClosedToday(connectedUser);
    if (isDanger && !modalClosed) {
      this.openSubsciptionModal(subscriptionNotification, connectedUser);
    }

    return subscriptionNotification;
  }

  /**
   *
   * @param numberOfDaysBeforeSubscriptionEnd
   */
  isRenewUrgent(numberOfDaysBeforeSubscriptionEnd: number): boolean {
    return (numberOfDaysBeforeSubscriptionEnd < this.dangerTimeInDays);
  }

  /**
   *
   * @param connectedUser need to check if it is the same user
   */
  private bannerHasBeenClosedToday(connectedUser: OnUser): boolean {
    const notification: BannerNotification = JSON.parse(localStorage.getItem(this.subBannerLocalStorageKey));
    const today = new Date();
    return notification ?
      (new Date(notification.LastClosedDate).getDate() === today.getDate() && connectedUser.Id === notification.UserId) : false;
  }

  /**
   *
   * @param routerLink need
   */
  private modalHasBeenClosedToday(connectedUser: OnUser): boolean {
    const notification = JSON.parse(localStorage.getItem(this.subModalLocalStorageKey));
    const today = new Date();
    return notification ?
      (new Date(notification.LastClosedDate).getDate() === today.getDate() && connectedUser.Id === notification.UserId) : false;
  }

  private openSubsciptionModal(notification: BannerNotification, connectedUser: OnUser) {
    const subModal = this.dialogService.OpenDialog();

    subModal.componentInstance.modalSource = `subscibtion`;
    subModal.componentInstance.title = this.translateService.instant('shared.renew-sub.modal-title');
    subModal.componentInstance.message = notification.Message;
    subModal.componentInstance.class = notification.Class;
    subModal.componentInstance.routerLink = notification.RouterLink;

    subModal.result.then((data) => {
    }, (reason) => {
      const date = new Date();
      localStorage.setItem('SubscibtionModalClosed', JSON.stringify({ LastClosedDate: date.toString(), UserId: connectedUser.Id }));
    });
  }
}
