<div class="modal-header">
  <div class="modal-title" style="width: 100%;">
    {{'accountant-expert.connect-as-title' | translate}}
    <span class="close-cross" (click)="closeModal()">
      <nb-icon icon="fa-times"></nb-icon>
    </span>
  </div>
</div>
<div class="modal-body">
  <div class="row d-flex align-items-center">
    <div class="col-12">
      <on-loading *ngIf="!employees || isLoading"></on-loading>
      <div class="row d-flex align-items-center" *ngIf="employees">
        <div class="col-10">
          <ng-select #selectEmployees [(ngModel)]="employeeId"
            [placeholder]="'accountant-expert.choose-employee' | translate" [disabled]="!employees.length">
            <ng-option *ngFor="let e of employees" [value]="e.Id">{{e.FirstName}} {{e.LastName}} ({{e.UserName}})
            </ng-option>
          </ng-select>
        </div>
        <div class="col-2">
          <button class="btn btn-sm btn-success d-flex" (click)="onChangeEmployeeSelected()">
            <nb-icon icon="fa-sign-in-alt"></nb-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>