import { Report } from './../../shared/_models/Report';


export class AccountantExport {
  Id?: number;
  CompanyId?: number;
  Name?: string;
  Comment?: string;
  NbOfReports?: number;
  PeriodBeginDate?: Date;
  PeriodEndDate?: Date;
  SentToVPDate?: Date;
  StoredToVPDate?: Date;
  VPState?: VPState;
  IsTreated?: boolean;

  Reports?: Report[];
  disabled?: boolean;
  tooltipMessage?: string;

  constructor(model?: AccountantExport) {
    if (model) {
      this.Id = model.Id;
      this.CompanyId = model.CompanyId;
      this.Name = model.Name;
      this.Comment = model.Comment;
      this.NbOfReports = model.NbOfReports ? model.NbOfReports : 0;
      this.PeriodBeginDate = model.PeriodBeginDate ? new Date(model.PeriodBeginDate) : null;
      this.PeriodEndDate = model.PeriodEndDate ? new Date(model.PeriodEndDate) : null;
      this.SentToVPDate = model.SentToVPDate ? new Date(model.SentToVPDate) : null;
      this.StoredToVPDate = model.StoredToVPDate ? new Date(model.StoredToVPDate) : null;
      this.VPState = model.VPState;
      this.Reports = model.Reports ? model.Reports.map((c) => new Report(c)) : [];
      this.disabled = model.disabled;
      this.tooltipMessage = model.tooltipMessage;
      this.IsTreated = model.IsTreated;
    } else {
      this.Id = null;
      this.CompanyId = null;
      this.Name = null;
      this.Comment = null;
      this.NbOfReports = 0;
      this.PeriodBeginDate = null;
      this.PeriodEndDate = null;
      this.SentToVPDate = null;
      this.StoredToVPDate = null;
      this.VPState = null;
      this.Reports = [];
      this.disabled = null;
      this.tooltipMessage = null;
      this.IsTreated = null;
    }
  }
}

export enum VPState {
  /// <summary>
  /// The export has been created.
  /// </summary>
  Created = 'Created',
  /// <summary>
  /// The export has been sent to VP (but still processing).
  /// </summary>
  SentToVP = 'SentToVP',
  /// <summary>
  /// The export has been successfully stored to VP.
  /// </summary>
  StoredInVP = 'StoredInVP',
  /// <summary>
  /// An error occured storing the export to VP.
  /// </summary>
  ErrorInVP = 'ErrorInVP',
}
