<form [formGroup]="ruleForm">
  <div class="col-12 form-group p-0">
    <label for="name">{{'expense-rule.rule-name' | translate}}</label>
    <input class="form-control" type="text" formControlName="Name">
  </div>

  <div class="col-12 form-group p-0">
    <label for="expenseType">{{'expense-rule.expense-type' | translate}}</label>
    <on-expense-type-select [allowAll]="true" formControlName="ExpenseType"></on-expense-type-select>
  </div>

  <div class="col-12 form-group p-0">
    <label for="expenseType">{{'expense-rule.limit-value' | translate}}</label>
    <input libEnforcePointSeparator class="form-control" type="number" formControlName="Value">
  </div>

  <div class="col-12 form-group p-0">
    <label for="employeeCategory">{{'expense-rule.employee-category' | translate}}</label>
    <on-employee-category-select [allowAll]="true" formControlName="EmployeeCategory"></on-employee-category-select>
  </div>
</form>

<div *ngIf="!new" class="col p-0" align="right">
  <button nb-button class="btn btn-danger" style="margin-right: 5px" (click)="deleteRule()">{{'expense-rule.delete' |
    translate}}</button>
  <button nb-button class="btn btn-primary" [disabled]="isDisabled()" (click)="updateRule()">{{'expense-rule.edit' |
    translate}}</button>
</div>

<div *ngIf="new" class="col p-0" align="right">
  <button nb-button class="btn btn-primary" [disabled]="isDisabled()" (click)="createRule()">{{'expense-rule.add-rule' |
    translate}}</button>
</div>