import { OnUser } from './../../../../shared/_models/OnUser';
import { Component, OnInit, Input, forwardRef, EventEmitter, Output, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'on-select-manager',
  templateUrl: './select-manager.component.html',
  styleUrls: ['./select-manager.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectManagerComponent),
      multi: true
    }
  ]
})
export class SelectManagerComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() allEmployees: OnUser[];

  @Input() isOpen = false;

  @ViewChild('ngSelect', {static: true}) ngSelect: NgSelectComponent;

  @Output() managerChanged = new EventEmitter<number>();

  selectedId: number;

  onChange;
  onTouched;
  isDisabled: boolean;


  constructor() { }

  ngOnInit(): void {
    this.ngSelect.isOpen = this.isOpen;
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  writeValue(obj: any): void {
    this.selectedId = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  selectedValueChanged() {
    if (<any>this.selectedId === 'null') {
      this.selectedId = null;
    }
    this.onChange(this.selectedId);
    // Some bug prevents from updating the value with standard (change)
    this.managerChanged.emit(this.selectedId);
  }

  selectTouched() {
    this.onTouched();
  }

}
