import { CustomField } from './CustomField';

export class CustomFieldValue {
  Field: CustomField;
  Value?: string;
  ExpenseId: number;

  constructor(model?: CustomFieldValue) {
    if (model) {
      this.Field = new CustomField(model.Field);
      this.Value = model.Value;
      this.ExpenseId = model.ExpenseId;
    } else {
      this.Field = new CustomField();
      this.Value = null;
      this.ExpenseId = null;
    }
  }
}
