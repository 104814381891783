<on-loading *ngIf="isLoading"></on-loading>

<on-report-list *ngIf="!isLoading && reportsToSign && reportsToSign.length" [reports]="reportsToSign" [canSelect]="false"
  [showOwner]="true">

</on-report-list>

<div *ngIf="!isLoading && reportsToSign && !reportsToSign.length" class="text-center information-text">
  {{'company.signature.message-to-sign' | translate}}<br>
  <nb-icon size="3x" icon="fa-smile"></nb-icon>
</div>
