<on-page-title [title]="'account.my-account' | translate" icon="fa-user"></on-page-title>

<on-two-columns-display [title]="'account.update-password' | translate"
  [description]="'account.update-password-description' | translate" *ngIf="!canLinkMicrosoftAccount && !isLinkedToMicrosoftAccount">
  <div content>
    <div *ngIf="!isChangingPassword">
      <div class="form-group">
        <label>{{'account.previous-password' | translate}}</label>
        <input [(ngModel)]="oldPassword" type="password" class="form-control">
      </div>
      <div class="form-group">
        <label>{{'account.new-password' | translate}}</label>
        <input [(ngModel)]="newPassword" type="password" class="form-control">
      </div>
      <div class="form-group">
        <label>{{'account.confirm-password' | translate}}</label>
        <input [(ngModel)]="confirmationPassword" type="password" class="form-control">
      </div>
      <button (click)="changePassword()" class="btn btn-primary">{{'account.update-password-btn' | translate}}</button>
    </div>
    <on-loading *ngIf="isChangingPassword"></on-loading>
  </div>
</on-two-columns-display>

<on-two-columns-display [title]="'account.associate' | translate"
  [description]="'account.associate-description' | translate" *ngIf="canLinkMicrosoftAccount || isLinkedToMicrosoftAccount">
  <div content>
    <p *ngIf="isLinkedToMicrosoftAccount">{{'account.already-linked-microsoft' | translate}}</p>
    <div *ngIf="!isLinkedToMicrosoftAccount">
      <p>{{'account.association-disclaimer' | translate}}</p>
      <a class="microsoft-button" [href]="'/api/v2/account/connectmicrosoft?token=' + apiToken">
        <img src="/assets/images/ms-logo.png" />
        {{'account.connect-with-microsoft' | translate}}</a>
    </div>
  </div>
</on-two-columns-display>

<on-two-columns-display *ngIf="user$ | async as connectedUser"
  [title]="'account.my-onexpense' | translate : {appName:appName}"
  [description]="'account.my-onexpense-description' | translate: {appName:appName}">
  <div content>
    <div *ngIf="!connectedUser.CompanyId">
      <div *ngIf="!connectedUser.License; else hasSubscription">
        <h3>{{'account.prenium-title' | translate : {appName:appName} }}</h3>
        <div [innerHTML]="'account.prenium-description' | translate : {appName:appName} "></div>
        <div style="font-weight: bold;" [innerHTML]="'account.company-needed' | translate : {appName:appName}"></div>
        <br>
        <button class="btn btn-primary" [routerLink]="['/company']">{{'menu.create' | translate }}</button>
        <br>
      </div>
      <ng-template #hasSubscription>
        <!-- Not in a company with a license. He is among the FREE_30 users-->
        <div class="row d-flex">
          <div class="col-12">
            <p>Vous possédez un type de licence gratuite qui n'est plus disponible dans {{appName}} vous donnant accès à
              30
              reçus gratuits par mois. Si vous souhaitez créer une entreprise ou prendre un abonnement supérieur, merci
              de <a routerLink="/support">contacter le support</a></p>
          </div>
        </div>
      </ng-template>
    </div>
    <div *ngIf="connectedUser.CompanyId">
      <p [innerHTML]="'account.in-company' | translate: {company: (company$ | async)?.Name, appName:appName}"></p>
    </div>
  </div>
</on-two-columns-display>
<on-two-columns-display *ngIf="user$ | async as connectedOnUser" [title]="'account.delete-account' | translate"
  [description]="'account.delete-account-description' | translate : {appName:appName}">
  <div content>
    <button *ngIf="!connectedOnUser.CompanyId" class="btn btn-danger mt-4" (click)="deleteAccount()">
      {{'account.delete-account-button' | translate}}
    </button>
    <p *ngIf="connectedOnUser.CompanyId">
      {{'account.company-contact-admin' | translate}}
    </p>
  </div>
</on-two-columns-display>