import { EmployeeCategory } from 'on-shared/_models/EmployeeCategory';
import { ExpenseType } from 'on-shared/_models/ExpenseType';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { Rule } from 'projects/onexpense/src/app/shared/_models/Rule';

@Component({
  selector: 'on-create-rule-modal',
  templateUrl: './create-rule-modal.component.html',
  styleUrls: ['./create-rule-modal.component.scss']
})
export class CreateRuleModalComponent implements OnInit {
  @Input() companyId: number;
  @Input() expenseTypes: ExpenseType[];
  @Input() employeeCategories: EmployeeCategory[];

  ruleToCreate: Rule;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.initRuleToCreate();
  }

  initRuleToCreate() {
    this.ruleToCreate = {
      Id: null,
      Name: '',
      Type: 'limit',
      Value: 0,
      ExpenseTypeId: null,
      ExpenseType: null,
      EmployeeCategoryId: null,
      EmployeeCategory: null
    };
  }

  selectedRuleTypeChanged(event) {
    this.ruleToCreate.Type = event;
  }

  ruleIsCreated(event) {
    this.activeModal.close();
  }
}
