export class Country {
  Id: number;
  ISOCode: string;
  LocalName: string;
  FlagFileName: string;
  PrettyCode: string;

  constructor(model?: Country) {
    if (model) {
      this.Id = model.Id;
      this.ISOCode = model.ISOCode;
      this.LocalName = model.LocalName;
      this.FlagFileName = model.FlagFileName;
      this.PrettyCode = model.PrettyCode;
    } else {
      this.Id = 0;
      this.ISOCode = null;
      this.LocalName = null;
      this.FlagFileName = null;
      this.PrettyCode = null;
    }
  }
}
