import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'on-expense-status-select',
  templateUrl: './expense-status-select.component.html',
  styleUrls: ['./expense-status-select.component.scss']
})
export class ExpenseStatusSelectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
