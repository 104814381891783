import { CustomField } from './CustomField';
import { License } from './License';
import { Country } from 'on-common/_models/Country';
import { Currency } from 'on-common/_models/Currency';
import { Address } from 'on-common/_models/Address';
import { UserAccountingPlanValue } from './UserAccountingPlanValue';
import { EmployeeCategory } from './EmployeeCategory';

export class OnUser {
  Id: number;
  UserSettingsId: number;
  UserName: string;
  FirstName: string;
  LastName: string;
  ContactEmail: string;
  PhoneNumber: string;
  LinkedIn: string;
  Facebook: string;
  Twitter: string;
  ReceiveNewsletter: boolean;
  PreferedMobileDevice: string;
  CreationDate: Date;
  LastConnexion: Date;
  LanguageCode: string;
  Country: Country;
  Currency: Currency;
  Address: Address;

  IBAN: string;
  ManagerId: number;
  CompanyId?: number;
  Role: string;
  DisplayMetric: string;
  CustomFields: CustomField[];
  MileageRatio: number;
  MileageRateType: number;
  TypeOfVehicule: number;
  FiscalPower: number;
  IsAccountantExpert: boolean;

  ManagedUsers: OnUser[];
  HasEmployees: boolean;
  License: License;
  IsLinkedToMicrosoftAccount: boolean;

  DistanceHomeToWork: number;
  Identifier: string;
  OrganizationUnit: string;
  OtherInfo1: string;
  OtherInfo2: string;
  OtherInfo3: string;
  OtherInfo4: string;
  ThirdPartyAccount: string;
  HasRestaurantTicket: boolean;
  HasPerDiem: boolean;
  HasProfessionalCard: boolean;

  AccountingPlanValues: UserAccountingPlanValue[];
  EmployeeCategory: EmployeeCategory;

  constructor(model?: OnUser) {
    if (model) {
      this.Id = model.Id;
      this.UserSettingsId = model.UserSettingsId;
      this.UserName = model.UserName;
      this.FirstName = model.FirstName;
      this.LastName = model.LastName;
      this.ContactEmail = model.ContactEmail;
      this.LinkedIn = model.LinkedIn;
      this.Facebook = model.Facebook;
      this.Twitter = model.Twitter;
      this.PhoneNumber = model.PhoneNumber;
      this.ReceiveNewsletter = model.ReceiveNewsletter;
      this.PreferedMobileDevice = model.PreferedMobileDevice;
      this.CreationDate = new Date(model.CreationDate);
      this.LastConnexion = new Date(model.LastConnexion);
      this.LanguageCode = model.LanguageCode;
      this.Country = new Country(model.Country);
      this.Currency = new Currency(model.Currency);
      this.Address = new Address(model.Address);

      this.IBAN = model.IBAN;
      this.ManagerId = model.ManagerId;
      this.CompanyId = model.CompanyId;
      this.Role = model.Role;
      this.DisplayMetric = model.DisplayMetric;
      this.CustomFields = (model.CustomFields || []).map((c) => new CustomField(c));
      this.MileageRatio = model.MileageRatio;
      this.MileageRateType = model.MileageRateType;
      this.TypeOfVehicule = model.TypeOfVehicule;
      this.FiscalPower = model.FiscalPower;
      this.IsAccountantExpert = model.IsAccountantExpert;

      this.ManagedUsers = (model.ManagedUsers || []).map((c) => new OnUser(c));
      this.HasEmployees = model.HasEmployees;
      this.License = new License(model.License);
      this.IsLinkedToMicrosoftAccount = model.IsLinkedToMicrosoftAccount;

      this.DistanceHomeToWork = model.DistanceHomeToWork;
      this.Identifier = model.Identifier;
      this.OrganizationUnit = model.OrganizationUnit;
      this.OtherInfo1 = model.OtherInfo1;
      this.OtherInfo2 = model.OtherInfo2;
      this.OtherInfo3 = model.OtherInfo3;
      this.OtherInfo4 = model.OtherInfo4;
      this.ThirdPartyAccount = model.ThirdPartyAccount;
      this.AccountingPlanValues = model.AccountingPlanValues ? model.AccountingPlanValues.map(upv => new UserAccountingPlanValue(upv)) : [];

      this.EmployeeCategory = model.EmployeeCategory ? model.EmployeeCategory : null;

      this.HasRestaurantTicket = model.HasRestaurantTicket;
      this.HasPerDiem = model.HasPerDiem;
      this.HasProfessionalCard = model.HasProfessionalCard;
    } else {
      this.Id = null;
      this.UserSettingsId = null;
      this.UserName = null;
      this.FirstName = null;
      this.LastName = null;
      this.ContactEmail = null;
      this.LinkedIn = null;
      this.Facebook = null;
      this.Twitter = null;
      this.ReceiveNewsletter = null;
      this.PreferedMobileDevice = null;
      this.PhoneNumber = null;
      this.CreationDate = new Date();
      this.LastConnexion = new Date();
      this.LanguageCode = null;
      this.Country = new Country();
      this.Currency = new Currency();
      this.Address = new Address();

      this.IBAN = null;
      this.ManagerId = null;
      this.CompanyId = null;
      this.Role = null;
      this.DisplayMetric = null;
      this.CustomFields = [];
      this.MileageRatio = null;
      this.MileageRateType = null;
      this.TypeOfVehicule = null;
      this.FiscalPower = null;
      this.IsAccountantExpert = null;

      this.ManagedUsers = [];
      this.HasEmployees = null;
      this.License = null;
      this.IsLinkedToMicrosoftAccount = false;

      this.DistanceHomeToWork = null;
      this.Identifier = null;
      this.OrganizationUnit = null;
      this.OtherInfo1 = null;
      this.OtherInfo2 = null;
      this.OtherInfo3 = null;
      this.OtherInfo4 = null;
      this.ThirdPartyAccount = null;

      this.AccountingPlanValues = [];
      this.EmployeeCategory = null;

      this.HasRestaurantTicket = true;
      this.HasPerDiem = false;
      this.HasProfessionalCard = false;
    }
  }
}
