<ul class="m-0">
  <li class="nav-item" ngbDropdown placement="bottom-right" display="dynamic">
    <button class="btn btn-outline-primary" ngbDropdownToggle>
      <nb-icon icon="fa-user"></nb-icon>
    </button>
    <div class="dropdown-menu" ngbDropdownMenu>
      <div class="full-width px-3"><strong>{{($currentUser | async)?.UserName}}</strong></div>
      <div class="divider"></div>
      <a class="dropdown-item" [routerLink]="['/account']">
        <nb-icon icon="fa-user"></nb-icon> {{ 'account.my-profil' | translate }}
      </a>
      <a class="dropdown-item" [routerLink]="['/account', 'mysettings']">
        <nb-icon icon="fa-cog"></nb-icon> {{ 'account.my-settings' | translate }}
      </a>
      <div class="divider"></div>
      <button class="dropdown-item" (click)="signOut()">
        <nb-icon icon="fa-sign-out-alt"></nb-icon> {{ 'account.logout' | translate }}
      </button>
    </div>
  </li>
</ul>
