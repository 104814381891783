import { Component, OnInit } from '@angular/core';
import { TOKEN_STORAGE_KEY_CONNECTED_AS, USERNAME_STORAGE_KEY_CONNECTED_AS } from 'on-common/constants';
import { AccountantExpertService } from 'on-shared/stores/accountant-expert/accountant-expert.service';
import { OnUser } from 'on-shared/_models/OnUser';

@Component({
  selector: 'on-connected-as-status',
  templateUrl: './connected-as-status.component.html',
  styleUrls: ['./connected-as-status.component.scss']
})
export class ConnectedAsStatusComponent implements OnInit {

  currentUser: OnUser;
  usernameConnectedAs: string;

  showConnectedAs = false;

  constructor(private accountantExpertService: AccountantExpertService,
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem(TOKEN_STORAGE_KEY_CONNECTED_AS)) {
      this.usernameConnectedAs = localStorage.getItem(
        USERNAME_STORAGE_KEY_CONNECTED_AS,
      );
      this.showConnectedAs = true;
    }
  }

  logout() {
    this.accountantExpertService.SetActiveToken(null, null);
  }
}
