import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserStateModel } from '../stores/user/user.model';
import { Store } from '@ngxs/store';
import { UserActions } from '../stores/user/user.actions';
import { AccountService } from 'on-auth/_services/account.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserGuard implements CanActivate {
  constructor(
    private store: Store,
    private accountService: AccountService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const userState = this.store.selectSnapshot<UserStateModel>(
      (storeState) => storeState.user,
    );
    if (!userState?.current) {
      // If not connected
      if (!this.accountService.isLoggedIn()) {
        return this.router.navigate(['/login']);
      }
      return new Observable((oberver) => {
        this.store
          .dispatch(new UserActions.GetCurrentUser())
          .subscribe((res) => {
            oberver.next(res.user.isSuccess);
          });
      });
    } else {
      return of(true);
    }
  }
}
