<on-loading *ngIf="isLoading"></on-loading>

<div *ngIf="!isLoading">
  <div class="row">
    <div class="col-12" *ngIf="!currentSubscription">
      <i>{{'payment.stripe-payment.choose-license-type' | translate}}</i>
    </div>
    <div class="col-12" *ngIf="currentSubscription">
      <i>{{'payment.stripe-payment.choose-license-type-description' | translate}}</i>
    </div>
  </div>
  <div class="row justify-content-around">
    <ng-container *ngFor="let product of products">
      <div class="product-item col rounded" [ngClass]="{
        'selected': product === selectedProduct,
        'shadow': product === selectedProduct,
        'shadow-sm': product !== selectedProduct,
        'disabled': product.disabled
      }" (click)="selectProduct(product)" *ngIf="!product.disabled">
        <h4 class="option-title text-center">{{product.Name}}</h4>
        <p style="font-style: italic;">{{product.Description}}</p>
        <div *ngIf="selectedProduct === product">
          <div *ngIf="!currentSubscription">
            <p>{{'payment.stripe-payment.choose-renew' | translate}}:</p>
            <select class="form-control" (change)="selectPrice()" [(ngModel)]="selectedPrice">
              <option [ngValue]="null" selected [hidden]="this.selectedPrice">
                {{'payment.stripe-payment.your-choice' | translate}}</option>
              <option *ngFor="let price of selectedProduct.Prices" [ngValue]="price">
                <span *ngIf="!price.IsFloating">
                  {{price.Price | number : '.2'}}{{'payment.stripe-payment.per-user-every' | translate}}
                  {{price.IntervalCount}}
                  {{price.Interval | translate}}
                </span>
                <span *ngIf="price.IsFloating">
                  {{'payment.stripe-payment.floating-license' | translate}}
                </span>
              </option>
            </select>
          </div>
          <div *ngIf="currentSubscription && selectedPrice">
            <p>{{selectedPrice.Price | number : '.2'}}{{'payment.stripe-payment.per-user-every' | translate}}
              {{selectedPrice.IntervalCount}}
              {{selectedPrice.Interval | translate}}</p>
          </div>
          <div *ngIf="selectedPrice && !selectedPrice.IsFloating">
            <br>
            <div>
              <label class="m-0">{{'payment.stripe-payment.nb-of-licenses' | translate}}:</label>
              <input class="form-control" type="number" [ngModel]="quantity" (ngModelChange)="quantityChanged($event)">
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="col-12 align-items-center m-0" *ngIf="selectedPrice && quantity && !currentSubscription">
      <br>
      <p *ngIf="!selectedPrice.IsFloating" class="font-italic"
        [innerHTML]="'payment.stripe-payment.buy-summary' | translate : {quantity: quantity, selectedProductName: selectedProduct.Name, totalPriceHt: (totalPrice | number : '.2'), totalPriceTtc: (totalPrice * 1.2 | number : '.2')}">
      </p>
      <p *ngIf="selectedPrice.IsFloating">
        {{selectedPrice.Description}}
      </p>
    </div>
  </div>
  <div class="row" *ngIf="selectedPrice && quantity && !currentSubscription">
    <div class="col-12 text-center mt2">
      <button class="btn btn-primary" (click)="buy()">
        {{'payment.stripe-payment.buy-confirmation' | translate:{totalPrice: (totalPrice | number : '.2')} }}
      </button>
    </div>
  </div>

  <div class="row"
    *ngIf="currentSubscription && selectedPrice && (selectedPrice.Id !== currentSubscription.RecurringPrice.Id || quantity !== currentSubscription.Quantity)">
    <br>
    <div class="col-12 text-center mt2">
      <button class="btn btn-primary" (click)="changeSubscription()">{{'payment.stripe-payment.edit-subscription' |
        translate}}</button>
    </div>
  </div>
</div>