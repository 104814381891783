import { TranslateService } from '@ngx-translate/core';
import { AccountantExport } from 'onexpense/company/_models/AccountantExport';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { AccountantExportState } from 'on-shared/stores/accountant-export/accountant-export.state';
import { AccountantExportAction } from 'on-shared/stores/accountant-export/accountant-export.actions';
import { CompanyState } from 'on-shared/stores/company/company.state';
import { Company } from 'on-shared/_models/Company';
import { CompanyAction } from 'on-shared/stores/company/company.action';

@Component({
  selector: 'on-accountant-exports',
  templateUrl: './accountant-exports-page.component.html',
  styleUrls: ['./accountant-exports-page.component.scss'],
})
export class AccountantExportsComponent implements OnInit {

  @Input() showOnlyTreatedExports = false;

  isLoading = false;
  canArchived = false;
  exports: AccountantExport[];
  @Select(AccountantExportState.exports) exports$: Observable<AccountantExport[]>;
  @Select(AccountantExportState.exportsTreated) exportsTreated$: Observable<AccountantExport[]>;
  @Select(CompanyState.current) company$: Observable<Company>;
  destroy$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private store: Store,
  ) { }

  ngOnInit() {
    if (!this.showOnlyTreatedExports) {
      this.store.dispatch(new AccountantExportAction.LoadAccountantExportsIfNeeded());
    } else {
      this.store.dispatch(new AccountantExportAction.LoadTreatedAccountantExportsIfNeeded());
    }

    this.store.dispatch(new CompanyAction.LoadCompaniesIfNeeded());
    this.company$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response) {
        this.canArchived = response.IsVP;
      }
    });
    this.initExports();
  }

  initExports() {
    this.isLoading = true;
    if (!this.showOnlyTreatedExports) {
      this.exports$.pipe(takeUntil(this.destroy$)).subscribe(response => {
        if (response) {
          this.exports = response.map((c) => new AccountantExport(c));

          this.isLoading = false;
        }
      });
    } else {
      this.exportsTreated$.pipe(takeUntil(this.destroy$)).subscribe(response => {
        if (response) {
          this.exports = response.map((c) => new AccountantExport(c));

          this.isLoading = false;
        }
      });
    }
  }

  create() {
    this.router.navigate(['company', 'exports', 'create']);
  }

  reload() {
    this.isLoading = true;
    if (!this.showOnlyTreatedExports) {
      this.store.dispatch(new AccountantExportAction.LoadAccountantExports());
    } else {
      this.store.dispatch(new AccountantExportAction.LoadTreatedAccountantExports());
    }
  }
}
