import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { EmployeeCategoriesTableComponent } from './employee-categories-table/employee-categories-table.component';

@Component({
  selector: 'on-employee-categories-tab',
  templateUrl: './employee-categories-tab.component.html',
  styleUrls: ['./employee-categories-tab.component.scss']
})
export class EmployeeCategoryTabComponent {

  @Input() companyId: number;
  @ViewChild('EmployeeCategoriesTab', { static: false }) employeeCategoriesTab: EmployeeCategoriesTableComponent;

  constructor() { }

  reload() {
    this.employeeCategoriesTab.reload();
  }

}
