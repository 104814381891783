<on-page-title icon="fa-edit"
  [title]="'report.edit-report' | translate: { report : (reportToEdit ? reportToEdit.Name : '')}">
</on-page-title>

<on-loading *ngIf="isLoading"></on-loading>
<on-edit-report-form *ngIf="!isLoading && reportToEdit" (reload)="LoadReport()" (saveRequested)="saveReport()"
  (managerActionsVisible)="managerActionsVisible($event)" [report]="reportToEdit"
  [user]="connectedUser"></on-edit-report-form>
<div
  *ngIf="!isLoading && reportToEdit && reportToEdit.ManagerId === (currentUser$ | async)?.Id && reportToEdit.State.Code === 'SENT' && showManagerActions">
  <hr>
  <div class="row d-flex justify-content-center">
    <div class="col-6 text-center">
      <span [nbTooltip]="'report.tooltip-validation' | translate" nbTooltipStatus="primary">
        <button class="btn btn-primary margin-5" (click)="acceptReport()" [disabled]="!canValidReport()">
          <nb-icon icon="fa-check"></nb-icon> {{'report.valid-report' | translate}}
        </button>
      </span>
    </div>
    <div class="col-6 text-center">
      <span [nbTooltip]="'report.tooltip-reject' | translate" nbTooltipStatus="danger">
        <button class="btn btn-danger margin-5" (click)="refuseReport()" [disabled]="!canRejectReport()">
          <nb-icon icon="fa-times"></nb-icon> {{'report.reject-report' | translate}}
        </button>
      </span>
    </div>
  </div>
</div>


<!-- In case the report is validated, the manager or accountant can stil refuse it, back to the user -->
<div class="row" *ngIf="reportToEdit && canUnvalidate()">
  <div class="text-center col-12 alert alert-warning" *ngIf="reportToEdit.AccountantExportId">
    <nb-icon icon="fa-exclamation-triangle"></nb-icon> {{'report.in-accountant-export' | translate}}
  </div>
  <div class="col text-center">
    <button (click)="refuseReport()" class="btn btn-danger">
      <nb-icon icon="fa-times"></nb-icon> {{'report.cancel-report-validation' | translate}}
    </button>
  </div>
</div>