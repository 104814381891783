import { CustomField } from './../../../shared/_models/CustomField';

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'on-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss']
})
export class CustomFieldsComponent implements OnInit {
  @Input() customFields: CustomField[];
  @Input() userId: number;
  @Input() companyId: number;
  @Output() saveRequest = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  addCustomField() {
    this.customFields.push({
      Id: 0,
      Name: '',
      Description: '',
      ExpenseType: { Id: 1, Code: 'MISC', Name: '' },
      Type: 'string',
      UserId: this.companyId ? null : this.userId,
      CompanyId: this.companyId,
      IsRequired: false,
      PossibleValues: []
    });
  }

  deleteCustomField(i) {
    this.customFields.splice(i, 1);
  }

  Save() {
    this.saveRequest.emit();
  }
}
