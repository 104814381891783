import { ToastService } from 'on-common/_services/toast.service';
import { AccountingPlanService } from './accounting-plan.service';
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { AccountingPlanModel } from "./accounting-plan.model";
import { TranslateService } from '@ngx-translate/core';
import { AccountingPlanAction } from './accounting-plan.action';

@State<AccountingPlanModel>({
  name: 'accountingPlan',
  defaults: new AccountingPlanModel()
})
@Injectable()
export class AccountingPlanState {
  constructor(
    private accountingPlanService: AccountingPlanService,
    private store: Store,
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  @Selector()
  static userAccountingPlans(state: AccountingPlanModel) {
    return state.userAccountingPlans;
  }

  @Selector()
  static isLoading(state: AccountingPlanModel) {
    return state.isLoading;
  }


  @Action(AccountingPlanAction.LoadAccountingPlans)
  public loadAccountingPlans(ctx: StateContext<AccountingPlanModel>) {
    ctx.patchState({
      isLoading: true
    });
    return this.accountingPlanService.GetAccountingPlansForCompany().subscribe(response => {
      if (response.IsSuccess) {
        ctx.patchState({
          isLoading: false,
          userAccountingPlans: response.Result.sort(this.sortPlans)
        });
      } else {
        this.toastService.error(
          this.translateService.instant('accounting-plan.get-error-content'),
          this.translateService.instant('accounting-plan.get-error-title'),
        );
        ctx.patchState({
          isLoading: false,
          userAccountingPlans: null
        });
      }
    });
  }

  @Action(AccountingPlanAction.UpdateAccountingPlans)
  public updateAccountingPlans(ctx: StateContext<AccountingPlanModel>, { plansToUpdate }: AccountingPlanAction.UpdateAccountingPlans) {
    ctx.patchState({
      isLoading: true
    });
    return this.accountingPlanService.UpdateAccountingPlans(plansToUpdate).subscribe(
      {
        next: response => {
          if (response.IsSuccess) {
            ctx.patchState({
              isLoading: false,
              userAccountingPlans: response.Result.sort(this.sortPlans)
            });
            this.toastService.success(
              this.translateService.instant('accounting-plan.update-success-content'),
              this.translateService.instant('accounting-plan.update-success-title'),
            );
          } else {
            ctx.patchState({
              isLoading: false,
              userAccountingPlans: null
            });
            this.toastService.error(
              this.translateService.instant('accounting-plan.update-error-content'),
              this.translateService.instant('accounting-plan.update-error-title'),
            );
          }
        },
        error: err => {
          this.toastService.error(
            this.translateService.instant('accounting-plan.update-error-content'),
            this.translateService.instant('accounting-plan.update-error-title'),
          );
          ctx.patchState({
            isLoading: false,
            userAccountingPlans: null
          });
        }
      });
  }

  private sortPlans = (a, b) => {
    if (a.Order > b.Order)
      return 1;
    else
      return -1
  }
}
