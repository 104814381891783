<div class="m-2">
  <h2>{{'company.employees.add-employee.title' | translate}}</h2>
  <hr>
  <on-loading *ngIf="isLoading"></on-loading>
  <div class="flex-row" *ngIf="!isLoading">
    <div [innerHTML]="'company.employees.add-employee.description' | translate">
    </div>
    <div>
      <textarea [(ngModel)]="employeesText" rows="10" class="form-control"></textarea>
    </div>
    <div class="alert alert-danger ml-4 mr-4" *ngIf="incorrectEmails && incorrectEmails.length">
      {{'company.employees.add-employee.not-valid-emails' | translate: {emails: incorrectEmails.join(', ')} }}
    </div>
    <div class="text-center">
      <button class="btn btn-primary" (click)="addEmployees()">{{'company.employees.add-employee.btn' | translate}}</button>
    </div>
  </div>
</div>
