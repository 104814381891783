import { Injectable } from '@angular/core';
import { Selector, Action, StateContext, State } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { StatisticAction } from './statistic.actions';
import { DashboardModel } from '../../_models/DashboardModel';
import { StatisticService } from './statistic.service';
import { DashboardStateModel } from './statistic.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'on-common/_services/toast.service';

@State<DashboardStateModel>({
  name: `statistic`,
  defaults: new DashboardStateModel()
})
@Injectable()
export class StatisticState {
  constructor(
    private statisticService: StatisticService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  @Selector()
  static dashboard(state: DashboardStateModel) {
    return state.dashboard;
  }

  @Action(StatisticAction.LoadDashboard)
  public loadDashboard(ctx: StateContext<DashboardStateModel>) {
    return this.statisticService.GetDashboardInfo().pipe(tap(response => {
      if (response.IsSuccess) {
        ctx.patchState({
          dashboard: new DashboardModel(response.Result),
          shouldReloadDashboard: false
        });
      } else {
        this.toastService.error(this.translateService.instant('dashboard.cannot-load-data'));
      }
    }, error => {
      this.toastService.error(this.translateService.instant('dashboard.cannot-load-data'));
    }));
  }

  @Action(StatisticAction.LoadDashboardIfNeeded)
  public loadDashboardIfNeeded(ctx: StateContext<DashboardStateModel>) {
    const state = ctx.getState();

    if (state.shouldReloadDashboard || !state.dashboard) {
      return this.loadDashboard(ctx);
    }
  }
}
