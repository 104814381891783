import { Report } from 'on-shared/_models/Report';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ReportsState } from 'on-shared/stores/reports/reports.state';
import { ReportsActions } from 'on-shared/stores/reports/reports.actions';

@Component({
  selector: 'on-select-report-modal',
  templateUrl: './select-report-modal.component.html',
  styleUrls: ['./select-report-modal.component.scss'],
})
export class SelectReportModalComponent implements OnInit, OnDestroy {
  isLoadingReports: boolean;
  toBeIncludedReports: Report[];

  @Select(ReportsState.getReportsForAccountant) readonly $reports;
  $destroy = new Subject<void>();

  constructor(public modal: NgbActiveModal, private store: Store) {}

  ngOnInit() {
    this.isLoadingReports = true;
    this.toBeIncludedReports = [];
    this.store.dispatch(
      new ReportsActions.IfValidatedReportsForAccountantNeeded(),
    );
    this.$reports.pipe(takeUntil(this.$destroy)).subscribe((res: Report[]) => {
      if (res) {
        this.toBeIncludedReports = res.map((r) => new Report(r));
        this.isLoadingReports = false;
      }
    });
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }

  canAdd(): boolean {
    return this.toBeIncludedReports.filter((r) => r.IsSelected).length > 0;
  }

  add() {
    const reportsToInclude = this.toBeIncludedReports.filter(
      (r) => r.IsSelected,
    );
    this.modal.close({
      reports: reportsToInclude,
    });
  }
}
