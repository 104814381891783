import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { UserState } from 'on-shared/stores/user/user.state';
import { Observable } from 'rxjs';
import { OnUser } from 'on-shared/_models/OnUser';
import { UserActions } from 'on-shared/stores/user/user.actions';

@Component({
  selector: 'on-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
})
export class AccountMenuComponent implements OnInit {
  @Select(UserState.currentUser) $currentUser: Observable<OnUser>;

  constructor(
    public store: Store,
    private router: Router,
  ) { }

  ngOnInit() { }

  signOut() {
    this.router.navigate(['/login']);
    setTimeout(() => {
      this.store.dispatch(new UserActions.Logout());
    }, 500);
  }
}
