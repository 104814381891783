import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from 'on-common/_services/toast.service';
import { TOKEN_STORAGE_KEY, TOKEN_STORAGE_KEY_CONNECTED_AS } from 'on-common/constants';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class HttpTokenInterceptorService implements HttpInterceptor {

  constructor(
    private toastService: ToastService,
    private router: Router,
    private accountService: AccountService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Prevent problem with CORS for the externals API
    if (req.url.includes('nominatim') || req.url.includes('photon') || req.url.includes('project-osrm')) {
      return next.handle(req);
    }

    // If accountant expert
    const tokenForAccountantExpert = localStorage.getItem(TOKEN_STORAGE_KEY_CONNECTED_AS);

    const accessToken = tokenForAccountantExpert ? tokenForAccountantExpert : localStorage.getItem(TOKEN_STORAGE_KEY);

    let toReturn: Observable<HttpEvent<any>>;
    if (accessToken) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization',
          'Bearer ' + accessToken)
      });

      toReturn = next.handle(cloned);
    } else {
      toReturn = next.handle(req);
    }


    return toReturn.pipe(tap(event => {
      if (event instanceof HttpResponse) {
        const response = event as HttpResponse<any>;

        if (response.ok && response.body.IsSuccess === false && response.body.Message) {
          this.toastService.warning(response.body.Message, 'Oups...');
        }
      }
      return event;
    }, error => {
      const response = error as HttpErrorResponse;
      if (response.status === 401) {
        this.toastService.warning('Un problème est intervenu avec l\'authentification');
        this.accountService.logout();
        this.router.navigate(['/login']);
      }
    }));
  }
}
