export class Address {
  Id: number;
  Street1: string;
  Street2: string;
  City: string;
  CountryId: number;
  Zip: string;

  constructor(model?: Address) {
    if (model) {
      this.Id = model.Id;
      this.Street1 = model.Street1;
      this.Street2 = model.Street2;
      this.City = model.City;
      this.CountryId = model.CountryId;
      this.Zip = model.Zip;
    } else {
      this.Id = 0;
      this.Street1 = null;
      this.Street2 = null;
      this.City = null;
      this.CountryId = null;
      this.Zip = null;
    }
  }
}
