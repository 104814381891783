import { Expense } from '../../_models/Expense';
import { ExpenseType } from '../../_models/ExpenseType';

export class ExpenseStateModel {
  current: Expense; // Current expense in edition
  expenses: Expense[];
  expenseTypes: ExpenseType[];

  shouldReloadExpenses: boolean;
  shouldReloadExpenseTypes: boolean;

  constructor(model?: ExpenseStateModel) {
    if (model) {
      this.current = model.current;
      this.expenses = model.expenses;
      this.expenseTypes = model.expenseTypes;
      this.shouldReloadExpenses = model.shouldReloadExpenses;
      this.shouldReloadExpenseTypes = model.shouldReloadExpenseTypes;
    } else {
      this.current = null;
      this.expenses = null;
      this.expenseTypes = null;
      this.shouldReloadExpenses = true;
      this.shouldReloadExpenseTypes = true;
    }
  }
}
