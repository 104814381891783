import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'on-verif-gridcell',
  templateUrl: './verif-gridcell.component.html',
  styleUrls: ['./verif-gridcell.component.scss']
})
export class VerifGridcellComponent implements ICellRendererAngularComp {

  params: any;

  constructor() { }

  refresh(params: any): boolean {
    return false;
  }
  agInit(params: import('ag-grid-community').ICellRendererParams): void {
    this.params = params;
  }
  afterGuiAttached?(params?: import('ag-grid-community').IAfterGuiAttachedParams): void {
  }

}
