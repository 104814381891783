import { BehaviorSubject } from 'rxjs';
import { BannerNotification } from './../_models/BannerNotification';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationBannerService {

  notificationToDisplay: BehaviorSubject<BannerNotification> = new BehaviorSubject<BannerNotification>(undefined);
  closeBanner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);

  constructor() { }
}
