export namespace BadgesAction {

  export class LoadBadges {
    public static readonly type = '[Badges] load badges data';
    constructor() {}
  }

  export class AutoReloadingBadges {
    public static readonly type = '[Badges] reload badges data automatically';
    constructor() {}
  }

  export class StopReloadingBadges {
    public static readonly type = '[Badges] stop reload badges data automatically';
    constructor() {}
  }
}
