import { TranslateService } from '@ngx-translate/core';
import { DateGridcellComponent } from 'on-shared/grid-cell/date-gridcell/date-gridcell.component';
import { Report } from 'on-shared/_models/Report';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  HostListener,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ExportGridcellComponent } from '../grid-cell/export-gridcell/export-gridcell.component';
import { ReportValidationGridcellComponent } from '../grid-cell/report-validation-gridcell/report-validation-gridcell.component';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { ColDef, SelectionChangedEvent } from 'ag-grid-community';
import { TotalGridcellComponent } from 'on-shared/grid-cell/total-gridcell/total-gridcell.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SendValidationComponent } from './send-validation/send-validation.component';
import { ReportsActions } from 'on-shared/stores/reports/reports.actions';
import { Store } from '@ngxs/store';
import { ActionButton } from 'on-common/_models/ActionButton';

@Component({
  selector: 'on-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss'],
})
export class ReportListComponent implements OnInit, OnChanges {
  @Input() reports: Report[];
  @Input() canSelect = true;
  @Input() canEdit = true;
  @Input() minimalist = false;
  @Input() showValidationDate = false;
  @Input() showPagination = true;
  @Input() pageSize = 10;

  @Input() useValidation = false;
  @Input() showOwner = false;
  // This is used in the ExportGridcellComponent to display only the PDF button when stored in VP
  @Input() isInVP = false;

  @Input() actionButtons: ActionButton[] = [];

  allReportsSelected = false;

  // Grid Expenses
  @ViewChild('agGridReports', { static: true }) gridReports: AgGridAngular;
  @ViewChild('divGrid', { static: true }) divGrid;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const timeout = setTimeout((_) => {
      this.gridReports.api.autoSizeAllColumns();
      this.gridReports.api.sizeColumnsToFit();
      this.gridReports.api.resetRowHeights();
      clearTimeout(timeout);
    }, 200);
  }

  constructor(
    private translate: TranslateService,
    private bsModalService: NgbModal,
    private store: Store,
    public router: Router
  ) { }

  ngOnInit() {
    const columnDefsReports: ColDef[] = [
      {
        headerName: this.translate.instant('report.name'),
        field: 'Name',
        sortable: true,
        filter: 'agTextColumnFilter',
        resizable: true,
        checkboxSelection: this.canSelect,
        headerCheckboxSelection: this.canSelect,
        headerCheckboxSelectionFilteredOnly: true,
        tooltipField: 'Name',
        minWidth: 200
      },
      {
        headerName: this.translate.instant('report.comments'),
        field: 'Comments',
        hide: this.minimalist,
        sortable: true,
        filter: 'agTextColumnFilter',
        resizable: true,
        tooltipField: 'Comments',
        minWidth: 150
      },
      {
        headerName: this.translate.instant('report.creation-date'),
        field: 'CreationDate',
        cellRenderer: DateGridcellComponent,
        cellRendererParams: {
          for: 'dateCreaReport',
        },
        sortable: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          browserDatePicker: true,

          comparator: function (filterLocalDateAtMidnight, cellValue) {
            const dateAsString = formatDate(cellValue, 'dd/MM/yyyy', 'fr');

            if (dateAsString == null) {
              return 0;
            }

            const dateParts = dateAsString.split('/');
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(day, month, year);

            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
          minWidth: 200
        },
        resizable: true,
        cellClass: 'd-flex align-items-center justify-content-end',
        // width: 120
      },
      {
        headerName: this.translate.instant('report.total-in-report'),
        field: 'TotalInReport',
        cellRenderer: TotalGridcellComponent,
        cellRendererParams: {
          for: 'reports',
        },
        sortable: true,
        filter: 'agNumberColumnFilter',
        cellClass: 'd-flex align-items-center justify-content-end',
        resizable: true,
        minWidth: 92,
      },
      {
        headerName: this.translate.instant('report.expenses'),
        field: 'NbOfExpenses',
        sortable: true,
        filter: 'agNumberColumnFilter',
        cellClass: 'd-flex align-items-center justify-content-end',
        resizable: true,
        width: 120
      },
      {
        headerName: this.translate.instant('report.employee'),
        field: 'Username',
        hide: !this.showOwner,
        sortable: true,
        filter: 'agTextColumnFilter',
        resizable: true,
        // width: 90,
        tooltipField: this.translate.instant('report.username'),
      },
      {
        headerName: this.translate.instant('report.exports'),
        colId: 'exports',
        hide: this.minimalist,
        cellRenderer: ExportGridcellComponent,
        sortable: true,
        resizable: true,
        autoHeight: this.useValidation,
        cellClass: 'd-flex align-items-center justify-content-center',
        // width: 188
      },
      {
        headerName: this.translate.instant('report.validation'),
        field: 'Comments',
        hide: !this.useValidation,
        cellRenderer: ReportValidationGridcellComponent,
        sortable: true,
        filter: false,
        resizable: true,
        autoHeight: true,
        cellClass:
          'd-flex align-items-center justify-content-center text-center',
        width: 100,
      },
      {
        headerName: this.translate.instant('report.validation-date'),
        field: 'ValidationDate',
        hide: !this.showValidationDate,
        cellRenderer: DateGridcellComponent,
        cellRendererParams: {
          for: 'dateValidReport',
        },
        sortable: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          browserDatePicker: true,

          comparator: function (filterLocalDateAtMidnight, cellValue) {
            const dateAsString = formatDate(cellValue, 'dd/MM/yyyy', 'fr');

            if (dateAsString == null) {
              return 0;
            }

            const dateParts = dateAsString.split('/');
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(day, month, year);

            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
        },
        resizable: true,
        cellClass: 'd-flex align-items-center justify-content-end',
        width: 130,
      },
    ];

    // Grid Reports Options
    this.gridReports.gridOptions = {
      columnDefs: columnDefsReports,
      rowData: this.reports,

      rowSelection: 'multiple',
      // floatingFilter: true,
      domLayout: 'autoHeight',

      pagination: this.showPagination,
      paginationPageSize: this.pageSize, // Nb items displayed per page - default

      rowHeight: 38,

      suppressCellFocus: true,      
      suppressRowClickSelection: true,

      onCellClicked: this.onCellClicked,

      onSelectionChanged: (event: SelectionChangedEvent) => {
        this.reports.forEach((r) => (r.IsSelected = false));
        event.api.getSelectedRows().forEach((r) => (r.IsSelected = true));
      },

      onGridReady: function (params) {
        params.columnApi.autoSizeAllColumns();
        const columnsWidth = this.context.parent.getSizeOfColumns(
          params.columnApi.getColumnState(),
        );
        const divGridWidth = this.context.parent.divGrid.nativeElement
          .offsetWidth;

        // If columnsWidth < divWidth => Adjust to full size
        if (columnsWidth < divGridWidth) {
          params.api.sizeColumnsToFit();
        }
        this.context.parent.onResize(null);
      },

      // For get 'this' in child component
      context: { parent: this },

      // Translate
      localeText: {
        noRowsToShow: this.translate.instant('report.noRowsToShow'),
        // Pagination
        to: this.translate.instant('table.to'),
        of: this.translate.instant('table.of'),
        // Filters
        filterOoo: this.translate.instant('table.filterOoo'),
        contains: this.translate.instant('table.contains'),
        equals: this.translate.instant('table.equals'),
        notEqual: this.translate.instant('table.notEqual'),
        lessThan: this.translate.instant('table.lessThan'),
        lessThanOrEqual: this.translate.instant('table.lessThanOrEqual'),
        greaterThan: this.translate.instant('table.greaterThan'),
        greaterThanOrEqual: this.translate.instant('table.greaterThanOrEqual'),
        inRange: this.translate.instant('table.inRange'),
        startsWith: this.translate.instant('table.startsWith'),
        endsWith: this.translate.instant('table.endsWith'),
        notContains: this.translate.instant('table.notContains'),

        andCondition: this.translate.instant('table.andCondition'),
        orCondition: this.translate.instant('table.orCondition'),
      },
    };

    this.gridReports.defaultColDef = {
      cellClass: 'd-flex align-items-center',
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reports && !changes.reports.firstChange) {
      this.changeData(changes.reports.currentValue);
      this.onResize(null);
    }
  }

  onCellClicked(event) {
    // Block for exports cell
    const parentComponent = event.context.parent as ReportListComponent;
    if (parentComponent.canEdit && event.colDef.colId !== 'exports') {
      parentComponent.router.navigate(['/reports', 'edit', event.data.Id]);
    }
  }

  exportCSV(reportId: number) {
    this.store.dispatch(new ReportsActions.ExportCSV(reportId)).subscribe();
  }

  exportExcel(reportId: number) {
    this.store.dispatch(new ReportsActions.ExportExcel(reportId)).subscribe();
  }

  exportZip(reportId: number) {
    this.store.dispatch(new ReportsActions.ExportZip(reportId)).subscribe();
  }

  exportPdf(reportId: number) {
    this.store.dispatch(new ReportsActions.ExportPDF(reportId)).subscribe();
  }

  CheckAllReports() {
    for (const report of this.reports) {
      report.IsSelected = this.allReportsSelected;
    }
  }

  sendByMail(repositoryId: number) {
    this.store.dispatch(new ReportsActions.SendByMail(repositoryId));
  }

  sendToValidation(report: Report) {
    const modalInstance = this.bsModalService.open(SendValidationComponent);
    modalInstance.componentInstance.report = report;
  }

  setPaginationSize(pageSize: number) {
    this.pageSize = pageSize;
    this.gridReports.api.updateGridOptions({paginationPageSize: pageSize});
    this.gridReports.api.paginationGoToFirstPage(); // Go to first page
  }

  // Call in parent for delete reports
  getSelectedRowsIds(): number[] {
    const selectedNodes = this.gridReports.api.getSelectedNodes();
    const selectedIds = selectedNodes.map((node) => node.data.Id);
    return selectedIds;
  }

  getSizeOfColumns(columns: any[]): number {
    let size = 0;
    for (const tmp of columns) {
      // If visible
      if (!tmp.hide) {
        size += tmp.width;
      }
    }

    return size;
  }

  onChangeFilter(searchTerms?: string) {
    this.gridReports.api.setGridOption("quickFilterText", searchTerms);
  }

  changeData(reports: Report[]) {
    this.gridReports.api.updateGridOptions({ rowData: reports });
    setTimeout(() => {
      this.gridReports.api.resetRowHeights();
    }, 500);
  }
}
