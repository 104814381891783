import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { AccountantAdministrationComponent } from '../accountant-settings-page/accountant-administration/accountant-administration.component';
import { AccountantExportsComponent } from '../accountant-exports-page/accountant-exports-page.component';

@Component({
  selector: 'on-my-accountant-page',
  templateUrl: './my-accountant-page.component.html',
  styleUrls: ['./my-accountant-page.component.scss']
})
export class MyAccountantPageComponent implements OnInit {

  @ViewChild(NgbNav, { static: true }) ngbNav: NgbNav;
  @ViewChild('AccountantExports', { static: false }) accountantExports: AccountantExportsComponent;
  @ViewChild('AccountantTreatedExports', { static: false }) accountantTreatedExports: AccountantExportsComponent;

  constructor() { }

  ngOnInit() {
  }

  reload() {
    if (this.ngbNav.activeId === 'exports') {
      this.accountantExports.reload();
    } else if (this.ngbNav.activeId === 'exports-treated') {
      this.accountantTreatedExports.reload();
    }
  }
}
