<div class="row" *ngIf="!report.IsEditable">
  <div class="col">
    <div class="alert alert-info text-center">
      <nb-icon icon="fa-info-circle" size="2x"></nb-icon>
      {{'report.cannot-edit-report' | translate}}
    </div>
  </div>
</div>

<div class="row" *ngIf="report.State.Code === 'REF'">
  <div class="col">
    <div class="alert alert-danger text-center">
      <nb-icon icon="fa-exclamation-triangle" size="2x"></nb-icon>
      <span [innerHTML]="'report.report-rejected' | translate: {comment: report.ValidationComments}"></span>
    </div>
  </div>
</div>
<div class="row" *ngIf="report.State.Code !== 'REF' && report.ValidationComments">
  <div class="col">
    <div class="alert alert-info text-center">
      <nb-icon icon="fa-exclamation-triangle" size="2x"></nb-icon>
      <span [innerHTML]="'report.report-has-comments' | translate: {comment: report.ValidationComments}"></span>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="form-group">
      <label class="label">{{'report.name' | translate}} *</label>
      <input [disabled]="!report.IsEditable" [(ngModel)]="report.Name" type="text" class="form-control">
    </div>
    <div class="d-flex justify-content-center" *ngIf="report.IsEditable">
      <button class="btn btn-sm btn-primary" (click)="saveReport()" [disabled]="!report.IsEditable">{{'report.save' |
        translate}}</button>
    </div>
  </div>
  <div class="col">
    <div class="form-group">
      <label class="label">{{'report.comments' | translate}}</label>
      <textarea [disabled]="!report.IsEditable" [(ngModel)]="report.Comments" class="form-control"></textarea>
    </div>
  </div>
</div>

<hr>

<div class="col row" *ngIf="report.State.Code === 'ARCHIVED'">
  <p class="col-12 text-center info-text">{{'report.archived-message' | translate}}</p>
</div>

<div class="row">
  <div class="col">
    <on-expense-list #expensesInReportList *ngIf="mode === 'report'" (changesDone)="expensesChanged()"
      [expenses]="report.Expenses" [canEdit]="true" [canSelect]="true" [canAddColumns]="true"
      [leftActions]="user.Id === report.UserId || report.ManagerId === user.Id ? leftActions : null"
      [rightActions]="user.Id === report.UserId || report.ManagerId === user.Id ? rightActions : null"
      [report]="report" gridStateKey="report-expenses-grid-state">
    </on-expense-list>
    <div class="col d-flex justify-content-end total" *ngIf="mode === 'report'">
      {{'report.total' | translate: { amount: (report.TotalInReport | number:'.2'), symbol: (currentUser$ |
      async)?.Currency.Symbol } }}
    </div>
    <hr>
    <div class="col d-flex justify-content-end total" *ngIf="mode === 'report'
                && (report.State.Code === 'VAL' || report.State.Code === 'SENT')">
      {{'report.total-to-reimbursed' | translate: { amount: (report.AmountToBeReimbursed ? (report.AmountToBeReimbursed
      | number:'.2') : 0), symbol: (currentUser$ | async)?.Currency.Symbol} }}
    </div>
    <div class="d-flex justify-content-center">
      <p class="info-text" *ngIf="mode === 'expense'">
        {{'report.expense-mode' | translate}}
      </p>
    </div>

    <on-expense-list #expensesNotInReportList *ngIf="mode === 'expense' && !isLoading" [expenses]="expensesNotInReport"
      [canEdit]="false" [canSelect]="true" [canAddColumns]="true"></on-expense-list>
  </div>
</div>

<div class="row d-flex justify-content-center"
  *ngIf="mode === 'report' && !isLoading && report.IsEditable && (currentUser$ | async)?.ManagerId && (report.State.Code === 'CREATED' || report.State.Code === 'REF')">
  <div class="text-center">
    <button (click)="sendToValidation(report)" class="btn btn-outline-secondary">
      <nb-icon icon="fa-paper-plane"></nb-icon> {{'report.send' | translate}}
    </button>
  </div>
</div>

<on-loading *ngIf="isLoading"></on-loading>

<div class="row" *ngIf="mode === 'expense' && !isLoading">
  <div class="col text-center">
    <button class="btn btn-round btn-primary" (click)="addExpensesToReport()">
      <nb-icon icon="fa-check"></nb-icon>
    </button>
    &nbsp;&nbsp;&nbsp;
    <button class="btn btn-round btn-danger" (click)="goToReportMode()">
      <nb-icon icon="fa-times"></nb-icon>
    </button>
  </div>
</div>