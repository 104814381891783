export const TOKEN_STORAGE_KEY = 'ONToken';
export const TOKEN_EXPIRATION_STORAGE_KEY = 'ONTokenExpiration';
export const USERNAME_STORAGE_KEY = 'ONUsername';

export const TOKEN_STORAGE_KEY_CONNECTED_AS = 'ONTokenConnectedAs';
export const USERNAME_STORAGE_KEY_CONNECTED_AS = 'ONUsernameConnectedAs';

export const API_V2_URL = '/api/v2';
export const API_V3_URL = '/api/v3';

export const PAGE_SIZE = 'expensePageSize';

export const LANGUAGE_STORAGE_KEY = 'language';

export const AGGRID_TRANSLATIONS = {
  // Set Filter
  selectAll: '(Tout sélectionner)',
  selectAllSearchResults: '(Sélectionner tous les résultats de recherche)',
  addCurrentSelectionToFilter: 'Ajouter la sélection actuelle au filtre',
  searchOoo: 'Rechercher...',
  blanks: '(Vides)',
  noMatches: 'Aucun résultat',

  // Number Filter & Text Filter
  filterOoo: 'Filtrer...',
  equals: 'Égal à',
  notEqual: 'Différent de',
  blank: 'Vide',
  notBlank: 'Non vide',
  empty: 'Choisir un',

  // Number Filter
  lessThan: 'Inférieur à',
  greaterThan: 'Supérieur à',
  lessThanOrEqual: 'Inférieur ou égal à',
  greaterThanOrEqual: 'Supérieur ou égal à',
  inRange: 'Entre',
  inRangeStart: 'De',
  inRangeEnd: 'À',

  // Text Filter
  contains: 'Contient',
  notContains: 'Ne contient pas',
  startsWith: 'Commence par',
  endsWith: 'Finit par',

  // Date Filter
  dateFormatOoo: 'aaaa-mm-jj',
  before: 'Avant',
  after: 'Après',

  // Filter Conditions
  andCondition: 'ET',
  orCondition: 'OU',

  // Filter Buttons
  applyFilter: 'Appliquer',
  resetFilter: 'Réinitialiser',
  clearFilter: 'Effacer',
  cancelFilter: 'Annuler',

  // Filter Titles
  textFilter: 'Filtre de texte',
  numberFilter: 'Filtre numérique',
  dateFilter: 'Filtre de date',
  setFilter: 'Configurer le filtre',

  // Group Column Filter
  groupFilterSelect: 'Sélectionner le champ :',

  // Advanced Filter
  advancedFilterContains: 'contient',
  advancedFilterNotContains: 'ne contient pas',
  advancedFilterTextEquals: 'égal à',
  advancedFilterTextNotEqual: 'différent de',
  advancedFilterStartsWith: 'commence par',
  advancedFilterEndsWith: 'finit par',
  advancedFilterBlank: 'est vide',
  advancedFilterNotBlank: 'n’est pas vide',
  advancedFilterEquals: '=',
  advancedFilterNotEqual: '!=',
  advancedFilterGreaterThan: '>',
  advancedFilterGreaterThanOrEqual: '>=',
  advancedFilterLessThan: '<',
  advancedFilterLessThanOrEqual: '<=',
  advancedFilterTrue: 'est vrai',
  advancedFilterFalse: 'est faux',
  advancedFilterAnd: 'ET',
  advancedFilterOr: 'OU',
  advancedFilterApply: 'Appliquer',
  advancedFilterBuilder: 'Constructeur',
  advancedFilterValidationMissingColumn: 'Colonne manquante',
  advancedFilterValidationMissingOption: 'Option manquante',
  advancedFilterValidationMissingValue: 'Valeur manquante',
  advancedFilterValidationInvalidColumn: 'Colonne non trouvée',
  advancedFilterValidationInvalidOption: 'Option non trouvée',
  advancedFilterValidationMissingQuote: 'Il manque une guillemet à la valeur',
  advancedFilterValidationNotANumber: 'La valeur n’est pas un nombre',
  advancedFilterValidationInvalidDate: 'La valeur n’est pas une date valide',
  advancedFilterValidationMissingCondition: 'Condition manquante',
  advancedFilterValidationJoinOperatorMismatch: 'Les opérateurs de jonction au sein d’une condition doivent être identiques',
  advancedFilterValidationInvalidJoinOperator: 'Opérateur de jonction non trouvé',
  advancedFilterValidationMissingEndBracket: 'Il manque une parenthèse fermante',
  advancedFilterValidationExtraEndBracket: 'Trop de parenthèses fermantes',
  advancedFilterValidationMessage: 'L’expression contient une erreur. ${variable} - ${variable}.',
  advancedFilterValidationMessageAtEnd: 'L’expression contient une erreur. ${variable} à la fin de l’expression.',
  advancedFilterBuilderTitle: 'Filtre avancé',
  advancedFilterBuilderApply: 'Appliquer',
  advancedFilterBuilderCancel: 'Annuler',
  advancedFilterBuilderAddButtonTooltip: 'Ajouter un filtre ou un groupe',
  advancedFilterBuilderRemoveButtonTooltip: 'Supprimer',
  advancedFilterBuilderMoveUpButtonTooltip: 'Monter',
  advancedFilterBuilderMoveDownButtonTooltip: 'Descendre',
  advancedFilterBuilderAddJoin: 'Ajouter un groupe',
  advancedFilterBuilderAddCondition: 'Ajouter un filtre',
  advancedFilterBuilderSelectColumn: 'Sélectionner une colonne',
  advancedFilterBuilderSelectOption: 'Sélectionner une option',
  advancedFilterBuilderEnterValue: 'Entrer une valeur...',
  advancedFilterBuilderValidationAlreadyApplied: 'Le filtre actuel est déjà appliqué.',
  advancedFilterBuilderValidationIncomplete: 'Toutes les conditions ne sont pas complètes.',
  advancedFilterBuilderValidationSelectColumn: 'Vous devez sélectionner une colonne.',
  advancedFilterBuilderValidationSelectOption: 'Vous devez sélectionner une option.',
  advancedFilterBuilderValidationEnterValue: 'Vous devez entrer une valeur.',

  // Side Bar
  columns: 'Colonnes',
  filters: 'Filtres',

  // columns tool panel
  pivotMode: 'Mode Pivot',
  groups: 'Groupes de lignes',
  rowGroupColumnsEmptyMessage: 'Glissez ici pour définir les groupes de lignes',
  values: 'Valeurs',
  valueColumnsEmptyMessage: 'Glissez ici pour agréger',
  pivots: 'Étiquettes de colonne',
  pivotColumnsEmptyMessage: 'Glissez ici pour définir les étiquettes de colonne',

  // Header of the Default Group Column
  group: 'Groupe',

  // Row Drag
  rowDragRow: 'ligne',
  rowDragRows: 'lignes',

  // Other
  loadingOoo: 'Chargement...',
  loadingError: 'ERR',
  noRowsToShow: 'Aucune ligne à afficher',
  enabled: 'Activé',

  // Menu
  pinColumn: 'Épingler la colonne',
  pinLeft: 'Épingler à gauche',
  pinRight: 'Épingler à droite',
  noPin: 'Sans épinglage',
  valueAggregation: 'Agrégation de valeur',
  noAggregation: 'Aucune',
  autosizeThiscolumn: 'Ajuster automatiquement cette colonne',
  autosizeAllColumns: 'Ajuster automatiquement toutes les colonnes',
  groupBy: 'Grouper par',
  ungroupBy: 'Dissocier par',
  ungroupAll: 'Dissocier tout',
  addToValues: 'Ajouter ${variable} aux valeurs',
  removeFromValues: 'Retirer ${variable} des valeurs',
  addToLabels: 'Ajouter ${variable} aux étiquettes',
  removeFromLabels: 'Retirer ${variable} des étiquettes',
  resetColumns: 'Réinitialiser les colonnes',
  expandAll: 'Développer tous les groupes de lignes',
  collapseAll: 'Réduire tous les groupes de lignes',
  copy: 'Copier',
  ctrlC: 'Ctrl+C',
  ctrlX: 'Ctrl+X',
  copyWithHeaders: 'Copier avec les en-têtes',
  copyWithGroupHeaders: 'Copier avec les en-têtes de groupe',
  cut: 'Couper',
  paste: 'Coller',
  ctrlV: 'Ctrl+V',
  export: 'Exporter',
  csvExport: 'Export CSV',
  excelExport: 'Export Excel',
  columnFilter: 'Filtre de colonne',
  columnChooser: 'Sélectionner les colonnes',
  sortAscending: 'Trier par ordre croissant',
  sortDescending: 'Trier par ordre décroissant',
  sortUnSort: 'Annuler le tri',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'Somme',
  first: 'Premier',
  last: 'Dernier',
  min: 'Min',
  max: 'Max',
  none: 'Aucun',
  count: 'Nombre',
  avg: 'Moyenne',
  filteredRows: 'Lignes filtrées',
  selectedRows: 'Lignes sélectionnées',
  totalRows: 'Total des lignes',
  totalAndFilteredRows: 'Lignes',
  more: 'Plus',
  to: 'à',
  of: 'de',
  page: 'Page',
  pageLastRowUnknown: '?',
  nextPage: 'Page suivante',
  lastPage: 'Dernière page',
  firstPage: 'Première page',
  previousPage: 'Page précédente',
  pageSizeSelectorLabel: 'Taille de page :',
  footerTotal: 'Total',

  // Pivoting
  pivotColumnGroupTotals: 'Total',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Graphique Pivot & Mode Pivot',
  pivotChart: 'Graphique Pivot',
  chartRange: 'Plage du graphique',

  columnChart: 'Colonne',
  groupedColumn: 'Groupé',
  stackedColumn: 'Empilé',
  "normalizedColumn": "100% Empilé",

  "barChart": "Barre",
  "groupedBar": "Groupé",
  "stackedBar": "Empilé",
  "normalizedBar": "100% Empilé",

  "pieChart": "Camembert",
  "pie": "Camembert",
  "donut": "Donut",

  "line": "Ligne",

  "xyChart": "X Y (Dispersion)",
  "scatter": "Dispersion",
  "bubble": "Bulle",

  "areaChart": "Aire",
  "area": "Aire",
  "stackedArea": "Empilé",
  "normalizedArea": "100% Empilé",

  "histogramChart": "Histogramme",
  "histogramFrequency": "Fréquence",

  "polarChart": "Polaire",
  "radarLine": "Ligne radar",
  "radarArea": "Aire radar",
  "nightingale": "Nightingale",
  "radialColumn": "Colonne radiale",
  "radialBar": "Barre radiale",

  "statisticalChart": "Statistique",
  "boxPlot": "Boîte à moustaches",
  "rangeBar": "Barre de plage",
  "rangeArea": "Aire de plage",

  "hierarchicalChart": "Hiérarchique",
  "treemap": "Carte arborescente",
  "sunburst": "Sunburst",

  "specializedChart": "Spécialisé",
  "waterfall": "Cascade",
  "heatmap": "Carte de chaleur",

  "combinationChart": "Combinaison",
  "columnLineCombo": "Colonne & Ligne",
  "AreaColumnCombo": "Aire & Colonne",

  "pivotChartTitle": "Graphique Pivot",
  "rangeChartTitle": "Graphique de Plage",
  "settings": "Paramètres",
  "data": "Données",
  "format": "Format",
  "categories": "Catégories",
  "defaultCategory": "(Aucun)",
  "series": "Séries",
  "xyValues": "Valeurs X Y",
  "paired": "Mode apparié",
  "axis": "Axe",
  "radiusAxis": "Axe de rayon",
  "navigator": "Navigateur",
  "color": "Couleur",
  "thickness": "Épaisseur",
  "preferredLength": "Longueur préférée",
  "xType": "Type X",
  "automatic": "Automatique",
  "category": "Catégorie",
  "number": "Nombre",
  "time": "Temps",
  "autoRotate": "Rotation automatique",
  "xRotation": "Rotation X",
  "yRotation": "Rotation Y",
  "labelRotation": "Rotation d'étiquette",
  "circle": "Cercle",
  "polygon": "Polygone",
  "orientation": "Orientation",
  "fixed": "Fixe",
  "parallel": "Parallèle",
  "perpendicular": "Perpendiculaire",
  "radiusAxisPosition": "Position de l'axe du rayon",
  "ticks": "Graduations",
  "width": "Largeur",
  "height": "Hauteur",
  "length": "Longueur",
  "padding": "Marge intérieure",
  "spacing": "Espacement",
  "chart": "Graphique",
  "title": "Titre",
  "titlePlaceholder": "Titre du graphique - double-cliquez pour modifier",
  "background": "Fond",
  "font": "Police",
  "top": "Haut",
  "right": "Droite",
  "bottom": "Bas",
  "left": "Gauche",
  "labels": "Étiquettes",
  "calloutLabels": "Étiquettes d'appel",
  "sectorLabels": "Étiquettes de secteur",
  "positionRatio": "Ratio de position",
  "size": "Taille",
  "shape": "Forme",
  "minSize": "Taille minimum",
  "maxSize": "Taille maximum",
  "legend": "Légende",
  "position": "Position",
  "markerSize": "Taille du marqueur",
  "markerStroke": "Contour du marqueur",
  "markerPadding": "Marge intérieure du marqueur",
  "itemSpacing": "Espacement des éléments",
  "itemPaddingX": "Marge intérieure X des éléments",
  "itemPaddingY": "Marge intérieure Y des éléments",
  "layoutHorizontalSpacing": "Espacement horizontal",
  "layoutVerticalSpacing": "Espacement vertical",
  "strokeWidth": "Largeur du trait",
  "offset": "Décalage",
  "offsets": "Décalages",
  "tooltips": "Infobulles",
  "callout": "Appel",
  "markers": "Marqueurs",
  "shadow": "Ombre",
  "blur": "Flou",
  "xOffset": "Décalage X",
  "yOffset": "Décalage Y",
  "lineWidth": "Largeur de ligne",
  "lineDash": "Tiret de ligne",
  "lineDashOffset": "Décalage de tiret",
  "normal": "Normal",
  "bold": "Gras",
  "italic": "Italique",
  "boldItalic": "Gras italique",
  "predefined": "Prédéfini",
  "fillOpacity": "Opacité de remplissage",
  "strokeColor": "Couleur du trait",
  "strokeOpacity": "Opacité du trait",
  "histogramBinCount": "Nombre de bacs",
  "connectorLine": "Ligne de connexion",
  "seriesItems": "Éléments de séries",
  "seriesItemType": "Type d'élément de série",
  "seriesItemPositive": "Positif",
  "seriesItemNegative": "Négatif",
  "seriesItemLabels": "Étiquettes d'élément de série",
  "columnGroup": "Colonne",
  "barGroup": "Barre",
  "pieGroup": "Camembert",
  "lineGroup": "Ligne",
  "scatterGroup": "Dispersion",
  "areaGroup": "Aire",
  "polarGroup": "Polaire",
  "statisticalGroup": "Statistique",
  "hierarchicalGroup": "Hiérarchique",
  "specializedGroup": "Spécialisé",
  "combinationGroup": "Combinaison",
  "groupedColumnTooltip": "Groupé",
  "stackedColumnTooltip": "Empilé",
  "normalizedColumnTooltip": "100% Empilé",
  "groupedBarTooltip": "Groupé",
  "stackedBarTooltip": "Empilé",
  "normalizedBarTooltip": "100% Empilé",
  "pieTooltip": "Camembert",
  "donutTooltip": "Donut",
  "lineTooltip": "Ligne",
  "groupedAreaTooltip": "Aire",
  "stackedAreaTooltip": "Empilé",
  "normalizedAreaTooltip": "100% Empilé",
  "scatterTooltip": "Dispersion",
  "bubbleTooltip": "Bulle",
  "histogramTooltip": "Histogramme",
  "radialColumnTooltip": "Colonne radiale",
  "radialBarTooltip": "Barre radiale",
  "radarLineTooltip": "Ligne radar",
  "radarAreaTooltip": "Aire radar",
  "nightingaleTooltip": "Nightingale",
  "rangeBarTooltip": "Barre de plage",
  "rangeAreaTooltip": "Aire de plage",
  "boxPlotTooltip": "Boîte à moustaches",
  "treemapTooltip": "Carte arborescente",
  "sunburstTooltip": "Sunburst",
  "waterfallTooltip": "Cascade",
  "heatmapTooltip": "Carte de chaleur",
  "columnLineComboTooltip": "Colonne & Ligne",
  "areaColumnComboTooltip": "Aire & Colonne",
  "customComboTooltip": "Combinaison personnalisée",
  "innerRadius": "Rayon intérieur",
  "startAngle": "Angle de départ",
  "endAngle": "Angle de fin",
  "reverseDirection": "Direction inverse",
  "groupPadding": "Marge du groupe",
  "seriesPadding": "Marge de la série",
  "tile": "Tuile",
  "whisker": "Moustache",
  "cap": "Capuchon",
  "capLengthRatio": "Ratio de longueur du capuchon",
  "labelPlacement": "Placement de l'étiquette",
  "inside": "Intérieur",
  "outside": "Extérieur",
  "noDataToChart": "Aucune donnée disponible pour le graphique.",
  "pivotChartRequiresPivotMode": "Le graphique pivot nécessite que le mode Pivot soit activé.",
  "chartSettingsToolbarTooltip": "Menu",
  "chartLinkToolbarTooltip": "Lié au tableau",
  "chartUnlinkToolbarTooltip": "Détaché du tableau",
  "chartDownloadToolbarTooltip": "Télécharger le graphique",
  "seriesChartType": "Type de graphique de séries",
  "seriesType": "Type de séries",
  "secondaryAxis": "Axe secondaire",

  "ariaAdvancedFilterBuilderItem": "${variable}. Niveau ${variable}. Appuyez sur ENTRER pour modifier.",
  "ariaAdvancedFilterBuilderItemValidation": "${variable}. Niveau ${variable}. ${variable} Appuyez sur ENTRER pour modifier.",
  "ariaAdvancedFilterBuilderList": "Liste du constructeur de filtre avancé",
  "ariaAdvancedFilterBuilderFilterItem": "Condition de filtre",
  "ariaAdvancedFilterBuilderGroupItem": "Groupe de filtre",
  "ariaAdvancedFilterBuilderColumn": "Colonne",
  "ariaAdvancedFilterBuilderOption": "Option",
  "ariaAdvancedFilterBuilderValueP": "Valeur",
  "ariaAdvancedFilterBuilderJoinOperator": "Opérateur de jonction",
  "ariaAdvancedFilterInput": "Entrée de filtre avancé",
  "ariaChecked": "coché",
  "ariaColumn": "Colonne",
  "ariaColumnGroup": "Groupe de colonnes",
  "ariaColumnFiltered": "Colonne filtrée",
  "ariaColumnSelectAll": "Activer/Désactiver la sélection de toutes les colonnes",
  "ariaDateFilterInput": "Entrée de filtre de date",
  "ariaDefaultListName": "Liste",
  "ariaFilterColumnsInput": "Entrée de filtrage des colonnes",
  "ariaFilterFromValue": "Filtrer à partir de la valeur",
  "ariaFilterInput": "Entrée de filtre",
  "ariaFilterList": "Liste de filtres",
  "ariaFilterToValue": "Filtrer vers la valeur",
  "ariaFilterValue": "Valeur de filtre",
  "ariaFilterMenuOpen": "Ouvrir le menu de filtre",
  "ariaFilteringOperator": "Opérateur de filtrage",
  "ariaHidden": "caché",
  "ariaIndeterminate": "indéterminé",
  "ariaInputEditor": "Éditeur d'entrée",
  "ariaMenuColumn": "Appuyez sur ALT BAS pour ouvrir le menu de la colonne",
  "ariaFilterColumn": "Appuyez sur CTRL ENTRER pour ouvrir le filtre",
  "ariaRowDeselect": "Appuyez sur ESPACE pour désélectionner cette ligne",
  "ariaRowSelectAll": "Appuyez sur Espace pour activer/désactiver la sélection de toutes les lignes",
  "ariaRowToggleSelection": "Appuyez sur Espace pour activer/désactiver la sélection de la ligne",
  "ariaRowSelect": "Appuyez sur ESPACE pour sélectionner cette ligne",
  "ariaSearch": "Rechercher",
  "ariaSortableColumn": "Appuyez sur ENTRER pour trier",
  "ariaToggleVisibility": "Appuyez sur ESPACE pour activer/désactiver la visibilité",
  "ariaToggleCellValue": "Appuyez sur ESPACE pour activer/désactiver la valeur de la cellule",
  "ariaUnchecked": "non coché",
  "ariaVisible": "visible",
  "ariaSearchFilterValues": "Rechercher des valeurs de filtre",
  "ariaPageSizeSelectorLabel": "Taille de page",

  "ariaRowGroupDropZonePanelLabel": "Groupes de lignes",
  "ariaValuesDropZonePanelLabel": "Valeurs",
  "ariaPivotDropZonePanelLabel": "Étiquettes de colonne",
  "ariaDropZoneColumnComponentDescription": "Appuyez sur SUPPRIMER pour supprimer",
  "ariaDropZoneColumnValueItemDescription": "Appuyez sur ENTRER pour changer le type d'agrégation",
  "ariaDropZoneColumnGroupItemDescription": "Appuyez sur ENTRER pour trier",
  "ariaDropZoneColumnComponentAggFuncSeparator": " de ",
  "ariaDropZoneColumnComponentSortAscending": "ascendant",
  "ariaDropZoneColumnComponentSortDescending": "descendant",

  "ariaLabelColumnMenu": "Menu de la colonne",
  "ariaLabelColumnFilter": "Filtre de la colonne",
  "ariaLabelCellEditor": "Éditeur de cellule",
  "ariaLabelDialog": "Dialogue",
  "ariaLabelSelectField": "Sélectionner le champ",
  "ariaLabelRichSelectField": "Champ de sélection riche",
  "ariaLabelTooltip": "Infobulle",
  "ariaLabelContextMenu": "Menu contextuel",
  "ariaLabelSubMenu": "Sous-menu",
  "ariaLabelAggregationFunction": "Fonction d'agrégation",
  "ariaLabelAdvancedFilterAutocomplete": "Autocomplétion du filtre avancé",
  "ariaLabelAdvancedFilterBuilderAddField": "Ajouter un champ au constructeur de filtre avancé",
  "ariaLabelAdvancedFilterBuilderColumnSelectField": "Sélectionner le champ de colonne du constructeur de filtre avancé",
  "ariaLabelAdvancedFilterBuilderOptionSelectField": "Sélectionner le champ d'option du constructeur de filtre avancé",
  "ariaLabelAdvancedFilterBuilderJoinSelectField": "Sélectionner le champ d'opérateur de jonction du constructeur de filtre avancé",

  "ariaColumnPanelList": "Liste des colonnes",
  "ariaFilterPanelList": "Liste des filtres",

  "thousandSeparator": ",",
  "decimalSeparator": ".",

  "true": "Vrai",
  "false": "Faux",
  "invalidDate": "Date invalide",
  "invalidNumber": "Nombre invalide",
  "january": "Janvier",
  "february": "Février",
  "march": "Mars",
  "april": "Avril",
  "may": "Mai",
  "june": "Juin",
  "july": "Juillet",
  "august": "Août",
  "september": "Septembre",
  "october": "Octobre",
  "november": "Novembre",
  "december": "Décembre"
}
