import { AccountingCode } from '../../../company/accountant/_models/AccountingCode';
import { AccountantExport } from '../../../company/_models/AccountantExport';

export class AccountantExportStateModel {
  exports: AccountantExport[];
  exportsTreated: AccountantExport[];
  editingExport: AccountantExport;
  accountingCodes: AccountingCode[];
  shouldReloadExports: boolean;
  shouldReloadTreatedExports: boolean;
  shouldReloadAccountingCodes: boolean;

  constructor(model?: AccountantExportStateModel) {
    if (model) {
      this.exports = (model.exports || []).map((c) => new AccountantExport(c));
      this.exportsTreated = (model.exportsTreated || []).map((c) => new AccountantExport(c));
      this.accountingCodes = (model.accountingCodes || []).map((c) => new AccountingCode(c));
      this.editingExport = model.editingExport;
      this.shouldReloadExports = model.shouldReloadExports;
      this.shouldReloadTreatedExports = model.shouldReloadTreatedExports;
      this.shouldReloadAccountingCodes = model.shouldReloadAccountingCodes;
    } else {
      this.exports = null;
      this.exportsTreated = null;
      this.accountingCodes = null;
      this.editingExport = null;
      this.shouldReloadExports = true;
      this.shouldReloadTreatedExports = true;
      this.shouldReloadAccountingCodes = true;
    }
  }
}
