<on-loading *ngIf="isLoading"></on-loading>

<div class="col" [hidden]="!allEmployees || isLoading">
  <div>
    <h3>{{'company.hierarchy.title' | translate}}</h3>
    <p class="info-text">{{'company.hierarchy.description' | translate }}</p>
  </div>

  <div class="row col-12 d-flex justify-content-center align-items-center">
    <div class="input-group col-6">
      <div class="input-group-text input-rounded">
        <nb-icon icon="fa-search"></nb-icon>
      </div>
      <input type="text" [(ngModel)]="searchTerms" class="form-control input-rounded" placeholder="Recherche..."
        (input)="onChangeFilter()">
    </div>
  </div>
  <br>
  <div class="col-12">
    <ag-grid-angular #agGridHierarchy class="ag-theme-material" id="gridHierarchy">
    </ag-grid-angular>
  </div>
</div>

<!--<div *ngIf="!isLoading && filteredEmployees">
  <div>
    <h3>Hiérarchie</h3>
    <p>Vous pouvez ici changer le manager pour chacun de vos employées</p>
  </div>
  <div class="row">
    <div class="col">
      <span><strong>Chercher un employé :</strong></span>
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <nb-icon icon="fa-search"></nb-icon>
          </div>
        </div>
        <input class="form-control" (input)="searchUsers()" [(ngModel)]="searchTerms">
      </div>
    </div>
  </div>
  <div class="row" *ngIf="filteredEmployees.length">
    <div class="w-100"></div>
    <div class="col user-hierarchy">
      <br>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Nom d'utilisateur</th>
            <th>Prénom</th>
            <th>Nom</th>
            <th>Manager</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let employee of filteredEmployees">
            <td>{{employee.UserName}}</td>
            <td>{{employee.FirstName}}</td>
            <td>{{employee.LastName}}</td>
            <td>
              <on-select-manager (managerChanged)="changeManager(employee)" [(ngModel)]="employee.ManagerId"
                [allEmployees]="allEmployees"></on-select-manager>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <span *ngIf="filteredEmployees.length === 0">
    <br>
    Aucun employé correspondant à votre recherche.
  </span>
</div>-->