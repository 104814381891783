<div class="row p-1" (click)="$event.preventDefault()">
  <form [formGroup]="form" *ngIf="!isInVp">
    <select formControlName="select" class="form-control-sm" (change)="selectionChanged()"
      (click)="$event.preventDefault()">
      <option value="placeholder">{{'report.export-placeholder' | translate }}</option>
      <option value="csv">{{'report.export-csv' | translate }}</option>
      <option value="excel">{{'report.export-excel' | translate }}</option>
      <option value="zip">{{'report.export-zip' | translate }}</option>
      <option value="pdf">{{'report.export-pdf' | translate }}</option>
      <hr>
      <option value="excelAndArchive">{{'report.export-mail' | translate }}</option>
    </select>
  </form>
  <button nbPopover="Télécharger le fichier stocké en valeur probante" nbPopoverTrigger="hover"
    class="btn btn-info btn-sm" *ngIf="isInVp" (click)="exportPdf()">
    <i class="fa fa-file-pdf"></i>
  </button>
</div>