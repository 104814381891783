import { Injectable } from '@angular/core';
import { Selector, Action, StateContext, State, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ExpenseService } from './expense.service';
import { ExpenseStateModel } from './expense.model';
import { ExpenseAction } from './expense.actions';
import { Expense } from '../../_models/Expense';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ExpenseType } from '../../_models/ExpenseType';
import { patch, insertItem, updateItem } from '@ngxs/store/operators';
import { BadgesAction } from '../badges/badges.action';
import { ToastService } from 'on-common/_services/toast.service';

@State<ExpenseStateModel>({
  name: `expense`,
  defaults: new ExpenseStateModel()
})
@Injectable()
export class ExpenseState {
  constructor(
    private expenseService: ExpenseService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private router: Router,
    private store: Store
  ) { }

  @Selector()
  static expenses(state: ExpenseStateModel) {
    return state.expenses;
  }

  @Selector()
  static expenseTypes(state: ExpenseStateModel) {
    return state.expenseTypes;
  }

  @Selector()
  static getExpenseById(state: ExpenseStateModel) {
    return (expenseId: number) => state.expenses.find(expense => expense.Id == expenseId);
  }

  @Selector()
  static current(state: ExpenseStateModel) {
    return state.current;
  }

  @Action(ExpenseAction.LoadExpenses)
  public loadExpenses(ctx: StateContext<ExpenseStateModel>) {
    return this.expenseService.GetExpensesOfCurrentUser().pipe(tap(response => {
      if (response.IsSuccess) {
        ctx.patchState({
          expenses: (response.Result || []).map((c) => new Expense(c)),
          shouldReloadExpenses: false
        });
      } else {
        this.toastService.error(this.translateService.instant('expense.expenses-error-message'));
      }
    }, error => {
      this.toastService.error(this.translateService.instant('expense.expenses-error-message'));
    }));
  }

  @Action(ExpenseAction.LoadExpensesIfNeeded)
  public loadExpensesIfNeeded(ctx: StateContext<ExpenseStateModel>) {
    // if (ctx.getState().shouldReloadExpenses) {
    // Always reload data for now
    return this.loadExpenses(ctx);
    // }
  }

  @Action(ExpenseAction.LoadExpenseById)
  public loadExpenseById(ctx: StateContext<ExpenseStateModel>, { expenseId }: ExpenseAction.LoadExpenseById) {
    return this.expenseService.GetExpenseDetails(expenseId).pipe(tap(response => {
      if (response.IsSuccess) {
        const data = new Expense(response.Result);
        ctx.patchState({
          current: data
        });
        // If the expense loaded is in the expenses list
        if (ctx.getState().expenses.map(exp => exp.Id).includes(expenseId)) {
          ctx.setState(patch({
            expenses: updateItem(expense => expense.Id === expenseId, response.Result)
          }));
        }
      } else {
        this.toastService.error(this.translateService.instant('expense.cannot-get-details'));
      }
    }, error => {
      this.toastService.error(this.translateService.instant('expense.cannot-get-details'));
    }));
  }

  @Action(ExpenseAction.RemoveExpenses)
  public removeExpenses(ctx: StateContext<ExpenseStateModel>, { idsToRemove }: ExpenseAction.RemoveExpenses) {
    return this.expenseService.RemoveExpenses(idsToRemove).pipe(tap(response => {
      if (response.IsSuccess) {
        const newData = ctx.getState().expenses.filter(item => !idsToRemove.includes(item.Id)).map((c) => new Expense(c));

        ctx.patchState({
          expenses: newData
        });
        this.toastService.success(
          this.translateService.instant('expense.delete-expenses-success-message'),
          this.translateService.instant('expense.delete-expenses-success-message-complement')
        );
      } else {
        this.toastService.error(
          this.translateService.instant('expense.delete-expenses-error-message'),
          this.translateService.instant('expense.delete-expenses-error-message-complement')
        );
      }
    }, error => {
      this.toastService.error(
        this.translateService.instant('expense.delete-expenses-error-message'),
        this.translateService.instant('expense.delete-expenses-error-message-complement')
      );
    }));
  }

  @Action(ExpenseAction.CreateManualExpense)
  public createManualExpense(ctx: StateContext<ExpenseStateModel>, { expense }: ExpenseAction.CreateManualExpense) {
    return this.expenseService.CreateManualExpense(expense, null).pipe(tap(response => {
      if (response.IsSuccess) {
        ctx.setState(patch({
          expenses: insertItem<Expense>(response.Result)
        }));
        this.toastService.success(this.translateService.instant('expense.created-success-message'));

        this.router.navigate(['/expenses']);
      } else {
        this.toastService.error(this.translateService.instant('expense.created-error-message'));
      }
    }, error => {
      this.toastService.error(this.translateService.instant('expense.created-error-message'));
    }));
  }

  @Action(ExpenseAction.EditExpense)
  public editExpense(ctx: StateContext<ExpenseStateModel>, { expense, callback }: ExpenseAction.EditExpense) {
    return this.expenseService.EditExpense(expense).pipe(tap(response => {
      if (response.IsSuccess) {
        ctx.setState(patch({
          expenses: updateItem<Expense>(exp => exp.Id == expense.Id, new Expense(response.Result))
        }));
        this.toastService.success(this.translateService.instant('expense.updated-success-message'));

        // Reload badges
        this.store.dispatch(new BadgesAction.LoadBadges());

        callback();
      } else {
        this.toastService.error(this.translateService.instant('expense.updated-error-message'));
      }
    }, error => {
      this.toastService.error(this.translateService.instant('expense.updated-error-message'));
    }));
  }

  @Action(ExpenseAction.LoadExpenseTypes)
  public loadExpenseTypes(ctx: StateContext<ExpenseStateModel>) {
    return this.expenseService.GetExpenseTypes().pipe(tap(response => {
      if (response.IsSuccess) {
        ctx.patchState({
          expenseTypes: (response.Result || []).map((c) => new ExpenseType(c)),
          shouldReloadExpenseTypes: false
        });
      } else {
        this.toastService.error(this.translateService.instant('expense.load-types-error-message'));
      }
    }, error => {
      this.toastService.error(this.translateService.instant('expense.load-types-error-message'));
    }));
  }

  @Action(ExpenseAction.LoadExpenseTypesIfNeeded)
  public loadExpenseTypesIfNeeded(ctx: StateContext<ExpenseStateModel>) {
    if (ctx.getState().shouldReloadExpenseTypes) {
      return this.loadExpenseTypes(ctx);
    }
  }

  @Action(ExpenseAction.ValidateExpense)
  public validateExpense(ctx: StateContext<ExpenseStateModel>, { expense, callback }: ExpenseAction.ValidateExpense) {
    return this.expenseService.ValidExpense(expense).pipe(tap(response => {
      if (response.IsSuccess) {
        this.toastService.success(this.translateService.instant('expense.validated-success-message'));
        callback();
      } else {
        this.toastService.error(this.translateService.instant('expense.validated-error-message'));
      }
    }, error => {
      this.toastService.error(this.translateService.instant('expense.validated-error-message'));
    }));
  }

  @Action(ExpenseAction.RejectExpense)
  public rejectExpense(ctx: StateContext<ExpenseStateModel>, { expense, callback }: ExpenseAction.RejectExpense) {
    return this.expenseService.RejectExpense(expense).pipe(tap(response => {
      if (response.IsSuccess) {
        this.toastService.success(this.translateService.instant('expense.rejected-success-message'));
        callback();
      } else {
        this.toastService.error(this.translateService.instant('expense.rejected-error-message'));
      }
    }, error => {
      this.toastService.error(this.translateService.instant('expense.rejected-error-message'));
    }));
  }

  @Action(ExpenseAction.MustReloadExpenses)
  public mustReloadExpenses(ctx: StateContext<ExpenseStateModel>) {
    ctx.patchState({
      shouldReloadExpenses: true
    });
  }

  @Action(ExpenseAction.DuplicateExpense)
  public duplicateExpense(ctx: StateContext<ExpenseStateModel>, { expenseId }: ExpenseAction.DuplicateExpense) {
    return this.expenseService.DuplicateExpense(expenseId).pipe(
      tap(response => {
        if (response.IsSuccess) {
          this.toastService.success(this.translateService.instant('expense.duplicate-success-message'));

          ctx.setState(patch({
            expenses: insertItem(response.Result, 0)
          }));
        }
      })
    );
  }
}
