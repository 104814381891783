import { ExpertedCompany } from 'onexpense/accountant-expert/_models/ExpertedCompany';
import { Company } from '../../_models/Company';

export namespace AccountantExpertAction {

  export class LoadCompanies {
    public static readonly type = `[AccountantExpert] load the companies`;
    constructor() { }
  }

  export class LoadCompaniesIfNeeded {
    public static readonly type = `[AccountantExpert] load the companies if needed`;
    constructor() { }
  }

  export class LoadEmployees {
    public static readonly type = `[AccountantExpert] load the employees by company id`;
    constructor(public companyId: number) { }
  }

  export class CreateCompany {
    public static readonly type = `[AccountantExpert] create an new company`;
    constructor(public company: Company) { }
  }

  export class UpdateActivableLicenses {
    public static readonly type = `[AccountantExpert] update activable licenses`
    constructor(public company: ExpertedCompany, public newNumber: number) { }

  }

  export class ActivateVP {
    public static readonly type = `[AccountantExpert] activate VP to company`
    constructor(public company: ExpertedCompany) { }
  }

  export class DeactivateVP {
    public static readonly type = `[AccountantExpert] deactivate VP to company`
    constructor(public company: ExpertedCompany) { }
  }  
}
