import { AccountingPlanValue } from './../../../shared/_models/AccountingPlanValue';
import { Observable, Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AccountingPlan } from 'on-shared/_models/AccountingPlan';
import { AccountingPlanState } from 'on-shared/stores/accounting-plan/accounting-plan.state';
import { AccountingPlanAction } from 'on-shared/stores/accounting-plan/accounting-plan.action';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'on-accounting-plan-tab',
  templateUrl: './accounting-plan-tab.component.html',
  styleUrls: ['./accounting-plan-tab.component.scss']
})
export class AccountingPlanTabComponent implements OnInit, OnDestroy {

  @Select(AccountingPlanState.userAccountingPlans) userAccountingPlans$: Observable<AccountingPlan[]>;
  @Select(AccountingPlanState.isLoading) isLoading$: Observable<boolean>;

  userAccountingPlans: AccountingPlan[];

  userPlansSubscription: Subscription;

  exampleCode: string;
  showFieldsAreRequired = false;

  constructor(
    private store: Store,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.reload();
    this.userPlansSubscription = this.userAccountingPlans$.subscribe(newPlans => {
      if (newPlans) {
        this.userAccountingPlans = [];
        for (const p of newPlans) {
          this.userAccountingPlans.push(new AccountingPlan(p));
        }
      }
      else {
        this.userAccountingPlans = null;
      }
      this.recalculateOrder();
      this.calculateExample();
    });
  }

  ngOnDestroy(): void {
    if (this.userPlansSubscription) {
      this.userPlansSubscription.unsubscribe();
    }
  }

  reload(): void {
    this.store.dispatch(new AccountingPlanAction.LoadAccountingPlans());
  }

  addPlan(): void {
    if (!this.userAccountingPlans)
      return;

    this.userAccountingPlans.push(new AccountingPlan());
    this.recalculateOrder();
  }

  addValue(plan: AccountingPlan): void {
    plan.PossibleValues.push(new AccountingPlanValue());
    this.calculateExample();
  }

  removeValue(plan: AccountingPlan, valueToRemove: AccountingPlanValue) {

    if (!plan || !valueToRemove)
      return;

    const indexToRemove = plan.PossibleValues.indexOf(valueToRemove);
    if (indexToRemove < 0)
      return;

    if (valueToRemove.Id) {
      Swal.fire(
        {
          text: this.translateService.instant('accounting-plan.delete-value-content'),
          title: this.translateService.instant('accounting-plan.delete-value-title'),
          showCancelButton: true,
        }
      ).then(value => {
        if (value.value) {
          plan.PossibleValues.splice(indexToRemove, 1);
        }
      });
    } else {
      // The value was not yet saved, so we can delete it safely
      plan.PossibleValues.splice(indexToRemove, 1);
    }

    this.calculateExample();
  }

  removePlan(plan: AccountingPlan) {
    if (!plan)
      return;

    const indexToRemove = this.userAccountingPlans.indexOf(plan);
    if (indexToRemove < 0)
      return;

    Swal.fire(
      {
        text: this.translateService.instant('accounting-plan.delete-value-content'),
        title: this.translateService.instant('accounting-plan.delete-value-title'),
        showCancelButton: true,
      }
    ).then(value => {
      if (value.value) {
        this.userAccountingPlans.splice(indexToRemove, 1);
      }
    });
  }

  calculateExample() {
    if (!this.userAccountingPlans)
      return;

    let exampleCode = "";
    for (const plan of this.userAccountingPlans) {
      if (plan.PossibleValues && plan.PossibleValues.length > 0 && plan.PossibleValues[0].AnalyticsCode) {
        exampleCode = exampleCode + plan.PossibleValues[0].AnalyticsCode;
      }
    }

    this.exampleCode = exampleCode;
  }

  savePlans() {
    if (!this.userAccountingPlans)
      return;

    if (!this.allFieldsAreFilledIn()) {
      this.showFieldsAreRequired = true;
      return;
    }

    this.showFieldsAreRequired = false;
    this.recalculateOrder();
    this.store.dispatch(new AccountingPlanAction.UpdateAccountingPlans(this.userAccountingPlans));
  }

  allFieldsAreFilledIn(): boolean {
    if (!this.userAccountingPlans || !this.userAccountingPlans.length)
      return false;

    for (const plan of this.userAccountingPlans) {
      if (!plan.Name)
        return false;
      if (plan.PossibleValues && plan.PossibleValues.length) {
        for (const val of plan.PossibleValues) {
          if (!val.Name || !val.AnalyticsCode) {
            return false;
          }
        }
      }
    }

    return true;
  }

  goUp(plan: AccountingPlan) {
    if (!this.userAccountingPlans || !plan)
      return;

    const indexOfPlan = this.userAccountingPlans.indexOf(plan);

    // Not found or already the first
    if (indexOfPlan < 1)
      return;

    const valueToExchange = this.userAccountingPlans[indexOfPlan - 1];
    this.userAccountingPlans[indexOfPlan - 1] = plan;
    this.userAccountingPlans[indexOfPlan] = valueToExchange;

    this.recalculateOrder();
    this.calculateExample();
  }

  goDown(plan: AccountingPlan) {
    if (!this.userAccountingPlans || !plan)
      return;

    const indexOfPlan = this.userAccountingPlans.indexOf(plan);

    // Not found or already the last
    if (indexOfPlan < 0 || indexOfPlan > this.userAccountingPlans.length - 2) {
      return;
    }

    const valueToExchange = this.userAccountingPlans[indexOfPlan + 1];
    this.userAccountingPlans[indexOfPlan + 1] = plan;
    this.userAccountingPlans[indexOfPlan] = valueToExchange;

    this.recalculateOrder();
    this.calculateExample();
  }

  recalculateOrder() {
    if (!this.userAccountingPlans)
      return;

    let currentIndex = 0;
    for (const plan of this.userAccountingPlans) {
      plan.Order = currentIndex++;
    }
  }
}
