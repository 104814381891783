import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'on-manager-gridcell',
  templateUrl: './manager-gridcell.component.html',
  styleUrls: ['./manager-gridcell.component.scss']
})
export class ManagerGridcellComponent implements ICellRendererAngularComp {

  params: any;

  constructor() { }

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
  }

  afterGuiAttached?(params?: import('ag-grid-community').IAfterGuiAttachedParams): void {
  }

}
