import { BadgesData } from '../../_models/BadgesData';

export class BadgesStateModel {
  badgesData: BadgesData;
  reloadTime: number; // Reload every x seconds

  constructor(model?: BadgesStateModel) {
    if (model) {
      this.badgesData = new BadgesData(model.badgesData);
      this.reloadTime = model.reloadTime;
    } else {
      this.badgesData = new BadgesData();
      this.reloadTime = 30;
    }
  }
}
