<div class="row col-12 d-flex align-items-center justify-content-center">
  <div class="col-1 d-flex align-items-center justify-content-start">
    <button type="button" (click)="toggleSideMenu()" class="btn btn-primary toggle-menu d-flex">
      <nb-icon icon="fa-bars"></nb-icon>
    </button>
  </div>

  <div class="col-2">
    <ng-template #leftComponent></ng-template>
  </div>

  <div class="col-6 offset-1">
    <ng-template #middleComponent></ng-template>
  </div>

  <div class="col-2 d-flex align-items-center justify-content-end">
    <ng-template #rightComponent></ng-template>
    <div *ngIf="languages.length > 0">
      <ul class="m-0">
        <li class="nav-item" ngbDropdown placement="bottom-right" display="dynamic">
          <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle>
            <img [src]="'./assets/images/flags/'+translateService.currentLang+'.png'" />
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button *ngFor="let lang of languages" class="dropdown-item" (click)="selectLang(lang.Code)">
              <img src="./assets/images/flags/{{ lang.Code }}.png" />
              {{ lang.Name }}
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
