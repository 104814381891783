import { Vehicle } from '../../_models/Vehicle';

export class VehiclesStateModel {
  user: Vehicle[];
  company: Vehicle[];
  shouldReloadUser: boolean;
  shouldReloadCompany: boolean;


  constructor(model?: VehiclesStateModel) {
    if (model) {
      this.user = (model.user || []).map((c) => new Vehicle(c));
      this.company = (model.company || []).map((c) => new Vehicle(c));
      this.shouldReloadUser = model.shouldReloadUser;
      this.shouldReloadCompany = model.shouldReloadCompany;
    } else {
      this.user = null;
      this.company = null;
      this.shouldReloadUser = true;
      this.shouldReloadCompany = true;
    }
  }
}
