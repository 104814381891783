import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TopBarService {

  public languageSubject: BehaviorSubject<string>;

  constructor(private translate: TranslateService) {
    this.languageSubject = new BehaviorSubject<string>(
      null
    );
  }

  setUsedLanguage(code: string) {
    this.languageSubject.next(code);
  }
}
