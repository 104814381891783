<!-- <nb-layout id="main-container">
  <nb-sidebar class="on-sidebar" mode="push" [state]="isSideBarOpened? 'expanded' : 'collapsed'">
    <on-side-menu></on-side-menu>
  </nb-sidebar>

  <nb-layout-header fixed class="navbar flex-md-nowrap p-0 shadow">
    <on-topbar class="row col-12"></on-topbar>
  </nb-layout-header>

  <nb-layout-header subheader class="notification-banner-subheader"
    [class]="(notification && notification.Class) ? notification.Class : ''"
    [ngClass]="(notification && !notification.Closed) && !isBannerClosed ? 'visible' : ''">
    <on-notification-banner *ngIf="notification" class="row col-12" [notification]="notification"
      (closeNotificationBanner)="onBannerClose($event)"></on-notification-banner>
  </nb-layout-header>

  <nb-layout-column>
    <div id="content" class="container">
      <div id="page">
        <main [@routerTransition]="getPageTransition(routerOutlet)">
        </main>
      </div>
      <div id="footer">
        <on-footer></on-footer>
      </div>
    </div>

  </nb-layout-column>
</nb-layout> -->
<nb-layout id="main-container">
  <nb-sidebar class="on-sidebar" mode="push" [state]="isSideBarOpened? 'expanded' : 'collapsed'">
    <on-side-menu></on-side-menu>
  </nb-sidebar>
  <nb-layout-header fixed class="navbar flex-md-nowrap p-0 shadow">
    <on-topbar class="row col-12"></on-topbar>
  </nb-layout-header>

  <nb-layout-header subheader class="notification-banner-subheader"
    [class]="(notification && notification.Class) ? notification.Class : ''"
    [ngClass]="(notification && !notification.Closed) && !isBannerClosed ? 'visible' : ''">
    <on-notification-banner *ngIf="notification" class="row col-12" [notification]="notification"
      (closeNotificationBanner)="onBannerClose($event)"></on-notification-banner>
  </nb-layout-header>

  <nb-layout-column>
    <div id="content" class="container">
      <div id="page">
        <main>
          <router-outlet #routerOutlet="outlet"></router-outlet>
        </main>
      </div>
      <div id="footer">
        <on-footer></on-footer>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>