import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AccountantExpertService } from './accountant-expert.service';
import { AccountantExpertStateModel } from './accountant-expert.model';
import { AccountantExpertAction } from './accountant-expert.action';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { updateItem, patch } from '@ngxs/store/operators';
import { Router } from '@angular/router';
import { ToastService } from 'on-common/_services/toast.service';
import { ExpertedCompany } from 'onexpense/accountant-expert/_models/ExpertedCompany';

@State<AccountantExpertStateModel>({
  name: 'accountantExpert',
  defaults: new AccountantExpertStateModel()
})
@Injectable()
export class AccountantExpertState {

  constructor(
    private accountantExpertService: AccountantExpertService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private router: Router
  ) { }

  @Selector()
  static companies(state: AccountantExpertStateModel) {
    return state.companies;
  }

  @Selector()
  static selectedCompanyEmployees(state: AccountantExpertStateModel) {
    return state.selectedCompanyEmployees;
  }

  @Action(AccountantExpertAction.LoadCompanies)
  public loadCompanies(ctx: StateContext<AccountantExpertStateModel>) {
    return this.accountantExpertService.GetCompanies().pipe(tap(response => {
      if (response.IsSuccess) {
        ctx.patchState({
          companies: response.Result,
          shouldReload: false
        })
      } else {
        this.toastService.warning(
          this.translateService.instant('accountant.cannot-change-company'),
          this.translateService.instant('accountant.cannot-change-company-status'));
      }
    }, error => {
      this.toastService.warning(
        this.translateService.instant('accountant.cannot-change-company'),
        this.translateService.instant('accountant.cannot-change-company-status'));
    }));
  }

  @Action(AccountantExpertAction.LoadCompaniesIfNeeded)
  public loadCompaniesIfNeeded(ctx: StateContext<AccountantExpertStateModel>) {
    //if (ctx.getState().shouldReload) {
    return this.loadCompanies(ctx);
    //}
  }

  @Action(AccountantExpertAction.LoadEmployees)
  public loadEmployees(ctx: StateContext<AccountantExpertStateModel>, { companyId }: AccountantExpertAction.LoadEmployees) {
    ctx.setState(patch({
      selectedCompanyEmployees: null
    }));
    return this.accountantExpertService.GetEmployeesByCompanyId(companyId).pipe(tap(response => {
      if (response.IsSuccess) {
        ctx.setState(patch({
          selectedCompanyEmployees: response.Result
        }));
      } else {
        this.toastService.warning(
          this.translateService.instant('accountant.cannot-load-employees'),
          this.translateService.instant('accountant.cannot-load-employees-status'));
      }
    }, error => {
      this.toastService.warning(
        this.translateService.instant('accountant.cannot-load-employees'),
        this.translateService.instant('accountant.cannot-load-employees-status'));
    }));
  }

  @Action(AccountantExpertAction.CreateCompany)
  public createCompany(ctx: StateContext<AccountantExpertStateModel>, { company }: AccountantExpertAction.CreateCompany) {
    return this.accountantExpertService.CreateCompany(company).pipe(tap(response => {
      if (response.IsSuccess) {
        this.toastService.success(
          this.translateService.instant('accountant.create-company-success'),
          this.translateService.instant('accountant.create-company-success-status'));

        this.router.navigate(['/dashboard']); // Redirection to dashboard page

        ctx.dispatch(new AccountantExpertAction.LoadCompanies());
      } else {
        this.toastService.error(
          this.translateService.instant('accountant.create-company-error')
        );
      }
    }, error => {
      this.toastService.error(
        this.translateService.instant('accountant.create-company-error')
      );
    }));
  }

  @Action(AccountantExpertAction.UpdateActivableLicenses)
  public updateAssignableLicense(ctx: StateContext<AccountantExpertStateModel>, { company, newNumber }: AccountantExpertAction.UpdateActivableLicenses) {
    return this.accountantExpertService.UpdateActivableLicenses(company.Id, newNumber).pipe(tap(response => {
      if (response.IsSuccess) {
        const updatedCompany = new ExpertedCompany(company);
        updatedCompany.NbOfActivableLicenses = newNumber;
        ctx.setState(patch({
          companies: updateItem(c => c.Id == company.Id, updatedCompany)
        }))
      } else {
        // Oups error message
      }
    }));
  }

  @Action(AccountantExpertAction.ActivateVP)
  public activateVp(ctx: StateContext<AccountantExpertStateModel>, { company }: AccountantExpertAction.UpdateActivableLicenses) {

    const updatedCompany = new ExpertedCompany(company);
    updatedCompany.IsVP = true;
    ctx.setState(patch({
      companies: updateItem(c => c.Id == company.Id, updatedCompany)
    }));

    return this.accountantExpertService.ActivateVP(company.Id).pipe(tap(response => {
      if (!response.IsSuccess) {
        // Oups error message
        const updatedCompany = new ExpertedCompany(company);
        updatedCompany.IsVP = false;
        ctx.setState(patch({
          companies: updateItem(c => c.Id == company.Id, updatedCompany)
        }));
      } else {
        this.toastService.success(this.translateService.instant('accountant-expert.activate-vp-success'));
      }
    }));
  }

  @Action(AccountantExpertAction.DeactivateVP)
  public deactivateVp(ctx: StateContext<AccountantExpertStateModel>, { company }: AccountantExpertAction.UpdateActivableLicenses) {

    const updatedCompany = new ExpertedCompany(company);
    updatedCompany.IsVP = false;
    ctx.setState(patch({
      companies: updateItem(c => c.Id == company.Id, updatedCompany)
    }));
  }
}
