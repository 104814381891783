import { Report } from './../../../shared/_models/Report';
import { Expense } from './../../../shared/_models/Expense';
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ImageViewerConfig } from 'ngx-image-viewer';
import { Select, Store } from '@ngxs/store';
import { ExpenseState } from '../../../shared/stores/expense/expense.state';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ExpenseAction } from '../../../shared/stores/expense/expense.actions';
import { environment } from 'projects/onexpense/src/environments/environment';
import { Amount } from 'on-shared/_models/Amount';

@Component({
  selector: 'on-edit-page',
  templateUrl: './edit-page.component.html',
  styleUrls: ['./edit-page.component.scss']
})
export class EditPageComponent implements OnInit, OnDestroy {
  @Output() editionFinished = new EventEmitter<boolean>();
  @Input() isNew = false;
  @Input() report: Report;

  expenseId: number;
  expenseToEdit: Expense;

  @Select(ExpenseState.current) expense$: Observable<Expense>;
  destroy$ = new Subject<void>();

  isLoading = false;
  editFormHidden = false;

  imageConfig: ImageViewerConfig = {
    btnClass: 'default', // The CSS class(es) that will apply to the buttons
    zoomFactor: 0.1, // The amount that the scale will be increased by
    containerBackgroundColor: '#FFF', // The color to use for the background. This can provided in hex, or rgb(a).
    wheelZoom: true, // If true, the mouse wheel can be used to zoom in
    allowFullscreen: true, // If true, the fullscreen button will be shown, allowing the user to entr fullscreen mode
    allowKeyboardNavigation: true, // If true, the left / right arrow keys can be used for navigation
    btnIcons: { // The icon classes that will apply to the buttons. By default, font-awesome is used.
      zoomIn: 'fas fa-plus',
      zoomOut: 'fas fa-minus',
      rotateClockwise: 'fas fa-redo',
      rotateCounterClockwise: 'fas fa-undo',
      next: 'fas fa-arrow-right',
      prev: 'fas fa-arrow-left',
      fullscreen: 'fas fa-arrows-alt',
    },
    btnShow: {
      zoomIn: true,
      zoomOut: true,
      rotateClockwise: true,
      rotateCounterClockwise: true,
      next: true,
      prev: true
    }
  };

  constructor(
    private route: ActivatedRoute,
    private store: Store
  ) { }

  ngOnInit() {
    if (this.expenseId) {
      this.loadExpense();
    } else if (this.route.snapshot.url[this.route.snapshot.url.length - 1]
      && this.isNew) {
      this.initNewExpense(this.route.snapshot.queryParams);
    } else {
      this.route.queryParams.subscribe(params => {
        this.initNewExpense(params);
      });
    }
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initNewExpense(params: Params) {
    const isKilometer = params['type'] === 'km';
    this.expenseToEdit = {
      Country: { Id: 0, ISOCode: environment.defaultCountry, LocalName: 'Default', FlagFileName: '', PrettyCode: '' },
      Currency: { Id: 0, ISOCode: environment.defaultCurrency, Symbol: '', Name: 'Default' },
      CreationDate: new Date(),
      DateOnExpense: isKilometer ? null : new Date(),
      ExpenseState: { Id: 4, Code: 'DONE', Name: 'Done' },
      GlobalAmount: new Amount({ TtcOrigin: 0, IsTotal: true, HtOrigin: 0, TaxesOrigin: 0, TVA: 0 }),
      WasAutomatic: false,
      IsEditable: true,
      TVAAmounts: [],
      ExpenseType: isKilometer ? { Id: 11, Code: 'MGE', Name: 'Mileage' } : { Id: 2, Code: 'REST', Name: 'Restaurant' },
      DynamicFieldsValues: [],
      PaidWithPersonalAccount: true,
      ConversionRatio: 1
    };
  }

  loadExpense() {
    this.isLoading = true;


    this.store.dispatch(new ExpenseAction.LoadExpenseById(this.expenseId)).subscribe(() => {
      this.expense$.pipe(take(1)).subscribe(response => {
        this.expenseToEdit = new Expense(response);

        this.isLoading = false;
      });
    });
  }

  editionFinishedListener(event) {
    this.isLoading = true;
    this.editionFinished.emit(event);
  }

  setEditFormHidden(hide: boolean) {
    this.editFormHidden = hide;
  }
}
