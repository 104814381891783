import { Component, OnInit, ViewChild } from '@angular/core';
import { StripeService, StripeCardComponent, StripeIbanComponent } from 'ngx-stripe';

import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PaymentService } from '../_services/payment.service';
import { StripeCustomer } from '../_models/StripeCustomer';
import Swal from 'sweetalert2';
import { CheckoutDataKey } from '../constants';
import { Router } from '@angular/router';
import { CheckoutModel } from '../_models/CheckoutModel';
import { StripeSubscription } from '../_models/StripeSubscription';
import { NbStepperComponent } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { CompanyAction } from '../../shared/stores/company/company.action';
import { Store } from '@ngxs/store';
import { environment } from 'projects/onexpense/src/environments/environment';
import { CreatePaymentMethodData, PaymentIntentResult, SetupIntentResult, StripeCardElement, StripeCardElementOptions, StripeIbanElement, StripeIbanElementChangeEvent, StripeIbanElementOptions } from '@stripe/stripe-js';

@Component({
  selector: 'on-payment-payment-process',
  templateUrl: './payment-process.component.html',
  styleUrls: ['./payment-process.component.scss']
})
export class PaymentProcessComponent implements OnInit {

  @ViewChild(StripeCardComponent, { static: false }) card: StripeCardComponent;
  @ViewChild(StripeIbanComponent, { static: false }) iban: StripeIbanComponent;
  @ViewChild(NbStepperComponent, { static: false }) stepper: NbStepperComponent;

  isLoading: boolean;

  customerFormGroup: UntypedFormGroup;

  checkoutData: CheckoutModel;

  setupIntentSecret: string;

  cardOptions: Partial<StripeCardElementOptions> = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  ibanOptions: Partial<StripeIbanElementOptions> = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    },
    supportedCountries: ['SEPA'],
    placeholderCountry: 'FR'
  };

  elementsOptions: any = {
  };

  currentCustomer: StripeCustomer;

  conditionsChecked = false;
  showConfirmationRequired = false;

  appName = environment.appName;
  selectedPaymentMethod: 'card' | 'sepa' = 'card';
  identifiedCountry = 'FR';

  constructor(
    private stripeService: StripeService,
    private paymentService: PaymentService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private t: TranslateService,
    private store: Store
  ) { }

  ngOnInit(): void {

    this.customerFormGroup = this.fb.group({
      Id: [''],
      CompanyName: [''],
      FullName: ['', Validators.required],
      Email: ['', Validators.compose([Validators.required, Validators.email])],
      Address: ['', Validators.required],
      City: ['', Validators.required],
      ZipCode: ['', Validators.required],
    });

    this.isLoading = true;
    this.paymentService.GetStripeCustomer().subscribe(result => {
      if (result.IsSuccess) {
        this.currentCustomer = result.Result;
        this.customerFormGroup.setValue(this.currentCustomer);
      }
      this.isLoading = false;
    });

    const checkoutDataString = sessionStorage.getItem(CheckoutDataKey);
    if (!checkoutDataString) {
      this.router.navigate(['/company', 'licenses']);
    } else {
      this.checkoutData = JSON.parse(checkoutDataString) as CheckoutModel;
      if (!this.checkoutData.price
        || !this.checkoutData.product
        || !this.checkoutData.nbOfLicenses) {
        this.router.navigate(['/company', 'licenses']);
      }
    }
  }

  goToPayment() {
    if (!this.customerFormGroup.valid) {
      this.customerFormGroup.markAllAsTouched();
      this.customerFormGroup.markAsDirty();
      return;
    }

    this.isLoading = true;
    this.paymentService.CreateOrUpdateStripeCustomer(new StripeCustomer(this.customerFormGroup.value as StripeCustomer))
      .subscribe((response) => {
        if (response.IsSuccess) {
          this.currentCustomer = response.Result;
          this.customerFormGroup.setValue(this.currentCustomer);
          // Create the setup intent for the payment
          this.paymentService.CreateSetupIntent().subscribe((setupIntentResponse) => {
            if (setupIntentResponse.IsSuccess) {
              this.setupIntentSecret = setupIntentResponse.Result;
            } else {
              Swal.fire(
                this.t.instant('payment.payment-process.customer-error-title'),
                this.t.instant('payment.payment-process.customer-error')
              )
            }
            this.isLoading = false;
          });
          this.stepper.next();
        } else {
          this.isLoading = false;
          Swal.fire(
            this.t.instant('payment.payment-process.customer-error-title'),
            this.t.instant('payment.payment-process.customer-error'));
        }
      });
  }

  previousStep(): void {
    this.stepper.previous();
  }
  nextStep() {
    this.isLoading = false;
    this.stepper.next();
  }
  
  ibanChanged(e: StripeIbanElementChangeEvent) {
    if (e.complete) {
      this.identifiedCountry = e.country;
    } else {
      this.identifiedCountry = 'FR';
    }
  }

  buy(cardComponent: StripeCardComponent, ibanComponent: StripeIbanComponent) {
    if (!this.conditionsChecked) {
      this.showConfirmationRequired = true;
      return;
    }

    this.isLoading = true;
    if (this.selectedPaymentMethod === 'card') {
      this.stripeService.getInstance().confirmCardSetup(this.setupIntentSecret, {
        payment_method: {
          card: cardComponent.element,
          billing_details: {
            name: this.customerFormGroup.value.CompanyName,
            email: this.customerFormGroup.value.Email,
            address: {
              line1: this.customerFormGroup.value.Address,
              city: this.customerFormGroup.value.City,
              postal_code: this.customerFormGroup.value.ZipCode,
              country: this.identifiedCountry
            }
          }
        }
      }).then(setupIntentResponse => {
        this.handleSetupIntentResponse(setupIntentResponse);
      });
    } else if (this.selectedPaymentMethod === 'sepa') {
      this.stripeService.getInstance().confirmSepaDebitSetup(this.setupIntentSecret, {
        payment_method: {
          sepa_debit: ibanComponent.element,
          billing_details: {
            name: this.customerFormGroup.value.CompanyName,
            email: this.customerFormGroup.value.Email,
            address: {
              line1: this.customerFormGroup.value.Address,
              city: this.customerFormGroup.value.City,
              postal_code: this.customerFormGroup.value.ZipCode,
              country: this.identifiedCountry
            }
          }
        }
      }).then(setupIntentResponse => {
        this.handleSetupIntentResponse(setupIntentResponse);
      });
    }
  }

  handleSetupIntentResponse(setupIntentResult: SetupIntentResult) {
    if (setupIntentResult.error) {
      Swal.fire(
        this.t.instant('payment.payment-process.payment-error-title'),
        this.t.instant('payment.payment-process.payment-error-card'),
        'error');
      this.isLoading = false;
      this.router.navigate(['/company', 'licenses']);
    } else {
      if (setupIntentResult.setupIntent.status === 'succeeded') {
        this.paymentService.CreateNewStripeSubscription(
          setupIntentResult.setupIntent.payment_method as string,
          this.checkoutData.price.Id,
          this.checkoutData.nbOfLicenses).subscribe(response => {
            if (response.IsSuccess) {
              sessionStorage.removeItem(CheckoutDataKey);
              this.handleSuccessSubscription(response.Result);
            } else {
              Swal.fire(
                this.t.instant('payment.payment-process.payment-error-title'),
                this.t.instant('payment.payment-process.payment-error-card'),
                'error');
              this.isLoading = false;
            }
          });
        return;
      }
    }
  }

  handleSuccessSubscription(subscription: StripeSubscription) {
    this.store.dispatch(CompanyAction.ShouldReloadEmployees);

    if (subscription && subscription.Status === 'active') {
      this.nextStep();
      return;
    }

    const paymentIntent = subscription.LatestInvoice.PaymentIntent;

    if (paymentIntent.Status === 'requires_action' && this.selectedPaymentMethod === 'card') {
      this.stripeService.confirmCardPayment(paymentIntent.ClientSecret).subscribe((intentResult: PaymentIntentResult) => {
        if (intentResult.error) {
          Swal.fire(
            this.t.instant('payment.payment-process.payment-error-title'),
            this.t.instant('payment.payment-process.payment-error-card'),
            'error');
          this.isLoading = false;
          this.router.navigate(['/company', 'licenses']);
        } else {
          if (intentResult.paymentIntent.status === 'succeeded') {
            this.nextStep();
            return;
          }
        }
      });
    }
    else if (this.selectedPaymentMethod === 'sepa' && paymentIntent.Status === 'requires_confirmation') {
      this.stripeService.confirmSepaDebitPayment(paymentIntent.ClientSecret).subscribe((intentResult: PaymentIntentResult) => {
        if (intentResult.error) {
          Swal.fire(
            this.t.instant('payment.payment-process.payment-error-title'),
            this.t.instant('payment.payment-process.payment-error-card'),
            'error');
          this.isLoading = false;
          this.router.navigate(['/company', 'licenses']);
        } else {
          if (intentResult.paymentIntent.status === 'succeeded' || intentResult.paymentIntent.status == 'processing') {
            this.nextStep();
            return;
          }
        }
      });
    } else {
      Swal.fire(
        this.t.instant('payment.payment-process.payment-error-title'),
        this.t.instant('payment.payment-process.payment-error-card'),
        'error');
      this.isLoading = false;
    }
  }
}
