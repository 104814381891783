import { UserActions } from 'on-shared/stores/user/user.actions';
import { Store } from '@ngxs/store';
import { AccountService } from '../_services/account.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'on-external-sign-in-page',
  templateUrl: './external-sign-in-page.component.html',
  styleUrls: ['./external-sign-in-page.component.scss']
})
export class ExternalSignInPageComponent implements OnInit {
  constructor(
    private accountService: AccountService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store
  ) {
  }

  ngOnInit() {
    // If no param for jwt, go to login page
    const jwt = this.activatedRoute.snapshot.params['jwt'];
    if (!jwt) {
      this.router.navigate(['/login']);
    }
    else {
      this.store.dispatch(new UserActions.ExternalLogin(jwt)).subscribe();
    }
  }
}
