import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import Swal from 'sweetalert2';
import { AccountantExport } from '../../_models/AccountantExport';
import { Report } from '../../../shared/_models/Report';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ReportListComponent } from '../../../report/_shared/report-list/report-list.component';
import { Select, Store } from '@ngxs/store';
import { ReportsState } from '../../../shared/stores/reports/reports.state';
import { takeUntil, tap, map } from 'rxjs/operators';
import { ReportsActions } from '../../../shared/stores/reports/reports.actions';
import { ReportsModel } from '../../../shared/stores/reports/reports.model';
import { AccountantExportAction } from '../../../shared/stores/accountant-export/accountant-export.actions';

@Component({
  selector: 'on-expenses-to-be-exported-page',
  templateUrl: './expenses-to-be-exported-page.component.html',
  styleUrls: ['./expenses-to-be-exported-page.component.scss'],
})
export class ExpensesToBeExportedPageComponent implements OnInit, OnDestroy {
  isLoading: boolean;

  reports: Report[] = [];

  searchTerms: string;

  @ViewChild('reportListComponent') reportListComponent: ReportListComponent;

  @Select(ReportsState.getReportsForAccountant) readonly $reports;
  $destroy = new Subject<void>();

  constructor(
    private store: Store,
    private router: Router,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.store.dispatch(
      new ReportsActions.IfValidatedReportsForAccountantNeeded(),
    );
    this.isLoading = true;
    this.$reports.pipe(takeUntil(this.$destroy)).subscribe((res: Report[]) => {
      if (res) {
        this.reports = res.map((r) => new Report(r));
        this.isLoading = false;
      }
    });
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }

  canAdd(): boolean {
    return this.reports.filter((r) => r.IsSelected).length > 0;
  }

  async openSwalCreate() {
    const swal = this.getCustomSwal();

    swal
      .fire({
        title: this.translate.instant(
          'company.accountant-export.create-export',
        ),
        text: this.translate.instant('company.accountant-export.enter-name'),
        input: 'text',
        showCancelButton: true,
        cancelButtonText: this.translate.instant(
          'company.accountant-export.cancel',
        ),
        confirmButtonText: this.translate.instant(
          'company.accountant-export.create',
        ),
      })
      .then((result) => {
        if (result.value) {
          const reports = this.reports.filter((r) => r.IsSelected);
          this.createAccountantExport(result.value, reports).subscribe(
            (exportId) => {
              // If the export was created
              if (exportId) {
                this.store.dispatch(
                  new ReportsActions.RemoveExportedReport(reports),
                );
                this.openSwalEditOrClose(exportId);
              }
            },
          );
        }
      });
  }

  openSwalEditOrClose(exportId: number) {
    const swal = this.getCustomSwal();

    swal
      .fire({
        title: this.translate.instant(
          'company.accountant-export.edit-question',
        ),
        showCancelButton: true,
        cancelButtonText: this.translate.instant(
          'company.accountant-export.close',
        ),
        confirmButtonText: this.translate.instant(
          'company.accountant-export.edit',
        ),
      })
      .then((result) => {
        if (result.value) {
          // Redirect to edit
          this.router.navigate(['company', 'exports', exportId]);
        }
      });
  }

  // Create an accountantExport and return its id
  // Return null if problem
  private createAccountantExport(
    name: string,
    reports: Report[],
  ): Observable<number> {
    const obs = new Subject<number>();

    const today = new Date();
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0,
    );
    const beginDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const endDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      lastDayOfMonth.getDate(),
    );
    const userTimezoneOffset = today.getTimezoneOffset() * 60000;

    const periodBeginDate = new Date(beginDate.getTime() - userTimezoneOffset);
    const periodEndDate = new Date(endDate.getTime() - userTimezoneOffset);

    const accountantExport: AccountantExport = {
      Name: name,
      Reports: reports,
      PeriodBeginDate: periodBeginDate,
      PeriodEndDate: periodEndDate,
    };

    this.store.dispatch(
      new AccountantExportAction.CreateAccountantExport(
        accountantExport,
        (exportId) => {
          obs.next(exportId);
          obs.complete();
        },
      ),
    );

    return obs;
  }

  private getCustomSwal(): typeof Swal {
    const swal = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary m-1',
        cancelButton: 'btn btn-danger m-1',
      },
      buttonsStyling: false,
    });

    return swal;
  }

  onChangeFilter() {
    this.reportListComponent.onChangeFilter(this.searchTerms);
  }

  reload() {
    this.isLoading = true;
    this.store.dispatch(new ReportsActions.GetValidatedReportsForAccountant());
  }
}
