/**
 * Inspired by Nebular (https://akveo.github.io/nebular/)
 **/

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { MenuItem } from '../../_models/MenuItem';

export enum ToggleStates {
  Expanded = 'expanded',
  Collapsed = 'collapsed'
}

@Component({
  selector: 'on-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  animations: [
    trigger('toggle', [
      state(ToggleStates.Collapsed, style({ height: '0', margin: '0' })),
      state(ToggleStates.Expanded, style({ height: '*' })),
      transition(`${ToggleStates.Collapsed} <=> ${ToggleStates.Expanded}`, animate(300)),
    ]),
  ],
})
export class MenuItemComponent implements OnInit {

  @Input() item: MenuItem;
  @Output() onClick = new EventEmitter<MenuItem>();

  toggleState: ToggleStates;

  constructor() { }

  ngOnInit(): void {
    this.doCheck();
  }

  onItemClick() {
    this.onClick.emit(this.item);
  }

  displayChildren() {
    this.item.expanded = !this.item.expanded;
    this.doCheck();
  }

  doCheck() {
    this.toggleState = this.item.expanded ? ToggleStates.Expanded : ToggleStates.Collapsed;
  }

}
