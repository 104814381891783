import { CreateCompanyPageComponent } from './create-company-page/create-company-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListExpertedCompaniesPageComponent } from './list-experted-companies-page/list-experted-companies-page.component';


const routes: Routes = [
  { path: 'create_company', component: CreateCompanyPageComponent },
  { path: 'companies', component: ListExpertedCompaniesPageComponent },
  { path: '', pathMatch: 'full', redirectTo: 'companies' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountantExpertRoutingModule { }
