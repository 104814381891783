<h2 *ngIf="!isAllowed">{{'company.create.title' | translate}}</h2>

<div class="row" *ngIf="isAllowed">
  <button class="btn-hidden" placement="right" ngbPopover="Annuler" triggers="mouseenter:mouseleave"
    (click)="cancelCreationCompany()">
    <nb-icon icon="fa-arrow-left" style="color: rgb(54, 141, 189); font-size: 1.5em"></nb-icon>
  </button>
  <h2 class="m-0">{{'company.create-bu.title' | translate}}</h2>
</div>

<on-loading *ngIf="isLoading;else loaded"></on-loading>

<ng-template #loaded>
  <div *ngIf="currentUser$ | async as currentUser">
    <div
      *ngIf="!currentUser.License;else alreadySubscribed">
      <div [innerHTML]="'company.create.description' | translate"></div>
      <div class="alert alert-warning" *ngIf="!hasAlreadyPro && !isAllowed">
        <nb-icon icon="fa-exclamation-triangle" class="mr-2"></nb-icon>
        <span [innerHTML]="'company.create.waning-message' | translate : {appName:appName}"></span>
      </div>

      <div class="alert alert-warning" *ngIf="hasAlreadyPro">
        <nb-icon icon="fa-exclamation-triangle" class="mr-2"></nb-icon>
        {{'company.create.already-pro-license' | translate : {appName:appName} }}
      </div>

      <on-two-columns-display title="Groupe" *ngIf="!isLoading && isAllowed">
        <div content>
          <form [formGroup]="companyGroupForm">
            <div class="form-group">
              <label for="name" class="label">Nom *</label>
              <input type="text" id="name" class="form-control" formControlName="Name">
              <on-required-field-display [field]="companyGroupForm.get('Name')"></on-required-field-display>
            </div>
          </form>
        </div>
      </on-two-columns-display>

      <on-two-columns-display [title]="'company.create.general-informations' | translate" *ngIf="!isLoading">
        <div content>
          <form [formGroup]="companyForm">
            <div class="form-group">
              <label for="name" class="label">{{ 'company.create.company-name' | translate }} *</label>
              <input type="text" id="name" class="form-control" formControlName="Name">
              <on-required-field-display [field]="companyForm.get('Name')"></on-required-field-display>
            </div>
            <div class="form-group">
              <label for="siret" class="label">{{ 'company.create.siret' | translate }} *</label>
              <input type="text" id="siret" class="form-control" formControlName="SIRET">
              <on-required-field-display [field]="companyForm.get('SIRET')"></on-required-field-display>
            </div>
            <div class="form-group">
              <label for="" class="label">{{ 'company.create.country' | translate }} *</label>
              <on-country-select type="text" formControlName="Country"></on-country-select>
              <on-required-field-display [field]="companyForm.get('Country')"></on-required-field-display>
            </div>
            <div class="form-group">
              <label for="" class="label">{{ 'company.create.curreny' | translate }} *</label>
              <on-currency-select type="text" formControlName="Currency"></on-currency-select>
              <on-required-field-display [field]="companyForm.get('Currency')"></on-required-field-display>
            </div>
          </form>
        </div>
      </on-two-columns-display>

      <on-two-columns-display [title]="'company.create.address' | translate " *ngIf="!isLoading">
        <div content>
          <form [formGroup]="addressForm">
            <div class="form-group">
              <label for="street1">{{ 'company.create.street1' | translate }}</label>
              <input type="text" class="form-control" id="street1" formControlName="Street1">
            </div>

            <div class="form-group">
              <label for="street2">{{ 'company.create.street2' | translate }}</label>
              <input type="text" class="form-control" id="street2" formControlName="Street2">
            </div>

            <div class="form-group">
              <label for="cityname">{{ 'company.create.city' | translate }}</label>
              <input type="text" class="form-control" id="cityname" formControlName="City">
            </div>

            <div class="form-group">
              <label for="zip">{{ 'company.create.zip-code' | translate }}</label>
              <input type="number" class="form-control" id="zip" formControlName="Zip">
            </div>
          </form>
        </div>
      </on-two-columns-display>

      <on-two-columns-display [title]="'company.create.admin-contact' | translate " *ngIf="!isLoading">
        <div content>
          <form [formGroup]="companyForm">
            <div class="form-group">
              <label for="adminemail" class="label">{{ 'company.create.email' | translate }} *</label>
              <input id="adminemail" type="text" class="form-control" formControlName="AdminEmail">
              <on-required-field-display [field]="companyForm.get('Currency')"></on-required-field-display>
            </div>
            <on-required-field-display [field]="companyForm.get('AdminEmail')"></on-required-field-display>
            <div class="form-group">
              <label for="adminphone" class="label">{{ 'company.create.phone' | translate }} *</label>
              <input id="adminphone" type="text" class="form-control" formControlName="AdminPhone">
              <on-required-field-display [field]="companyForm.get('Currency')"></on-required-field-display>
            </div>
          </form>
        </div>
      </on-two-columns-display>

      <div class="row" *ngIf="!isLoading">
        <div class="col text-center pb-3">
          <button class="btn btn-primary" (click)="createCompany()">
            <nb-icon icon="fa-save"></nb-icon>
            {{ 'company.create.btn' | translate }}
          </button>
        </div>
      </div>
    </div>
    <ng-template #alreadySubscribed>
      <div class="alert alert-warning" [innerHTML]="'company.create.already-subscribed' | translate : {appName:appName} ">
      </div>
    </ng-template>
  </div>
</ng-template>
