<on-loading *ngIf="isLoading"></on-loading>

<div *ngIf="!isLoading">
  <on-two-columns-display [title]="'company.administration.rights-title' | translate">
    <div description>
      <p>{{'company.administration.rights-description' | translate}}</p>
    </div>

    <div content class="row col-12" *ngIf="company">
      <div class="col-12 form-check">
        <input class="form-check-input" type="checkbox" id="manager" [(ngModel)]="company.ManagerCanEdit"
          (change)="updateCompany()">
        <label class="form-check-label"
          for="manager">{{'company.administration.manager-can-manage' | translate}}</label>
      </div>
      <br>
      <br>
      <div class="col-12 form-check">
        <input class="form-check-input" type="checkbox" id="accountant" [(ngModel)]="company.AccountantCanEdit"
          (change)="updateCompany()">
        <label class="form-check-label"
          for="accountant">{{'company.administration.accountant-can-unvalidate' | translate}}</label>
      </div>
    </div>
  </on-two-columns-display>

  <on-two-columns-display [title]="'company.administration.delete-company-title' | translate">
    <div description>
      <p>{{'company.administration.delete-company-description-1' | translate}}</p>
      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <nb-icon icun="fa-exclamation-triangle" class="red"></nb-icon>
        </div>
        <div class="col">
          {{'company.administration.delete-company-description-2' | translate}}
        </div>
      </div>

    </div>
    <div content>
      <button class="btn btn-danger"
        (click)="deleteCompany()">{{'company.administration.delete-company-btn' | translate}}</button>
    </div>
  </on-two-columns-display>
</div>