import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Vehicle } from '../../_models/Vehicle';
import { API_V2_URL } from 'on-common/constants';
import { IApiResult } from 'on-common/_models/ApiResult';

const VEHICLES_URL = API_V2_URL + '/vehicle';

@Injectable({ providedIn: 'root' })
export class VehiclesService {

  constructor(private http: HttpClient) {}

  GetForCurrentUser(): Observable<IApiResult<Vehicle[]>> {
    return this.http.get<IApiResult<Vehicle[]>>(VEHICLES_URL + '/me');
  }

  GetByUserId(userId: number): Observable<IApiResult<Vehicle[]>> {
    return this.http.get<IApiResult<Vehicle[]>>(VEHICLES_URL + '/user/' + userId);
  }

  GetByCompanyId(companyId: number): Observable<IApiResult<Vehicle[]>> {
    return this.http.get<IApiResult<Vehicle[]>>(VEHICLES_URL + '/user/' + companyId);
  }

  Create(vehicle: Vehicle): Observable<IApiResult<Vehicle>> {
    return this.http.post<IApiResult<Vehicle>>(VEHICLES_URL + '/create', vehicle);
  }

  Update(vehicle: Vehicle): Observable<IApiResult<Vehicle>> {
    return this.http.post<IApiResult<Vehicle>>(VEHICLES_URL + '/update', vehicle);
  }

  CanDelete(vehicle: Vehicle): Observable<IApiResult<boolean>> {
    return this.http.get<IApiResult<boolean>>(VEHICLES_URL + '/candelete/' + vehicle.Id);
  }

  Delete(vehicle: Vehicle): Observable<IApiResult<Vehicle>> {
    return this.http.delete<IApiResult<Vehicle>>(VEHICLES_URL + '/delete/' + vehicle.Id);
  }

  Disabled(vehicle: Vehicle): Observable<IApiResult<Vehicle>> {
    return this.http.get<IApiResult<Vehicle>>(VEHICLES_URL + '/disabled/' + vehicle.Id);
  }
}
