import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { API_V2_URL } from "on-common/constants";
import { IApiResult } from "on-common/_models/ApiResult";
import { AccountingPlan } from "on-shared/_models/AccountingPlan";
import { Observable } from "rxjs";

const ACCOUNTING_PLAN_URL = API_V2_URL + '/accountingplan';

@Injectable({
  providedIn: 'root'
})
export class AccountingPlanService {

  constructor(private http: HttpClient) {
  }

  GetAccountingPlansForCompany(): Observable<IApiResult<AccountingPlan[]>> {
    return this.http.get<IApiResult<AccountingPlan[]>>(ACCOUNTING_PLAN_URL);
  }

  UpdateAccountingPlans(plans: AccountingPlan[]): Observable<IApiResult<AccountingPlan[]>> {
    return this.http.post<IApiResult<AccountingPlan[]>>(ACCOUNTING_PLAN_URL, plans);
  }
}
