import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SideBarService } from '../_services/side-bar.service';
import { MenuItem } from '../_models/MenuItem';
import { AccountService } from 'on-auth/_services/account.service';
import { DialogService } from 'on-common/_services/dialog.service';

@Component({
  selector: 'on-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit, OnDestroy {

  sidebarSubsciption: Subscription;
  routeParamsSubsciption: Subscription;

  tree: MenuItem[] = null;
  appLogo: string;
  hasTree = false;

  constructor(
    public sidebarService: SideBarService,
    public accountService: AccountService,
    public dialogService: DialogService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.routeParamsSubsciption = this.activatedRoute.url.subscribe(params => {
      if (params) {
        this.appLogo = this.activatedRoute.snapshot.data.logo;
      }
    });

    this.sidebarSubsciption = this.sidebarService.sibarTree.subscribe(sbTree => {
      if (sbTree) {
        this.hasTree = true;
        this.tree = sbTree;
      }
    });
  }

  ngOnDestroy() {
    this.sidebarSubsciption.unsubscribe();
    this.routeParamsSubsciption.unsubscribe();
  }
}
