import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { SharePageComponent } from './share-page/share-page.component';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { SharebuttonsComponent } from './sharebuttons/sharebuttons.component';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
const shareProp = {
  facebook: {
    icon: faFacebookF,
  },
  twitter: {
    icon: faTwitter,
  },
  linkedin: {
    icon: faLinkedinIn,
  },
};

@NgModule({
  declarations: [SharePageComponent, SharebuttonsComponent],
  imports: [
    SharedModule,
    ShareButtonsModule.withConfig({ prop: shareProp }),
    ShareIconsModule,
  ],
})
export class ShareOnexpenseModule {}
