import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'on-checkbox-gridcell',
  templateUrl: './checkbox-gridcell.component.html',
  styleUrls: ['./checkbox-gridcell.component.scss'],
})
export class CheckboxGridcellComponent implements ICellRendererAngularComp {
  @ViewChild('.checkbox') checkbox: ElementRef;

  public params: ICellRendererParams;

  constructor() {}

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  afterGuiAttached?(
    params?: import('ag-grid-community').IAfterGuiAttachedParams,
  ): void {}

  public onChange(event) {
    this.params.data[this.params.colDef.field] = event.currentTarget.checked;
    this.params.context.parent.checkChanged(this.params);
  }
}
