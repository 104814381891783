<br>
<div ngb-accordion *ngIf="!isLoading && monthlyConsumptions">
  <p class="text-center" *ngIf="!monthlyConsumptions.length">
    {{'company.vp-consumption.no-consumption' | translate}}
  </p>
  <div ngbAccordionItem *ngFor="let monthlyConsumption of monthlyConsumptions">
    <div ngbAccordionHeader>
      <div class="d-flex align-items-center justify-content-between">
        <h5 class="m-0">{{'company.vp-consumption.month' | translate}} {{monthlyConsumption.DateMonth | date:'yyyy-MM'}}
        </h5>
        <div>
          <button class="btn btn-link p-0" ngbPanelToggle>{{'company.vp-consumption.total-pages' | translate}}
            {{monthlyConsumption.nbOfPages}}</button>
        </div>
      </div>
    </div>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <table class="table">
          <thead>
            <tr>
              <th>{{'company.vp-consumption.report-name' | translate}}</th>
              <th>{{'company.vp-consumption.user-name' | translate}}</th>
              <th>{{'company.vp-consumption.page-quantity' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let consumptionPerReport of monthlyConsumption.ConsumptionPerExport">
              <tr>
                <th colspan="10">{{consumptionPerReport.ExportName}}
                  ({{consumptionPerReport.Consumptions[0].StorageDate | date:'yyyy-MM-dd'}})</th>
              </tr>
              <tr *ngFor="let consumption of consumptionPerReport.Consumptions">
                <td>{{consumption.ReportName}}</td>
                <td>{{consumption.Username}}</td>
                <td>{{consumption.NbOfPages}}</td>
              </tr>
              <tr>
                <td colspan="10" class="total-indication">{{'company.vp-consumption.total' | translate}}
                  {{consumptionPerReport.nbOfPages}}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<on-loading *ngIf="isLoading"></on-loading>