import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { ExpenseType } from 'projects/onexpense/src/app/shared/_models/ExpenseType';

@Component({
  selector: 'on-expense-type-grid-cell',
  templateUrl: './expense-type-grid-cell.component.html',
  styleUrls: ['./expense-type-grid-cell.component.scss']
})
export class ExpenseTypeGridCellComponent implements ICellRendererAngularComp {

  type: ExpenseType;

  constructor() { }

  refresh(params: any): boolean {
    return false;
  }
  agInit(params: ICellRendererParams): void {
    this.type = params.value;
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
  }
}
