import { TranslationWidth } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

const I18N_VALUES = {
    'fr': {
        weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
        months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
    },
    'en': {
        weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dev']
    },
    'es': {
        weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dev']
    },
    'de': {
        weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dev']
    }
    // other languages you would support
};

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
    constructor(private translateService: TranslateService) {
        super();
    }

    getWeekdayShortName(weekday: number): string {
        return I18N_VALUES[this.translateService.currentLang].weekdays[weekday - 1];
    }
    getMonthShortName(month: number): string {
        return I18N_VALUES[this.translateService.currentLang].months[month - 1];
    }
    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }
    getDayAriaLabel(date: NgbDateStruct): string {
        if (this.translateService.currentLang === 'en') {
            return `${date.month}/${date.day}/${date.year}`;
        } else {
            return `${date.day}/${date.month}/${date.year}`;
        }
    }
    getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
        return this.getWeekdayShortName(weekday);
    }
}

@Injectable()
export class CustomDatepickerFormatter extends NgbDateParserFormatter {

    constructor(private translateService: TranslateService) {
        super();
    }

    parse(value: string): NgbDateStruct {
        if (!value || value === '') {
            return null;
        }
        return this.translateService.currentLang === 'en' ?
            {
                // English case MM/dd/yyyy
                day: +value.substr(3, 2),
                month: +value.substr(0, 2),
                year: +value.substr(6, 4)
            }
            : {
                // All other cases dd/MM/yyyy
                day: +value.substr(0, 2),
                month: +value.substr(3, 2),
                year: +value.substr(6, 4)
            };

    }

    format(date: NgbDateStruct): string {
        if (!date) {
            return '';
        }
        return this.translateService.currentLang === 'en' ?
            // English version MM/dd/yyyy
            `${('0' + date.month).slice(-2)}/${('0' + date.day).slice(-2)}/${date.year}`
            // Other versions dd/MM/yyyy
            : `${('0' + date.day).slice(-2)}/${('0' + date.month).slice(-2)}/${date.year}`;
    }


}
