export class ISelectableIdObject {
  Id?: number;
  IsSelected?: boolean;

  constructor(model?: ISelectableIdObject) {
    if (model) {
      this.Id = model.Id;
      this.IsSelected = model.IsSelected;
    } else {
      this.Id = null;
      this.IsSelected = null;
    }
  }
}
