
export class License {
  ProductTypeCode: string;

  constructor(model?: License) {
    if (model) {
      this.ProductTypeCode = model.ProductTypeCode;
    } else {
      this.ProductTypeCode = null;
    }
  }
}
