import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'on-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  @Input() title;
  @Input() message;
  @Input() class;
  @Input() routerLink;
  @Input() href;
  @Input() modalSouce;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
