import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Component, OnInit, ElementRef } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'on-delete-row-gridcell',
  templateUrl: './delete-row-gridcell.component.html',
  styleUrls: ['./delete-row-gridcell.component.scss']
})
export class DeleteRowGridcellComponent implements ICellRendererAngularComp {

  public params: ICellRendererParams;

  constructor() { }

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  afterGuiAttached?(params?: import('ag-grid-community').IAfterGuiAttachedParams): void {
  }

  public onChange(event) {
    this.params.context.parent.deleteRow(this.params);
  }
}
