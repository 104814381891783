import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/onexpense/src/environments/environment';

@Component({
  selector: 'on-company-presentation-page',
  templateUrl: './company-presentation-page.component.html',
  styleUrls: ['./company-presentation-page.component.scss']
})
export class CompanyPresentationPageComponent implements OnInit {


  appName = environment.appName;

  constructor() { }

  ngOnInit() {
  }

}
