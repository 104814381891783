import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Company } from '../../_models/Company';
import { EmployeeRole } from '../../_models/EmployeeRole';
import { API_V2_URL, TOKEN_STORAGE_KEY_CONNECTED_AS, USERNAME_STORAGE_KEY_CONNECTED_AS } from 'on-common/constants';
import { IApiActionResult, IApiResult } from 'on-common/_models/ApiResult';
import { IApiToken } from 'on-auth/_models/ApiToken';
import { ExpertedCompany } from 'onexpense/accountant-expert/_models/ExpertedCompany';


const ACCOUNTANT_EXPERT_URL = API_V2_URL + '/accexpert';
const ACCOUNT_URL = API_V2_URL + '/account';

@Injectable({ providedIn: 'root' })
export class AccountantExpertService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  // Get the companies of the current accountant expert
  GetCompanies(): Observable<IApiResult<ExpertedCompany[]>> {
    return this.http.post<IApiResult<ExpertedCompany[]>>(ACCOUNTANT_EXPERT_URL + '/companies', null);
  }

  GetEmployeesByCompanyId(companyId: number): Observable<IApiResult<EmployeeRole[]>> {
    return this.http.post<IApiResult<EmployeeRole[]>>(ACCOUNTANT_EXPERT_URL + '/company/employees', companyId);
  }

  // Create company with account 'admin'
  CreateCompany(company: Company): Observable<IApiActionResult> {
    return this.http.post<IApiActionResult>(ACCOUNTANT_EXPERT_URL + '/createcompany', company);
  }

  // Get Token from user.Id
  GetTokenByUserId(userId: number): Observable<IApiToken> {
    return this.http.post<IApiToken>(ACCOUNT_URL + '/connectas', userId);
  }

  SetActiveToken(token: string, username: string) {
    if (token) {
      localStorage.setItem(TOKEN_STORAGE_KEY_CONNECTED_AS, token);
      localStorage.setItem(USERNAME_STORAGE_KEY_CONNECTED_AS, username);
    } else {
      localStorage.removeItem(TOKEN_STORAGE_KEY_CONNECTED_AS);
      localStorage.removeItem(USERNAME_STORAGE_KEY_CONNECTED_AS);
    }
    this.router.navigate(['/dashboard']); // Redirection to expenses
    setTimeout(() => { // For validate redirection
      window.location.reload(); // Reload page for update navbar & others
    }, 500);
  }

  UpdateActivableLicenses(companyId: number, newNumber: number) {
    return this.http.post<IApiActionResult>(ACCOUNTANT_EXPERT_URL + '/allowed-licenses', { companyId, newNumber });
  }

  ActivateVP(companyId: number) {
    return this.http.post<IApiActionResult>(ACCOUNTANT_EXPERT_URL + '/activate-vp', { companyId });
  }
}
