<div class="modal-header">
  <h2 class="modal-title" id="modal-basic-title">{{'company.accountant-export.select-report.title' | translate}}</h2>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="!isLoadingReports">
      <on-report-list [showOwner]="true" [reports]="toBeIncludedReports" [canSelect]="true" *ngIf="toBeIncludedReports && toBeIncludedReports.length"></on-report-list>
      <on-empty-list [message]="'company.accountant-export.select-report.no-report' | translate" *ngIf="!toBeIncludedReports || !toBeIncludedReports.length"></on-empty-list>
      <div class="text-center">
        <button class="btn btn-round btn-primary" (click)="add()" [disabled]="!canAdd()">
          <nb-icon icon="fa-check"></nb-icon>
        </button>
        <button class="btn btn-round btn-danger ml-2" (click)="modal.dismiss()">
          <nb-icon icon="fa-times"></nb-icon>
        </button>
      </div>
    </div>
    <on-loading *ngIf="isLoadingReports"></on-loading>
</div>
