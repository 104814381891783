import { TranslateService } from '@ngx-translate/core';
import { ManagerCelleditorComponent } from './../../_shared/grid-cell/manager-celleditor/manager-celleditor.component';
import { ManagerGridcellComponent } from './../../_shared/grid-cell/manager-gridcell/manager-gridcell.component';
import { OnUser } from 'on-shared/_models/OnUser';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  HostListener,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AgGridAngular } from 'ag-grid-angular';
import { Select, Store } from '@ngxs/store';
import { CompanyState } from 'on-shared/stores/company/company.state';
import { Company } from 'on-shared/_models/Company';
import { CompanyAction } from 'on-shared/stores/company/company.action';
import { AGGRID_TRANSLATIONS } from 'on-common/constants';

@Component({
  selector: 'on-hierarchy-tab',
  templateUrl: './hierarchy-tab.component.html',
  styleUrls: ['./hierarchy-tab.component.scss'],
})
export class HierarchyTabComponent implements OnInit, OnChanges, OnDestroy {
  @Input() companyId: number;

  isLoading = false;
  allEmployees: OnUser[];
  @Select(CompanyState.current) company$: Observable<Company>;
  destroy$ = new Subject<void>();

  filteredEmployees: OnUser[];

  allManagersInfos: Map<number, string>;

  searchTerms: string;

  searchTerms$: Subject<string>;

  pageSize = 50;
  customPageSize: number;

  @ViewChild('agGridHierarchy', { static: true }) gridHierarchy: AgGridAngular;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const timeout = setTimeout((_) => {
      this.gridHierarchy.api.sizeColumnsToFit();
      clearTimeout(timeout);
    }, 200);
  }

  constructor(
    private translateService: TranslateService,
    private store: Store
  ) {
    this.searchTerms$ = new Subject<string>();
    this.searchTerms$
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((model) => this.doSearchUsers());
  }

  ngOnInit() {
    this.store.dispatch(new CompanyAction.LoadHierachyIfNeeded());

    const columnDefsHierarchy = [
      {
        headerName: this.translateService.instant('company.hierarchy.username'),
        field: 'UserName',
        sortable: true,
        filter: 'agTextColumnFilter',
        resizable: true,
        minWidth: 300,
      },
      {
        headerName: this.translateService.instant(
          'company.hierarchy.firstname',
        ),
        field: 'FirstName',
        sortable: true,
        filter: 'agTextColumnFilter',
        resizable: true,
      },
      {
        headerName: this.translateService.instant('company.hierarchy.lastname'),
        field: 'LastName',
        sortable: true,
        filter: 'agTextColumnFilter',
        resizable: true,
      },

      {
        headerName: this.translateService.instant('company.hierarchy.manager'),
        field: 'ManagerInfos',
        sortable: true,
        cellRenderer: ManagerGridcellComponent,
        cellRendererParams: {
          allEmployees: this.allEmployees,
        },
        cellStyle: { display: 'block !important' },
        cellEditor: ManagerCelleditorComponent,
        cellEditorParams: {
          allEmployees: this.allEmployees,
        },
        filter: 'agTextColumnFilter',
        resizable: true,
        editable: true,
        singleClickEdit: true,
        autoHeight: true,
        minWidth: 450,
      },
    ];

    // Grid Hierarchy Options
    this.gridHierarchy.gridOptions = {
      columnDefs: columnDefsHierarchy,

      rowSelection: 'multiple',
      // floatingFilter: true,
      domLayout: 'autoHeight',

      pagination: true,
      paginationPageSize: this.pageSize, // Nb items displayed per page - default

      rowHeight: 42,

      suppressCellFocus: true,
      suppressRowClickSelection: true,

      onGridReady: () => {
        this.loadHierarchy();
      },

      // For get 'this' in child component
      context: { parent: this },

      // Translate
      localeText: AGGRID_TRANSLATIONS
    };

    this.gridHierarchy.defaultColDef = {
      cellClass: 'd-flex align-items-center',
      editable: false,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    //this.loadHierarchy();
    this.searchTerms = '';
    if (this.gridHierarchy.api != null) {
      this.onChangeFilter();

      this.gridHierarchy.api.sizeColumnsToFit(); // Adjust size
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadHierarchy() {
    this.isLoading = true;

    this.company$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response.Hierarchy) {

        this.allEmployees = (response.Hierarchy || []).map((c) => new OnUser(c));

        this.filteredEmployees = this.allEmployees;
        this.allManagersInfos = this.createAllManagersInfos();
        this.addManagerInfosOnAllEmployees();
        this.gridHierarchy.api.updateGridOptions({rowData: this.allEmployees});

        this.gridHierarchy.api.sizeColumnsToFit(); // Adjust size

        this.isLoading = false;
      }
    });
  }

  changeManager(employee: OnUser) {
    this.store.dispatch(new CompanyAction.UpdateHierarchy(employee.Id, employee.ManagerId, this.companyId));
  }

  saveHierarchy() {
    this.store.dispatch(new CompanyAction.SaveHierarchy(this.allEmployees));
  }

  searchUsers() {
    this.searchTerms$.next(this.searchTerms);
  }

  doSearchUsers() {
    if (!this.searchTerms) {
      this.filteredEmployees = this.allEmployees;
      return;
    }

    const terms = this.searchTerms.toLowerCase();
    this.filteredEmployees = this.allEmployees.filter(
      (emp) =>
        emp.UserName.toLowerCase().indexOf(terms) > -1 ||
        emp.LastName.toLowerCase().indexOf(terms) > -1 ||
        emp.FirstName.toLowerCase().indexOf(terms) > -1,
    );
  }

  addManagerInfosOnAllEmployees() {
    for (const emp of this.allEmployees) {
      (emp as any).ManagerInfos = this.allManagersInfos.get(emp.ManagerId);
    }
  }

  createAllManagersInfos(): Map<number, string> {
    const tmp = new Map<number, string>();

    // For each user create managerInfos
    for (const user of this.allEmployees) {
      let infos = '';

      if (user.FirstName) {
        infos += user.FirstName + ' ';
      }
      if (user.LastName) {
        infos += user.LastName + ' ';
      }

      infos += '(' + user.UserName + ')';

      tmp.set(user.Id, infos);
    }
    return tmp;
  }

  onChangeFilter() {
    this.gridHierarchy.api.setGridOption("quickFilterText", this.searchTerms);
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.gridHierarchy.api.paginationSetPageSize(pageSize);
    this.gridHierarchy.api.paginationGoToFirstPage(); // Go to first page

    if (pageSize === 10 || pageSize === 20 || pageSize === 50) {
      this.customPageSize = null;
    }
  }

  // Call when choice with input
  onChangePageSize() {
    if (this.customPageSize) {
      this.changePageSize(this.customPageSize);
    }
  }

  reload() {
    this.isLoading = true;
    this.store.dispatch(new CompanyAction.LoadHierachy());
  }
}
