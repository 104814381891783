import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuItem } from '../_models/MenuItem';

@Injectable({
  providedIn: 'root'
})
export class SideBarService {
  sibarTree: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>(undefined);

  isSideBarOpened = new BehaviorSubject<boolean>(true);

  constructor() { }

  toggle() {
    this.isSideBarOpened.next(!this.isSideBarOpened.getValue());
  }

  close() {
    this.isSideBarOpened.next(false);
  }

  open() {
    this.isSideBarOpened.next(true);
  }
}
