<on-page-title icon="fa-archive" [title]="'company.accountant-export.edit-title' | translate"></on-page-title>
<on-loading *ngIf="isLoading"></on-loading>
<div *ngIf="!isLoading">
  <form [formGroup]="exportForm" class="row">
    <div class="col-sm-6 form-group">
      <label class="label form-group">{{'company.accountant-export.name' | translate}}</label>
      <input class="form-control" formControlName="Name">
      <on-required-field-display [field]="exportForm.get('Name')"></on-required-field-display>
    </div>
    <div class="col-sm-6 form-group">
      <label class="label form-group">{{'company.accountant-export.period' | translate}}</label>
      <div class="row d-flex">
        <on-form-date-picker class="col-sm-6" formControlName="PeriodBeginDate"></on-form-date-picker>
        <on-form-date-picker class="col-sm-6" formControlName="PeriodEndDate"></on-form-date-picker>
      </div>

    </div>
    <div class="col-12 form-group">
      <label class="label">{{'company.accountant-export.comments' | translate}}</label>
      <textarea class="form-control" formControlName="Comment"></textarea>
    </div>
  </form>

  <div class="col row" *ngIf="isTreated && !isArchived && !isBeingArchived">
    <p class="col-12 text-center info-text">{{'company.accountant-export.export-treated' | translate}}</p>
  </div>

  <div class="col row" *ngIf="isArchived">
    <p class="col-12 text-center info-text">{{'company.accountant-export.export-archived' | translate}}</p>
  </div>

  <div class="col row" *ngIf="isBeingArchived">
    <p class="col-12 text-center info-text">{{'company.accountant-export.export-archiving' | translate}}</p>
  </div>

  <div class="row">
    <div class="col-12 d-flex mt-5 mb-1 p-0">
      <div class="col-6">
        <h6>{{'company.accountant-export.expenses-in' | translate: {count: includedReports.length} }}</h6>
      </div>
      <div class="col-6" align="right">
        <button class="btn btn-primary" (click)="openAddReportsToExportModal()"
          [nbTooltip]="'company.accountant-export.add-more-reports' | translate" nbTooltipStatus="primary"
          [disabled]="!canEdit">
          <nb-icon icon="fa-plus" class="mr-1"></nb-icon>
          <nb-icon icon="fa-archive"></nb-icon>
        </button>

        <button *ngIf="displayActionButtons()" class="btn btn-danger ml-2" (click)="excludeSelectedReports()"
          [nbTooltip]="'company.accountant-export.exclude-reports' | translate" nbTooltipStatus="danger"
          [disabled]="!canEdit">
          <nb-icon icon="fa-minus" class="mr-1"></nb-icon>
          <nb-icon icon="fa-archive"></nb-icon>
        </button>
      </div>
    </div>

    <div class="col-12 p-0" *ngIf="!gettingReports && export$ | async as exp">
      <on-report-list [isInVP]="exp.VPState === 'StoredInVP'" [showValidationDate]="true" [showOwner]="true"
        *ngIf="includedReports && includedReports.length" [reports]="includedReports" [canSelect]="true"
        [canEdit]="true"></on-report-list>
      <on-empty-list [message]="'company.accountant-export.no-report' | translate"
        *ngIf="!includedReports || !includedReports.length">
      </on-empty-list>
      <div class="justify-content-around col-12 row" *ngIf="canEdit">
        <div class="col text-center">
          <button class="btn btn-primary" (click)="Save()">{{'company.accountant-export.save-btn' | translate}}</button>
        </div>
        <div class="col text-center">
          <button *ngIf="canBeArchivedVP && !isNew" class="btn btn-success" (click)="archivedInProbativeValue()">
            <nb-icon icon="fa-archive"></nb-icon>&nbsp;&nbsp;
            {{'company.accountant-export.archive-btn' | translate}}
          </button>
          <button *ngIf="canEdit && !canBeArchivedVP" class="btn btn-success" (click)="markAsTreated()">
            <nb-icon icon='fa-archive'></nb-icon>&nbsp;&nbsp;
            {{'company.accountant-export.mark-as-treated-btn' | translate}}
          </button>
        </div>
      </div>
    </div>

  </div>
</div>