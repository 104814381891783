import { ReportState } from './ReportState';
import { Expense } from './Expense';
import { ISelectableIdObject } from 'on-common/_models/ISelectableIdObject';

export class Report extends ISelectableIdObject {
  Id?: number;
  Name: string;
  IsEditable?: boolean;
  CreationDate?: Date;
  NbOfExpenses?: number;
  TotalInReport?: number;
  LocalId?: number;
  UserId?: number;
  State?: ReportState;
  Comments?: string;
  ValidationComments?: string;
  Username?: string;
  ManagerId?: number;
  AffectedCompanyId?: number;
  Expenses?: Expense[];
  IsSelected?: boolean;
  AreAllReceiptsValidated?: boolean;
  ValidationDate?: Date;
  AccountantExportId?: number;
  AmountToBeReimbursed?: number;

  constructor(model?: Report) {
    super();
    if (model) {
      this.Id = model.Id || null;
      this.Name = model.Name || null;
      this.IsEditable = model.IsEditable;
      this.CreationDate = model.CreationDate;
      this.NbOfExpenses = model.NbOfExpenses || 0;
      this.TotalInReport = model.TotalInReport || 0;
      this.LocalId = model.LocalId || null;
      this.UserId = model.UserId || null;
      this.State = model.State ? new ReportState(model.State) : null;
      this.Comments = model.Comments || '';
      this.ValidationComments = model.ValidationComments || '';
      this.Username = model.Username || null;
      this.ManagerId = model.ManagerId || null;
      this.AffectedCompanyId = model.AffectedCompanyId || null;
      this.Expenses = (model.Expenses || []).map((c) => new Expense(c));
      this.IsSelected = model.IsSelected || null;
      this.AreAllReceiptsValidated = model.AreAllReceiptsValidated || false;
      this.ValidationDate = model.ValidationDate;
      this.AccountantExportId = model.AccountantExportId || null;
      this.AmountToBeReimbursed = model.AmountToBeReimbursed || 0;
    } else {
      this.Name = null;
    }
  }
}
