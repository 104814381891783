import { ReadableTVA } from './../../../../report/_models/ReadableTVA';
import { Report } from 'on-shared/_models/Report';
import { OnUser } from 'on-shared/_models/OnUser';
import { AmountService } from 'on-shared/_services/amount.service';
import { Amount } from 'on-shared/_models/Amount';
import { Subscription, Observable, Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CustomFieldValue } from 'on-shared/_models/CustomFieldValue';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  OnDestroy,
  EventEmitter,
  Output,
  SimpleChanges,
  HostListener,
  ViewChild,
} from '@angular/core';
import { CountryTaxes, ICountryTaxe } from 'on-shared/_models/taxes';
import { Store, Select } from '@ngxs/store';
import { ExpenseAction } from 'on-shared/stores/expense/expense.actions';
import { Expense } from 'on-shared/_models/Expense';
import { CompanyState } from 'on-shared/stores/company/company.state';
import { Company } from 'on-shared/_models/Company';
import { CompanyAction } from 'on-shared/stores/company/company.action';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { UserState } from 'on-shared/stores/user/user.state';
import { VehiclesActions } from 'on-shared/stores/vehicles/vehicles.actions';
import { Vehicle } from 'on-shared/_models/Vehicle';
import { VehiclesState } from 'on-shared/stores/vehicles/vehicles.state';
import { ExpenseService } from 'on-shared/stores/expense/expense.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MapModalComponent } from '../map-modal/map-modal.component';
import * as moment_ from 'moment';
import { VehiclesService } from 'on-shared/stores/vehicles/vehicles.service';
import { Currency } from 'on-common/_models/Currency';
import { CountryService } from 'on-common/_services/country.service';
import { ToastService } from 'on-common/_services/toast.service';
import { CompanyService } from 'on-shared/stores/company/company.service';

const moment = moment_;

@Component({
  selector: 'on-edit-form',
  templateUrl: './edit-form.component.html',
  styleUrls: ['./edit-form.component.scss'],
})
export class EditFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() expense: Expense;
  @Input() isNew = false;
  @Input() report: Report;
  @Output() editionFinished = new EventEmitter<boolean>();

  @Output() isLoadingChange = new EventEmitter<boolean>();

  company: Company;

  vehicles: Vehicle[];
  selectedVehicleId: number;
  needVehicule: boolean;
  hasUsableVehicle: boolean;
  needCarRate: boolean;
  carRate: number;

  kilometersFree: boolean; // True if type isn't home to work
  nbJourneyHomeToWork: number;

  resultMileageTotal: Observable<any> = null;
  subjectMileageTotal = new Subject<void>();

  isLoadingTotal = false; // Used for mileage expenses

  @Select(CompanyState.current) company$: Observable<Company>;
  @Select(UserState.currentUser) currentUser$: Observable<OnUser>;
  @Select(VehiclesState.user) vehicles$: Observable<Vehicle[]>;
  destroy$ = new Subject<void>();

  connectedOnUser: OnUser;

  allCustomFields: CustomFieldValue[];

  receiptForm: UntypedFormGroup;

  tvas: ReadableTVA[];

  isKilometer: boolean;
  shouldContinueAfterEditing = true;

  userSubscription: Subscription;
  isDifferentCurrency = false;
  referenceCurrency: Currency;

  // Indicated if the user tried to validate this form (used for required dynamic fields)
  isValidated: boolean;

  // Manager validation
  rejectClicked = false;

  // Indicates if the interface should recalculate the tva fields
  isAutoCalculationActivated = true;

  // Date of conversion rate
  conversionRateDate: Date;
  conversionRateWarning = false;

  searchTVAArray: any[] = [];

  incoherencyDetected: boolean;
  nbOfIncoherentLines: number;

  @ViewChild('informations') informations;
  @ViewChild('taxes') taxes;
  @ViewChild('dynamicFields') dynamicFields;

  openingMatrix: boolean[] = [true, false, false];
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const relatedElement = event.target as HTMLElement;
    if (relatedElement.id === 'rate' || relatedElement.id === 'currency') {
      this.informations.open();
      if (this.taxes) {
        this.taxes.close();
      }
      if (this.dynamicFields) {
        this.dynamicFields.close();
      }
    } else if (relatedElement.id === 'userComment' && event.key === 'Tab') {
      this.informations.close();
      if (this.taxes) {
        this.taxes.open();
      }
      if (this.dynamicFields) {
        this.dynamicFields.close();
      }
    } else if (relatedElement.id === 'addTaxe' && this.dynamicFields) {
      this.informations.close();
      if (this.taxes) {
        this.taxes.close();
      }
      if (this.dynamicFields) {
        this.dynamicFields.open();
      }
    }
  }

  constructor(
    private companyService: CompanyService,
    private fb: UntypedFormBuilder,
    private amountService: AmountService,
    private countryService: CountryService,
    private store: Store,
    private expenseService: ExpenseService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private modal: NgbModal,
    private vehiclesService: VehiclesService
  ) { }

  ngOnInit() {
    this.store.dispatch(new CompanyAction.LoadCompaniesIfNeeded());

    this.isKilometer = this.expense.ExpenseType.Code === 'MGE';

    this.store.dispatch(new VehiclesActions.LoadCurrentUserIfNeeded());

    this.company$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      if (response) {
        this.company = response;
        this.needVehicule = this.company.Settings.UseMileageScale !== 'CustomUserRate';
        this.needCarRate = this.company.Settings.UseMileageScale === 'CustomUserRate';
      }
    });

    this.receiptForm = this.fb.group({
      country: this.fb.control(null, Validators.required),
      currency: this.fb.control(null, Validators.required),
    });

    this.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.connectedOnUser = user;
      if (this.isNew) {
        if (user && user.CustomFields) {
          this.allCustomFields = user.CustomFields.map((val, index) => {
            return {
              ExpenseId: this.expense.Id,
              Field: val,
              Value: '',
            } as CustomFieldValue;
          });
          this.carRate = user.MileageRatio;
        } else {
          this.allCustomFields = [];
        }
      } else {
        this.expense.TVAAmounts.map(tvaAmount => {
          if (this.tvas.filter(tva => tva.Value === tvaAmount.TVA).length === 0) {
            this.tvas.push({ Readable: (tvaAmount.TVA * 100).toString(), Value: tvaAmount.TVA });
          }
        });
      }

      this.currencyChanged(false);

      this.conversionRateDate = this.expense.DateOnExpense;
      this.updateWarning();

      this.UpdateCustomFields();
      this.autoSelectCustomFields();

      this.getVehicles();

      if (this.isKilometer) {
        this.updateMileageFields();
      }

    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.expense && this.expense) {
      this.changeCountry(false);
      this.restoreCarRate();
      this.currencyChanged(false);
      this.isLoadingChange.emit(false);
      if (this.expense) {
        this.allCustomFields = [...this.expense.DynamicFieldsValues];
        this.checkCoherency();
      }
    }
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  getVehicles() {
    if (this.isNew || this.connectedOnUser.Id === this.expense.UserId) {
      this.vehicles$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
        if (response) {
          this.vehicles = response;
          this.hasUsableVehicle = this.vehicles.filter(v => !v.disabled).length > 0;

          if (!this.isNew) {
            this.selectedVehicleId = this.expense.VehicleId ?? 0;
          } else {
            for (const vehicle of this.vehicles) {
              if (!vehicle.disabled) {
                this.selectedVehicleId = vehicle.Id;
                this.expense.VehicleId = vehicle.Id;
                break;
              }
            }
          }
        }
      });
    } else {
      this.vehiclesService.GetByUserId(this.expense.UserId).subscribe(response => {
        if (response.IsSuccess) {
          this.vehicles = response.Result;
          this.hasUsableVehicle = this.vehicles.filter(v => !v.disabled).length > 0 && !this.expense.VehicleId;

          if (!this.isNew) {
            this.selectedVehicleId = this.expense.VehicleId ?? 0;
          } else {
            for (const vehicle of this.vehicles) {
              if (!vehicle.disabled) {
                this.selectedVehicleId = vehicle.Id;
                this.expense.VehicleId = vehicle.Id;
                break;
              }
            }
          }
        } else {
          this.toastService.error(
            this.translateService.instant('vehicle.loading-failed'),
            this.translateService.instant('vehicle.loading-failed-title'),
          );
        }
      }, error => {
        this.toastService.error(
          this.translateService.instant('vehicle.loading-failed'),
          this.translateService.instant('vehicle.loading-failed-title'),
        );
      });
    }
  }

  currencyChanged(getRatio: boolean) {
    if (this.expense && this.connectedOnUser) {
      this.isDifferentCurrency =
        this.connectedOnUser.Currency.ISOCode !== this.expense.Currency.ISOCode;
      this.referenceCurrency = this.connectedOnUser.Currency;
      if (!this.isDifferentCurrency) {
        if (this.expense.ConversionRatio !== 1) {
          this.resetAmountToBeReimbursed();
        }
        this.expense.ConversionRatio = 1;
      } else if (getRatio) {
        this.getCurrencyRate(false);
      }
    }
  }

  checkCoherency() {
    this.incoherencyDetected = false;
    this.nbOfIncoherentLines = 0;
    if (!this.expense || this.expense.IsPerDiem) {
      return;
    }

    if (!this.expense.GlobalAmount.calculateIsCoherent()) {
      this.incoherencyDetected = true;
    }

    for (const am of this.expense.TVAAmounts) {
      if (!am.calculateIsCoherent()) {
        this.nbOfIncoherentLines += 1;
        this.incoherencyDetected = true;
      }
    }
  }

  getCurrencyRate(applyImmediately: boolean = false) {
    this.countryService
      .getConversionRatioAt(
        this.expense.Currency.ISOCode,
        this.referenceCurrency.ISOCode,
        this.expense.DateOnExpense,
      )
      .subscribe((response) => {
        const initialRateValue = this.expense.ConversionRatio;
        if (response.IsSuccess) {
          // If apply immediately, change the values.
          if (applyImmediately) {
            this.expense.ConversionRatio = response.Result;
            this.conversionRateDate = this.expense.DateOnExpense;
            this.updateWarning();
          } else {
            this.conversionRateDate = null;
            this.conversionRateWarning = this.expense.ConversionRatio !== response.Result;
            if (!this.conversionRateWarning) {
              this.conversionRateDate = this.expense.DateOnExpense;
            }
          }
        } else {
          this.expense.ConversionRatio = 1;
        }

        // If the rate has changed, update the amount to be reimbursed
        if (initialRateValue !== this.expense.ConversionRatio) {
          this.resetAmountToBeReimbursed();
        }
      });
  }

  addRate() {
    // Find the first TVA % that is not yet used in the interface to select it in the new amount line
    let tvaToPropose = { Value: 0 };
    for (let i = 0; i < this.tvas.length; i++) {
      tvaToPropose = this.tvas[i];
      let isAlreadyUsed = false;
      for (let j = 0; j < this.expense.TVAAmounts.length; j++) {
        if (this.expense.TVAAmounts[j].TVA === tvaToPropose.Value) {
          isAlreadyUsed = true;
        }
      }

      if (!isAlreadyUsed) {
        break;
      }
    }

    let remainingTtc = this.expense.GlobalAmount.TtcOrigin;

    for (let i = 0; i < this.expense.TVAAmounts.length; i++) {
      remainingTtc -= this.expense.TVAAmounts[i].TtcOrigin;
    }

    remainingTtc = +Math.max(remainingTtc, 0).toFixed(2);

    const amountToCreate = new Amount({
      IsTotal: false,
      TtcOrigin: remainingTtc,
      HtOrigin: 0,
      TaxesOrigin: 0,
      TVA: tvaToPropose.Value,
    });
    if (this.isAutoCalculationActivated) {
      this.amountService.UpdateAmountFromTTC(
        tvaToPropose.Value,
        amountToCreate,
      );
    }
    this.expense.TVAAmounts.push(amountToCreate);
    this.amountService.UpdateGlobalAmountFromTVAs(
      this.expense.TVAAmounts,
      this.expense.GlobalAmount,
    );
    this.checkCoherency();
  }

  deleteRate(i) {
    this.expense.TVAAmounts.splice(i, 1);
    this.checkCoherency();
  }

  TotalTtcChanged() {
    this.RecalculateAllTaxes();
  }

  RecalculateAllTaxes() {
    const tvaToUse =
      this.expense.TVAAmounts.length > 0
        ? this.expense.TVAAmounts[0].TVA
        : this.tvas[0].Value;
    const amount: Amount = new Amount({
      IsTotal: false,
      TtcOrigin: this.expense.GlobalAmount.TtcOrigin,
      HtOrigin: 0,
      TaxesOrigin: 0,
      TVA: tvaToUse,
    });
    this.amountService.UpdateAmountFromTTC(tvaToUse, amount);
    this.expense.TVAAmounts = [amount];
    this.amountService.UpdateGlobalAmountFromTVAs(
      this.expense.TVAAmounts,
      this.expense.GlobalAmount,
    );
    this.resetAmountToBeReimbursed();
    this.checkCoherency();
  }

  resetAmountToBeReimbursed() {
    this.expense.GlobalAmount.Ttc = this.expense.GlobalAmount.TtcOrigin * this.expense.ConversionRatio;
    this.expense.AmountToBeReimbursed = this.expense.GlobalAmount.Ttc;
  }

  UpdateTaxesWithHT(changedHtIndex: number) {
    let remainingTtc = +this.expense.GlobalAmount.TtcOrigin;

    if (this.isAutoCalculationActivated) {
      for (let i = 0; i < this.expense.TVAAmounts.length; i++) {
        if (
          this.expense.TVAAmounts[i].IsEnteredByUser &&
          i !== changedHtIndex
        ) {
          remainingTtc -= this.expense.TVAAmounts[i].TtcOrigin;
        }
      }

      const amountToUpdate = this.expense.TVAAmounts[changedHtIndex];
      this.amountService.UpdateAmountFromHT(amountToUpdate.TVA, amountToUpdate);
      amountToUpdate.IsEnteredByUser = true;
      this.expense.TVAAmounts[changedHtIndex] = amountToUpdate;
      remainingTtc -= amountToUpdate.TtcOrigin;

      for (let i = 0; i < this.expense.TVAAmounts.length; i++) {
        if (!this.expense.TVAAmounts[i].IsEnteredByUser) {
          this.expense.TVAAmounts[i].TtcOrigin = +remainingTtc.toFixed(2);
          this.amountService.UpdateAmountFromTTC(
            this.expense.TVAAmounts[i].TVA,
            this.expense.TVAAmounts[i],
          );
          remainingTtc = 0;
          break;
        }
      }
    }

    this.amountService.UpdateGlobalAmountFromTVAs(
      this.expense.TVAAmounts,
      this.expense.GlobalAmount,
    );
    this.checkCoherency();
  }

  UpdateTaxesWithTaxes(changedTaxesIndex: number) {
    let remainingTtc = +this.expense.GlobalAmount.TtcOrigin;

    if (this.isAutoCalculationActivated) {
      for (let i = 0; i < this.expense.TVAAmounts.length; i++) {
        if (
          this.expense.TVAAmounts[i].IsEnteredByUser &&
          i !== changedTaxesIndex
        ) {
          remainingTtc -= this.expense.TVAAmounts[i].TtcOrigin;
        }
      }

      const amountToUpdate = this.expense.TVAAmounts[changedTaxesIndex];
      this.amountService.UpdateAmountFromTaxes(
        amountToUpdate.TVA,
        amountToUpdate,
      );
      amountToUpdate.IsEnteredByUser = true;
      this.expense.TVAAmounts[changedTaxesIndex] = amountToUpdate;
      remainingTtc -= amountToUpdate.TtcOrigin;

      for (let i = 0; i < this.expense.TVAAmounts.length; i++) {
        if (!this.expense.TVAAmounts[i].IsEnteredByUser) {
          this.expense.TVAAmounts[i].TtcOrigin = +remainingTtc.toFixed(2);
          this.amountService.UpdateAmountFromTTC(
            this.expense.TVAAmounts[i].TVA,
            this.expense.TVAAmounts[i],
          );
          remainingTtc = 0;
          break;
        }
      }
    }

    this.amountService.UpdateGlobalAmountFromTVAs(
      this.expense.TVAAmounts,
      this.expense.GlobalAmount,
    );
    this.checkCoherency();
  }

  UpdateTaxesWithTTC(changedTTCIndex: number) {
    let remainingTtc = +this.expense.GlobalAmount.TtcOrigin;

    if (this.isAutoCalculationActivated) {
      for (let i = 0; i < this.expense.TVAAmounts.length; i++) {
        if (
          this.expense.TVAAmounts[i].IsEnteredByUser &&
          i !== changedTTCIndex
        ) {
          remainingTtc -= this.expense.TVAAmounts[i].TtcOrigin;
        }
      }

      const amountToUpdate = this.expense.TVAAmounts[changedTTCIndex];
      this.amountService.UpdateAmountFromTTC(
        amountToUpdate.TVA,
        amountToUpdate,
      );
      amountToUpdate.IsEnteredByUser = true;
      this.expense.TVAAmounts[changedTTCIndex] = amountToUpdate;
      remainingTtc -= amountToUpdate.TtcOrigin;

      for (let i = 0; i < this.expense.TVAAmounts.length; i++) {
        if (!this.expense.TVAAmounts[i].IsEnteredByUser) {
          this.expense.TVAAmounts[i].TtcOrigin = +remainingTtc.toFixed(2);
          this.amountService.UpdateAmountFromTTC(
            this.expense.TVAAmounts[i].TVA,
            this.expense.TVAAmounts[i],
          );
          remainingTtc = 0;
          break;
        }
      }
    }

    this.amountService.UpdateGlobalAmountFromTVAs(
      this.expense.TVAAmounts,
      this.expense.GlobalAmount,
    );
    this.checkCoherency();
  }

  UpdateTaxesWithTVA(index: number) {
    let remainingTtc = +this.expense.GlobalAmount.TtcOrigin;
    if (this.isAutoCalculationActivated) {
      for (let i = 0; i < this.expense.TVAAmounts.length; i++) {
        if (this.expense.TVAAmounts[i].TtcOrigin !== 0 && i !== index) {
          remainingTtc -= this.expense.TVAAmounts[i].TtcOrigin;
        }
      }

      const amountToUpdate = this.expense.TVAAmounts[index];
      amountToUpdate.TtcOrigin = +remainingTtc.toFixed(2);
      amountToUpdate.IsEnteredByUser = false;
      this.amountService.UpdateAmountFromTTC(
        amountToUpdate.TVA,
        amountToUpdate,
      );
      this.expense.TVAAmounts[index] = amountToUpdate;
    }

    this.amountService.UpdateGlobalAmountFromTVAs(
      this.expense.TVAAmounts,
      this.expense.GlobalAmount,
    );
    this.checkCoherency();

  }

  changeCountry(recalculateTaxes = true) {
    const foundTaxes = CountryTaxes.filter((countryTaxe) => {
      if (countryTaxe.code === this.expense.Country.ISOCode) {
        return true;
      }
    });

    const foundTaxe: ICountryTaxe = foundTaxes?.length ? foundTaxes[0] : { taxes: [0], code: this.expense.Country.ISOCode }

    const tempTvas = foundTaxe.taxes.map((t) => t / 100);
    this.tvas = [];
    tempTvas.map((tva) => {
      this.tvas.push({ Readable: (tva * 100).toString(), Value: tva });
    });

    if (recalculateTaxes) {
      this.expense.TVAAmounts = [new Amount(
        {
          TtcOrigin: 0,
          HtOrigin: 0,
          TaxesOrigin: 0,
          TVA: this.tvas[0].Value,
          IsTotal: false,
        }),
      ];

      this.RecalculateAllTaxes();
    }
  }

  doFieldValidation() {
    if (!this.checkDate()) {
      return;
    }
    this.isValidated = true;
    // Check all required custom fields are filled up
    const nonFilledFields = this.expense.DynamicFieldsValues.filter(
      (df) => df.Field.IsRequired && !df.Value,
    );

    if (nonFilledFields.length) {
      for (const f of nonFilledFields) {
        f.Field.HasRequiredError = true;
      }

      return false;
    }

    return true;
  }

  Send() {
    if (!this.doFieldValidation()) {
      return;
    }

    if (!this.isNew) {
      this.isLoadingChange.emit(true);
      this.store.dispatch(
        new ExpenseAction.EditExpense(this.expense, () => {
          this.isLoadingChange.emit(false);
          this.editionFinished.emit(this.shouldContinueAfterEditing);
        }),
      );
    } else {
      // For mileage
      if (this.expense.ExpenseType.Code === 'MGE' && this.expense.VehicleId) {
        // Verification vehicle
        const vehicle = this.vehicles.find(v => v.Id === this.expense.VehicleId);

        if (vehicle.disabled) {
          this.toastService.error(
            this.translateService.instant('vehicle.is-disabled-error'),
            this.translateService.instant('vehicle.is-disabled-error-title'),
          );

          return;
        }
      }

      this.isLoadingChange.emit(true);
      this.store
        .dispatch(new ExpenseAction.CreateManualExpense(this.expense))
        .subscribe(() => {
          this.isLoadingChange.emit(false);
        });
    }
  }

  recalculateKmTotal() {
    if (this.expense.ExpenseType.Code === 'MGE') {
      this.expense.ConversionRatio = 1;
      this.expense.Currency = this.referenceCurrency;
      this.expense.GlobalAmount.TtcOrigin = +(
        this.expense.Distance * this.carRate
      ).toFixed(2);
    }
  }

  calculateDistanceFromJourney() {
    this.expense.Distance = this.connectedOnUser.DistanceHomeToWork * this.expense.NbJourneyHomeToWork;

    this.calculateMileageTotal();
  }

  calculateMileageTotal() {
    if (!this.isKilometer) {
      this.isLoadingTotal = false;
      return;
    }

    if (this.company.Settings.UseMileageScale !== 'CustomUserRate' && this.selectedVehicleId) {
      this.expense.VehicleId = this.selectedVehicleId;
      if (isNaN(this.expense.Distance) || !this.expense.Distance) {
        this.isLoadingTotal = false;
        return;
      }

      this.isLoadingTotal = true;

      if (this.kilometersFree) {
        this.expense.NbJourneyHomeToWork = 0;
      }

      if (!this.resultMileageTotal) {
        this.resultMileageTotal = this.subjectMileageTotal.pipe(debounceTime(1000));
        this.resultMileageTotal.subscribe(() => {
          this.getTotalForMileageExpense();
        });
      }

      if (!this.kilometersFree) {
        this.expense.Distance = this.connectedOnUser.DistanceHomeToWork * this.expense.NbJourneyHomeToWork;
      }

      this.subjectMileageTotal.next();
    } else {
      this.recalculateKmTotal();
    }
  }

  getTotalForMileageExpense() {
    if (!this.expense.Distance) {
      this.expense.GlobalAmount.TtcOrigin = 0;
      return;
    }

    if (!this.checkDate()) {
      this.isLoadingTotal = false;
      return;
    }

    this.expenseService.GetTotalMileageExpense(this.expense).subscribe(response => {
      if (response.IsSuccess) {
        this.expense.GlobalAmount.TtcOrigin = response.Result;
      } else {
        this.toastService.error(
          this.translateService.instant('vehicle.calcul-total-failed'),
          this.translateService.instant('vehicle.is-disabled-error-title'),
        );
      }
      this.isLoadingTotal = false;
    }, error => {
      this.toastService.error(
        this.translateService.instant('vehicle.calcul-total-failed'),
        this.translateService.instant('vehicle.is-disabled-error-title'),
      );

      this.isLoadingTotal = false;
    });
  }

  restoreCarRate() {
    if (this.expense.ExpenseType.Code === 'MGE') {
      this.carRate = this.expense.Distance
        ? +(
          this.expense.GlobalAmount.TtcOrigin / this.expense.Distance
        ).toFixed(2)
        : 0;
    }
  }

  UpdateCustomFields() {
    this.expense.DynamicFieldsValues = [];
    for (let i = 0; i < this.allCustomFields.length; i++) {
      const fieldValue = this.allCustomFields[i];

      if (
        !fieldValue.Field.ExpenseType ||
        !fieldValue.Field.ExpenseType.Id ||
        fieldValue.Field.ExpenseType.Id === this.expense.ExpenseType.Id
      ) {
        this.expense.DynamicFieldsValues.push(fieldValue);
      }
    }
  }

  autoSelectCustomFields() {
    if (!this.allCustomFields) {
      return;
    }

    for (const customField of this.allCustomFields) {
      if (customField.Field.Type === 'list') {
        // Check if the value still exists
        if (
          customField.Value &&
          !customField.Field.PossibleValues.includes(customField.Value)
        ) {
          customField.Field.PossibleValues = [customField.Value, ...customField.Field.PossibleValues];
        }
        // If no Value and not nullable and only one value
        if (
          !customField.Value &&
          customField.Field.IsRequired &&
          customField.Field.PossibleValues.length === 1
        ) {
          customField.Value = customField.Field.PossibleValues[0];
        }
      }
    }
  }

  saveExpense() {
    this.isLoadingChange.emit(true);

    this.store.dispatch(
      new ExpenseAction.EditExpense(this.expense, () => {
        this.isLoadingChange.emit(false);
      }),
    );
  }

  validationClick() {
    if (!this.doFieldValidation()) {
      return;
    }

    this.rejectClicked = false;
    this.isLoadingChange.emit(true);

    if (this.company.ManagerCanEdit) {
      this.store.dispatch(
        new ExpenseAction.EditExpense(this.expense, () => {
          this.doValidation();
          this.isLoadingChange.emit(false);
        }),
      );
    } else {
      this.doValidation();
    }
  }

  doValidation() {
    this.store.dispatch(
      new ExpenseAction.ValidateExpense(this.expense, () => {
        this.editionFinished.emit(this.shouldContinueAfterEditing);
      }),
    );
  }

  rejectionClick() {
    this.rejectClicked = true;
    if (
      !!this.expense.ValidationComment &&
      this.expense.ValidationComment.length > 0
    ) {
      if (this.company.ManagerCanEdit) {
        this.isLoadingChange.emit(true);

        this.store.dispatch(
          new ExpenseAction.EditExpense(this.expense, () => {
            this.doReject();
            this.isLoadingChange.emit(false);
          }),
        );
      } else {
        this.doReject();
      }
    }
  }

  doReject() {
    this.store.dispatch(
      new ExpenseAction.RejectExpense(this.expense, () => {
        this.rejectClicked = false;
        this.editionFinished.emit(this.shouldContinueAfterEditing);
      }),
    );
  }

  isExpenseOrSimpleUserSave() {
    return (
      (this.expense.IsEditable && !this.report) ||
      (this.expense.IsEditable &&
        this.connectedOnUser &&
        this.report &&
        this.report.State.Code !== 'VAL' &&
        this.report.State.Code !== 'SENT' &&
        this.report.State.Code !== 'ARCHIVED')
    )
      && (
        this.expense.ExpenseType.Code !== 'MGE' || !this.needVehicule
        || this.vehicles && this.vehicles.length
      );
  }

  isReportAndIsManagerValidation() {
    return (
      this.connectedOnUser &&
      this.report &&
      this.report.ManagerId &&
      this.report.ManagerId === this.connectedOnUser.Id &&
      this.report.State.Code === 'SENT'
    );
  }

  searchTVA(search: { term: string; items: any[] }, index: number) {
    const searchTerm = +search.term;
    if (
      search.items.length === 0 &&
      searchTerm &&
      typeof searchTerm === 'number'
    ) {
      this.searchTVAArray[index] = {
        Readable: searchTerm,
        Value: searchTerm / 100,
        Index: index,
      };
      let searchValueIndex = null;
      this.tvas.map((tva, i) => {
        if (tva.Index === index) {
          searchValueIndex = i;
        }
      });
      if (typeof searchValueIndex === 'number' && searchValueIndex >= 0) {
        this.tvas[searchValueIndex] = { ...this.searchTVAArray[index] };
      } else {
        this.tvas.push(this.searchTVAArray[index]);
      }
      // * Need imutable syntax to display list changes
      this.tvas = [...this.tvas];
    }
  }

  checkDate(): boolean {
    if (!this.expense.DateOnExpense) {
      document.getElementById('inputDate').style.borderColor = 'red';
      document.getElementById('dateErrorMessage').style.visibility = 'visible';

      return false;
    } else {
      const date = new Date(this.expense.DateOnExpense);
      const dateMin = new Date('01/01/2000');

      // Verification valid date
      if (!moment(date).isValid() || date.getTime() < dateMin.getTime()) {
        document.getElementById('inputDate').style.borderColor = 'red';
        document.getElementById('dateErrorMessage').style.visibility = 'visible';
        return false;
      }

      document.getElementById('inputDate').style.borderColor = null;
      document.getElementById('dateErrorMessage').style.visibility = 'hidden';

      return true;
    }
  }

  updateWarning() {
    const expenseDateTmp = new Date(this.expense.DateOnExpense);
    expenseDateTmp.setHours(0, 0, 0, 0);
    const conversionRateDateTmp = new Date(this.conversionRateDate);
    conversionRateDateTmp.setHours(0, 0, 0, 0);

    // Conversion rate
    if (conversionRateDateTmp.getTime() !== expenseDateTmp.getTime()) {
      this.conversionRateWarning = true;
    } else {
      this.conversionRateWarning = false;
    }
  }

  updateMileageFields() {
    this.kilometersFree = this.expense.NbJourneyHomeToWork ? false : true;
  }

  openMapModal() {
    const modal = this.modal.open(MapModalComponent, { size: 'lg', centered: true, windowClass: 'full-width' });

    modal.result.then(routeInfo => {
      if (routeInfo) {
        this.expense.Distance = routeInfo.distance;
        this.expense.StartPlace = routeInfo.start;
        this.expense.EndPlace = routeInfo.end;
        this.calculateMileageTotal();
      }
    });
  }

  toReimburseChanged() {
    if (this.expense.PaidWithPersonalAccount && !this.expense.IsPerDiem) {
      this.expense.AmountToBeReimbursed = this.expense.GlobalAmount.Ttc;
    } else {
      this.expense.AmountToBeReimbursed = 0;
    }
  }

  perDiemChanged() {
    this.toReimburseChanged();
    this.checkCoherency();
  }

  currencyRatioChanged() {
    this.resetAmountToBeReimbursed();
  }
}
