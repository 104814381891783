import { PasswordRecoverPageComponent } from './password-recover-page/password-recover-page.component';
import { PasswordRecoverFormComponent } from './password-recover-page/password-recover-form/password-recover-form.component';
import { ResetPasswordPageComponent } from './reset-password-page/reset-password-page.component';
import { RegisterPageComponent } from './register-page/register-page.component';
import { RegisterFormComponent } from './register-page/register-form/register-form.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoginPageComponent } from './login-page/login-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalSignInPageComponent } from './external-sign-in-page/external-sign-in-page.component';
import { LoginFormComponent } from './login-page/login-form/login-form.component';
import { RouterModule } from '@angular/router';
import { OnCommonModule } from 'on-common/on-common.module';
import { ExternalSigninErrorPageComponent } from './external-signin-error-page/external-signin-error-page.component';

@NgModule({
  declarations: [
    ExternalSignInPageComponent,
    LoginPageComponent,
    LoginFormComponent,
    RegisterFormComponent,
    RegisterPageComponent,
    ResetPasswordPageComponent,
    PasswordRecoverFormComponent,
    PasswordRecoverPageComponent,
    ExternalSigninErrorPageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RouterModule,
    OnCommonModule
  ],
  exports: [
    ExternalSignInPageComponent,
    LoginPageComponent,
    RegisterPageComponent,
    ResetPasswordPageComponent,
    PasswordRecoverPageComponent
  ]
})
export class OnAuthModule { }
