import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AccountService } from 'on-auth/_services/account.service';

@Component({
  selector: 'on-kick-gridcell',
  templateUrl: './kick-gridcell.component.html',
  styleUrls: ['./kick-gridcell.component.scss']
})
export class KickGridcellComponent implements ICellRendererAngularComp {

  params: any;

  constructor(public accountService: AccountService) { }


  onKick(event) {
    this.params.context.parent.kickEmployee(this.params.data);
  }


  /*** Implements ***/
  refresh(params: any): boolean {
    return false;
  }

  agInit(params: import('ag-grid-community').ICellRendererParams): void {
    this.params = params;
  }

  afterGuiAttached?(params?: import('ag-grid-community').IAfterGuiAttachedParams): void {
  }

}
