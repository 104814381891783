import { debounceTime } from 'rxjs/operators';
import { Currency, CurrencyGroupEnum } from '../../_models/Currency';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, forwardRef, EventEmitter, Output, HostListener } from '@angular/core';
import { CountryService } from './../../_services/country.service';

@Component({
  selector: 'on-currency-select',
  templateUrl: './currency-select.component.html',
  styleUrls: ['./currency-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencySelectComponent),
      multi: true
    }
  ]
})
export class CurrencySelectComponent implements OnInit, ControlValueAccessor {

  @Output() currencyChange = new EventEmitter<Currency>();

  allCurrencies: Currency[];
  primaryCurrencies: Currency[];
  listCurrencies: Currency[];
  otherCurrencies: Currency[];
  selectedCurrency: Currency;

  isDisabled: boolean;
  onChanged: any;
  onTouched: any;

  initialValue: Currency;

  constructor(private countryService: CountryService) { }

  ngOnInit() {
    this.countryService.getCurrencies().subscribe(currencies => {
      currencies.map(currency => {
        switch (currency.ISOCode) {
          case 'USD':
            currency.group = CurrencyGroupEnum.PRIMARY;
            break;
          case 'EUR':
            currency.group = CurrencyGroupEnum.PRIMARY;
            break;
          case 'GBP':
            currency.group = CurrencyGroupEnum.PRIMARY;
            break;
          case 'CHF':
            currency.group = CurrencyGroupEnum.PRIMARY;
            break;
          case null:
            currency.group = CurrencyGroupEnum.OTHER;
            break;
          default:
            currency.group = CurrencyGroupEnum.LIST;
            break;
        }
      });

      const top = currencies.filter(currency => currency.group === CurrencyGroupEnum.PRIMARY);
      const middle = currencies.filter(currency => currency.group === CurrencyGroupEnum.LIST);
      const last = currencies.filter(currency => currency.group === CurrencyGroupEnum.OTHER);
      this.allCurrencies = [
        ...top,
        ...middle,
        ...last
      ];
      if (this.initialValue) {
        this.writeValue(this.allCurrencies.find(c => c.ISOCode === this.initialValue.ISOCode));
      }
    });
  }

  compareCurrency(currency1: Currency, currency2: Currency) {
    return currency1 && currency2 && currency1.ISOCode === currency2.ISOCode;
  }

  writeValue(obj: any): void {
    if (obj && this.allCurrencies) {
      this.selectedCurrency = this.allCurrencies.find(c => c.ISOCode === obj.ISOCode);
      this.currencyChanged();
    } else {
      this.initialValue = obj;
      this.selectedCurrency = undefined;
    }
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;

    if (this.selectedCurrency) {
      this.onChanged(this.selectedCurrency);
    }
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  currencyChanged() {
    if (!this.selectedCurrency) {
      return;
    }

    if (this.onChanged) {
      this.onChanged(this.selectedCurrency);
    }
    if (this.onTouched) {
      this.onTouched();
    }
    this.currencyChange.emit(this.selectedCurrency);
  }

  customCurrencySearch(term: string, item: Currency) {
    if (!item) {
      return false;
    }
    term = term.toLowerCase();
    return item.Name.toLowerCase().indexOf(term) > -1 || item.Symbol.toLowerCase() === term;
  }
}
