import { Component, OnInit } from '@angular/core';
import { CheckoutModel } from '../_models/CheckoutModel';
import { UpdateSubscriptionDataKey } from '../constants';
import { Router } from '@angular/router';
import { PaymentService } from '../_services/payment.service';
import { StripeSubscription } from '../_models/StripeSubscription';
import { Store } from '@ngxs/store';
import { CompanyAction } from '../../shared/stores/company/company.action';
import { ToastService } from 'on-common/_services/toast.service';

@Component({
  selector: 'on-update-subscription',
  templateUrl: './update-subscription.component.html',
  styleUrls: ['./update-subscription.component.scss']
})
export class UpdateSubscriptionComponent implements OnInit {

  checkoutData: CheckoutModel;

  originalSubscription: StripeSubscription;
  destinationSubscription: StripeSubscription;

  prorationValue: number;

  isLoading = false;

  constructor(
    private router: Router,
    private paymentService: PaymentService,
    private toastService: ToastService,
    private store: Store
  ) { }

  ngOnInit(): void {
    const checkoutDataString = sessionStorage.getItem(UpdateSubscriptionDataKey);
    if (!checkoutDataString) {
      this.router.navigate(['/company', 'licenses']);
      return;
    } else {
      this.checkoutData = JSON.parse(checkoutDataString) as CheckoutModel;
      if (!this.checkoutData.price
        || !this.checkoutData.product
        || !this.checkoutData.nbOfLicenses
        || !this.checkoutData.originalNbOfLicenses
        || !this.checkoutData.originalPrice
        || !this.checkoutData.originalProduct
        || !this.checkoutData.originalRenewingDate) {
        this.router.navigate(['/company', 'licenses']);
        return;
      }
    }
    this.isLoading = true;

    this.destinationSubscription = new StripeSubscription({
      Quantity: this.checkoutData.nbOfLicenses,
      RecurringPrice: this.checkoutData.price,
      Id: null,
      CustomerId: null,
      LatestInvoice: null,
      NextRenewalDate: this.calculateDestinationRenewDate(),
      StartDate: null,
      Status: null
    });
    this.destinationSubscription.RecurringPrice.Product = this.checkoutData.product;

    this.originalSubscription = new StripeSubscription({
      Quantity: this.checkoutData.originalNbOfLicenses,
      RecurringPrice: this.checkoutData.originalPrice,
      Id: null,
      CustomerId: null,
      LatestInvoice: null,
      NextRenewalDate: this.checkoutData.originalRenewingDate,
      StartDate: null,
      Status: null
    });
    this.originalSubscription.RecurringPrice.Product = this.checkoutData.originalProduct;

    this.paymentService.GetUpdateProration(this.checkoutData.price.Id, this.checkoutData.nbOfLicenses).subscribe((response) => {
      if (response.IsSuccess) {
        this.prorationValue = response.Result;
      }
      this.isLoading = false;
    });
  }

  calculateDestinationRenewDate(): Date {
    let finalDate = new Date(this.checkoutData.originalRenewingDate);
    switch (this.checkoutData.originalPrice.Interval) {
      case 'month':
        finalDate.setMonth(finalDate.getMonth() - 1);
        break;
      case 'year':
        finalDate.setFullYear(finalDate.getFullYear() - 1);
        break;
      default:
        return finalDate;
    }

    switch (this.checkoutData.price.Interval) {
      case 'month':
        finalDate.setMonth(finalDate.getMonth() + 1);
        break;
      case 'year':
        finalDate.setFullYear(finalDate.getFullYear() + 1);
        break;
      default:
        return finalDate;
    }

    return finalDate;
  }

  applySubscriptionUpdate(): void {
    this.isLoading = true;
    this.paymentService.UpdateSubscription(this.checkoutData.price.Id, this.checkoutData.nbOfLicenses).subscribe(response => {
      if (response.IsSuccess) {
        sessionStorage.removeItem(UpdateSubscriptionDataKey);
        this.toastService.success(
          'La demande de modification de votre abonnement a été effectuée, elle sera effective d\'ici quelques minutes',
          'Abonnement modifié');
        this.store.dispatch(CompanyAction.ShouldReloadEmployees);
        setTimeout(() => {
          this.router.navigate(['/company', 'licenses']);
        }, 5000);
      } else {
        this.isLoading = true;
        this.toastService.error(
          'Une erreur est intervenue lors de la modification de votre abonnement, merci de contacter le support',
          'Erreur sur votre abonnement');
      }
    });
  }
}
