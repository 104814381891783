import { Vehicle } from '../../_models/Vehicle';

export namespace VehiclesActions {

  export class LoadCurrentUser {
    public static readonly type = `[Vehicles] load current user's vehicles`;
    constructor() {}
  }

  export class LoadCompany {
    public static readonly type = `[Vehicles] load company's vehicles`;
    constructor(public companyId: number) {}
  }

  export class Create {
    public static readonly type = `[Vehicles] create a vehicle`;
    constructor(public vehicleToCreate: Vehicle) {}
  }

  export class Update {
    public static readonly type = `[Vehicles] update a vehicle`;
    constructor(public vehicleToUpdate: Vehicle) {}
  }

  export class Delete {
    public static readonly type = `[Vehicles] delete a vehicle`;
    constructor(public vehicleToDelete: Vehicle) {}
  }

  export class LoadCurrentUserIfNeeded {
    public static readonly type = `[Vehicles] load current user's vehicles if needed`;
    constructor() {}
  }

  export class LoadCompanyIfNeeded {
    public static readonly type = `[Vehicles] load company's vehicles if needed`;
    constructor(public companyId: number) {}
  }
}
