
  <div class="notification-banner row col-12">
    <div class="notification-banner-content" [ngClass]="notification.CanClose ? 'col-11' : 'col-12'">
        <span *ngIf="!notification.RouterLink && !notification.Url" [innerHTML]="notification.Message"></span>
        <a *ngIf="!!notification.RouterLink && !notification.Url" [routerLink]="notification.RouterLink" [innerHTML]="notification.Message"></a>
        <a *ngIf="!notification.RouterLink && !!notification.Url" [href]="notification.Url" [innerHTML]="notification.Message"></a>
    </div>

    <div *ngIf="notification.CanClose" class="col-1 d-flex align-items-center justify-content-end">
        <button nbButton ghost (click)="closeBanner()">
          <nb-icon icon="fa-times" class="notification-close-button"></nb-icon>
        </button>
    </div>
  </div>
