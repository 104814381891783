<div class="card shadow" *ngIf="subscription && !isLoading">
    <h4 class="subscription-title">{{'payment.stripe-sub-summary-card.subscription' | translate}}
        {{subscription.RecurringPrice.Product.Name}}<br>
        <span class="licenses" *ngIf="!subscription.RecurringPrice.IsFloating">{{subscription.Quantity}}
            {{'payment.stripe-sub-summary-card.licenses' | translate}}</span>
        <span class="licenses" *ngIf="subscription.RecurringPrice.IsFloating">
            {{'payment.floating-licenses' | translate}}</span>
    </h4><br>
    <span *ngIf="subscription.NextRenewalDate">{{'payment.stripe-sub-summary-card.auto-renew' | translate}}:
        {{subscription.NextRenewalDate | date}}</span>
    <span *ngIf="!subscription.RecurringPrice.IsFloating">{{'payment.stripe-sub-summary-card.price-per-user' | translate}}: {{subscription.RecurringPrice.Price |
        number:'1.2-2'}}€
        {{'payment.stripe-sub-summary-card.every' | translate}}
        {{subscription.RecurringPrice.IntervalCount != 1 ? subscription.RecurringPrice.IntervalCount: ''}}
        {{subscription.RecurringPrice.Interval | translate}}</span>
    <br>
    <span  *ngIf="!subscription.RecurringPrice.IsFloating">{{'payment.stripe-sub-summary-card.total-price' | translate}}:
        <strong>{{(subscription.RecurringPrice.Price * subscription.Quantity) | number:'1.2-2'}}€</strong>
        {{'payment.stripe-sub-summary-card.every' | translate}}
        {{subscription.RecurringPrice.IntervalCount != 1 ? subscription.RecurringPrice.IntervalCount: ''}}
        {{subscription.RecurringPrice.Interval | translate}}</span>
    <br>
</div>

<on-loading *ngIf="isLoading"></on-loading>
