export class EmployeeCategory {
    Id: number;
    CompanyId: number;
    Name: string;
    IsDefault: boolean;
  
    constructor(model?: EmployeeCategory) {
      if (model) {
        this.Id = model.Id;
        this.CompanyId = model.CompanyId;
        this.Name = model.Name;
        this.IsDefault = model.IsDefault;
      } else {
        this.Id = null;
        this.CompanyId = null;
        this.Name = null;
        this.IsDefault = null;
      }
    }
  }
  