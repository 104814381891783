import { Report } from './../../../shared/_models/Report';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { ReportsActions } from '../../../shared/stores/reports/reports.actions';
import { ReportsState } from '../../../shared/stores/reports/reports.state';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'on-reports-signed',
  templateUrl: './reports-signed.component.html',
  styleUrls: ['./reports-signed.component.scss'],
})
export class ReportsSignedComponent implements OnInit, OnDestroy {

  isLoading = true;

  reportsSigned: Report[];

  @Select(ReportsState.getSignedReportList) reportsSigned$: Observable<Report[]>;
  destroy$ = new Subject<void>();

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(new ReportsActions.IfSignedReportListNeeded());

    this.reportsSigned$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response) {
        this.reportsSigned = response.map(rep => new Report(rep));
        this.isLoading = false;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  reload() {
    this.isLoading = true;
    this.store.dispatch(new ReportsActions.GetSignedReports());
  }
}
