import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'on-connect-as-cell',
  templateUrl: './connect-as-cell.component.html',
  styleUrls: ['./connect-as-cell.component.scss']
})
export class ConnectAsCellComponent implements ICellRendererAngularComp {

  params: any;

  constructor() { }

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
  }

  afterGuiAttached?(params?: import('ag-grid-community').IAfterGuiAttachedParams): void {
  }

  openConnectAs() {
    this.params.context.parentList.openConnectAs(this.params.node.data);
  }
}
