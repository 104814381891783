import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { forwardRef, OnInit, Input, Component, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ExpenseType } from '../../../shared/_models/ExpenseType';
import { ExpenseState } from '../../../shared/stores/expense/expense.state';
import { ExpenseAction } from '../../../shared/stores/expense/expense.actions';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { Store, Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Mark expenses category to be translated
marker('db.expense-categories.MISC');
marker('db.expense-categories.REST');
marker('db.expense-categories.SVC');
marker('db.expense-categories.CAR');
marker('db.expense-categories.HOST');
marker('db.expense-categories.TSP');
marker('db.expense-categories.LOC');
marker('db.expense-categories.BUY');

// Mark expenses code to be translated
marker('db.expense-types.MISC');
marker('db.expense-types.REST');
marker('db.expense-types.PGE');
marker('db.expense-types.PKG');
marker('db.expense-types.HTL');
marker('db.expense-types.HOST');
marker('db.expense-types.GAS');
marker('db.expense-types.TAXI');
marker('db.expense-types.FURN');
marker('db.expense-types.TSP');
marker('db.expense-types.SVC');
marker('db.expense-types.MGE');
marker('db.expense-types.PST');
marker('db.expense-types.RESTINVIT');
marker('db.expense-types.DINER');
marker('db.expense-types.BKFST');
marker('db.expense-types.ALIM');
marker('db.expense-types.TELET');
marker('db.expense-types.TRAINING');
marker('db.expense-types.BANK');
marker('db.expense-types.LOC');
marker('db.expense-types.LOCSOFT');
marker('db.expense-types.LOCCAR');
marker('db.expense-types.LOCWEB');
marker('db.expense-types.LOCPHONE');
marker('db.expense-types.LOCHARD');
marker('db.expense-types.BUY');
marker('db.expense-types.BUYSOFT');
marker('db.expense-types.BUYHARD');
marker('db.expense-types.TRAIN');
marker('db.expense-types.PLANE');
marker('db.expense-types.BOAT');
marker('db.expense-types.CADCL');
marker('db.expense-types.CARCARE');
marker('db.expense-types.SAM');


@Component({
  selector: 'on-expense-type-select',
  templateUrl: './expense-type-select.component.html',
  styleUrls: ['./expense-type-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ExpenseTypeSelectComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class ExpenseTypeSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {

  allTypes: ExpenseType[];
  selectedType: ExpenseType;
  @Input() isMultiple: boolean;
  @Input() allowAll = false;

  isDisabled: boolean;

  onChanged: any;
  onTouched: any;

  initialValue: ExpenseType;

  nullType: ExpenseType = {
    Id: 0,
    Code: '',
    Name: '',
    CategoryCode: '',
    CategoryId: 0,
    CategoryName: '',
    Icon: 'receipt'
  };

  @Select(ExpenseState.expenseTypes) expenseTypes$: Observable<ExpenseType[]>;
  destroy$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private store: Store
  ) {
    this.nullType.Name = this.translateService.instant('expense.all-expense-type');
  }

  ngOnInit() {
    if (this.isMultiple) {
      throw Error('Is multiple is not implemented anymore, need to be reimplemented');
    }

    this.store.dispatch(new ExpenseAction.LoadExpenseTypesIfNeeded());

    this.expenseTypes$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response) {

        if (this.allowAll) {
          this.allTypes = [
            this.nullType
            , ...response];
          if (!this.initialValue || !this.initialValue.Id) {
            this.initialValue = this.nullType;
          }
        } else {
          this.allTypes = response.filter(t => t.Code !== 'MGE'); // All types excepted mileage
        }

        if (this.initialValue) {
          this.writeValue(this.initialValue);
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(obj: any): void {
    if (obj && this.allTypes) {
      this.selectedType = this.allTypes.find(t => t.Id === obj.Id);
    } else {
      this.initialValue = obj;

      this.selectedType = this.nullType;
    }
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  compareExpenseType(expensesTypes1: ExpenseType, expensesTypes2: ExpenseType) {
    return expensesTypes1 && expensesTypes2 && expensesTypes1.Code === expensesTypes2.Code;
  }

  expenseTypeChanged() {
    let toUpdate = this.selectedType;
    if (this.selectedType === this.nullType) {
      toUpdate = null;
    }
    if (this.onChanged) {
      this.onChanged(toUpdate);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  searchInCategoryAndName(term: string, item: ExpenseType) {
    term = term.toLowerCase();
    return item.CategoryName.toLowerCase().indexOf(term) > -1 || item.Name.toLowerCase().indexOf(term) > -1;
  }
}
