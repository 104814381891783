import { TranslateService } from '@ngx-translate/core';
import { TopBarService } from '../_services/top-bar.service';
import {
  Component,
  OnInit,
  OnDestroy,
  ComponentFactoryResolver,
  ViewContainerRef,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SideBarService } from '../_services/side-bar.service';
import { Language } from 'on-common/_models/Language';

@Component({
  selector: 'on-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit, OnDestroy {
  pushed: boolean;
  sidebarSub: Subscription;
  routeSub: Subscription;
  @ViewChild('leftComponent', { read: ViewContainerRef, static: true })
  leftContainerRef: ViewContainerRef;
  @ViewChild('middleComponent', { read: ViewContainerRef, static: true })
  middleContainerRef: ViewContainerRef;
  @ViewChild('rightComponent', { read: ViewContainerRef, static: true })
  rightContainerRef: ViewContainerRef;
  languages: Language[] = [];

  currentLang: string;

  constructor(
    private sidebarService: SideBarService,
    private activatedRoute: ActivatedRoute,
    private factoryResolver: ComponentFactoryResolver,
    private topBarService: TopBarService,
    public translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.sidebarSub = this.sidebarService.isSideBarOpened.subscribe(
      isOpened => {
        this.pushed = isOpened;
      },
    );
    this.routeSub = this.activatedRoute.data.subscribe(data => {

      const leftFactory = this.factoryResolver.resolveComponentFactory(
        data.leftComponentToDisplay,
      );
      const leftComponent = leftFactory.create(
        this.leftContainerRef.parentInjector,
      );
      this.leftContainerRef.insert(leftComponent.hostView);

      const middleFactory = this.factoryResolver.resolveComponentFactory(
        data.middleComponentToDisplay,
      );
      const middleComponent = middleFactory.create(
        this.middleContainerRef.parentInjector,
      );
      this.middleContainerRef.insert(middleComponent.hostView);

      const rightFactory = this.factoryResolver.resolveComponentFactory(
        data.rightComponentToDisplay,
      );
      const rightComponent = rightFactory.create(
        this.middleContainerRef.parentInjector,
      );
      this.rightContainerRef.insert(rightComponent.hostView);

      this.languages = data.languagesAvailable;
    });
  }

  ngOnDestroy() {
    this.sidebarSub.unsubscribe();
  }

  toggleSideMenu() {
    this.sidebarService.toggle();
  }

  selectLang(code: string) {
    this.topBarService.setUsedLanguage(code);
  }
}
