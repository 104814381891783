<div class="card">
  <div class="card-body">
    <h4 class="card-title">Erreur de connection à votre compte Microsoft</h4>
    <br>
    <br>
    <div class="d-flex justify-content-around align-items-center">
      <img width="50" src="/assets/images/ms-logo.png" />
      <i class="fa fa-ellipsis-h fa-2x"></i>
      <img width="50" src="/assets/logo.png" />
    </div>
    <br>
    <p>
      Une erreur rend l'association de votre compte Microsoft avec ONexpense impossible.
      Vous pouvez nous écrire sur l'adresse e-mail du support <a
        href="mailto:support@onexpense.com">support&#64;onexpense.com</a> si vous avez besoin d'aide pour vous connecter.
    </p>
    <br>
    <div class="text-center">
      <a class="btn btn-primary" routerLink="/login">Retour à l'accueil</a>
    </div>
  </div>
</div>
