import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from "@angular/core";

@Injectable()
export class DatepickerStringAdapter implements NgbDateAdapter<string> {
    fromModel(value: string): NgbDateStruct {
        if (!value || value === '') {
            return null;
        }
        const date = new Date(value);
        return {
            day: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear()
        };
    }
    toModel(date: NgbDateStruct): string {
        if (!date) {
            return null;
        }
        const localDate = new Date(date.year, date.month - 1, date.day);
        const userTimezoneOffset = localDate.getTimezoneOffset() * 60000;
        return new Date(localDate.getTime() - userTimezoneOffset).toISOString();
    }
}
