import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, } from '@angular/core';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { OnUser } from 'on-shared/_models/OnUser';
import { Observable } from 'rxjs';
import { Company } from 'on-shared/_models/Company';
import { CompanyState } from 'on-shared/stores/company/company.state';
import { Select, Store } from '@ngxs/store';
import { UserActions } from 'on-shared/stores/user/user.actions';
import { UserState } from 'on-shared/stores/user/user.state';
import { ToastService } from 'on-common/_services/toast.service';
import { DialogService } from 'on-common/_services/dialog.service';
import { environment } from 'projects/onexpense/src/environments/environment';
import { AccountService } from 'on-auth/_services/account.service';

@Component({
  selector: 'on-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss'],
})
export class AccountPageComponent implements OnInit {
  isRenewUrgent?: boolean;

  isChangingPassword = false;
  oldPassword: string;
  newPassword: string;
  confirmationPassword: string;

  appName = environment.appName;

  canLinkMicrosoftAccount = false;
  isLinkedToMicrosoftAccount = false;
  apiToken: string;

  @Select(CompanyState.current) company$: Observable<Company>;
  @Select(UserState.currentUser) user$: Observable<OnUser>;

  constructor(
    public store: Store,
    private toastService: ToastService,
    private router: Router,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private accountService: AccountService
  ) { }

  ngOnInit() {
    this.user$.subscribe(user => {
      this.canLinkMicrosoftAccount = user.License.ProductTypeCode === 'ENT';
      this.isLinkedToMicrosoftAccount = user.IsLinkedToMicrosoftAccount;
      this.apiToken = this.accountService.getToken();
    });
  }

  deleteAccount() {
    swal
      .fire({
        title: this.translateService.instant('account.swarl-title'),
        text: this.translateService.instant('account.swarl-text'),
        cancelButtonText: this.translateService.instant(
          'account.swarl-cancel-btn',
        ),
        confirmButtonText: this.translateService.instant(
          'account.swarl-confirm-btn',
        ),
        customClass: {
          confirmButton: 'btn btn-danger w-100 mb-4',
          cancelButton: 'btn btn-secondary w-100'
        },
        allowEnterKey: false,
        focusCancel: true,
        buttonsStyling: false,
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          this.dialogService.ShowLoading();
          this.store.dispatch(new UserActions.DeleteAccount()).subscribe(
            (state) => {
              if (state.isSuccess) {
                this.router.navigate(['/login']);
              }
              this.dialogService.CloseLoading();
            },
            () => {
              this.toastService.error(
                this.translateService.instant('account.swarl-error-message'),
              );
              this.dialogService.CloseLoading();
            },
          );
        }
      });
  }

  changePassword() {
    this.isChangingPassword = true;
    this.store
      .dispatch(
        new UserActions.ChangePassword(
          this.oldPassword,
          this.newPassword,
          this.confirmationPassword,
        ),
      )
      .subscribe(
        (state) => {
          if (state.isSuccess) {
            this.oldPassword = '';
            this.newPassword = '';
            this.confirmationPassword = '';
          }
          this.isChangingPassword = false;
        },
        (error) => {
          this.toastService.error(
            this.translateService.instant('account.password-change-error'),
          );
          this.isChangingPassword = false;
        },
      );
  }
}
