<on-page-title [title]="'account.my-parameters' | translate" icon="fa-cog"></on-page-title>

<on-loading *ngIf="isLoading"></on-loading>

<div *ngIf="!isLoading">
  <ul ngbNav #settingsNav="ngbNav" class="settings-tabs nav-tabs">
    <li ngbNavItem="settings-tab">
      <a ngbNavLink>{{'account.settings-title' | translate}}</a>
      <ng-template ngbNavContent>
        <div class="col alert alert-warning mt-3" *ngIf="!!currentUser.CompanyId">
          {{'account.company-profil' | translate}}
        </div>
        <on-global-settings [user]="currentUser" [disabled]="!!currentUser.CompanyId"> </on-global-settings>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="settingsNav" class="mt-3"></div>
</div>