export interface IAmount {
  Id?: number;
  IsTotal: boolean;
  Ttc?: number;
  TtcOrigin: number;
  TtcConfidence?: number;
  Ht?: number;
  HtOrigin: number;
  HtConfidence?: number;
  Taxes?: number;
  TaxesOrigin: number;
  TaxesConfidence?: number;
  TVA: number;
  TVAConfidence?: number;
  IsEnteredByUser?: boolean;
  AmountToBeReimboursed?: number;
}

export class Amount implements IAmount {
  Id?: number;
  IsTotal: boolean;
  Ttc?: number;
  TtcOrigin: number;
  TtcConfidence?: number;
  Ht?: number;
  HtOrigin: number;
  HtConfidence?: number;
  Taxes?: number;
  TaxesOrigin: number;
  TaxesConfidence?: number;
  TVA: number;
  TVAConfidence?: number;
  IsEnteredByUser?: boolean;
  AmountToBeReimboursed?: number;

  // Used only on the interface
  IsCoherent: boolean;

  constructor(model?: IAmount) {
    if (model) {
      this.Id = model.Id;
      this.IsTotal = model.IsTotal;
      this.Ttc = model.Ttc;
      this.TtcOrigin = model.TtcOrigin;
      this.TtcConfidence = model.TtcConfidence;
      this.Ht = model.Ht;
      this.HtOrigin = model.HtOrigin;
      this.HtConfidence = model.HtConfidence;
      this.Taxes = model.Taxes;
      this.TaxesOrigin = model.TaxesOrigin;
      this.TaxesConfidence = model.TaxesConfidence;
      this.TVA = model.TVA;
      this.TVAConfidence = model.TVAConfidence;
      this.IsEnteredByUser = model.IsEnteredByUser;
      this.AmountToBeReimboursed = model.AmountToBeReimboursed;
    } else {
      this.Id = null;
      this.IsTotal = false;
      this.Ttc = null;
      this.TtcOrigin = null;
      this.TtcConfidence = null;
      this.Ht = null;
      this.HtOrigin = null;
      this.HtConfidence = null;
      this.Taxes = null;
      this.TaxesOrigin = null;
      this.TaxesConfidence = null;
      this.TVA = null;
      this.TVAConfidence = null;
      this.IsEnteredByUser = false;
      this.AmountToBeReimboursed = null;
    }
  }

  public calculateIsCoherent(): boolean {
    this.IsCoherent = Math.abs((this.HtOrigin + this.TaxesOrigin) - this.TtcOrigin) < 0.01;

    return this.IsCoherent;
  }
}
