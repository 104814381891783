import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { CompanyAction } from 'projects/onexpense/src/app/shared/stores/company/company.action';

@Component({
  selector: 'on-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss'],
})
export class AddEmployeeComponent implements OnInit {
  employeesText: string;
  incorrectEmails: string[];

  isLoading = false;

  constructor(private activeModal: NgbActiveModal, private store: Store) {}

  ngOnInit() {}

  addEmployees() {
    if (this.employeesText == null || this.employeesText.length === 0) {
      this.activeModal.dismiss();
      return;
    }

    this.incorrectEmails = [];
    // Do not take ampty lines
    const emails = this.employeesText
      .split('\n')
      .filter((email) => email && email.trim() !== '');

    for (const email of emails) {
      if (!this.validateEmail(email)) {
        this.incorrectEmails.push(email);
      }
    }

    if (this.incorrectEmails.length > 0) {
      return;
    }

    this.isLoading = true;
    this.store.dispatch(new CompanyAction.AddEmployees(emails));
    this.activeModal.close();
  }

  private validateEmail(email: string): boolean {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
}
