import { Expense } from 'on-shared/_models/Expense';
import { Amount } from './../_models/Amount';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AmountService {

  constructor() { }


  static $inject: string[] = [];


  CalculateHT(ttc: number, tva: number): number {
    return Math.abs(+ (+ttc / (1 + +tva)).toFixed(2));
  }
  CalculateHT2(taxes: number, tva: number): number {
    return Math.abs(+(+taxes / +tva).toFixed(2));
  }

  CalculateTaxes(ht: number, tva: number): number {
    return Math.abs(+(+ht * +tva).toFixed(2));
  }

  CalculateTTC(ht: number, tva: number): number {
    return Math.abs(+(+ht + +ht * +tva).toFixed(2));
  }

  UpdateAmountFromTTC(tva: number, amountToEdit: Amount) {
    amountToEdit.TaxesOrigin = parseFloat(amountToEdit.TaxesOrigin.toString());
    const ht = this.CalculateHT(amountToEdit.TtcOrigin, tva);
    amountToEdit.IsTotal = false;
    amountToEdit.HtOrigin = ht;
    amountToEdit.TVA = tva;
    amountToEdit.TaxesOrigin = this.CalculateTaxes(ht, tva);
  }

  UpdateAmountFromHT(tva: number, amountToEdit: Amount) {
    amountToEdit.HtOrigin = parseFloat(amountToEdit.HtOrigin.toString());
    const ttc = this.CalculateTTC(amountToEdit.HtOrigin, tva);
    amountToEdit.IsTotal = false;
    amountToEdit.TtcOrigin = ttc;
    amountToEdit.TVA = tva;
    amountToEdit.TaxesOrigin = this.CalculateTaxes(amountToEdit.HtOrigin, tva);
  }

  UpdateAmountFromTaxes(tva: number, amountToEdit: Amount) {
    amountToEdit.TaxesOrigin = parseFloat(amountToEdit.TaxesOrigin.toString());
    const ht = this.CalculateHT2(amountToEdit.TaxesOrigin, tva);
    amountToEdit.IsTotal = false;
    amountToEdit.TtcOrigin = this.CalculateTTC(ht, tva);
    amountToEdit.HtOrigin = ht;
    amountToEdit.TVA = tva;
  }

  UpdateGlobalAmountFromTVAs(tvaAmounts: Amount[], amountToEdit: Amount) {
    amountToEdit.IsTotal = true;
    amountToEdit.HtOrigin = this.GetGlobalHt(tvaAmounts);
    amountToEdit.TaxesOrigin = this.GetGlobalTaxes(tvaAmounts);
    amountToEdit.TVA = 0;
  }

  // Private Functions
  private GetGlobalHt(tvaAmounts: Amount[]) {
    return tvaAmounts.reduce((pv: number, cv: Amount, currVal, arr) => +pv + +cv.HtOrigin, 0);
  }

  private GetGlobalTaxes(tvaAmounts: Amount[]) {
    return tvaAmounts.reduce((pv: number, cv: Amount, currVal, arr) => +pv + +cv.TaxesOrigin, 0);
  }
}
