<div class="row" (click)="$event.stopPropagation()">
    <span *ngIf="!!params.data.tooltipMessage">
        <button class="btn btn-sm btn-danger" (click)="onChange($event)" [disabled]="params.data.disabled">
          <nb-icon icon="fa-trash"></nb-icon>
        </button>
    </span>
    <span *ngIf="!params.data.tooltipMessage" >
      <button class="btn btn-sm btn-danger" (click)="onChange($event)" [disabled]="params.data.disabled">
        <nb-icon icon="fa-trash"></nb-icon>
      </button>
    </span>

</div>

