export class ExpenseState {
  Id: number;
  Code: string;
  Name: string;

  constructor(model?: ExpenseState) {
    if (model) {
      this.Id = model.Id;
      this.Code = model.Code;
      this.Name = model.Name;
    } else {
      this.Id = null;
      this.Code = null;
      this.Name = null;
    }
  }
}
