import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ListExpertedCompaniesPageComponent } from '../list-experted-companies-page.component';

@Component({
  selector: 'on-change-activable-licenses-cell',
  templateUrl: './change-activable-licenses-cell.component.html',
  styleUrls: ['./change-activable-licenses-cell.component.scss']
})
export class ChangeActivableLicensesCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams & { parentList: ListExpertedCompaniesPageComponent };

  initialValue: number;
  currentValue: number;

  isEditing = false;

  constructor() {
  }

  refresh(params: ICellRendererParams & { parentList: ListExpertedCompaniesPageComponent }): boolean {
    this.params = params;
    this.initialValue = params.value;
    this.currentValue = this.initialValue;
    return true;
  }

  agInit(params: ICellRendererParams & { parentList: ListExpertedCompaniesPageComponent }): void {
    this.params = params;
    this.initialValue = params.value;
    this.currentValue = this.initialValue;
  }

  afterGuiAttached?(params?: import('ag-grid-community').IAfterGuiAttachedParams): void {
  }

  update() {
    this.params.context.parentList.updateActivableLicense(this.params.node.data, this.currentValue);
    this.initialValue = this.currentValue;
    this.isEditing = false;
  }

  cancelEdit() {
    this.currentValue = this.initialValue;
    this.isEditing = false;
  }
}
