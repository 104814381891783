import { Component, OnInit } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'on-manager-celleditor',
  templateUrl: './manager-celleditor.component.html',
  styleUrls: ['./manager-celleditor.component.scss']
})
export class ManagerCelleditorComponent implements ICellEditorAngularComp {

  params: any;
  allEmployees: any;

  width: string;

  agInit(params: any): void {

    this.width = params.column.actualWidth + 'px';

    this.params = params;
    this.allEmployees = params.context.parent.allEmployees;
  }


  getValue(): any {
    return this.params.context.parent.allManagersInfos.get(this.params.data.ManagerId);
  }

  isPopup(): boolean {
      return true;
  }

  changeManager(user: any) {
    this.params.context.parent.changeManager(user);

    this.params.api.stopEditing();
  }

}
