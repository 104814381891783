<div class="row edit-page" *ngIf="!isLoading" [ngClass]="{hide: editFormHidden}">
  <div class="col-sm-5 zoomable-image" *ngIf="expenseToEdit && expenseToEdit.ExpenseType.Code !== 'MGE'">
    <ngx-image-viewer *ngIf="expenseToEdit?.ImageUrls?.length" [config]="imageConfig" [src]="expenseToEdit?.ImageUrls">
    </ngx-image-viewer>
    <!--For retro-compatibility -->
    <ngx-image-viewer *ngIf="!expenseToEdit?.ImageUrls?.length && expenseToEdit?.ImageUrl" [config]="imageConfig" [src]="[expenseToEdit.ImageUrl]">
    </ngx-image-viewer>
    <!-- <img [src]="expenseToEdit.ThumbUrl" class="expense-img" *ngIf="expenseToEdit.ThumbUrl"> -->
  </div>
  <div
    [ngClass]="{'col-sm-7':expenseToEdit.ExpenseType.Code !== 'MGE', 'col-sm': expenseToEdit.ExpenseType.Code === 'MGE'}">
    <on-edit-form [isNew]="isNew" (editionFinished)="editionFinishedListener($event)" [expense]="expenseToEdit" (isLoadingChange)="setEditFormHidden($event)"
      [report]="report"></on-edit-form>
  </div>
</div>

<div class="row" *ngIf="isLoading || editFormHidden">
  <div class="col-12 text-center">
    <on-loading></on-loading>
  </div>
</div>
