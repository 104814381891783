import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/onexpense/src/environments/environment';


@Component({
  selector: 'on-share-page',
  templateUrl: './share-page.component.html',
  styleUrls: ['./share-page.component.scss']
})
export class SharePageComponent implements OnInit {

  closeResult: string;
  // modal: NgbModalRef;

  appName = environment.appName;

  constructor() {
  }

  ngOnInit() {
  }
}
