import { Company } from '../../_models/Company';
import { OnUser } from '../../_models/OnUser';
import { CustomField } from '../../_models/CustomField';
import { EmployeeCategory } from 'on-shared/_models/EmployeeCategory';

// tslint:disable-next-line: no-namespace
export namespace CompanyAction {

  export class LoadCompanies {
    public static readonly type = '[Company] load companies for the current user';
    constructor() { }
  }

  export class LoadCompaniesIfNeeded {
    public static readonly type = '[Company] load companies for the current user if needed';
    constructor() { }
  }

  export class SaveAuthorizationsChanges {
    public static readonly type = '[Company] change authorizations of the company';
    constructor(public payload: Company) { }
  }
  
  export class SaveRestaurantTicket {
    public static readonly type = '[Company] save restaurant ticket configuration of the company';
    constructor(public company: Company) { }
  }

  export class UpdateMileageSettings {
    public static readonly type = '[Company] update the mileage settings of the company';
    constructor(public toUpdateCompany: Company) { }
  }

  export class Delete {
    public static readonly type = '[Company] delete the company';
    constructor() { }
  }

  export class LoadEmployees {
    public static readonly type = '[Company] get employees of the current company';
    constructor() { }
  }

  export class LoadEmployeesIfNeeded {
    public static readonly type = '[Company] get employees of the current company if needed';
    constructor() { }
  }

  export class AddEmployees {
    public static readonly type = '[Company] add employees in the company';
    constructor(public emails: string[]) { }
  }

  export class CancelInvitation {
    public static readonly type = '[Company] cancel an invitation to the company';
    constructor(public email: string) { }
  }

  export class KickEmployee {
    public static readonly type = '[Company] kick an employee from the company';
    constructor(public employee: OnUser, public newManagerId?: number) { }
  }

  export class AssignSubscription {
    public static readonly type = '[Company] assign a subscription to an employee';
    constructor(public userId: number, public shouldApply: boolean) { }
  }

  export class ChangeEmployeeRole {
    public static readonly type = '[Company] change the role of an employee';
    constructor(public companyId: number, public userId: number, public role: string) { }
  }

  export class UpdateEmployee {
    public static readonly type = '[Company] update the settings of an employee';
    constructor(public employee: OnUser) { }
  }

  export class LoadHierachy {
    public static readonly type = '[Company] get hierarchy of the current company';
    constructor() { }
  }

  export class LoadHierachyIfNeeded {
    public static readonly type = '[Company] get hierarchy of the current company if needed';
    constructor() { }
  }

  export class UpdateHierarchy {
    public static readonly type = '[Company] update hierarchy of an employee';
    constructor(public employeeId: number, public managerId: number, public companyId: number) { }
  }

  export class SaveHierarchy {
    public static readonly type = '[Company] update hierarchy of all employees';
    constructor(public allUsers: OnUser[]) { }
  }

  export class LoadCustomFields {
    public static readonly type = '[Company] get custom fields of the current company';
    constructor() { }
  }

  export class LoadDomainNames {
    public static readonly type = '[Company] load company\'s domain names';
    constructor() { }
  }

  export class RequestDomainName {
    public static readonly type = '[Company] request to add a domain name to the company';
    constructor(public domainName: string) { }
  }

  export class LoadCustomFieldsIfNeeded {
    public static readonly type = '[Company] get custom fields of the current company if needed';
    constructor() { }
  }

  export class SaveCustomFields {
    public static readonly type = '[Company] save custom fields of the current company';
    constructor(public fieldsToSave: CustomField[]) { }
  }

  export class SetCompanySelected {
    public static readonly type = '[Company] change the current company selected';
    constructor(public companyId: number) { }
  }

  export class ShouldReloadEmployees {
    public static readonly type = '[Company] mark company data as needing reloading';
  }

  export class LoadEmployeeCategories {
    public static readonly type = '[Company] get employee categories of the current company';
    constructor() { }
  }

  export class LoadEmployeeCategoriesIfNeeded {
    public static readonly type = '[Company] get employee categories of the current company if needed';
    constructor() { }
  }

  export class AddEmployeeCategory {
    public static readonly type = '[Company] add employee category of the current company';
    constructor(public employeeCategory: EmployeeCategory) { }
  }

  export class UpdateEmployeeCategory {
    public static readonly type = '[Company] update employee category of the current company';
    constructor(public employeeCategory: EmployeeCategory) { }
  }

  export class DeleteEmployeeCategory {
    public static readonly type = '[Company] delete employee category of the current company';
    constructor(public employeeCategory: EmployeeCategory) { }
  }
}
