import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';


@Component({
  selector: 'on-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnChanges {

  @Input() title: string;
  @Input() icon: string;

  @Input() showBackButton = true;

  @Input() showReloadButton = false;
  @Output() reload = new EventEmitter();

  firstWord: string;
  restOfTitle: string;

  constructor(private location: Location) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.title) {
      this.refreshTitle();
    }
  }

  refreshTitle() {
    const splittedTitle = this.title.toLowerCase().split(' ');
    if (splittedTitle.length > 0) {
      this.firstWord = splittedTitle[0];
      if (this.title.length > this.firstWord.length + 1) {
        this.restOfTitle = this.title.substring(this.firstWord.length + 1, this.title.length);
      }
    }
  }

  back() {
    this.location.back();
  }

  reloadEvent() {
    this.reload.emit();
  }
}
