import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { confirmationPasswordValidator } from './../../_directives/confirmPassword.directive';
import { IRegisterModel } from './../../_models/RegisterModel';
import { environment } from 'projects/onexpense/src/environments/environment';

@Component({
  selector: 'on-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
})
export class RegisterFormComponent implements OnInit {
  @Input() email: string;
  @Input() defaultCountryISOCode = environment.defaultCountry;
  @Input() defaultCurrencyISOCode = environment.defaultCurrency;

  @Output() registerRequested = new EventEmitter<IRegisterModel>();

  constructor(private fb: UntypedFormBuilder, public route: ActivatedRoute) { }

  registerForm: UntypedFormGroup;

  ngOnInit() {
    this.registerForm = this.fb.group(
      {
        UserName: this.fb.control(
          {
            value: this.email ? this.email : '',
            disabled: this.email !== undefined,
          },
          Validators.compose([Validators.required, Validators.email]),
        ),
        Password: ['', Validators.required],
        ConfirmPassword: ['', Validators.required],
        Country: [
          {
            ISOCode: this.defaultCountryISOCode
          },
          Validators.required,
        ],
        Currency: [
          { ISOCode: this.defaultCurrencyISOCode },
          Validators.required,
        ],
        cgu: [false, Validators.requiredTrue],
      },
      {
        validator: confirmationPasswordValidator,
      },
    );
  }

  Register() {
    if (this.registerForm.valid) {
      const registermodel: IRegisterModel = Object.assign(
        {},
        this.registerForm.value,
      );
      if (!registermodel.UserName && this.email) {
        registermodel.UserName = this.email;
      }
      registermodel.LanguageCode = registermodel.Country.PrettyCode.substr(
        0,
        2,
      );

      this.registerRequested.emit(registermodel);
    } else {
      for (const key in this.registerForm.controls) {
        if (this.registerForm.controls[key].disabled) {
          continue;
        }

        if (this.registerForm.controls[key]) {
          this.registerForm.controls[key].markAsTouched();
        }
      }
    }
  }
}
