import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountPageComponent } from './account-page/account-page.component';
import { UserSettingsPageComponent } from './user-settings-page/user-settings-page.component';


const routes: Routes = [
  { path: '', component: AccountPageComponent },
  { path: 'mysettings', component: UserSettingsPageComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
