export class Vehicle {

  Id: number;
  Name: string;
  Type: Vehicle.Type;
  Energy: Vehicle.Energy;
  Category: Vehicle.Category;
  FiscalHorsePower: number;

  UserId: number;
  CompanyId: number;

  KmsWithCurrentUser: number;

  disabled: boolean;

  constructor(model?: Vehicle) {
    if(model) {
      this.Id = model.Id;
      this.Name = model.Name;
      this.Type = model.Type;
      this.Energy = model.Energy;
      this.Category = model.Category;
      this.FiscalHorsePower = model.FiscalHorsePower;

      this.UserId = model.UserId;
      this.CompanyId = model.CompanyId;

      this.KmsWithCurrentUser = model.KmsWithCurrentUser;

      this.disabled = model.disabled;
    } else {
      this.Id = 0;
      this.Name = null;
      this.Type = Vehicle.Type.Auto;
      this.Energy = Vehicle.Energy.Diesel;
      this.Category = Vehicle.Category.Tourism;
      this.FiscalHorsePower = 5;

      this.UserId = 0;
      this.CompanyId = 0;

      this.KmsWithCurrentUser = 0;

      this.disabled = false;
    }
  }
}

export namespace Vehicle {

  export enum Type {
    Auto = 1, //"Automobile",
    Moto = 2, //"Moto",
    Scooter = 3, //"Scooter",
    Bicycle = 4 //"Bicycle"
  }

  export enum Energy {
    Diesel = 1, //"Diesel",
    Essence = 2, //"Essence",
    Hybrid = 3, //"Hybrid",
    Electric = 4, //"Electric",
    Hydrogen = 5 //"Hydrogen"
  }

  export enum Category {
    Tourism = 1, //"Tourism"
    Commercial = 2 //"Commercial"
  }

}
