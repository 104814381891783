import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Company } from 'on-shared/_models/Company';
import { Observable } from 'rxjs';

@Component({
  selector: 'on-subscription-gridcell',
  templateUrl: './subscription-gridcell.component.html',
  styleUrls: ['./subscription-gridcell.component.scss']
})
export class SubscriptionGridcellComponent implements ICellRendererAngularComp {

  params: any;
  hasSubscription: boolean;
  isReadOnly = false;

  constructor() { }


  assignSubscription(event) {
    this.params.context.parent.assignSubscription(this.params.data); // Call parent function
  }


  /*** Implements ***/
  refresh(params: any): boolean {
    return false;
  }

  agInit(params: import('ag-grid-community').ICellRendererParams): void {
    this.params = params;
    this.hasSubscription = params.data.HasSubscription;
    const company$ = params.context.parent.company$ as Observable<Company>;
    const subscription = company$.subscribe(c => {
      this.isReadOnly = c.HasFloatingLicense;
    });
    subscription.unsubscribe();
  }

  afterGuiAttached?(params?: import('ag-grid-community').IAfterGuiAttachedParams): void {
  }
  /**/

}
