export class CompanySettings {
  Id: number;
  CompanyId: number;
  UseMileageScale: 'FrenchLegal' | 'GlobalCompanyRate' | 'CustomUserRate';
  RateAuto: number;
  RateMoto: number;
  RateScooter: number;
  RateBicycle: number;
  BicycleTotalMax: number;

  constructor(model?: CompanySettings) {
    if (model) {
      this.Id = model.Id;
      this.CompanyId = model.CompanyId;
      this.UseMileageScale = model.UseMileageScale;
      this.RateAuto = model.RateAuto;
      this.RateMoto = model.RateMoto;
      this.RateScooter = model.RateScooter;
      this.RateBicycle = model.RateBicycle;
      this.BicycleTotalMax = model.BicycleTotalMax;
    } else {
      this.Id = 0;
      this.CompanyId = 0;
      this.UseMileageScale = 'FrenchLegal';
      this.RateAuto = 0;
      this.RateMoto = 0;
      this.RateScooter = 0;
      this.RateBicycle = 0;
      this.BicycleTotalMax = 0;
    }
  }
}
