import { TranslateService } from '@ngx-translate/core';
import { Company } from 'on-shared/_models/Company';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import swal from 'sweetalert2';
import { CompanyState } from 'on-shared/stores/company/company.state';
import { Observable, Subject } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { CompanyAction } from 'on-shared/stores/company/company.action';
import Swal from 'sweetalert2';
import { DomainName } from 'on-shared/_models/DomainName';

@Component({
  selector: 'on-settings-tab',
  templateUrl: './settings-tab.component.html',
  styleUrls: ['./settings-tab.component.scss']
})
export class SettingsTabComponent implements OnInit, OnDestroy {

  isLoading: boolean;

  @Input() companyId: number;
  company: Company;

  useRate: boolean = false;
  rate: number;
  canEditRate: boolean = false;

  @Select(CompanyState.current) company$: Observable<Company>;
  @Select(CompanyState.domainNames) domainNames$: Observable<DomainName[]>;
  @Select(CompanyState.isLoadingDomainNames) isLoadingDomainNames$: Observable<boolean>;

  destroy$ = new Subject<void>();

  constructor(private translateService: TranslateService,
    private store: Store) { }

  ngOnInit() {
    this.loadCompany();
    this.store.dispatch(new CompanyAction.LoadDomainNames());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadCompany() {
    this.isLoading = true;

    this.company$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.company = new Company(response);

      this.useRate = !this.company.Settings.UseMileageScale;
      this.rate = this.company.Settings.RateAuto;
      this.canEditRate = this.useRate;

      this.isLoading = false;
    });
  }

  updateCompanyRights() {
    this.store.dispatch(new CompanyAction.SaveAuthorizationsChanges(this.company));
  }

  updateCompanyRestaurantTicket() {
    this.store.dispatch(new CompanyAction.SaveRestaurantTicket(this.company));
  }

  deleteCompany() {
    swal.fire({
      title: this.translateService.instant('company.administration.swarl-title'),
      text: this.translateService.instant('company.administration.swarl-text'),
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: this.translateService.instant('company.administration.swarl-confirm-btn'),
      cancelButtonText: this.translateService.instant('company.administration.swarl-cancel-btn')
    }).then(result => {
      if (result.value) {
        this.store.dispatch(new CompanyAction.Delete());
      }
    });
  }

  saveMileageSettings() {
    this.store.dispatch(new CompanyAction.UpdateMileageSettings(this.company));
  }

  reload() {
    this.isLoading = true;
    this.store.dispatch(new CompanyAction.LoadCompanies());
    this.store.dispatch(new CompanyAction.LoadDomainNames());
  }

  requestNewDomainName() {
    Swal.fire({
      title: this.translateService.instant('company.administration.request-dn-title'),
      text: this.translateService.instant('company.administration.request-dn-text'),
      input: 'text',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: this.translateService.instant('company.administration.request-dn-text-confirm'),
      cancelButtonText: this.translateService.instant('company.administration.request-dn-text-cancel')
    }).then(domainEntered => {
      if (domainEntered.value) {
        const dnRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/gi;

        const matches = dnRegex.exec(domainEntered.value);
        if (!matches || !matches.length) {
          Swal.fire({
            title: this.translateService.instant('company.administration.invalid-dn-title'),
            text: this.translateService.instant('company.administration.invalid-dn-text')
          });
          return;
        }

        this.store.dispatch(new CompanyAction.RequestDomainName(domainEntered.value));
      }
    });
  }
}
