// tslint:disable-next-line: no-namespace
export namespace StatisticAction {

  export class LoadDashboard {
    public static readonly type = `[Statistic] load the informations related to the dashboard`;
    constructor() {}
  }

  export class LoadDashboardIfNeeded {
    public static readonly type = `[Statistic] load the informations related to the dashboard if needed`;
    constructor() {}
  }
}
