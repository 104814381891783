import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {

  activated: boolean;

  constructor() {
    this.activated = location.host.indexOf('localhost') < 0;
  }

  logPageView() {
    if (!this.activated) {
      return;
    }
  }

  startNavigationEvent(url: string) {
    if (!this.activated) {
      return;
    }
  }

  endNavigationEvent(url: string) {
    if (!this.activated) {
      return;
    }
  }

  setUserId(userId: number, companyId: number = null) {
    if (!this.activated) {
      return;
    }
  }
}
