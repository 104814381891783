import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { UserState } from 'projects/onexpense/src/app/shared/stores/user/user.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { OnUser } from 'projects/onexpense/src/app/shared/_models/OnUser';

@Component({
  selector: 'on-select-role-gridcell',
  templateUrl: './select-role-gridcell.component.html',
  styleUrls: ['./select-role-gridcell.component.scss'],
})
export class SelectRoleGridcellComponent implements ICellRendererAngularComp {
  @Select(UserState.currentUser) currentUser$: Observable<OnUser>;

  params: any;
  empRole: any;

  constructor() {}

  onChangeRole(event) {
    this.params.context.parent.changeRole(
      event.Employee.Id,
      event.Employee.Role,
    );
  }

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: import('ag-grid-community').ICellRendererParams): void {
    this.params = params;
    this.empRole = params.data;
  }

  afterGuiAttached?(
    params?: import('ag-grid-community').IAfterGuiAttachedParams,
  ): void {}
}
