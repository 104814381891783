<on-page-title [title]="'report.my-reports' | translate" icon="fa-archive" [showReloadButton]="true"
  (reload)="reload()"></on-page-title>

<on-loading *ngIf="isLoadingReports$ | async"></on-loading>

<div class="row" style="padding-left: 30px; padding-right: 30px" *ngIf="company$ | async as company">
  <div class="col alert alert-warning" *ngIf="!(isLoadingReports$ | async)
            && currentUser
            && currentUser.CompanyId
            && !currentUser.ManagerId">
    {{'report.in-company-message' | translate: {company: company.Name} }}
  </div>
</div>


<div *ngIf="!(isLoadingReports$ | async)" class="row col justify-content-space-between mt-2 mb-2">
  <div class="col col-auto">
    <div ngbDropdown class="d-inline-block">
      <button class="btn btn-primary" id="createDropdown" ngbDropdownToggle>
        <nb-icon icon="fa-plus"></nb-icon> {{'report.create' | translate}}
      </button>
      <div ngbDropdownMenu aria-labelledby="createDropdown">
        <button class="dropdown-item" (click)="CreateEmptyReport()">{{'report.create-empty' | translate}}</button>
        <button class="dropdown-item" (click)="CreateSinceLastReport()">{{'report.create-with-awaiting' |
          translate}}</button>
      </div>
    </div>
  </div>
  <div class="col"></div>
  <div class="col col-auto">
    <button class="btn btn-danger" (click)="DeleteReports()">
      <nb-icon icon="fa-trash"></nb-icon>
    </button>
  </div>
</div>

<!--Table-->
<div *ngIf="!(isLoadingReports$ | async)">
  <ul ngbNav #myReportsNav="ngbNav" class="nav-tabs">
    <li ngbNavItem="ongoing" #onGoingTab>
      <a ngbNavLink>{{'report.on-going-tab' | translate}}</a>
      <ng-template ngbNavContent>
        <on-report-list #reportsList [useValidation]="(currentUser$ | async)?.ManagerId"
          [reports]="onGoingReports" [pageSize]="pageSize">
        </on-report-list>
      </ng-template>
    </li>
    <li ngbNavItem="validated" #validatedTab>
      <a ngbNavLink>{{'report.validated-tab' | translate}}</a>
      <ng-template ngbNavContent>
        <on-report-list #reportsList2 [useValidation]="false" [reports]="validatedReports"
          [pageSize]="pageSize">
        </on-report-list>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="myReportsNav" class="mt-2"></div>
</div>


<p class="info-text text-center" *ngIf="!(isLoadingReports$ | async) && freeAccount"> {{'list.limited-free-account' |
  translate}}</p>

<!-- PAGINATION -->
<div class="d-flex-column align-items-start"
  *ngIf="!(isLoadingReports$ | async) && reportsToShow && reportsToShow.length">
  <div class="col text-end">
    {{'table.display' | translate}}
    <span class="page-size-selection" (click)="changePageSize(10)" [class.font-weight-bold]="pageSize === 10">
      10
    </span>
    | <span class="page-size-selection" (click)="changePageSize(20)" [class.font-weight-bold]="pageSize === 20">
      20
    </span>
    | <span class="page-size-selection" (click)="changePageSize(50)" [class.font-weight-bold]="pageSize === 50">
      50
    </span>
    | <input type="number" class="form-input-sm col-1" [(ngModel)]="customPageSize" (change)='onChangePageSize()'>
    {{'table.report-by-page' | translate}}
  </div>
</div>