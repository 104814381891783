import { Subscription } from '../../_models/Subscription';

export class SubscriptionStateModel {
    companySubscriptions: Subscription[];

    shouldReloadLicenses: boolean;

    constructor(model?: SubscriptionStateModel) {
        if (model) {
            this.companySubscriptions = model.companySubscriptions;

            this.shouldReloadLicenses = model.shouldReloadLicenses;
        } else {
            this.shouldReloadLicenses = true;
        }
    }
}
