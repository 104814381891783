<on-page-title [title]="'shared.support.contact' | translate" icon="fa-question"></on-page-title>
<on-two-columns-display [title]="'shared.support.demand-title' | translate"
  [description]="'shared.support.demand-description' | translate">
  <div content>
    <on-loading *ngIf="isLoading"></on-loading>
    <form action="" class="col-12" *ngIf="!isLoading">
      <div class="form-group col-sm-8">
        <label>{{'shared.support.email' | translate}}</label>
        <input type="email" class="form-control" name="mail" [(ngModel)]="mail">
      </div>
      <div class="form-group col-sm-8">
        <label>{{'shared.support.title' | translate}}</label>
        <input type="text" class="form-control" name="title" [(ngModel)]="title">
      </div>
      <div class="form-group col-10">
        <label>{{'shared.support.message' | translate}}</label>
        <textarea class="form-control" rows="8" name="message" [(ngModel)]="message"></textarea>
      </div>
      <div class="form-row d-flex justify-content-center text-center" *ngIf="!formValid">
        <div class="form-group col-4">
          <p class="warning-text">{{'shared.support.required' | translate}}</p>
        </div>
      </div>
      <div class="form-row d-flex justify-content-center">
        <button type="submit" class="btn btn-secondary" (click)="onSend()">{{'shared.support.send' |
          translate}}</button>
      </div>
    </form>
  </div>
</on-two-columns-display>