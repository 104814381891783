import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ExpensesCriteria } from '../../../shared/_models/ExpenseCriteria';

@Component({
  selector: 'on-filter-expenses',
  templateUrl: './filter-expenses.component.html',
  styleUrls: ['./filter-expenses.component.scss']
})
export class FilterExpensesComponent implements OnInit {

  @Output() filterApplied = new EventEmitter<ExpensesCriteria>();

  displayAllFilters: boolean;

  @Input() filter: ExpensesCriteria = {
    AreInReport: false,
    MinCreationDate: null,
    MaxCreationDate: null,
    MinDateOnExpense: null,
    MaxDateOnExpense: null
  };

  onChanged: any;

  constructor() { }

  ngOnInit() {
  }

  expenseMinDateChanged() {
    if (this.onChanged) {
      this.onChanged(this.filter.MinCreationDate);
    }
  }

  expenseMaxDateChanged() {
    if (this.onChanged) {
      this.onChanged(this.filter.MaxCreationDate);
    }
  }


  applyFilters() {
    this.filterApplied.emit(this.filter);
  }

}
