

  <on-loading *ngIf="isLoading"></on-loading>

  <div class="col" [hidden]="isLoading">
      <ag-grid-angular #agGridEmployeeCategories class="ag-theme-material" id="gridEmployeeCategories">
      </ag-grid-angular>
      <div class="d-flex align-items-center justify-content-start margin-5">
        <p>
          <nb-icon icon="fa-info-circle" class="icon-primary"></nb-icon>
          {{'company.category.table.description' | translate}}
        </p>
    </div>
  </div>

  <div class="row d-flex col-6">
    <div class="d-flex col-10">
        <input type="text" nbInput fullWidth [placeholder]="'company.category.table.input-placeholder' | translate" [(ngModel)]="employeeCategoryToAdd.Name"/>
    </div>
    <div class="d-flex col-2" style="padding-left: 0px">
      <button nbButton (click)="addEmployeeCategory()" [disabled]="employeeCategoryToAdd.Name.length === 0">
          <nb-icon icon="fa-plus" ></nb-icon> <span class="button-with-icon">{{'company.category.table.add-btn' | translate}}</span>
        </button>
    </div>
  </div>
