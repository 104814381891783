export class StripeCustomer {
    Id: string;
    CompanyName: string;
    FullName: string;
    Email: string;
    Address: string;
    City: string;
    ZipCode: string;

    constructor(model?: StripeCustomer) {
        if (model) {
            this.Id = model.Id;
            this.CompanyName = model.CompanyName;
            this.FullName = model.FullName;
            this.Email = model.Email;
            this.Address = model.Address;
            this.City = model.City;
            this.ZipCode = model.ZipCode;
        } else {
            this.Id = null;
            this.CompanyName = null;
            this.FullName = null;
            this.Email = null;
            this.Address = null;
            this.City = null;
            this.ZipCode = null;
        }
    }
}
