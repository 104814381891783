import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserState } from 'on-shared/stores/user/user.state';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnUser } from 'on-shared/_models/OnUser';
import { Select } from '@ngxs/store';
import { ToastService } from 'on-common/_services/toast.service';
import { IApiActionResult } from 'on-common/_models/ApiResult';

@Component({
  selector: 'on-support-page',
  templateUrl: './support-page.component.html',
  styleUrls: ['./support-page.component.scss'],
})
export class SupportPageComponent implements OnInit, OnDestroy {
  mail: string;
  title: string;
  message: string;
  userID: number;

  formValid = true;

  isLoading = false;

  @Select(UserState.currentUser) currentUser$: Observable<OnUser>;
  destroy$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private toastService: ToastService,
    private router: Router,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.mail = user.ContactEmail;
      this.userID = user.Id;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSend() {
    if (!this.isFormValid()) {
      return;
    }

    const supportForm = {
      Mail: this.mail,
      Title: this.title,
      Message: this.message,
      UserID: this.userID,
    };

    this.isLoading = true;

    // Call API
    this.httpClient
      .post<IApiActionResult>('/api/v2/support/sendMail', supportForm)
      .subscribe({
        next: (data) => {
          if (data.IsSuccess) {
            this.toastService.success(
              this.translateService.instant('shared.support.message-sent'),
              this.translateService.instant('shared.support.message-sent-status'),
            );
            this.router.navigate(['expenses']); // Redirect to expenses page
          } else {
            this.isLoading = false;

            this.toastService.error(
              this.translateService.instant('shared.support.message-not-sent'),
              this.translateService.instant('shared.support.message-error'),
            );
          }
        },
        error: err => {
          this.isLoading = false;

          this.toastService.error(
            this.translateService.instant('shared.support.message-not-sent'),
            this.translateService.instant('shared.support.message-error'),
          );
        }
      });
  }

  isFormValid() {
    let emptyMail = false;
    let emptyTitle = false;
    let emptyMessage = false;

    if (!this.mail) {
      emptyMail = true;
    }
    if (!this.title) {
      emptyTitle = true;
    }
    if (!this.message) {
      emptyMessage = true;
    }

    this.formValid = !(emptyMail || emptyTitle || emptyMessage);

    return this.formValid;
  }
}
