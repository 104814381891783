import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ImageService {
  constructor(private http: HttpClient) {
  }

  public postImage(url: string, image: File,
    headers?: HttpHeaders | { [name: string]: any },
    partName: string = 'image',
    customFormData?: { [name: string]: any },
    withCredentials?: boolean): Observable<HttpResponse<any>> {

    if (!url || url === '') {
      throw new Error('Url is not set! Please set it before doing queries');
    }

    // add custom form data
    const formData = new FormData();
    for (const key in customFormData) {
      if (customFormData.hasOwnProperty(key)) {
        formData.append(key, customFormData[key]);
      }
    }
    formData.append(partName, image);
    return this.http.post<HttpResponse<any>>(url, formData, {
      withCredentials: withCredentials,
      observe: 'response',
      headers: headers,
      responseType: 'json'
    });
  }
}
