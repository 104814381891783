import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'on-rule-type-selector',
  templateUrl: './rule-type-selector.component.html',
  styleUrls: ['./rule-type-selector.component.scss']
})
export class RuleTypeSelectorComponent implements OnInit {
  @Input() selectedRuleType: string;

  @Output() ruleTypeEmitter: EventEmitter<boolean> = new EventEmitter(undefined);

  ruleTypes: { key: string, display: string }[] = [];

  constructor(private t: TranslateService) {
  }

  ngOnInit() {
    this.ruleTypes = [
      { key: 'limit', display: this.t.instant('expense-rule.limit') }
    ]
  }

  selectRuleType(ruleType) {
    this.ruleTypeEmitter.emit(ruleType);
  }

}
