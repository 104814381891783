import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ChartOptions, ChartData } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';


@Component({
  selector: 'on-expenses-repartition',
  templateUrl: './expenses-repartition.component.html',
  styleUrls: ['./expenses-repartition.component.scss']
})
export class ExpensesRepartitionComponent implements OnChanges {

  // Pie
  @Input() labels: string[] = [];
  @Input() data: number[] = [];
  public pieChartType = 'pie';
  public chartData: ChartData<'pie', number[], string | string[]> = null;

  public chartOptions: ChartOptions = {
    responsive: true
  }

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.chartData = {
      labels: this.labels,
      datasets: [{
        data: this.data
      }]
    }

    this.chart?.update();
  }

}
