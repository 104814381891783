import { AccountantSettingsPageComponent } from './accountant/accountant-settings-page/accountant-settings-page.component';
import { MySignaturesPageComponent } from './my-signatures-page/my-signatures-page.component';
import { LicensesPageComponent } from './my-company-page/licenses-page/licenses-page.component';
import { MyCompanyPageComponent } from './my-company-page/my-company-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExpensesToBeExportedPageComponent } from './accountant/expenses-to-be-exported-page/expenses-to-be-exported-page.component';
import { MyAccountantPageComponent } from './accountant/my-accountant-page/my-accountant-page.component';
import { EditExportPageComponent } from './accountant/accountant-exports-page/edit-export-page/edit-export-page.component';
import { StatisticsExportPageComponent } from 'onexpense/statistics/statistics-export-page/statistics-export-page.component';

const routes: Routes = [
  { path: '', component: MyCompanyPageComponent },
  { path: 'licenses', component: LicensesPageComponent },
  {
    path: 'mysignatures',
    component: MySignaturesPageComponent,
  },
  {
    path: 'exports',
    component: MyAccountantPageComponent,
  },
  {
    path: 'exports/create',
    component: EditExportPageComponent
  },
  {
    path: 'exports/:exportId',
    component: EditExportPageComponent
  },
  {
    path: 'validatedexpenses',
    component: ExpensesToBeExportedPageComponent,
  },
  {
    path: 'accountant-settings',
    component: AccountantSettingsPageComponent,
  },
  {
    path: 'statistics',
    component: StatisticsExportPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CompanyRoutingModule { }
