import { DashboardModel } from '../../_models/DashboardModel';

export class DashboardStateModel {
  dashboard: DashboardModel;
  shouldReloadDashboard: boolean;

  constructor(model?: DashboardStateModel) {
    if (model) {
      this.dashboard = new DashboardModel(model.dashboard);
      this.shouldReloadDashboard = model.shouldReloadDashboard;
    } else {
      this.dashboard = null;
      this.shouldReloadDashboard = true;
    }
  }
}
