import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { VehiclesStateModel } from './vehicles.model';
import { VehiclesService } from './vehicles.service';
import { TranslateService } from '@ngx-translate/core';
import { VehiclesActions } from './vehicles.actions';
import { map } from 'rxjs/operators';
import { Vehicle } from '../../_models/Vehicle';
import { ToastService } from 'on-common/_services/toast.service';

@State<VehiclesStateModel>({
  name: `vehicles`,
  defaults: new VehiclesStateModel()
})
@Injectable()
export class VehiclesState {
  constructor(
    private vehiclesService: VehiclesService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  @Selector()
  static user(state: VehiclesStateModel) {
    return state.user;
  }

  @Action(VehiclesActions.LoadCurrentUser)
  public loadCurrentUser(ctx: StateContext<VehiclesStateModel>) {
    return this.vehiclesService.GetForCurrentUser().pipe(
      map((res) => {
        if (res.IsSuccess) {
          ctx.patchState({
            user: res.Result.map((c) => new Vehicle(c)),
            shouldReloadUser: false,
          });
        } else {
          this.toastService.error(
            this.translateService.instant('user.loading-data-failed'),
            this.translateService.instant('user.loading-data-failed-title'),
          );
        }
        return res;
      }),
    );
  }

  @Action(VehiclesActions.LoadCompany)
  public loadCompany(ctx: StateContext<VehiclesStateModel>, { companyId }: VehiclesActions.LoadCompany) {
    return this.vehiclesService.GetByCompanyId(companyId).pipe(
      map((res) => {
        if (res.IsSuccess) {
          ctx.patchState({
            company: res.Result.map((c) => new Vehicle(c)),
            shouldReloadCompany: false,
          });
        } else {
          this.toastService.error(
            this.translateService.instant('user.loading-data-failed'),
            this.translateService.instant('user.loading-data-failed-title'),
          );
        }
        return res;
      }),
    );
  }

  @Action(VehiclesActions.Create)
  public create(ctx: StateContext<VehiclesStateModel>, { vehicleToCreate }: VehiclesActions.Create) {
    return this.vehiclesService.Create(vehicleToCreate).pipe(
      map((res) => {
        if (res.IsSuccess) {
          this.toastService.success(
            this.translateService.instant('vehicle.create-success'), // ""
            this.translateService.instant('vehicle.create-success-title'), // "Véhicule ajouté"
          );
        } else {
          this.toastService.error(
            this.translateService.instant('vehicle.create-failed'),
            this.translateService.instant('vehicle.create-failed-title'),
          );
        }
        return res;
      }),
    );
  }

  @Action(VehiclesActions.Update)
  public update(ctx: StateContext<VehiclesStateModel>, { vehicleToUpdate }: VehiclesActions.Update) {
    return this.vehiclesService.Update(vehicleToUpdate).pipe(
      map((res) => {
        if (res.IsSuccess) {
          this.toastService.success(
            this.translateService.instant('vehicle.updated-success'),
            this.translateService.instant('vehicle.updated-success-title'),
          );
        } else {
          this.toastService.error(
            this.translateService.instant('vehicle.updated-failed'),
            this.translateService.instant('vehicle.updated-failed-title'),
          );
        }
        return res;
      }),
    );
  }

  @Action(VehiclesActions.Delete)
  public delete(ctx: StateContext<VehiclesStateModel>, { vehicleToDelete }: VehiclesActions.Delete) {
    return this.vehiclesService.Delete(vehicleToDelete).pipe(
      map((res) => {
        if (res.IsSuccess) {
          this.toastService.success(
            this.translateService.instant('vehicle.deleted-success'),
            this.translateService.instant('vehicle.deleted-success-title'),
          );
        } else {
          this.toastService.error(
            this.translateService.instant('vehicle.deleted-failed'),
            this.translateService.instant('vehicle.deleted-failed-title'),
          );
        }
        return res;
      }),
    );
  }

  @Action(VehiclesActions.LoadCurrentUserIfNeeded)
  public loadCurrentUserIfNeeded(ctx: StateContext<VehiclesStateModel>) {
    const state = ctx.getState();

    if (state.shouldReloadUser || !state.user) {
      return this.loadCurrentUser(ctx);
    }
  }

  @Action(VehiclesActions.LoadCompanyIfNeeded)
  public loadCompanyIfNeeded(ctx: StateContext<VehiclesStateModel>, { companyId }: VehiclesActions.LoadCompanyIfNeeded) {
    const state = ctx.getState();

    if (state.shouldReloadCompany || !state.company) {
      return this.loadCompany(ctx, new VehiclesActions.LoadCompany(companyId));
    }
  }
}
