<ng-select multiple="true" [items]="invitees$ | async" bindLabel="Email" [addTag]="false" [multiple]="true"
    [hideSelected]="true" [trackByFn]="trackByFn" [minTermLength]="2" [loading]="loading" appendTo="body"
    [notFoundText]="'select-employees.not-found-text' | translate"
    [typeToSearchText]="'select.enter-min-2-char' | translate" [typeahead]="inviteeInput$"
    [(ngModel)]="selectedInvitees" (change)="selectionChanged()">
    <!-- <ng-template ng-label-tmp let-item="item">
        <span>{{item.Email}}</span>
    </ng-template> -->
    <ng-template ng-option-tmp let-item="item">
        <span *ngIf="item.FirstName && item.LastName;else emailOnly">{{item.FirstName}} {{item.LastName}}
            ({{item.Email}})</span>
        <ng-template #emailOnly>
            <span>{{item.Email}}</span>
        </ng-template>
    </ng-template>
</ng-select>