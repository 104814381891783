<span *ngIf="params.data.IsValidated === false"
    [nbTooltip]="'expense.reject-tooltip' | translate: { comments : params.data.ValidationComment }"
    nbTooltipStatus="danger" nbTooltipPlacement="right"
  >
  <nb-icon icon="fa-times" class="icon-danger"></nb-icon>
</span>

<span *ngIf="params.data.IsValidated === true"
    [nbTooltip]="'expense.validated' | translate" nbTooltipStatus="success" nbTooltipPlacement="right">
  <nb-icon icon="fa-check" class="icon-success"></nb-icon>
</span>

<span *ngIf="params.data.IsValidated === null"
    [nbTooltip]="'expense.not-verified' | translate" nbTooltipStatus="warning" nbTooltipPlacement="right">
  <nb-icon icon="fa-question" class="icon-warning"></nb-icon>

</span>

