<div class="m-2">
  <h2>{{'report.valid-report-title' | translate}}</h2>
  <hr>
  <on-loading *ngIf="isLoading"></on-loading>
  <div class="flex-row" *ngIf="!isLoading">
    <div>
      <p>{{'report.send-to-validation-message' | translate}}</p>
    </div>
    <div *ngIf="allManagerInfos.length > 1">
      <label><strong>Manager :</strong></label>
      <select #ngSelect class="form-control-sm col-12" [(ngModel)]="selectedManagerIndex">
        <option [value]="i" *ngFor="let managerInfos of allManagerInfos; let i = index">{{managerInfos.FirstName}}
          {{managerInfos.LastName}}
          ({{managerInfos.UserName}}) - {{managerInfos.CompanyName}}</option>
      </select>
    </div>
    <div class="text-center m-0 p-0 row col-12 justify-content-center">
      <button class="btn btn-danger col-3 m-2" (click)="cancel()">{{'report.cancel' | translate}}</button>
      <button class="btn btn-primary col-3 m-2" (click)="sendToValidation()">{{'report.send' | translate}}</button>
    </div>
  </div>
</div>
