import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { CompanyAction } from 'on-shared/stores/company/company.action';
import { CompanyState } from 'on-shared/stores/company/company.state';
import { EmployeeCategory } from 'on-shared/_models/EmployeeCategory';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'on-employee-category-select',
  templateUrl: './employee-category-select.component.html',
  styleUrls: ['./employee-category-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmployeeCategorySelectComponent),
      multi: true
    }
  ],
})
export class EmployeeCategorySelectComponent implements OnInit, OnDestroy, ControlValueAccessor {

  allCategories: EmployeeCategory[];
  selectedCategory: EmployeeCategory;
  @Input() allowAll = false;

  onChanged: any;
  onTouched: any;

  @Select(CompanyState.employeeCategories)
  employeeCategories$: Observable<EmployeeCategory[]>;

  destroy$ = new Subject<void>();

  initialValue: EmployeeCategory;

  isDisabled: boolean;

  nullType: EmployeeCategory = {
    Id: 0,
    Name: '',
    IsDefault: false,
    CompanyId: 0
  };

  constructor(
    private translateService: TranslateService,
    private store: Store
  ) {
    this.nullType.Name = this.translateService.instant('expense-category.any');
  }

  ngOnInit() {
    this.store.dispatch(new CompanyAction.LoadEmployeeCategoriesIfNeeded());

    this.employeeCategories$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response) {

        if (this.allowAll) {
          this.allCategories = [
            this.nullType
            , ...response];
          if (!this.initialValue || !this.initialValue.Id) {
            this.initialValue = this.nullType;
          }
        } else {
          this.allCategories = response; // All types excepted mileage
        }

        if (this.initialValue) {
          this.writeValue(this.initialValue);
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(obj: any): void {
    if (obj && this.allCategories) {
      this.selectedCategory = this.allCategories.find(t => t.Id === obj.Id);
    } else {
      this.initialValue = obj;

      this.selectedCategory = this.nullType;
    }
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  compareEmployeeCategory(category1: EmployeeCategory, category2: EmployeeCategory) {
    return category1 && category2 && category1.Id === category2.Id;
  }

  categoryChanged() {
    let toUpdate = this.selectedCategory;
    if (this.selectedCategory === this.nullType) {
      toUpdate = null;
    }
    if (this.onChanged) {
      this.onChanged(toUpdate);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  searchInName(term: string, item: EmployeeCategory) {
    term = term.toLowerCase();
    return item.Name.toLowerCase().indexOf(term) > -1;
  }

}