<on-loading *ngIf="isLoading"></on-loading>

<on-report-list *ngIf="!isLoading && reportsSigned && reportsSigned.length" [reports]="reportsSigned" [canSelect]="false"
  [showOwner]="true">

</on-report-list>

<div *ngIf="!isLoading && reportsSigned && !reportsSigned.length" class="text-center information-text">
  {{'company.signature.message-signed' | translate}}<br>
  <nb-icon size="3x" icon="fa-smile"></nb-icon>
</div>
