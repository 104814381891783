import { Component, ViewChild } from '@angular/core';
import { AgFrameworkComponent } from 'ag-grid-angular';
import { IFloatingFilterParams, IFloatingFilter } from 'ag-grid-community';

export interface CheckboxFloatingFilterChange {
  model: any;
}


export interface CheckboxFloatingFilterParams extends IFloatingFilterParams {
}

@Component({
  selector: 'on-checkbox-floating-filter',
  templateUrl: './checkbox-floating-filter.component.html',
  styleUrls: ['./checkbox-floating-filter.component.scss']
})
export class CheckboxFloatingFilterComponent implements
  IFloatingFilter,
  AgFrameworkComponent<CheckboxFloatingFilterParams> {

  params: CheckboxFloatingFilterParams;

  @ViewChild('inputCk') inputCk: any;
  checkbox = { state: 'crossed' };


  constructor() { }


  onParentModelChanged(parentModel: any): void {
  }

  agInit(params: CheckboxFloatingFilterParams): void {
    this.params = params;
  }
  afterGuiAttached?(params?: import('ag-grid-community').IAfterGuiAttachedParams): void {
  }

  onChange(checkbox) {

    switch (checkbox.state) {
      case 'checked': {
        checkbox.state = 'unchecked';
        this.params.parentFilterInstance(instance => {
          instance.onFloatingFilterChanged('equals', 'false');
        });
        break;
      }
      case 'unchecked': {
        this.checkbox.state = 'crossed';
        this.params.parentFilterInstance(instance => {
          instance.onFloatingFilterChanged('equals', '');
        });
        break;
      }
      case 'crossed': {
        this.checkbox.state = 'checked';
        this.params.parentFilterInstance(instance => {
          instance.onFloatingFilterChanged('equals', 'true');
        });
        break;
      }
    }
    this.inputCk.nativeElement.blur(); // Remove focus for update data, important
  }

}
