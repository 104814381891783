<on-page-title icon="fa-calculator" title="{{ 'company.accountant-export.reports-to-export' | translate }}"
  [showReloadButton]="true" (reload)="reload()"></on-page-title>

<on-loading *ngIf="isLoading"></on-loading>

<div class="col" *ngIf="!isLoading">
  <div>
    <h3>{{ 'company.accountant-export.export-reports' | translate }}</h3>
    <p class="info-text">{{ 'company.accountant-export.export-reports-description' | translate }}</p>
  </div>

  <div class="row col-12 d-flex justify-content-center align-items-center" *ngIf="reports">
    <div class="col-6">
      <div class="input-group">
        <div class="input-group-text input-rounded">
          <nb-icon icon="fa-search"></nb-icon>
        </div>
        <input type="text" [(ngModel)]="searchTerms" class="form-control input-rounded"
          [placeholder]="'company.search' | translate" (input)="onChangeFilter()">
      </div>
    </div>
  </div>

  <div class="ml-4">
    <button class="btn btn-primary" (click)="openSwalCreate()" [disabled]="!canAdd()">
      <nb-icon icon="fa-plus"></nb-icon>
      {{ 'company.accountant-export.new-export' | translate }}
    </button>
  </div>

  <on-report-list #reportListComponent [showOwner]="true" [reports]="reports" [canSelect]="true"></on-report-list>

</div>