import { StripeInvoice } from './StripeInvoice';
import { StripePrice } from './StripePrice';

export class StripeSubscription {
    Id: string;
    Status: string;
    LatestInvoice: StripeInvoice;
    RecurringPrice: StripePrice;
    StartDate: Date;
    NextRenewalDate: Date;
    Quantity: number;
    CustomerId: string;

    constructor(model: StripeSubscription) {
        if (model) {
            this.Id = model.Id;
            this.Status = model.Status;
            this.LatestInvoice = model.LatestInvoice;
            this.RecurringPrice = model.RecurringPrice;
            this.NextRenewalDate = model.NextRenewalDate;
            this.StartDate = model.StartDate;
            this.Quantity = model.Quantity;
            this.CustomerId = model.CustomerId;
        } else {
            this.Id = null;
            this.Status = null;
            this.LatestInvoice = null;
            this.RecurringPrice = null;
            this.StartDate = null;
            this.NextRenewalDate = null;
            this.Quantity = null;
            this.CustomerId = null;
        }
    }
}

