import { ExpenseType } from './ExpenseType';
import { ExpenseState } from './ExpenseState';
import { Amount } from './Amount';
import { CustomFieldValue } from './CustomFieldValue';
import { ISelectableIdObject } from 'on-common/_models/ISelectableIdObject';
import { Country } from 'on-common/_models/Country';
import { Currency } from 'on-common/_models/Currency';
import { InvitedUser } from 'onexpense/expense/_models/InvitedUser';

export class Expense extends ISelectableIdObject {
  Id?: number;
  UserId?: number;
  CreationDate?: Date;
  ProcessingDate?: Date;
  DateOnExpense?: Date;
  ImageUrl?: string;
  ImageUrls?: string;
  ThumbUrl?: string;
  ThumbUrls?: string;
  PdfUrl?: string;
  ExpenseType?: ExpenseType;
  IsInReport?: boolean;
  IsEditable?: boolean;
  Emitter?: string;
  Comments?: string;
  ExpenseState?: ExpenseState;
  TVAAmounts?: Amount[];
  GlobalAmount?: Amount;
  Country?: Country;
  Currency?: Currency;
  ConversionRatio?: number;
  ReportId?: number;

  VehicleId?: number;
  Distance?: number;
  NbJourneyHomeToWork?: number;
  StartPlace?: string;
  EndPlace?: string;

  WasAutomatic?: boolean;
  IsSelected?: boolean;
  DynamicFieldsValues?: CustomFieldValue[];
  IsValidated?: boolean;
  ValidationComment?: string;
  AmountToBeReimbursed?: number;

  PaidWithPersonalAccount?: boolean;
  IsPerDiem?: boolean;

  NumberOfNight?: number;

  InvitedUsers?: InvitedUser[];

  constructor(model?: Expense) {
    super();
    if (model) {
      this.Id = model.Id;
      this.UserId = model.UserId;
      this.CreationDate = new Date(model.CreationDate);
      this.ProcessingDate = new Date(model.ProcessingDate);
      this.DateOnExpense = new Date(model.DateOnExpense);
      this.ImageUrl = model.ImageUrl;
      this.ImageUrls = model.ImageUrls;
      this.ThumbUrl = model.ThumbUrl;
      this.ThumbUrls = model.ThumbUrls;
      this.PdfUrl = model.PdfUrl;
      this.ExpenseType = model.ExpenseType ? new ExpenseType(model.ExpenseType) : null;
      this.IsInReport = model.IsInReport;
      this.IsEditable = model.IsEditable;
      this.Emitter = model.Emitter;
      this.Comments = model.Comments || '';
      this.ExpenseState = model.ExpenseState ? new ExpenseState(model.ExpenseState) : null;
      this.TVAAmounts = (model.TVAAmounts || []).map((c) => new Amount(c));
      this.GlobalAmount = new Amount(model.GlobalAmount);
      this.Country = model.Country ? new Country(model.Country) : null;
      this.Currency = model.Currency ? new Currency(model.Currency) : null;
      this.ConversionRatio = model.ConversionRatio;
      this.ReportId = model.ReportId;
      this.PaidWithPersonalAccount = model.PaidWithPersonalAccount;
      this.IsPerDiem = model.IsPerDiem;
      this.NumberOfNight = model.NumberOfNight;

      this.VehicleId = model.VehicleId;
      this.Distance = model.Distance;
      this.NbJourneyHomeToWork = model.NbJourneyHomeToWork;
      this.StartPlace = model.StartPlace;
      this.EndPlace = model.EndPlace;

      this.WasAutomatic = model.WasAutomatic;
      this.IsSelected = model.IsSelected;
      this.DynamicFieldsValues = (model.DynamicFieldsValues || []).map(
        (c) => new CustomFieldValue(c),
      );
      this.IsValidated = model.IsValidated;
      this.ValidationComment = model.ValidationComment;
      this.AmountToBeReimbursed = model.AmountToBeReimbursed;
      this.InvitedUsers = model.InvitedUsers;
    } else {
      this.Id = 0;
      this.UserId = 0;
      this.CreationDate = new Date();
      this.ProcessingDate = new Date();
      this.DateOnExpense = new Date();
      this.ImageUrl = null;
      this.ImageUrls = null;
      this.ThumbUrl = null;
      this.ThumbUrls = null;
      this.PdfUrl = null;
      this.ExpenseType = new ExpenseType();
      this.IsInReport = false;
      this.IsEditable = true;
      this.Emitter = null;
      this.Comments = '';
      this.ExpenseState = new ExpenseState();
      this.TVAAmounts = [];
      this.GlobalAmount = new Amount();
      this.Country = new Country();
      this.Currency = new Currency();
      this.ConversionRatio = 1;
      this.ReportId = null;
      this.PaidWithPersonalAccount = true;
      this.IsPerDiem = false;
      this.NumberOfNight = 0;

      this.VehicleId = null;
      this.Distance = 0;
      this.NbJourneyHomeToWork = 0;
      this.StartPlace = null;
      this.EndPlace = null;

      this.WasAutomatic = false;
      this.IsSelected = false;
      this.DynamicFieldsValues = [];
      this.IsValidated = false;
      this.ValidationComment = null;
      this.AmountToBeReimbursed = 0;
      this.InvitedUsers = [];
    }
  }
}
