import { Injectable } from '@angular/core';
import { SnotifireService } from 'ngx-snotifire';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private notifyService: SnotifireService) {

  }

  success(content: string, title: string = '') {
    this.notifyService.success(content, title);
  }

  error(content: string, title: string = '') {
    this.notifyService.error(content, title, {
      timeout: 6000
    });
  }

  info(content: string, title: string = '') {
    this.notifyService.info(content, title);
  }

  warning(content: string, title: string = '') {
    this.notifyService.warning(content, title, {
      timeout: 6000
    });
  }
}
