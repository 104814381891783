<on-loading *ngIf="isLoading"></on-loading>
<div class="col m-2" *ngIf="!isLoading">
  <h3 class="rules-title">
    <span>{{'expense-rule.tab-title' | translate}}</span>
    <button nb-button class="btn btn-primary float-right" [nbTooltip]="'expense-rule.add-new-rule' | translate"
      nbTooltipStatus="primary" (click)="openCreateRuleModal()">
      <nb-icon icon="fa-plus"></nb-icon> {{'expense-rule.new-rule' | translate}}
    </button>
  </h3>
</div>
<div *ngIf="!isLoading && rules.length === 0" class="col m-5" align="center">
  <h6>
    <span [innerHtml]="'expense-rule.no-rule-description' | translate"></span>
    <nb-icon icon="fa-arrow-up" class="margin-5 icon-primary" style="transform: rotate(50deg)"></nb-icon>
  </h6>
</div>
<div class="col m-2">
  <nb-accordion multi>
    <nb-accordion-item *ngFor="let rule of rules">
      <nb-accordion-item-header>
        <div>
          <span>{{'expense-rule.rule' | translate}} {{rule.Name}}</span>
          <!-- |
          <span *ngIf="rule.EmployeeCategory">
            <nb-icon icon="fa-users"></nb-icon> &nbsp;&nbsp; Employés : {{rule.EmployeeCategory.Name}}
          </span>
          <span *ngIf="!rule.EmployeeCategory">
            <nb-icon icon="fa-users"></nb-icon> &nbsp;&nbsp; Employés : {{'employee-category.all' | translate}}
          </span>
          |
          {{rule.RuleType}} {{rule.Value}} <span
            *ngIf="connectedOnUser$ | async as connectedOnUser">{{connectedOnUser?.Currency?.Symbol}}</span> /
          <span *ngIf="rule.ExpenseType?.Name">{{rule.ExpenseType.Name}}</span>
          <span *ngIf="!rule.ExpenseType?.Name">{{'expense.all-expense-type' | translate}}</span> -->
        </div>
      </nb-accordion-item-header>
      <nb-accordion-item-body style="background-color: white">
        <!-- <div class="col">
          <on-rule-type-selector [selectedRuleType]="rule.RuleType" (ruleTypeEmitter)="selectedRuleTypeChanged(rule, $event)"></on-rule-type-selector>
        </div> -->
        <div class="col">
          <on-limit-rule-form [rule]="rule" [expenseTypes]="expenseTypes" [employeeCategories]="employeeCategories"
            (reloadListEmitter)="reloadList($event)" (deleteRuleEmitter)="deleteRule($event)"></on-limit-rule-form>
        </div>
      </nb-accordion-item-body>
    </nb-accordion-item>
  </nb-accordion>
</div>