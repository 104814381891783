import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IApiResult } from 'on-common/_models/ApiResult';
import { DashboardModel } from '../../_models/DashboardModel';
import { HttpClient } from '@angular/common/http';
import { API_V2_URL } from 'on-common/constants';

const STATISTICS_URL = API_V2_URL + '/statistics';

@Injectable({ providedIn: 'root' })
export class StatisticService {
  constructor(private http: HttpClient) { }

  // Api calls
  GetDashboardInfo(): Observable<IApiResult<DashboardModel>> {
    return this.http.get<IApiResult<DashboardModel>>(
      STATISTICS_URL + '/dashboard',
    );
  }
}
