import { TranslateService } from '@ngx-translate/core';
import { CompanyEmployeesData } from './../../../shared/_models/CompanyEmployeesData';
import { ManageEmployeeSettingsModalComponent } from './manage-employee-settings-modal/manage-employee-settings-modal.component';
import { OnUser } from './../../../shared/_models/OnUser';
import { AddEmployeeComponent } from './add-employee/add-employee.component';
import { CheckboxFloatingFilterComponent } from './../../../shared/grid-cell/checkbox-floating-filter/checkbox-floating-filter.component';
import { RemoveInvitationGridcellComponent } from './../../_shared/grid-cell/employee-tab/remove-invitation-gridcell/remove-invitation-gridcell.component';
import { ResendInvitationGridcellComponent } from './../../_shared/grid-cell/employee-tab/resend-invitation-gridcell/resend-invitation-gridcell.component';
import { DateGridcellComponent } from './../../../shared/grid-cell/date-gridcell/date-gridcell.component';
import { KickGridcellComponent } from './../../../shared/grid-cell/kick-gridcell/kick-gridcell.component';
import { SubscriptionGridcellComponent } from './../../_shared/grid-cell/employee-tab/subscription-gridcell/subscription-gridcell.component';
import { SelectRoleGridcellComponent } from './../../_shared/grid-cell/employee-tab/select-role-gridcell/select-role-gridcell.component';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  HostListener,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import { Observable, Subject } from 'rxjs';
import { AgGridAngular } from 'ag-grid-angular';
// tslint:disable-next-line: max-line-length
import { formatDate } from '@angular/common';
import { ColDef, GridApi } from 'ag-grid-community';
import { EmployeeRole } from '../../../shared/_models/EmployeeRole';
import { CompanyInvitation } from '../../../shared/_models/CompanyInvitation';
import { Select, Store } from '@ngxs/store';
import { CompanyState } from '../../../shared/stores/company/company.state';
import { Company } from '../../../shared/_models/Company';
import { CompanyAction } from '../../../shared/stores/company/company.action';
import { takeUntil } from 'rxjs/operators';
import { UserState } from '../../../shared/stores/user/user.state';
import { AGGRID_TRANSLATIONS } from 'on-common/constants';

interface AdditionalColumn {
  name: string;
  field: string;
}

@Component({
  selector: 'on-employees-tab',
  templateUrl: './employees-tab.component.html',
  styleUrls: ['./employees-tab.component.scss'],
})
export class EmployeesTabComponent implements OnInit, OnDestroy, OnChanges {
  @Input() companyId: number;

  searchTerms: string;
  isLoading: boolean;

  filteredEmployees: EmployeeRole[];
  employeesData: CompanyEmployeesData;
  @Select(CompanyState.current) company$: Observable<Company>;
  destroy$ = new Subject<void>();

  assignedSubscriptions = 0;

  @Select(UserState.currentUser) currentUser$: Observable<OnUser>;
  connectedUser: OnUser;
  isAdmin = false;

  // More columns
  additionalColumns: ColDef[] = [
    {
      headerName: this.translateService.instant('company.employees.identifier'),
      field: 'Employee.Identifier',
      hide: true,
      sortable: true,
      resizable: true,
      width: 120,
      filter: 'agTextColumnFilter',
      floatingFilter: false,
    },
    {
      headerName: this.translateService.instant(
        'company.employees.organization-unit',
      ),
      field: 'Employee.OrganizationUnit',
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      width: 120,
      hide: true,
    }
  ];
  selectedAdditionalColumns: ColDef[] = [];

  @ViewChild('agGridEmployees', { static: true }) gridEmployees: AgGridAngular;
  gridEmployeesApi: GridApi;
  @ViewChild('agGridInvitations', { static: true })
  gridInvitations: AgGridAngular;

  // employeesGrid
  columnDefsEmployees: ColDef[] = [
    ...this.additionalColumns,
    {
      headerName: this.translateService.instant('company.employees.username'),
      field: 'Employee.UserName',
      sortable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      resizable: true,
    },
    {
      headerName: this.translateService.instant('company.employees.firstname'),
      field: 'Employee.FirstName',
      sortable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      resizable: true,
    },
    {
      headerName: this.translateService.instant('company.employees.lastname'),
      field: 'Employee.LastName',
      sortable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      resizable: true,
    },
    {
      headerName: this.translateService.instant('company.employees.role'),
      cellRenderer: SelectRoleGridcellComponent,
      cellClass: 'd-flex justify-content-center align-items-center',
      filter: false,
      floatingFilter: false,
      resizable: true,
      width: 152,
    },
    {
      headerName: this.translateService.instant(
        'company.employees.has-subscription',
      ),
      field: 'HasSubscription',
      cellRenderer: SubscriptionGridcellComponent,
      cellClass: 'd-flex justify-content-center align-items-center',
      filter: true,
      floatingFilter: false,
      sortable: true,
      minWidth: 170,
      floatingFilterComponent: 'checkboxFloatingFilter',
      floatingFilterComponentParams: {
        isSubscribe: true,
        isNotSubscribe: true,
        suppressFilterButton: true,
      },
      suppressMenu: true,
    },
    {
      headerName: this.translateService.instant('company.employees.kick'),
      cellRenderer: KickGridcellComponent,
      cellClass: 'd-flex justify-content-center align-items-center',
      filter: false,
      floatingFilter: false,
      minWidth: 180,
    },
  ];

  // invitationsGrid
  columnDefsInvitations = [
    {
      headerName: this.translateService.instant('company.employees.email'),
      field: 'InvitedEmail',
      sortable: true,
      filter: 'agTextColumnFilter',
      resizable: true,
      width: 350,
    },
    {
      headerName: this.translateService.instant(
        'company.employees.expiration-date',
      ),
      field: 'ExpirationDate',
      cellRenderer: DateGridcellComponent,
      cellRendererParams: {
        for: 'invitations',
      },
      sortable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        browserDatePicker: true,

        comparator: function (filterLocalDateAtMidnight, cellValue) {
          const dateAsString = formatDate(cellValue, 'dd/MM/yyyy', 'fr');

          if (dateAsString == null) {
            return 0;
          }

          const dateParts = dateAsString.split('/');
          const day = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[0]);
          const cellDate = new Date(day, month, year);

          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      },
      resizable: true,
    },
    {
      headerName: this.translateService.instant('company.employees.resend'),
      cellRenderer: ResendInvitationGridcellComponent,
      cellClass: 'd-flex justify-content-center align-items-center',
      filter: false,
      width: 100,
    },
    {
      headerName: this.translateService.instant('company.employees.delete'),
      cellRenderer: RemoveInvitationGridcellComponent,
      cellClass: 'd-flex justify-content-center align-items-center',
      filter: false,
      width: 100,
    },
  ];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const timeout = setTimeout((_) => {
      this.gridEmployees.api.sizeColumnsToFit();
      this.gridInvitations.api.sizeColumnsToFit();
      clearTimeout(timeout);
    }, 200);
  }

  constructor(
    private bsModalService: NgbModal,
    private translateService: TranslateService,
    private store: Store,
  ) { }

  ngOnInit() {
    this.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.connectedUser = user;
      this.isAdmin = user.Role === 'ADMIN';
    });

    this.store.dispatch(new CompanyAction.LoadEmployeesIfNeeded());

    // GridEmployees Options
    this.gridEmployees.gridOptions = {
      defaultColDef: {
        floatingFilter: false
      },
      columnDefs: this.columnDefsEmployees,

      domLayout: 'autoHeight',

      pagination: true,
      paginationPageSize: 15, // Nb items displayed per page

      rowHeight: 38,

      suppressCellFocus: true,

      onRowClicked: this.onRowClicked,

      onGridReady: (params) => {
        this.loadCompany();
      },

      components: {
        checkboxFloatingFilter: CheckboxFloatingFilterComponent,
      },

      // For get 'this' in child component
      context: { parent: this },

      // Translate
      localeText: { ...AGGRID_TRANSLATIONS,
        noRowsToShow: this.translateService.instant('table.no-employee')
      },
    };

    this.gridEmployees.defaultColDef = {
      cellClass: 'd-flex align-items-center',
    };

    // GridInvitations Options
    this.gridInvitations.gridOptions = {
      columnDefs: this.columnDefsInvitations,

      domLayout: 'autoHeight',

      pagination: true,
      paginationPageSize: 20, // Nb items displayed per page

      rowHeight: 40,

      suppressCellFocus: true,

      // For get 'this' in child component
      context: { parent: this },

      localeText: {
        ...AGGRID_TRANSLATIONS,
        noRowsToShow: this.translateService.instant('table.no-invitation')
      },
    };

    this.gridInvitations.defaultColDef = {
      cellClass: 'd-flex align-items-center',
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.searchTerms = '';
    if (this.gridEmployees.api != null) {
      this.onChangeFilter();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadCompany() {
    this.isLoading = true;

    this.company$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      if (response?.CompanyEmployeesData) {
        this.employeesData = new CompanyEmployeesData(
          response.CompanyEmployeesData,
        );

        // employeesGrid
        this.gridEmployees.api.setRowData(this.employeesData.EmployeeRoles);
        this.gridEmployees.api.sizeColumnsToFit(); // Adjust size
        /******/

        // invitationsGrid
        this.gridInvitations.api.setRowData(this.employeesData.Invitations);
        this.gridInvitations.api.sizeColumnsToFit(); // Adjust size
        /******/

        this.isLoading = false;
      } else {
        // Load data
        this.store.dispatch(new CompanyAction.LoadEmployees());
      }
    });
  }

  addEmployee() {
    const modalInstance = this.bsModalService.open(AddEmployeeComponent);
  }

  assignSubscription(empRole: EmployeeRole) {
    this.store.dispatch(
      new CompanyAction.AssignSubscription(
        empRole.Employee.Id,
        empRole.HasSubscription,
      ),
    );
  }

  resendInvitation(invitation: CompanyInvitation) {
    swal
      .fire({
        title: this.translateService.instant(
          'company.employees.swarl-send-invitation-title',
        ),
        text: this.translateService.instant(
          'company.employees.swarl-send-invitation-text',
          { email: invitation.InvitedEmail },
        ),
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: this.translateService.instant(
          'company.employees.swarl-send-invitation-cancel-btn',
        ),
        confirmButtonText: this.translateService.instant(
          'company.employees.swarl-send-invitation-confirm-btn',
        ),
      })
      .then((result) => {
        if (result.value) {
          this.store.dispatch(
            new CompanyAction.AddEmployees([invitation.InvitedEmail]),
          );
        }
      });
  }

  removeInvitation(invitation: CompanyInvitation) {
    swal
      .fire({
        title: this.translateService.instant(
          'company.employees.swarl-remove-invitation-title',
        ),
        text: this.translateService.instant(
          'company.employees.swarl-remove-invitation-text',
          { email: invitation.InvitedEmail },
        ),
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: this.translateService.instant(
          'company.employees.swarl-remove-invitation-cancel-btn',
        ),
        confirmButtonText: this.translateService.instant(
          'company.employees.swarl-remove-invitation-confirm-btn',
        ),
      })
      .then((result) => {
        if (result.value) {
          this.store.dispatch(
            new CompanyAction.CancelInvitation(invitation.InvitedEmail),
          );
        }
      });
  }

  /*changeRole(employeeRole: EmployeeRole) {
    this.companyService.SaveEmployees([employeeRole]).subscribe(response => {
      if (response.IsSuccess) {
        this.toastService.success(this.translateService.instant('company.employees.updated'));
      } else {
        this.toastService.error(this.translateService.instant('company.employees.cannot-updated'));
      }
    }, () => {
      this.toastService.error(this.translateService.instant('company.employees.cannot-updated'));
    });
  }*/

  changeRole(employeeId: number, role: string) {
    this.store.dispatch(
      new CompanyAction.ChangeEmployeeRole(this.companyId, employeeId, role),
    );
  }

  kickEmployee(employeeRole: EmployeeRole) {
    const employee = employeeRole.Employee;

    // If the employee is manager
    if (employee.HasEmployees) {
      const employees = new Map();
      employees.set(
        0,
        this.translateService.instant(
          'company.employees.swarl-kick-none-manager',
        ),
      );
      this.employeesData.EmployeeRoles.forEach((empR) =>
        employees.set(empR.Employee.Id, empR.Employee.UserName),
      );
      employees.delete(employee.Id);

      swal
        .fire({
          title: this.translateService.instant(
            'company.employees.swarl-kick-title',
          ),
          text: this.translateService.instant(
            'company.employees.swarl-kick-manager-text',
            { username: employee.UserName },
          ),
          input: 'select',
          inputOptions: employees,
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: this.translateService.instant(
            'company.employees.swarl-kick-cancel-btn',
          ),
          confirmButtonText: this.translateService.instant(
            'company.employees.swarl-kick-confirm-btn',
          ),
        })
        .then((result) => {
          if (result.value) {
            this.store.dispatch(
              new CompanyAction.KickEmployee(employee, result.value),
            );
          }
        });
    } else {
      swal
        .fire({
          title: this.translateService.instant(
            'company.employees.swarl-kick-title',
          ),
          text: this.translateService.instant(
            'company.employees.swarl-kick-text',
            { username: employee.UserName },
          ),
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: this.translateService.instant(
            'company.employees.swarl-kick-cancel-btn',
          ),
          confirmButtonText: this.translateService.instant(
            'company.employees.swarl-kick-confirm-btn',
          ),
        })
        .then((result) => {
          if (result.value) {
            this.store.dispatch(new CompanyAction.KickEmployee(employee, null));
          }
        });
    }
  }

  onRowClicked(event) {
    event.context.parent.openUserParams(event.data.Employee);
  }

  openUserParams(selectedUser: OnUser) {
    const modal = this.bsModalService.open(
      ManageEmployeeSettingsModalComponent,
      { size: 'lg', ariaLabelledBy: 'modal-basic-title' },
    );
    modal.componentInstance.User = selectedUser;
    modal.componentInstance.connectedUser = this.connectedUser;
  }

  // Filter employeesGrid
  onChangeFilter() {
    this.gridEmployees.api.setGridOption("quickFilterText", this.searchTerms);
  }

  columnAdded(e: AdditionalColumn) {
    for (const colDef of this.columnDefsEmployees) {
      if (colDef.field === e.field) {
        this.gridEmployees.api.setColumnVisible(colDef.field, true);
        break;
      }
    }
  }

  columnRemoved(e: { value: AdditionalColumn }) {
    for (const colDef of this.columnDefsEmployees) {
      if (colDef.field === e.value.field) {
        this.gridEmployees.api.setColumnVisible(colDef.field, false);
        break;
      }
    }
  }

  columnCleared(e: any) {
    for (const colDef of this.additionalColumns) {
      this.gridEmployees.api.setColumnVisible(colDef.field, false);
    }
  }

  reload() {
    this.isLoading = true;
    this.store.dispatch(new CompanyAction.LoadEmployees());
  }
}
