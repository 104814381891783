import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'on-two-columns-display',
  templateUrl: './two-columns-display.component.html',
  styleUrls: ['./two-columns-display.component.scss']
})
export class TwoColumnsDisplayComponent implements OnInit {

  @Input() title;
  @Input() description;

  constructor() { }

  ngOnInit() {
  }

}
