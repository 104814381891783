import { RouterModule } from '@angular/router';
import { AccountRoutingModule } from './account-routing.module';
import { ExpenseModuleWithoutRouting } from './../expense/expense.module';
import { AccountFormComponent } from './account-page/account-form/account-form.component';
import { AccountPageComponent } from './account-page/account-page.component';
import { UserSettingsPageComponent } from './user-settings-page/user-settings-page.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TagInputModule } from 'ngx-chips';
import { CompanyModuleWithoutRouting } from '../company/company.module';
import { AccountMenuComponent } from './_shared/account-menu/account-menu.component';

@NgModule({
    declarations: [
        AccountFormComponent,
        AccountPageComponent,
        UserSettingsPageComponent,
        AccountMenuComponent
    ],
    imports: [
        SharedModule,
        ExpenseModuleWithoutRouting,
        TagInputModule,
        CompanyModuleWithoutRouting,
        RouterModule.forChild([])
    ],
    exports: [
        AccountMenuComponent
    ]
})
export class AccountModuleWithoutRouting { }

@NgModule({
  imports: [
    AccountRoutingModule,
    AccountModuleWithoutRouting
  ],
  exports: [
    AccountModuleWithoutRouting
  ]
})
export class AccountModule { }

