import { ManageEmployeeSettingsModalComponent } from './my-company-page/employees-tab/manage-employee-settings-modal/manage-employee-settings-modal.component';
import { CompanyRoutingModule } from './company-routing.module';
import { AddEmployeeComponent } from './my-company-page/employees-tab/add-employee/add-employee.component';
import { AgGridModule } from 'ag-grid-angular';
import { ReportModuleWithoutRouting } from './../report/report.module';
import { RemoveInvitationGridcellComponent } from './_shared/grid-cell/employee-tab/remove-invitation-gridcell/remove-invitation-gridcell.component';
import { MySignaturesPageComponent } from './my-signatures-page/my-signatures-page.component';
import { MyCompanyPageComponent } from './my-company-page/my-company-page.component';
import { HierarchyTabComponent } from './my-company-page/hierarchy-tab/hierarchy-tab.component';
import { EmployeesTabComponent } from './my-company-page/employees-tab/employees-tab.component';
import { AdministrationComponent } from './my-company-page/administration/administration.component';
import { CompanyPresentationPageComponent } from './company-presentation-page/company-presentation-page.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CompanyCustomFieldsTabComponent } from './my-company-page/company-custom-fields-tab/company-custom-fields-tab.component';
import { CreateCompanyComponent } from './my-company-page/create-company/create-company.component';
import { LicensesPageComponent } from './my-company-page/licenses-page/licenses-page.component';
import { ResendInvitationGridcellComponent } from './_shared/grid-cell/employee-tab/resend-invitation-gridcell/resend-invitation-gridcell.component';
import { SelectRoleGridcellComponent } from './_shared/grid-cell/employee-tab/select-role-gridcell/select-role-gridcell.component';
import { SubscriptionGridcellComponent } from './_shared/grid-cell/employee-tab/subscription-gridcell/subscription-gridcell.component';
import { ManagerCelleditorComponent } from './_shared/grid-cell/manager-celleditor/manager-celleditor.component';
import { ManagerGridcellComponent } from './_shared/grid-cell/manager-gridcell/manager-gridcell.component';
import { ReportsToSignComponent } from './my-signatures-page/reports-to-sign/reports-to-sign.component';
import { ReportsSignedComponent } from './my-signatures-page/reports-signed/reports-signed.component';
import { SelectManagerComponent } from './my-company-page/hierarchy-tab/select-manager/select-manager.component';
import { CustomFieldsComponent } from './_shared/custom-fields/custom-fields.component';
import { ExpenseModuleWithoutRouting } from '../expense/expense.module';
import { TagInputModule } from 'ngx-chips';
import { NbCheckboxModule, NbBadgeModule, NbAccordionModule } from '@nebular/theme';
import { MyAccountantPageComponent } from './accountant/my-accountant-page/my-accountant-page.component';
import { AccountantAdministrationComponent } from './accountant/accountant-settings-page/accountant-administration/accountant-administration.component';
import { SelectCompanyComponent } from './_shared/select-company/select-company.component';
import { VpConsumptionComponent } from './my-company-page/vp-consumption/vp-consumption.component';
import { PaymentModuleWithoutRouting } from '../payment/payment.module';
import { FormsModule } from '@angular/forms';
import { EditExportPageComponent } from './accountant/accountant-exports-page/edit-export-page/edit-export-page.component';
import { AccountantExportsComponent } from './accountant/accountant-exports-page/accountant-exports-page.component';
import { ExportFormatGridcellComponent } from './accountant/accountant-exports-page/gridcell/export-format-gridcell/export-format-gridcell.component';
import { ExportArchivedGridcellComponent } from './accountant/accountant-exports-page/gridcell/export-archived-gridcell/export-archived-gridcell.component';
import { SelectReportModalComponent } from './accountant/accountant-exports-page/select-report-modal/select-report-modal.component';
import { AccountantExportsGridComponent } from './accountant/accountant-exports-page/accountant-exports-grid/accountant-exports-grid.component';
import { RulesTabComponent } from './my-company-page/rules-tab/rules-tab.component';
import { LimitRuleFormComponent } from './my-company-page/rules-tab/rule-forms/limit-rule-form/limit-rule-form.component';
import { CreateRuleModalComponent } from './my-company-page/rules-tab/create-rule-modal/create-rule-modal.component';
import { RuleTypeSelectorComponent } from './my-company-page/rules-tab/rule-type-selector/rule-type-selector.component';
import { ExpensesToBeExportedPageComponent } from './accountant/expenses-to-be-exported-page/expenses-to-be-exported-page.component';
import { SettingsTabComponent } from './my-company-page/settings-tab/settings-tab.component';
import { AccountingPlanTabComponent } from './my-company-page/accounting-plan-tab/accounting-plan-tab.component';
import { AccountantSettingsPageComponent } from './accountant/accountant-settings-page/accountant-settings-page.component';
import { CreateVehiclesComponent } from './_shared/create-vehicles/create-vehicles.component';
import { EmployeeCategoryTabComponent } from './my-company-page/employee-categories-tab/employee-categories-tab.component';
import { EmployeeCategoriesTableComponent } from './my-company-page/employee-categories-tab/employee-categories-table/employee-categories-table.component';


@NgModule({
  declarations: [
    RemoveInvitationGridcellComponent,
    ResendInvitationGridcellComponent,
    SelectRoleGridcellComponent,
    SubscriptionGridcellComponent,
    ManagerCelleditorComponent,
    ManagerGridcellComponent,
    CustomFieldsComponent,
    AccountantExportsComponent,
    EditExportPageComponent,
    AccountantSettingsPageComponent,
    CompanyPresentationPageComponent,
    AdministrationComponent,
    CompanyCustomFieldsTabComponent,
    CreateCompanyComponent,
    AddEmployeeComponent,
    EmployeesTabComponent,
    HierarchyTabComponent,
    SelectManagerComponent,
    LicensesPageComponent,
    SettingsTabComponent,
    AccountingPlanTabComponent,
    EmployeeCategoryTabComponent,
    MyCompanyPageComponent,
    ReportsSignedComponent,
    ReportsToSignComponent,
    MySignaturesPageComponent,
    ManageEmployeeSettingsModalComponent,
    ExportFormatGridcellComponent,
    SelectReportModalComponent,
    ExportArchivedGridcellComponent,
    ExpensesToBeExportedPageComponent,
    MyAccountantPageComponent,
    AccountantAdministrationComponent,
    SelectCompanyComponent,
    VpConsumptionComponent,
    AccountantExportsGridComponent,
    RulesTabComponent,
    LimitRuleFormComponent,
    CreateRuleModalComponent,
    RuleTypeSelectorComponent,
    CreateVehiclesComponent,
    EmployeeCategoriesTableComponent,
  ],
  imports: [
    SharedModule,
    AgGridModule,
    TagInputModule,
    NbCheckboxModule,
    NbBadgeModule,
    ReportModuleWithoutRouting,
    ExpenseModuleWithoutRouting,
    PaymentModuleWithoutRouting,
    FormsModule,
    NbAccordionModule
  ],
  exports: [
    CustomFieldsComponent,
    RemoveInvitationGridcellComponent,
    ResendInvitationGridcellComponent,
    SelectRoleGridcellComponent,
    SubscriptionGridcellComponent,
    ManagerCelleditorComponent,
    ManagerGridcellComponent
  ]
})
export class CompanyModuleWithoutRouting { }

@NgModule({
  imports: [
    CompanyRoutingModule,
    CompanyModuleWithoutRouting,
  ],
  exports: [
    CompanyModuleWithoutRouting
  ]
})
export class CompanyModule { }
