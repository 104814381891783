import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { CompanyStateModel } from './company.model';
import { CompanyAction } from './company.action';
import { CompanyService } from './company.service';
import { Company } from '../../_models/Company';
import { tap } from 'rxjs/operators';
import { patch } from '@ngxs/store/operators';
import { TranslateService } from '@ngx-translate/core';
import { OnUser } from '../../_models/OnUser';
import { CustomField } from '../../_models/CustomField';
import { ExpenseAction } from '../expense/expense.actions';
import { UserActions } from '../user/user.actions';
import { ToastService } from 'on-common/_services/toast.service';
import Swal from 'sweetalert2';

@State<CompanyStateModel>({
  name: 'company',
  defaults: new CompanyStateModel()
})
@Injectable()
export class CompanyState {
  constructor(
    private companyService: CompanyService,
    private store: Store,
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  @Selector()
  static current(state: CompanyStateModel) {
    return state.current;
  }

  @Selector()
  static isLoadingDomainNames(state: CompanyStateModel) {
    return state.isLoadingDomainNames;
  }

  @Selector()
  static domainNames(state: CompanyStateModel) {
    return state.domainNames;
  }

  @Selector()
  static employeeCategories(state: CompanyStateModel) {
    return state.employeeCategories;
  }

  @Action(CompanyAction.LoadCompanies)
  public loadCompanies(ctx: StateContext<CompanyStateModel>) {
    //return forkJoin<IApiResult<Company>, IApiResult<Company[]>>([this.companyService.GetCompany(), this.companyService.GetCompanies()])
    return this.companyService.GetCompany().pipe(tap(companyResult => {

      // Company
      //const companyResult = results[0] as IApiResult<Company>;
      if (companyResult.IsSuccess) {
        ctx.setState(patch({
          current: companyResult.Result
        }));
      } else {
        this.toastService.error(this.translateService.instant('company.load-company-error'));
      }

      // Companies
      /*const companiesResult = results[1] as IApiResult<Company[]>;
      if (companiesResult.IsSuccess) {
        ctx.setState(patch({
          companies: companiesResult.Result
        }));
      } else {
        this.toastService.error(this.translateService.instant('company.load-companies-error'));
      }
      ctx.patchState({
        shouldReloadCompanies: false,
        shouldReloadEmployees: true,
        shouldReloadHierarchy: true,
        shouldReloadCustomFields: true,
      });*/
    }, error => {
      this.toastService.error(this.translateService.instant('company.load-data-error'));
    }));
  }

  @Action(CompanyAction.LoadCompaniesIfNeeded)
  public loadCompaniesIfNeeded(ctx: StateContext<CompanyStateModel>) {
    const state = ctx.getState();
    if (state.shouldReloadCompanies || !state.current) {
      return this.loadCompanies(ctx);
    }
  }
  @Action(CompanyAction.ShouldReloadEmployees)
  public shouldReloadEmployees(ctx: StateContext<CompanyStateModel>) {
    const state = ctx.getState();
    ctx.patchState({ shouldReloadEmployees: true });
  }

  @Action(CompanyAction.SaveAuthorizationsChanges)
  public saveAuthorizationsChanges(ctx: StateContext<CompanyStateModel>, { payload }: CompanyAction.SaveAuthorizationsChanges) {

    return this.companyService.SaveAuthorizationsChanges(payload)
      .pipe(tap(response => {
        if (response.IsSuccess) {
          ctx.setState(patch({
            current: payload
          }));
          // Notif
          this.toastService.success(
            this.translateService.instant('company.administration.update-success'),
            this.translateService.instant('company.administration.update-success-status'));
        } else {
          const initialData = ctx.getState().current;
          ctx.patchState({
            current: new Company(initialData)
          });
          // Notif
          this.toastService.error(response.Message, this.translateService.instant('company.administration.error-status'));
        }
      }, error => {
        const initialData = ctx.getState().current;
        ctx.patchState({
          current: new Company(initialData)
        });
        // Notif
        this.toastService.error(this.translateService.instant('company.administration.error-status'));
      }));
  }

  @Action(CompanyAction.UpdateMileageSettings)
  public updateMileageSettings(ctx: StateContext<CompanyStateModel>, { toUpdateCompany }: CompanyAction.UpdateMileageSettings) {
    return this.companyService.UpdateMileageSettings(toUpdateCompany)
      .pipe(tap(response => {
        if (response.IsSuccess) {
          this.toastService.success(this.translateService.instant('company.mileage-settings.updated-success'));

          ctx.patchState({
            current: new Company(response.Result)
          });

        } else {
          this.toastService.error(this.translateService.instant('company.mileage-settings.updated-failed'));
        }
      }, error => {
        this.toastService.error(this.translateService.instant('company.mileage-settings.updated-failed'));
      }));
  }

  @Action(CompanyAction.SaveRestaurantTicket)
  public saveRestaurantTicket(ctx: StateContext<CompanyStateModel>, { company }: CompanyAction.SaveRestaurantTicket) {
    return this.companyService.UpdateRestaurantTicketSettings(company.UseRestaurantTicket, company.RestaurantTicketValue)
      .pipe(tap(response => {
        if (response.IsSuccess) {
          this.toastService.success(this.translateService.instant('company.restaurant-ticket.updated-success'));

          ctx.patchState({
            current: new Company(response.Result)
          });
        } else {
          this.toastService.error(this.translateService.instant('company.restaurant-ticket.updated-failed'));
        }
      }, error => {
        this.toastService.error(this.translateService.instant('company.restaurant-ticket.updated-failed'));
      }));
  }

  @Action(CompanyAction.Delete)
  public delete(ctx: StateContext<CompanyStateModel>) {
    return this.companyService.Delete()
      .pipe(tap(response => {
        if (response.IsSuccess) {
          this.toastService.success(this.translateService.instant('company.administration.swarl-success'));

          this.companyService.SetCompanySelected(null).subscribe(() => {
            location.reload();
          });
        } else {
          this.toastService.error(this.translateService.instant('company.administration.swarl-error'));
        }
      }, error => {
        this.toastService.error(this.translateService.instant('company.administration.swarl-error'));
      }));
  }

  @Action(CompanyAction.LoadEmployees)
  public loadEmployees(ctx: StateContext<CompanyStateModel>) {
    return this.companyService.GetEmployees()
      .pipe(tap(response => {
        if (response.IsSuccess) {
          const initialData = ctx.getState().current;
          const newData = new Company(initialData);
          newData.CompanyEmployeesData = response.Result;

          ctx.patchState({
            current: newData,
            shouldReloadEmployees: false
          });
        } else {
          this.toastService.error(this.translateService.instant('company.employees.cannot-load-employees'));
        }
      }, error => {
        this.toastService.error(this.translateService.instant('company.employees.cannot-load-employees'));
      }));
  }

  @Action(CompanyAction.LoadEmployeesIfNeeded)
  public loadEmployeesIfNeeded(ctx: StateContext<CompanyStateModel>) {
    const state = ctx.getState();
    // if (state.shouldReloadEmployees || !state.current.CompanyEmployeesData) {
    // Always reload for now
    return this.loadEmployees(ctx);
    // }
  }

  @Action(CompanyAction.AddEmployees)
  public addEmployees(ctx: StateContext<CompanyStateModel>, { emails }: CompanyAction.AddEmployees) {
    return this.companyService.AddEmployees(emails)
      .pipe(tap(response => {
        if (response.IsSuccess) {
          // Reload employees
          this.store.dispatch(new CompanyAction.LoadEmployees());

          this.toastService.success(this.translateService.instant('company.employees.add-employee.success'));
        } else {
          this.toastService.error(this.translateService.instant('company.employees.add-employee.error'));
        }
      }, error => {
        this.toastService.error(this.translateService.instant('company.employees.add-employee.error'));
      }));
  }

  @Action(CompanyAction.CancelInvitation)
  public cancelInvitation(ctx: StateContext<CompanyStateModel>, { email }: CompanyAction.CancelInvitation) {
    return this.companyService.CancelInvitation(email)
      .pipe(tap(response => {
        if (response.IsSuccess) {
          const initialData = ctx.getState().current;
          const newData = new Company(initialData);
          const indexToDelete = newData.CompanyEmployeesData.Invitations.findIndex(inv => inv.InvitedEmail === email);
          newData.CompanyEmployeesData.Invitations.splice(indexToDelete, 1);

          ctx.patchState({
            current: newData
          });

          this.toastService.success(this.translateService.instant('company.employees.swarl-remove-invitation-success', { email }));
        } else {
          this.toastService.error(this.translateService.instant('company.employees.swarl-remove-invitation-error'));
        }
      }, error => {
        this.toastService.error(this.translateService.instant('company.employees.swarl-remove-invitation-error'));
      }));
  }

  @Action(CompanyAction.KickEmployee)
  public kickEmployee(ctx: StateContext<CompanyStateModel>, { employee, newManagerId }: CompanyAction.KickEmployee) {
    return this.companyService.KickEmployee(employee, newManagerId)
      .pipe(tap(response => {
        if (response.IsSuccess) {
          const initialData = ctx.getState().current;
          const newData = new Company(initialData);
          const indexToDelete = newData.CompanyEmployeesData.EmployeeRoles.findIndex(emp => emp.Employee.Id === employee.Id);
          newData.CompanyEmployeesData.EmployeeRoles.splice(indexToDelete, 1);

          ctx.patchState({
            current: newData
          });
        } else {
          this.toastService.error(this.translateService.instant('company.employees.swarl-kick-error'));
        }
      }, error => {
        this.toastService.error(this.translateService.instant('company.employees.swarl-kick-error'));
      }));
  }

  @Action(CompanyAction.AssignSubscription)
  public assignSubscription(ctx: StateContext<CompanyStateModel>, { userId, shouldApply }: CompanyAction.AssignSubscription) {
    return this.companyService.ApplySubscription(userId, shouldApply).pipe(tap(response => {
      if (response.IsSuccess) {
        const initialData = ctx.getState().current;
        const newData = new Company(initialData);
        newData.CompanyEmployeesData.EmployeeRoles.find(er => er.Employee.Id === userId).HasSubscription = shouldApply;
        if (shouldApply) {
          newData.CompanyEmployeesData.TotalSubscriptionNotAssigned--;
        } else {
          newData.CompanyEmployeesData.TotalSubscriptionNotAssigned++;
        }

        ctx.patchState({
          current: newData
        });
      } else {
        const initialData = ctx.getState().current;
        ctx.patchState({
          current: new Company(initialData)
        });
        // Notif
        this.toastService.error(
          this.translateService.instant(
            'company.employees.cannot-assign-sub',
          ),
        );
      }
    }, error => {
      const initialData = ctx.getState().current;
      ctx.patchState({
        current: new Company(initialData)
      });
      // Notif
      this.toastService.error(
        this.translateService.instant(
          'company.employees.cannot-assign-sub',
        ),
      );
    }));
  }

  @Action(CompanyAction.ChangeEmployeeRole)
  public changeEmployeeRole(ctx: StateContext<CompanyStateModel>, { companyId, userId, role }: CompanyAction.ChangeEmployeeRole) {
    return this.companyService.ChangeEmployeeRole(companyId, userId, role).pipe(tap(response => {
      if (response.IsSuccess) {
        const initialData = ctx.getState().current;
        const newData = new Company(initialData);
        newData.CompanyEmployeesData.EmployeeRoles.find(er => er.Employee.Id === userId).Employee.Role = role;

        ctx.patchState({
          current: newData
        });
        // Notif
        this.toastService.success(
          this.translateService.instant('company.employees.updated'),
        );
      } else {
        const initialData = ctx.getState().current;
        ctx.patchState({
          current: new Company(initialData)
        });
        // Notif
        this.toastService.error(
          this.translateService.instant('company.employees.cannot-updated'),
        );
      }
    }, error => {
      const initialData = ctx.getState().current;
      ctx.patchState({
        current: new Company(initialData)
      });
      // Notif
      this.toastService.error(
        this.translateService.instant('company.employees.cannot-updated'),
      );
    }));
  }

  @Action(CompanyAction.UpdateEmployee)
  public updateEmployee(ctx: StateContext<CompanyStateModel>, { employee }: CompanyAction.UpdateEmployee) {
    return this.companyService.UpdateEmployee(employee).pipe(tap(response => {

      if (response.IsSuccess) {
        const newData = new Company(ctx.getState().current);

        newData.CompanyEmployeesData.EmployeeRoles.find(emp => emp.Employee.Id === employee.Id).Employee = response.Result;

        ctx.setState(patch({
          current: newData
        }));

        this.store.dispatch(new UserActions.GetCurrentUser());

        this.toastService.success(
          this.translateService.instant('company.employees.updated'));
      } else {
        this.toastService.error(
          this.translateService.instant('company.employees.cannot-updated'));
      }
    }, error => {
      this.toastService.error(
        this.translateService.instant('company.employees.cannot-updated'));
    }));
  }

  @Action(CompanyAction.LoadHierachy)
  public loadHierarchy(ctx: StateContext<CompanyStateModel>) {
    return this.companyService.GetHierarchy()
      .pipe(tap(response => {
        if (response.IsSuccess) {
          const initialData = ctx.getState().current;
          const newData = new Company(initialData);
          newData.Hierarchy = response.Result;

          ctx.patchState({
            current: newData,
            shouldReloadHierarchy: false
          });
        } else {
          this.toastService.error(this.translateService.instant('company.hierarchy.cannot-load'));
        }
      }, error => {
        this.toastService.error(this.translateService.instant('company.hierarchy.cannot-load'));
      }));
  }

  @Action(CompanyAction.LoadHierachyIfNeeded)
  public loadHierarchyIfNeeded(ctx: StateContext<CompanyStateModel>) {
    const state = ctx.getState();
    // if (state.shouldReloadHierarchy || !state.current.Hierarchy) {
    // Always reload for now
    return this.loadHierarchy(ctx);
    // }
  }

  @Action(CompanyAction.UpdateHierarchy)
  public updateHierarchy(ctx: StateContext<CompanyStateModel>, { employeeId, managerId, companyId }: CompanyAction.UpdateHierarchy) {
    return this.companyService.UpdateHierarchy(employeeId, managerId, companyId)
      .pipe(tap(response => {
        if (response.IsSuccess) {
          const initialData = ctx.getState().current;
          const newData = new Company(initialData);
          newData.Hierarchy.find(emp => emp.Id === employeeId).ManagerId = managerId;

          ctx.patchState({
            current: newData
          });
        } else {
          const initialData = ctx.getState().current;
          ctx.patchState({
            current: new Company(initialData)
          });
          this.toastService.error(this.translateService.instant('company.hierarchy.cannot-update'));
        }
      }, error => {
        const initialData = ctx.getState().current;
        ctx.patchState({
          current: new Company(initialData)
        });
        this.toastService.error(this.translateService.instant('company.hierarchy.cannot-update'));
      }));
  }

  @Action(CompanyAction.SaveHierarchy)
  public saveHierarchy(ctx: StateContext<CompanyStateModel>, { allUsers }: CompanyAction.SaveHierarchy) {
    return this.companyService.SaveHierarchy(allUsers)
      .pipe(tap(response => {
        if (response.IsSuccess) {
          const initialData = ctx.getState().current;
          const newData = new Company(initialData);
          newData.Hierarchy = allUsers.map((c) => new OnUser(c));

          ctx.patchState({
            current: newData
          });

          this.toastService.success(this.translateService.instant('company.hierarchy.saved'));
        } else {
          const initialData = ctx.getState().current;
          ctx.patchState({
            current: new Company(initialData)
          });
          this.toastService.error(this.translateService.instant('company.hierarchy.cannot-save'));
        }
      }, error => {
        const initialData = ctx.getState().current;
        ctx.patchState({
          current: new Company(initialData)
        });
        this.toastService.error(this.translateService.instant('company.hierarchy.cannot-save'));
      }));
  }

  @Action(CompanyAction.LoadCustomFields)
  public loadCustomFields(ctx: StateContext<CompanyStateModel>) {
    return this.companyService.GetCustomFields()
      .pipe(tap(response => {
        if (response.IsSuccess) {
          const initialData = ctx.getState().current;
          const newData = new Company(initialData);
          newData.CustomFields = response.Result;

          ctx.patchState({
            current: newData,
            shouldReloadCustomFields: false
          });
        } else {
          this.toastService.error(this.translateService.instant('company.custom-fields.load-error'));
        }
      }, error => {
        this.toastService.error(this.translateService.instant('company.custom-fields.load-error'));
      }));
  }

  @Action(CompanyAction.LoadCustomFieldsIfNeeded)
  public loadCustomFieldsIfNeeded(ctx: StateContext<CompanyStateModel>) {
    const state = ctx.getState();
    // if (state.shouldReloadCustomFields || !state.current.CustomFields) {
    // Always reload for now
    return this.loadCustomFields(ctx);
    // }
  }

  @Action(CompanyAction.SaveCustomFields)
  public saveCustomFields(ctx: StateContext<CompanyStateModel>, { fieldsToSave }: CompanyAction.SaveCustomFields) {
    return this.companyService.SaveCustomFields(fieldsToSave)
      .pipe(tap(response => {
        if (response.IsSuccess) {
          const initialData = ctx.getState().current;
          const newData = new Company(initialData);
          newData.CustomFields = fieldsToSave.map((c) => new CustomField(c));

          ctx.patchState({
            current: newData
          });

          this.store.dispatch(new ExpenseAction.MustReloadExpenses());

          this.toastService.success(this.translateService.instant('company.custom-fields.save-success'));
        } else {
          const initialData = ctx.getState().current;
          ctx.patchState({
            current: new Company(initialData)
          });
          this.toastService.error(this.translateService.instant('company.custom-fields.save-error'));
        }
      }, error => {
        const initialData = ctx.getState().current;
        ctx.patchState({
          current: new Company(initialData)
        });
        this.toastService.error(this.translateService.instant('company.custom-fields.save-error'));
      }));
  }

  @Action(CompanyAction.SetCompanySelected)
  public setCompanySelected(ctx: StateContext<CompanyStateModel>, { companyId }: CompanyAction.SetCompanySelected) {
    return this.companyService.SetCompanySelected(companyId)
      .pipe(tap(response => {
        if (response.IsSuccess) {
          const newData = ctx.getState().companies.find(company => company.Id === companyId);

          ctx.patchState({
            current: new Company(newData)
          });

          // TODO : Maybe reload the connectedUser instead of reload page
          location.reload();
        } else {
          this.toastService.error(
            this.translateService.instant('company.loading-data-failed'),
            this.translateService.instant('company.loading-data-failed-title')
          );
        }
      }, error => {
        this.toastService.error(
          this.translateService.instant('company.loading-data-failed'),
          this.translateService.instant('company.loading-data-failed-title')
        );
      }));
  }

  @Action(CompanyAction.LoadDomainNames)
  public loadDomainNames(ctx: StateContext<CompanyStateModel>) {
    ctx.patchState({
      isLoadingDomainNames: true
    });
    return this.companyService
      .GetDomainNames()
      .pipe(tap(response => {
        if (response.IsSuccess) {
          ctx.patchState({
            domainNames: response.Result,
            isLoadingDomainNames: false
          });
        } else {
          this.toastService.error(
            this.translateService.instant('company.loading-dn-failed'),
            this.translateService.instant('company.loading-dn-failed-title')
          );
        }
      },
        error => {
          this.toastService.error(
            this.translateService.instant('company.loading-dn-failed'),
            this.translateService.instant('company.loading-dn-failed-title')
          );
        }));
  }

  @Action(CompanyAction.RequestDomainName)
  public requestDomainName(ctx: StateContext<CompanyStateModel>, { domainName }: CompanyAction.RequestDomainName) {
    ctx.patchState({
      isLoadingDomainNames: true
    });
    return this.companyService.RequestDomainName(domainName)
      .pipe(tap(response => {
        if (response.IsSuccess) {
          ctx.patchState({
            isLoadingDomainNames: false,
            domainNames: response.Result
          });
          Swal.fire(
            this.translateService.instant('company.request-dn-success-title'),
            this.translateService.instant('company.request-dn-success'),
            'success'
          );
        } else {
          Swal.fire(
            this.translateService.instant('company.request-dn-failed-title'),
            this.translateService.instant('company.request-dn-failed'),
            'error'
          );
        }
      },
        error => {
          Swal.fire(
            this.translateService.instant('company.request-dn-failed-title'),
            this.translateService.instant('company.request-dn-failed'),
            'error'
          );
        }));
  }

  @Action(CompanyAction.LoadEmployeeCategories)
  public loadEmployeeCategories(ctx: StateContext<CompanyStateModel>) {
    return this.companyService.GetEmployeeCategories(ctx.getState().current.Id)
      .pipe(tap(response => {
        if (response.IsSuccess) {
          ctx.patchState({
            employeeCategories: response.Result,
            shouldReloadEmployeesCategories: false
          });
        } else {
          this.toastService.error(this.translateService.instant('company.category.table.cannot-load-categories'));
        }
      }, error => {
        this.toastService.error(this.translateService.instant('company.category.table.cannot-load-categories'));
      }));
  }

  @Action(CompanyAction.LoadEmployeeCategoriesIfNeeded)
  public loadEmployeeCategoriesIfNeeded(ctx: StateContext<CompanyStateModel>) {
    const state = ctx.getState();
    if (state.shouldReloadEmployeesCategories || !state.employeeCategories) {
      return this.loadEmployeeCategories(ctx);
    }
  }

  @Action(CompanyAction.AddEmployeeCategory)
  public addEmployeeCategory(ctx: StateContext<CompanyStateModel>, { employeeCategory }: CompanyAction.AddEmployeeCategory) {
    return this.companyService.AddEmployeeCategory(employeeCategory.CompanyId, employeeCategory)
      .pipe(tap(response => {
        if (response.IsSuccess) {
          ctx.patchState({
            employeeCategories: response.Result
          });
        } else {
          this.toastService.error(this.translateService.instant('company.category.table.cannot-add-category'));
        }
      }, error => {
        this.toastService.error(this.translateService.instant('company.category.table.cannot-add-category'));
      }));
  }

  @Action(CompanyAction.UpdateEmployeeCategory)
  public updateEmployeeCategory(ctx: StateContext<CompanyStateModel>, { employeeCategory }: CompanyAction.UpdateEmployeeCategory) {
    return this.companyService.UpdateEmployeeCategory(employeeCategory.CompanyId, employeeCategory)
      .pipe(tap(response => {
        if (response.IsSuccess) {
          ctx.patchState({
            employeeCategories: response.Result
          });
        } else {
          this.toastService.error(this.translateService.instant('company.category.table.cannot-update-category'));
        }
      }, error => {
        this.toastService.error(this.translateService.instant('company.category.table.cannot-update-category'));
      }));
  }

  @Action(CompanyAction.DeleteEmployeeCategory)
  public deleteEmployeeCategory(ctx: StateContext<CompanyStateModel>, { employeeCategory }: CompanyAction.DeleteEmployeeCategory) {
    return this.companyService.DeleteEmployeeCategory(employeeCategory.CompanyId, employeeCategory.Id)
      .pipe(tap(response => {
        if (response.IsSuccess) {
          ctx.patchState({
            employeeCategories: response.Result
          });
        } else {
          this.toastService.error(this.translateService.instant('company.category.table.cannot-delete-category'));
        }
      }, error => {
        this.toastService.error(this.translateService.instant('company.category.table.cannot-delete-category'));
      }));
  }
}
