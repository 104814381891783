<on-loading *ngIf="isLoading"></on-loading>

<div *ngIf="!isLoading">

  <on-two-columns-display [title]="'company.mileage-expenses.rights-title' | translate">
    <div description>
      <p>{{'company.mileage-expenses.rights-description' | translate}}</p>
    </div>

    <div content class="row" *ngIf="company">
      <div class="col-12 m-0">
        <div class="form-check col-12 mb-1">
          <input class="form-check-input mr-2" type="radio" name="choice" value="FrenchLegal"
            [(ngModel)]="company.Settings.UseMileageScale" id="french-legal-choice">
          <label class="form-check-label" for="french-legal-choice"><b>{{ 'company.mileage-expenses.use-scale' |
              translate }}
              <a target="_blank" style="cursor: help;"
                href="https://www.urssaf.fr/portail/home/taux-et-baremes/indemnites-kilometriques/voiture.html">
                {{ 'company.mileage-expenses.national-scale' | translate }}
              </a>
            </b>
          </label>
        </div>

        <div class="form-check col-12 mb-1">
          <input class="form-check-input mr-2" type="radio" name="choice" value="GlobalCompanyRate"
            [(ngModel)]="company.Settings.UseMileageScale" id="company-rate-choice">
          <label class="form-check-label" for="company-rate-choice"><b>{{ 'company.mileage-expenses.use-custom-rates' |
              translate }}</b></label>
          <div class="row mt-2 col-12" *ngIf="company.Settings.UseMileageScale == 'GlobalCompanyRate'">
            <div class="col-5">
              <label class="form-check-label ps-0 justify-content-start">{{ 'vehicle.types.Auto' | translate }}</label>
              <div class="input-group">
                <input class="form-control col-6" type="number" step="0.01" [(ngModel)]="company.Settings.RateAuto">
                <span class="input-group-text" *ngIf="company?.Currency?.Symbol">
                  {{ company.Currency.Symbol }} / km
                </span>
              </div>
            </div>
            <div class="col-5">
              <label class="form-check-label ps-0 justify-content-start">{{ 'vehicle.types.Moto' | translate }}</label>
              <div class="input-group">
                <input class="form-control col-6" type="number" step="0.01" [(ngModel)]="company.Settings.RateMoto">
                <span class="input-group-text" *ngIf="company?.Currency?.Symbol">
                  {{ company.Currency.Symbol }} / km
                </span>
              </div>
            </div>
            <div class="col-5">
              <label class="form-check-label ps-0 justify-content-start">{{ 'vehicle.types.Scooter' | translate
                }}</label>
              <div class="input-group">
                <input class="form-control col-6" type="number" step="0.01" [(ngModel)]="company.Settings.RateScooter">
                <span class="input-group-text" *ngIf="company?.Currency?.Symbol">
                  {{ company.Currency.Symbol }} / km
                </span>
              </div>
            </div>
            <div class="col-5">
              <label class="form-check-label ps-0 justify-content-start">{{ 'vehicle.types.Bicycle' | translate
                }}</label>
              <div class="input-group">
                <input class="form-control col-6" type="number" step="0.01" [(ngModel)]="company.Settings.RateBicycle">
                <span class="input-group-text" *ngIf="company?.Currency?.Symbol">
                  {{ company.Currency.Symbol }} / km
                </span>
              </div>
            </div>


            <div class="form-check col-12 mb-1 ml-4 mt-4">
              <label class="form-check-label">{{ 'company.mileage-expenses.bicycleTotalMax' | translate }}</label>
              <div class="input-group">
                <input class="form-control col-4" type="number" step="1" [(ngModel)]="company.Settings.BicycleTotalMax">
                <span class="input-group-text" *ngIf="company?.Currency?.Symbol">
                  {{ company.Currency.Symbol }} / {{ 'company.mileage-expenses.year' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-check col-12 mb-1">
          <input class="form-check-input mr-2" type="radio" name="choice" value="CustomUserRate"
            [(ngModel)]="company.Settings.UseMileageScale" id="user-rate">
          <label class="form-check-label" for="user-rate"><b>{{ 'company.mileage-expenses.user-rate' | translate }}
            </b>
          </label>
        </div>

        <div class="col-12 d-flex justify-content-center">
          <button class="btn btn-primary" (click)="saveMileageSettings()">
            {{ 'company.mileage-expenses.save' | translate }}</button>
        </div>
      </div>
    </div>
  </on-two-columns-display>

  <on-two-columns-display [title]="'company.restaurant-ticket.title' | translate">
    <div description>
      <p>
        {{ 'company.restaurant-ticket.description' | translate }}
      </p>
    </div>
    <div content class="row" *ngIf="company">
      <div class="col-12">
        <div class="form-check col-12 mb-1">
          <input class="form-check-input mr-2" type="checkbox" [(ngModel)]="company.UseRestaurantTicket"
            id="activate-restaurant-ticket">
          <label class="form-check-label" for="activate-restaurant-ticket">
            <b>{{ 'company.restaurant-ticket.use-tickets' | translate }}</b></label>
        </div>
      </div>
      <div class="form-group col-12 mb-1" *ngIf="company.UseRestaurantTicket">
        <label class="form-check-label" for="restaurant-ticket-value">
          <b>{{ 'company.restaurant-ticket.ticket-value'| translate }}</b></label>
        <div class="input-group">
          <input style="max-width: 100pt;" class="form-control" type="input" libEnforcePointSeparator
            [(ngModel)]="company.RestaurantTicketValue" id="restaurant-ticket-value">
          <span class="input-group-text">{{company.Currency.Symbol}}</span>
        </div>
      </div>

      <div class="col-12 d-flex justify-content-center">
        <button class="btn btn-primary" (click)="updateCompanyRestaurantTicket()">
          {{ 'company.mileage-expenses.save' | translate }}</button>
      </div>
    </div>
  </on-two-columns-display>

  <on-two-columns-display [title]="'company.administration.rights-title' | translate">
    <div description>
      <p>{{'company.administration.rights-description' | translate}}</p>
    </div>

    <div content class="row col-12" *ngIf="company">
      <div class="col-12 form-check">
        <input class="form-check-input" type="checkbox" id="manager" [(ngModel)]="company.ManagerCanEdit"
          (change)="updateCompanyRights()">
        <label class="form-check-label" for="manager">{{'company.administration.manager-can-manage' |
          translate}}</label>
      </div>
      <br>
      <br>
      <div class="col-12 form-check">
        <input class="form-check-input" type="checkbox" id="accountant" [(ngModel)]="company.AccountantCanEdit"
          (change)="updateCompanyRights()">
        <label class="form-check-label" for="accountant">{{'company.administration.accountant-can-unvalidate' |
          translate}}</label>
      </div>
    </div>
  </on-two-columns-display>

  <on-two-columns-display [title]="'company.administration.dn-title' | translate">
    <div description>
      <p>{{'company.administration.dn-description' | translate}}</p>
    </div>
    <div content>
      <p>{{'company.administration.dn-only-ent' | translate}}</p>
      <table class="table table-bordered">
        <tr>
          <th>{{'company.administration.domainName' | translate }}</th>
          <th>{{'company.administration.dn-isValidated' | translate }}</th>
        </tr>
        <tr *ngFor="let dn of domainNames$ | async">
          <td>{{dn.Value}}</td>
          <td>
            <nb-icon *ngIf="!dn.IsValidated" icon="fa-question" status="warning"></nb-icon>
            <nb-icon *ngIf="dn.IsValidated" icon="fa-check" status="success"></nb-icon>
          </td>
        </tr>
      </table>
      <button class="btn btn-primary" (click)="requestNewDomainName()">
        {{'company.administration.request-dn' | translate }}
      </button>
    </div>
  </on-two-columns-display>

  <on-two-columns-display [title]="'company.administration.delete-company-title' | translate">
    <div description>
      <p>{{'company.administration.delete-company-description-1' | translate}}</p>
      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <nb-icon icun="fa-exclamation-triangle" class="red"></nb-icon>
        </div>
        <div class="col">
          {{'company.administration.delete-company-description-2' | translate}}
        </div>
      </div>

    </div>
    <div content>
      <button class="btn btn-danger" (click)="deleteCompany()">{{'company.administration.delete-company-btn' |
        translate}}</button>
    </div>
  </on-two-columns-display>
</div>