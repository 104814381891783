import { UserStateModel } from './user.model';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UserActions } from './user.actions';
import { UserService } from './user.service';
import { map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { StateResetAll } from 'ngxs-reset-plugin';
import { Router } from '@angular/router';
import { ToastService } from 'on-common/_services/toast.service';
import { AccountService } from 'on-auth/_services/account.service';

@State<UserStateModel>({
  name: `user`,
  defaults: new UserStateModel(),
})
@Injectable()
export class UserState {
  constructor(
    private userService: UserService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private accountService: AccountService,
    private routerService: Router
  ) { }

  @Selector()
  static currentUser(userStateModel: UserStateModel) {
    return userStateModel.current;
  }

  @Selector()
  static selectedUser(userStateModel: UserStateModel) {
    return userStateModel.selectedUser;
  }

  @Action(UserActions.GetCurrentUser)
  public getCurrentUser(ctx: StateContext<UserStateModel>) {
    return this.userService.GetUser().pipe(
      map((res) => {
        ctx.patchState({
          current: res.Result,
          isSuccess: res.IsSuccess,
        });
        if (!res.IsSuccess) {
          this.toastService.error(
            this.translateService.instant('user.loading-data-failed'),
            this.translateService.instant('user.loading-data-failed-title'),
          );
        }
        return res;
      }),
    );
  }

  @Action(UserActions.SaveUserSettings)
  public saveUserSettings(
    ctx: StateContext<UserStateModel>,
    { userToSave }: UserActions.SaveUserSettings,
  ) {
    return this.userService.SaveUserSettings(userToSave).pipe(
      map((res) => {
        if (res.Result.Id === ctx.getState().current.Id) {
          ctx.patchState({
            current: res.Result,
          });
        }
        ctx.patchState({
          isSuccess: res.IsSuccess,
        });
        if (res.IsSuccess) {
          this.toastService.success('Votre profil a été enregistré');
        } else {
          this.toastService.error('Impossible d\'enregistrer votre profil');
        }
      }),
    );
  }

  @Action(UserActions.DeleteAccount)
  public deleteAccount(ctx: StateContext<UserStateModel>) {
    return this.userService.DeleteAccount().pipe(
      map((res) => {
        if (res.IsSuccess) {
          ctx.patchState({
            current: null,
          });
          this.toastService.success(
            this.translateService.instant('account.swarl-confirmation-message'),
          );
          this.routerService.navigateByUrl('/login');
        } else {
          this.toastService.error(
            this.translateService.instant('account.swarl-error-message'),
          );
        }
        ctx.patchState({
          isSuccess: res.IsSuccess,
        });
      }),
    );
  }

  @Action(UserActions.ChangePassword)
  public changePassword(
    ctx: StateContext<UserStateModel>,
    { oldPassword, newPassword, confirmPassword }: UserActions.ChangePassword,
  ) {
    this.userService
      .ChangePassword(oldPassword, newPassword, confirmPassword)
      .pipe(
        map((res) => {
          if (res.IsSuccess) {
            this.toastService.success(
              this.translateService.instant('account.password-change-success'),
            );
          } else {
            this.toastService.error(
              this.translateService.instant('account.password-change-error'),
            );
          }
          ctx.patchState({
            isSuccess: res.IsSuccess,
          });
        }),
      );
  }

  @Action(UserActions.Logout)
  public logout(ctx: StateContext<UserStateModel>) {
    // Clear all states
    this.accountService.logout();
    ctx.dispatch(new StateResetAll());
  }

  @Action(UserActions.ExternalLogin)
  public externalLogin(ctx: StateContext<UserStateModel>,
    { jwtToken }: UserActions.ExternalLogin) {
    this.accountService.SetJwtToken(jwtToken);

    return this.getCurrentUser(ctx).pipe(tap(() => {
      this.routerService.navigate(['/expenses']);
    }));
  }
}
