import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FileHolder } from 'ng6-image-upload';
import { API_V2_URL } from 'on-common/constants';
import { ToastService } from 'on-common/_services/toast.service';

@Component({
  selector: 'on-send-receipt-page',
  templateUrl: './send-receipt-page.component.html',
  styleUrls: ['./send-receipt-page.component.scss']
})
export class SendReceiptPageComponent implements OnInit {

  imageUploadURL = API_V2_URL + '/expense/upload';

  isUploading = false;
  constructor(
    private toastService: ToastService,
    private translateService: TranslateService) { }

  ngOnInit() {
  }

  uploadFinished(fileHolder: FileHolder) {
    fileHolder.isSuccess = fileHolder.serverResponse.status === 200
      && fileHolder.serverResponse.response.body.IsSuccess;

    if (!fileHolder.isSuccess) {
      this.toastService.error(this.translateService.instant('expense.upload-error'), this.translateService.instant('expense.upload-error-title'));
    }
  }

  uploadStateChanged(isUploading: boolean) {
    this.isUploading = isUploading;
  }
}
