import { AccountingPlan } from "on-shared/_models/AccountingPlan";

export class AccountingPlanModel {
  userAccountingPlans: AccountingPlan[];
  isLoading: boolean;

  constructor(model?: AccountingPlanModel) {
    if (model) {
      this.userAccountingPlans = model.userAccountingPlans;
      this.isLoading = model.isLoading;
    } else {
      this.userAccountingPlans = null;
      this.isLoading = false;
    }
  }
}
