import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Report } from 'on-shared/_models/Report';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SelectReportModalComponent } from '../select-report-modal/select-report-modal.component';
import Swal from 'sweetalert2';
import { VPState, AccountantExport } from 'onexpense/company/_models/AccountantExport';
import { Store, Select } from '@ngxs/store';
import { AccountantExportAction } from 'on-shared/stores/accountant-export/accountant-export.actions';
import { AccountantExportState } from 'on-shared/stores/accountant-export/accountant-export.state';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CompanyState } from 'on-shared/stores/company/company.state';
import { Company } from 'on-shared/_models/Company';
import { CompanyAction } from 'on-shared/stores/company/company.action';
import { ToastService } from 'on-common/_services/toast.service';

@Component({
  selector: 'on-edit-export-page',
  templateUrl: './edit-export-page.component.html',
  styleUrls: ['./edit-export-page.component.scss']
})
export class EditExportPageComponent implements OnInit, OnDestroy {

  exportId: number;
  isNew = true;
  isLoading = false;
  gettingReports = false;
  canBeArchivedVP = false;
  isArchived = false;
  isBeingArchived = false;
  isTreated = false;

  canEdit: boolean;

  exportForm: UntypedFormGroup;

  includedReports: Report[] = [];
  toBeIncludedReports: Report[] = [];
  period: any = null;

  @Select(AccountantExportState.getEditingExport) export$: Observable<AccountantExport>;
  @Select(CompanyState.current) company$: Observable<Company>;
  destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private toastService: ToastService,
    private bsModal: NgbModal,
    private ref: ChangeDetectorRef,
    private translateService: TranslateService,
    private store: Store
  ) { }

  ngOnInit() {
    this.exportForm = this.fb.group({
      Id: this.fb.control(0),
      Name: this.fb.control('', Validators.required),
      Comment: this.fb.control(''),
      PeriodBeginDate: this.fb.control(''),
      PeriodEndDate: this.fb.control('')
    });

    if (this.route.snapshot.params.exportId) {
      this.exportId = +this.route.snapshot.params.exportId;
      this.isNew = false;
    }

    this.store.dispatch(new CompanyAction.LoadCompaniesIfNeeded());

    this.company$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response) {
        this.canBeArchivedVP = response.IsVP;
      }
    });

    this.Load();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  Load() {
    if (this.exportId) {
      this.isLoading = true;

      this.store.dispatch(new AccountantExportAction.LoadAccountantExportById(this.exportId));
      this.export$.subscribe(response => {
        if (response) {
          const exp = new AccountantExport(response);
          this.exportForm.patchValue(exp);
          this.includedReports = exp.Reports;
          this.isArchived = exp.VPState === VPState.StoredInVP;
          this.isBeingArchived = exp.VPState === VPState.SentToVP;
          this.isTreated = response.IsTreated;

          this.canEdit = !this.isArchived && !this.isBeingArchived && !this.isTreated;

          // If the export is archived or being archived, need to lock the form
          if (!this.canEdit) {
            this.exportForm.disable();
          }

          this.isLoading = false;
        }
      });
    } else {
      // Dates
      const today = new Date();
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      const beginDate = new Date(today.getFullYear(), today.getMonth(), 1);
      const endDate = new Date(today.getFullYear(), today.getMonth(), lastDayOfMonth.getDate());
      this.exportForm.patchValue({
        PeriodBeginDate: new Date(beginDate.getTime()),
        PeriodEndDate: new Date(endDate.getTime()),
      });
      this.canEdit = true;
    }
  }

  Save() {
    const editingExport = this.exportForm.value;
    editingExport.Reports = this.includedReports;
    if (this.isNew) {
      this.store.dispatch(new AccountantExportAction.CreateAccountantExport(editingExport));
    } else {
      this.store.dispatch(new AccountantExportAction.UpdateAccountantExport(editingExport));
    }
  }

  displayActionButtons(): boolean {
    return this.includedReports.filter(r => r.IsSelected).length > 0;
  }

  openAddReportsToExportModal() {
    const modal = this.bsModal.open(SelectReportModalComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title' });
    modal.result.then(data => {
      this.gettingReports = true;
      this.ref.detectChanges();
      if (data.reports) {
        data.reports.map(report => report.IsSelected = false);
        this.includedReports = [...this.includedReports, ...data.reports];

        this.Save();
      }
      this.gettingReports = false;
      this.ref.detectChanges();
    });
  }

  excludeSelectedReports() {
    const reportsToExclude = this.includedReports.filter(r => r.IsSelected);
    if (!reportsToExclude.length) {
      this.toastService.warning(
        this.translateService.instant('company.accountant-export.exclure-report-warning'),
        this.translateService.instant('company.accountant-export.exclure-report-warning-status'));
      return;
    }

    for (const rep of reportsToExclude) {
      rep.IsSelected = false;
      this.includedReports.splice(this.includedReports.indexOf(rep), 1);
    }

    this.Save();
  }

  archivedInProbativeValue() {
    const swal = this.getCustomSwal();

    swal.fire({
      title: this.translateService.instant('company.accountant-export.archive-title'),
      text: this.translateService.instant('company.accountant-export.archive-text'),
      showCancelButton: true,
      cancelButtonText: this.translateService.instant('company.accountant-export.cancel'),
      confirmButtonText: this.translateService.instant('company.accountant-export.archive-confirm'),
    }).then(result => {
      if (result.value) {
        const editingExport = this.exportForm.value;
        editingExport.Reports = this.includedReports;

        // Saved and archived
        this.store.dispatch(new AccountantExportAction.ArchivedInProbativeValue(editingExport));
      }
    });
  }

  markAsTreated() {
    const swal = this.getCustomSwal();

    swal.fire({
      title: this.translateService.instant('company.accountant-export.treated-title'),
      text: this.translateService.instant('company.accountant-export.treated-text'),
      showCancelButton: true,
      cancelButtonText: this.translateService.instant('company.accountant-export.cancel'),
      confirmButtonText: this.translateService.instant('company.accountant-export.treated-confirm'),
    }).then(result => {
      if (result.value) {

        const editingExport = this.exportForm.value;
        editingExport.Reports = this.includedReports;

        this.store.dispatch(new AccountantExportAction.MarkAsTreated(editingExport));
      }
    });
  }

  private getCustomSwal(): typeof Swal {
    const swal = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary m-1',
        cancelButton: 'btn btn-danger m-1'
      },
      buttonsStyling: false
    });
    return swal;
  }
}
