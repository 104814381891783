import { OnUser } from 'on-shared/_models/OnUser';
import { Report } from 'on-shared/_models/Report';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import swal from 'sweetalert2';
import { Subscription, Subject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store, Select } from '@ngxs/store';
import { ReportsActions } from 'on-shared/stores/reports/reports.actions';
import { takeUntil } from 'rxjs/operators';
import { ReportsState } from 'on-shared/stores/reports/reports.state';
import { CompanyState } from 'on-shared/stores/company/company.state';
import { Company } from 'on-shared/_models/Company';
import { CompanyAction } from 'on-shared/stores/company/company.action';
import { UserState } from 'on-shared/stores/user/user.state';

@Component({
  selector: 'on-edit-report-page',
  templateUrl: './edit-report-page.component.html',
  styleUrls: ['./edit-report-page.component.scss'],
})
export class EditReportPageComponent implements OnInit, OnDestroy {
  reportId: number;
  reportToEdit: Report;
  isLoading = true;

  showManagerActions = true;

  userSubscription: Subscription;
  connectedUser: OnUser;

  company: Company;

  @Select(ReportsState.getCurrentReport) readonly report$;
  @Select(CompanyState.current) readonly company$: Observable<Company>;
  @Select(UserState.currentUser) currentUser$: Observable<OnUser>;
  destroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private router: Router,
    private store: Store,
  ) { }

  ngOnInit() {
    this.reportId = +this.activatedRoute.snapshot.params.reportId;

    this.store.dispatch(new CompanyAction.LoadCompaniesIfNeeded());

    this.store.dispatch(
      new ReportsActions.GetReport(this.reportId),
    );

    this.company$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      if (response) {
        this.company = response;
      }
    });

    this.report$.pipe(takeUntil(this.destroy$)).subscribe((res: Report) => {
      if (res && res.Id === this.reportId) {
        this.isLoading = true;
        this.reportToEdit = new Report(res);

        this.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
          if (user) {
            this.connectedUser = user;

            this.isLoading = false;
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  LoadReport() {
    this.isLoading = true;
    this.store.dispatch(new ReportsActions.GetReport(this.reportId));
  }

  saveReport() {
    this.isLoading = true;
    this.store
      .dispatch(new ReportsActions.UpdateReport(this.reportToEdit))
      .subscribe(() => (this.isLoading = false));
  }

  canUnvalidate() {
    if (!this.reportToEdit || !this.company) {
      return false;
    }

    if (
      (this.reportToEdit.ManagerId === this.connectedUser.Id ||
        (this.connectedUser.Role &&
          (this.connectedUser.Role === 'ADMIN' ||
            (this.connectedUser.Role === 'ACC' &&
              this.company.AccountantCanEdit)))) &&
      this.reportToEdit.State.Code === 'VAL'
    ) {
      return true;
    }

    return false;
  }

  acceptReport() {
    const notValidateExpenses = this.reportToEdit.Expenses.filter(
      (expense) => !expense.IsValidated,
    );
    if (notValidateExpenses.length > 0) {
      const swarlMessage = this.translateService.instant('report.validation-sure-message');
      swal
        .fire({
          title: this.translateService.instant('report.validation-sure'),
          text: swarlMessage,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: this.translateService.instant('report.validation-confirmation'),
          cancelButtonText: this.translateService.instant('report.validation-cancel'),
          icon: 'question',
        })
        .then((result) => {
          if (result.value) {
            this.validReport();
          }
        });
    } else {
      this.validReport();
    }
  }

  async validReport() {
    if (await this.hasReportChangeToSave()) {
      return;
    }

    this.store
      .dispatch(new ReportsActions.Valid(this.reportToEdit.Id, () => {
        this.router.navigate(['/company', 'mysignatures']);
      }));
  }

  hasReportChangeToSave() {
    return new Promise<boolean>(resolver => {
      const report = this.store.selectSnapshot(ReportsState.getCurrentReport);
      if (report.Name !== this.reportToEdit.Name || report.Comments !== this.reportToEdit.Comments) {
        const result = swal.fire({
          title: this.translateService.instant('report.unsaved-report-title'),
          text: this.translateService.instant('report.unsaved-report-message'),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.translateService.instant('report.unsaved-report-cancel'),
          showConfirmButton: true,
          confirmButtonText: this.translateService.instant('report.unsaved-report-confirm'),
        }).then((result) => {
          if (result?.value) {
            resolver(false);
          } else {
            resolver(true);
          }
        });
      } else {
        resolver(false);
      }
    });
  }

  async refuseReport() {
    if (await this.hasReportChangeToSave()) {
      return;
    }

    swal
      .fire<string>({
        title: this.translateService.instant('report.refusal'),
        text: this.translateService.instant('report.refusal-comment'),
        input: 'textarea',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: this.translateService.instant('report.refuse-confirm'),
        cancelButtonText: this.translateService.instant('report.refuse-cancel'),
        icon: 'question',
        preConfirm: () => {
          const inputValue = swal.getInput().value;
          if (!inputValue || inputValue.trim() === '') {
            swal.showValidationMessage(
              this.translateService.instant('report.refuse-no-comment-error'),
            );
            return null;
          }
        }
      })
      .then((result) => {
        if (result.value) {
          this.store
            .dispatch(
              new ReportsActions.Refuse(this.reportToEdit.Id, result.value, () => {
                this.router.navigate(['/company', 'mysignatures']);
              })
            );
        }
      });
  }

  managerActionsVisible(event) {
    this.showManagerActions = event.visible;
  }

  canValidReport(): boolean {
    let canValid = true;
    this.reportToEdit.Expenses.map(
      (expense) => (canValid = !expense.IsValidated ? false : canValid),
    );
    return canValid;
  }

  canRejectReport(): boolean {
    let canReject = false;
    this.reportToEdit.Expenses.map(
      (expense) =>
      (canReject =
        expense.ExpenseState.Code === 'VAL' ||
          expense.ExpenseState.Code === 'REF'
          ? canReject
          : true),
    );
    return canReject;
  }
}
