<div fileDrop [accept]="extensions" (fileOver)="onFileOver($event)" (fileDrop)="onFileChange($event)" [ngClass]="class"
  [ngClass]="{'img-ul-file-is-over': fileOver}" [ngStyle]="style?.layout">
  <div class="col-12 img-ul-file-upload img-ul-hr-inline-group" style="position: relative; height: 125px">
    <div align="center">
      <label *ngIf="fileCounter != max" class="img-ul-upload img-ul-button" [ngStyle]="style?.selectButton"
        [ngClass]="{'img-ul-disabled': disabled}">
        <span [innerText]="buttonCaption"></span>
        <input type="file" [disabled]="disabled" [accept]="extensions" multiple (change)="onFileChange(input.files)"
          #input>
      </label>

      <button *ngIf="fileCounter > 0" [disabled]="disabled" class="img-ul-clear img-ul-button"
        (click)="deleteAll(); showFileTooLargeMessage = !showFileTooLargeMessage" [ngStyle]="style?.clearButton"
        [innerText]="clearButtonCaption" style="margin-left: 5px">
      </button>
      <br />
      <div class="img-ul-drag-box-msg" [innerText]="dropBoxMessage"></div>
    </div>
  </div>

  <div *ngIf="preview" class="col-12 img-ul-container img-ul-hr-inline-group" [ngStyle]="style?.previewPanel"
    style="display: flex; flex-wrap: wrap;">
    <div *ngFor="let file of files" style="width: 90px">
      <div class="custom-tooltip">
        <div class="img-ul-image" (click)="previewFileClicked(file)" style="position: relative" align="center"
          [ngStyle]="file.fileName.indexOf('.pdf') > -1 ? '' : {'background-image': 'url('+ file.src +')'}">
          <div *ngIf="file.fileName.indexOf('.pdf') > -1">
            <i class="fas fa-file-pdf" style="font-size: 84px; color: red"></i>
          </div>
          <div *ngIf="file.pending" class="img-ul-loading-overlay">
            <div class="img-ul-spinning-circle"></div>
          </div>
          <div *ngIf="file.isSuccess" class="img-ul-success-overlay">
            <span style="position: absolute; right: 23px; top: 40px">&#10003;</span>
          </div>
          <div *ngIf="!file.isSuccess && !file.pending" class="img-ul-error-overlay">
          </div>
          <div *ngIf="!file.pending" [ngClass]="{'img-ul-disabled': disabled}" class="img-ul-x-mark"
            style="position: absolute; right: 0; top: 0; z-index: 100" (click)="deleteFile(file)">
            <span class="img-ul-close"></span>
          </div>
        </div>
        <span class="custom-tooltiptext">
          {{file.fileName}}
        </span>
      </div>
    </div>
  </div>
  <div class="col-12">
    <p class="img-ul-file-too-large" *ngIf="showFileTooLargeMessage" [innerText]="fileTooLargeMessage" align="center">
    </p>
    <br />
  </div>
</div>