<on-page-title [title]="'company.title' | translate" icon="fa-building" [showReloadButton]="ngbNav?.activeId"
  (reload)="reload()"></on-page-title>

<on-loading *ngIf="isLoading"></on-loading>
<div *ngIf="!isLoading && currentUser$ | async as connectedUser">
  <on-create-company (companyCreated)="onCompanyCreated()" *ngIf="!connectedUser.CompanyId">
  </on-create-company>
  <on-create-company (companyCreated)="onCompanyCreated()" isAllowed="true" (back)="cancelCreationCompany()"
    *ngIf="wantCreateCompany"></on-create-company>
  <div *ngIf="connectedUser.CompanyId && !wantCreateCompany">
    <div class="row space-left space-bottom d-flex align-middle">
      <h2 class="m-0">{{company.Name}}</h2>
      <button *ngIf="false" class="btn-hidden space-left" placement="right"
        [ngbPopover]="'company.create-new-bu' | translate" triggers="mouseenter:mouseleave"
        (click)="createNewCompany()">
        <nb-icon icon="fa-plus" size="2x" style="color: rgb(54, 141, 189)"></nb-icon>
      </button>
    </div>


    <ul ngbNav #myCompanyNav="ngbNav" class="nav-tabs">
      <li ngbNavItem="employees">
        <a ngbNavLink>{{'company.tabs.employees' | translate}}</a>
        <ng-template ngbNavContent>
          <on-employees-tab #EmployeesPage [companyId]="company.Id"></on-employees-tab>
        </ng-template>
      </li>
      <li ngbNavItem="hierarchy" *ngIf="connectedUser.Role === 'ADMIN'">
        <a ngbNavLink>{{'company.tabs.hierarchy' | translate}}</a>
        <ng-template ngbNavContent>
          <on-hierarchy-tab #HierarchyPage [companyId]="company.Id"></on-hierarchy-tab>
        </ng-template>
      </li>
      <li ngbNavItem="customFields" *ngIf="connectedUser.Role === 'ADMIN'">
        <a ngbNavLink>{{'company.tabs.custom-fields' | translate}}</a>
        <ng-template ngbNavContent>
          <on-company-custom-fields-tab #CustomFieldsPage [companyId]="company.Id"></on-company-custom-fields-tab>
        </ng-template>
      </li>
      <li ngbNavItem="organisation" *ngIf="connectedUser.Role === 'ADMIN'">
        <a ngbNavLink>{{'company.tabs.organisation' | translate}}</a>
        <ng-template ngbNavContent>
          <on-accounting-plan-tab #AccountingPlanPage></on-accounting-plan-tab>
        </ng-template>
      </li>
      <li ngbNavItem="settings" *ngIf="connectedUser.Role === 'ADMIN'">
        <a ngbNavLink>{{'company.tabs.settings' | translate}}</a>
        <ng-template ngbNavContent>
          <on-settings-tab #SettingsPage [companyId]="company.Id"></on-settings-tab>
        </ng-template>
      </li>
      <li ngbNavItem="employeeCategories" *ngIf="connectedUser.Role === 'ADMIN'">
        <a ngbNavLink>{{'company.tabs.employee-categories' | translate}}</a>
        <ng-template ngbNavContent>
          <on-employee-categories-tab #EmployeeCategoriesPage [companyId]="company.Id"></on-employee-categories-tab>
        </ng-template>
      </li>
      <li ngbNavItem="admin" *ngIf="connectedUser.Role === 'ADMIN'">
        <a ngbNavLink>{{'company.tabs.rules' | translate}}</a>
        <ng-template ngbNavContent>
          <on-rules-tab [companyId]="connectedUser.Company.Id"></on-rules-tab>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="myCompanyNav" class="mt-2"></div>
  </div>
</div>