export class AccountingPlanValue {
  Id: number;
  Name: string;
  AnalyticsCode: string;

  constructor(model?: AccountingPlanValue) {
    if (model) {
      this.Id = model.Id;
      this.Name = model.Name;
      this.AnalyticsCode = model.AnalyticsCode;
    } else {
      this.Id = 0;
      this.Name = null;
      this.AnalyticsCode = null;
    }
  }
}
