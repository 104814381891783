<div class="modal-header">
  <h5 *ngIf="isNew">{{ 'vehicle.add-vehicle' | translate }}:</h5>
  <h5 *ngIf="!isNew">{{ vehicle.Name }}</h5>
  <button type="button" class="btn btn-light close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div [formGroup]="vehicleForm">
    <div class="form-group">
      <label>{{ 'vehicle.name' | translate }}:</label>
      <input type="text" class="form-control" formControlName="Name">
    </div>

    <div class="form-group">
      <label>{{ 'vehicle.type' | translate }}:</label>
      <select class="form-control" formControlName="Type" (change)="onChangeType($event)">
        <option *ngFor="let type of vehicleTypesOptions" [value]="type.value">
          {{ 'vehicle.types.' + type.key | translate }}</option>
      </select>
    </div>

    <div class="form-group" *ngIf="!isBicycle">
      <label>{{ 'vehicle.energy' | translate }}:</label>
      <select class="form-control" formControlName="Energy">
        <option *ngFor="let energy of vehicleEnergiesOptions" [value]="energy.value">
          {{ 'vehicle.energies.' + energy.key | translate }}</option>
      </select>
    </div>

    <div class="form-group" *ngIf="!isBicycle">
      <label>{{ 'vehicle.category' | translate }}:</label>
      <select class="form-control" formControlName="Category">
        <option *ngFor="let category of vehicleCategoryOptions" [value]="category.value">
          {{ 'vehicle.categories.' + category.key | translate }}</option>
      </select>
    </div>

    <div class="form-group" *ngIf="!isBicycle && !isScooter">
      <label>{{ 'vehicle.fiscal-powerhorse' | translate }}: </label>
      <input type="number" class="form-control" formControlName="FiscalHorsePower">
    </div>

    <div class="d-flex justify-content-center mb-3 text-danger" *ngIf="formInvalid">
      <small>{{ 'vehicle.all-fields-required' | translate }}:</small>
    </div>

    <div class="d-flex justify-content-center">
      <button class="btn btn-primary" (click)="Save()" [disabled]="!isNew"
        *ngIf="!vehicle.disabled">{{ 'vehicle.save' | translate }}</button>
      <button class="btn btn-success" (click)="Activate()"
        *ngIf="vehicle.disabled">{{ 'vehicle.activate' | translate }} </button>
    </div>
  </div>
</div>