import { Component, OnInit, Input } from '@angular/core';
import { StripeSubscription } from '../../_models/StripeSubscription';

@Component({
  selector: 'on-stripe-subscription-summary-card',
  templateUrl: './stripe-subscription-summary-card.component.html',
  styleUrls: ['./stripe-subscription-summary-card.component.scss']
})
export class StripeSubscriptionSummaryCardComponent implements OnInit {

  @Input() subscription: StripeSubscription;
  isLoading: boolean;

  constructor() { }

  ngOnInit(): void {
  }
}
