import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from 'on-shared/stores/company/company.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'on-statistics-export-page',
  templateUrl: './statistics-export-page.component.html',
  styleUrls: ['./statistics-export-page.component.scss']
})
export class StatisticsExportPageComponent implements OnInit {

  @ViewChild('startPicker', { static: true }) startPicker: NgbDatepicker;
  @ViewChild('endPicker', { static: true }) endPicker: NgbDatepicker;

  startDate: Date;
  endDate: Date;

  constructor(
    private translateService: TranslateService,
    private companyService: CompanyService) { }

  ngOnInit(): void {
    const now = new Date()
    this.startDate = new Date(now.getFullYear() - 1, 0, 1);
    this.endDate = new Date(now.getFullYear() - 1, 11, 31);

    this.startPicker.navigateTo(
      {
        year: this.startDate.getFullYear(),
        month: this.startDate.getMonth() + 1
      });
    this.endPicker.navigateTo(
      {
        year: this.endDate.getFullYear(),
        month: this.endDate.getMonth() + 1
      });
  }

  sendRequest() {
    this.startDate = new Date(this.startDate);
    this.endDate = new Date(this.endDate);
    Swal.fire({
      title: this.translateService.instant('statistics.confirm-request-title'),
      text: this.translateService.instant('statistics.confirm-request-description', {
        start: this.startDate.toLocaleDateString(),
        end: this.endDate.toLocaleDateString()
      }),
      confirmButtonText: this.translateService.instant('statistics.confirm-request-ok'),
      cancelButtonText: this.translateService.instant('statistics.confirm-request-cancel'),
      showCancelButton: true,
      showConfirmButton: true,
      icon: 'question'
    }).then(result => {
      if (result?.value) {
        this.companyService.GenerateStatisticsFile(this.startDate, this.endDate).subscribe(response => {
          if (response.IsSuccess) {
            Swal.fire({
              title: this.translateService.instant('statistics.request-created-title'),
              text: this.translateService.instant('statistics.request-created-text'),
              icon: 'success'
            })
          } else {
            Swal.fire({
              title: this.translateService.instant('statistics.request-error-title'),
              text: this.translateService.instant('statistics.request-error-text'),
              icon: 'error'
            })
          }
        });
      }
    });
  }
}
