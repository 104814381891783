import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'on-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss']
})
export class EmptyListComponent implements OnInit {

  @Input() message = this.translateService.instant('list.nothing-to-show');

  constructor(
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
  }

}
