import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[libEnforcePointSeparator]',
})
export class EnforcePointSeparatorDirective {

  attachedInput: HTMLInputElement;

  constructor(el: ElementRef) {
    this.attachedInput = el.nativeElement;
  }

  @HostListener('keyup') onKeyStroke() {
    if (this.attachedInput) {
      this.attachedInput.value = this.attachedInput.value.replace(',', '.');
    }
  }
}
