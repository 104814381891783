<ng-select
  [disabled]="isDisabled"
  type="currency"
  id="currency"
  (change)="currencyChanged()"
  [(ngModel)]="selectedCurrency"
  [compareWith]="compareCurrency"
  [items]="allCurrencies"
  [searchFn]="customCurrencySearch"
  groupBy="group"
  bindLabel="name"
>
  <ng-template ng-label-tmp ng-option-tmp let-item="item">
    <span *ngIf="item.Symbol">{{item.Symbol}}</span>
    <span *ngIf="!item.Symbol"><nb-icon icon="fa-money-bill-alt"></nb-icon></span> - {{item.Name}}
  </ng-template>
</ng-select>
