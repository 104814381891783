import { Report } from '../../_models/Report';
import { ReportExpenseModification } from '../../../report/_models/ReportExpenseModification';

// tslint:disable-next-line: no-namespace
export namespace ReportsActions {
  export class IfCurrentUserReportListNeeded {
    public static readonly type = `[Reports] Get current user reports if needed`;
    constructor() { }
  }

  export class GetCurrentUserReports {
    public static readonly type = `[Reports] Get current user reports`;
    constructor() { }
  }

  export class Create {
    public static readonly type = `[Reports] Create new report`;
    constructor(public report: Report) { }
  }

  export class CreateEmpty {
    public static readonly type = `[Reports] Create new empty report`;
    constructor() { }
  }

  export class CreateFromLast {
    public static readonly type = `[Reports] Create with last expenses`;
    constructor() { }
  }

  export class CreateWithSelected {
    public static readonly type = `[Reports] Create with selected expenses`;
    constructor(public expenseIds: number[]) { }
  }

  export class Delete {
    public static readonly type = `[Reports] Delete reports from id[]`;
    constructor(public ids: number[]) { }
  }

  export class IfCurrentReportNeeded {
    public static readonly type = `[Reports]  Get specific report if needed`;
    constructor(public id: number) { }
  }

  export class GetReport {
    public static readonly type = `[Reports] Get specific report`;
    constructor(public id: number) { }
  }

  export class UpdateReport {
    public static readonly type = `[Reports] Update specific report`;
    constructor(public report: Report) { }
  }

  export class Valid {
    public static readonly type = `[Reports] Valid report`;
    constructor(public reportId: number, public callback: () => void) { }
  }

  export class Refuse {
    public static readonly type = `[Reports] Refuse report`;
    constructor(public reportId: number, public comment: string, public callback: () => void) { }
  }

  export class SendByMail {
    public static readonly type = `[Reports] Send report by mail`;
    constructor(public reportId: number) { }
  }

  export class ExportCSV {
    public static readonly type = `[Reports] Export report to CSV`;
    constructor(public reportId: number) { }
  }

  export class ExportExcel {
    public static readonly type = `[Reports] Export report to Excel`;
    constructor(public reportId: number) { }
  }

  export class ExportPDF {
    public static readonly type = `[Reports] Export report to PDF`;
    constructor(public reportId: number) { }
  }

  export class ExportZip {
    public static readonly type = `[Reports] Export report to Zip`;
    constructor(public reportId: number) { }
  }

  export class SendToValidation {
    public static readonly type = `[Reports] Send report to validation`;
    constructor(
      public reportId: number,
      public companyId: number,
      public managerId: number,
    ) { }
  }

  export class IfToSignReportListNeeded {
    public static readonly type = `[Reports] Get reports to sign if needed`;
    constructor() { }
  }

  export class GetToSignReports {
    public static readonly type = `[Reports] Get reports to sign`;
    constructor() { }
  }

  export class IfSignedReportListNeeded {
    public static readonly type = `[Reports] Get signed reports if needed`;
    constructor() { }
  }

  export class GetSignedReports {
    public static readonly type = `[Reports] Get signed reports`;
    constructor() { }
  }

  export class IfValidatedReportsForAccountantNeeded {
    public static readonly type = `[Reports] Get validated reports for accountant if needed`;
    constructor() { }
  }

  export class GetValidatedReportsForAccountant {
    public static readonly type = `[Reports] Get validated reports for accountant`;
    constructor() { }
  }

  export class RemoveExportedReport {
    public static readonly type = `[Report] Remove reports exported in accountant export`;
    constructor(public reportsToRemove: Report[]) { }
  }

  export class MustReloadReports {
    public static readonly type = `[Reports] Must reload reports of the current user`;
    constructor() { }
  }

  export class MustReloadReportsToSign {
    public static readonly type = `[Reports] Must reload reports to sign`;
    constructor() { }
  }

  export class MustReloadReportsSigned {
    public static readonly type = `[Reports] Must reload reports signed`;
    constructor() { }
  }

  export class MustReloadValidatedReportsForAccountant {
    public static readonly type = `[Reports] Must reload validated reports for accountant`;
    constructor() { }
  }
}
