import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { CompanyService } from '../../stores/company/company.service';

@Component({
  selector: 'on-company-role-select',
  templateUrl: './company-role-select.component.html',
  styleUrls: ['./company-role-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CompanyRoleSelectComponent),
    multi: true
  }]
})
export class CompanyRoleSelectComponent implements OnInit, ControlValueAccessor {

  @Input() display: string = null;

  allRoles: string[];

  selectedRole: string;
  onChange: any;
  onTouch: any;

  disabled = false;

  initialValue: string;

  constructor(private companyService: CompanyService) { }

  ngOnInit() {
    this.companyService.GetRoles().subscribe(response => {
      this.allRoles = response;
      if (this.initialValue) {
        this.writeValue(this.initialValue);
      }
    });
  }

  writeValue(obj: any): void {
    if (obj && this.allRoles) {
      this.selectedRole = this.allRoles.find(c => c === obj);
    } else {
      this.initialValue = obj;
      this.selectedRole = undefined;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectionChanged() {
    this.onChange(this.selectedRole);
  }

  compareRole(role1: string, role2: string) {
    return role1 && role2 && role1 === role2;
  }
}
