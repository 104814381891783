import { OnUser } from './OnUser';


export class EmployeeRole {
  Employee: OnUser;
  Role: string;
  HasSubscription: boolean;

  constructor(model?: EmployeeRole) {
    if (model) {
      this.Employee = new OnUser(model.Employee);
      this.Role = model.Role;
      this.HasSubscription = model.HasSubscription;
    } else {
      this.Employee = new OnUser();
      this.Role = null;
      this.HasSubscription = false;
    }
  }
}
