import { CountryService } from './../../_services/country.service';
import { Country } from '../../_models/Country';
import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'on-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountrySelectComponent),
      multi: true
    }
  ]
})
export class CountrySelectComponent implements OnInit, ControlValueAccessor {

  allCountries: Country[];
  selectedCountry: Country;

  onChanged: any;
  onTouched: any;

  isDisabled: boolean;

  initialValue: Country;

  constructor(private countryService: CountryService) { }

  ngOnInit() {
    this.countryService.getCountries().subscribe(countries => {
      this.allCountries = countries;
      if (this.initialValue) {
        this.writeValue(this.allCountries.find(c => c.ISOCode === this.initialValue.ISOCode));
      }
    });
  }

  writeValue(obj: any): void {
    if (obj && this.allCountries) {
      this.selectedCountry = this.allCountries.find(c => c.ISOCode === obj.ISOCode);
      this.countryChanged();
    } else {
      this.initialValue = obj;
      this.selectedCountry = undefined;
    }
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
    if (this.selectedCountry) {
      this.onChanged(this.selectedCountry);
    }
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  countryChanged() {
    if (this.onChanged) {
      this.onChanged(this.selectedCountry);
    }
  }

  compareCountry(country1: Country, country2: Country) {
    return country1 && country2 && country1.ISOCode === country2.ISOCode;
  }

}
