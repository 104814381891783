export class BadgesData {
  ExpensesToBeVerified: number;
  ReportsRefused: number;
  ReportsToBeExported: number;
  ReportsToSign: number;
  AccountantExportsNotTreated: number;

  constructor(model?: BadgesData) {
    if (model) {
      this.ExpensesToBeVerified = model.ExpensesToBeVerified;
      this.ReportsRefused = model.ReportsRefused;
      this.ReportsToBeExported = model.ReportsToBeExported;
      this.ReportsToSign = model.ReportsToSign;
      this.AccountantExportsNotTreated = model.AccountantExportsNotTreated;
    } else {
      this.ExpensesToBeVerified = 0;
      this.ReportsRefused = 0;
      this.ReportsToBeExported = 0;
      this.ReportsToSign = 0;
      this.AccountantExportsNotTreated = 0;
    }
  }
}
