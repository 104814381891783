<div class="row p-1" (click)="$event.stopPropagation()">
  <form [formGroup]="accountantExportForm">
    <select formControlName="ExportFromSelect" class="form-control-sm width-fill-column" (click)="$event.stopPropagation()"
      (change)="generate()">
      <option value="placeholder">{{'company.accountant-export.grid-cell.export' | translate}}</option>
      <option value="accountPayments">{{'company.accountant-export.grid-cell.for-pay' | translate}}</option>
      <option value="accounting">{{'company.accountant-export.grid-cell.for-accounting' | translate}}</option>
      <option value="accountingSoftware">{{'company.accountant-export.grid-cell.for-accounting-software' | translate}}
      </option>
    </select>
  </form>
</div>
