import { Component, OnInit, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { OnUser } from '../../_models/OnUser';
import { Select } from '@ngxs/store';
import { UserState } from '../../stores/user/user.state';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Report } from 'on-shared/_models/Report';

@Component({
  selector: 'on-total-gridcell',
  templateUrl: './total-gridcell.component.html',
  styleUrls: ['./total-gridcell.component.scss'],
})
export class TotalGridcellComponent
  implements ICellRendererAngularComp, OnInit, OnDestroy {
  params: any;
  info: string;
  symbol: string;

  @Select(UserState.currentUser) currentUser$: Observable<OnUser>;
  destroy$ = new Subject<void>();

  constructor() { }

  ngOnInit() {
    this.currentUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: OnUser) => {
        if (user) {
          this.symbol = user.Currency.Symbol;
          this.recalculateInfoText();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: import('ag-grid-community').ICellRendererParams): void {
    this.params = params;

    this.recalculateInfoText();
  }

  afterGuiAttached?(
    params?: import('ag-grid-community').IAfterGuiAttachedParams,
  ): void { }

  recalculateInfoText() {
    if (!this.params || !this.symbol) {
      return;
    }

    // If for expenses
    if (this.params.for === 'expenses') {
      // If Mileage
      if (this.params.data.ExpenseType.Id === 11) {
        this.info = `${this.params.data.Distance
          } km | ${this.params.data.GlobalAmount.Ttc.toFixed(2)} ${this.symbol}`;
      } else {
        this.info = `${this.params.data.GlobalAmount.Ttc.toFixed(2)} ${this.symbol
          }`;
      }
      if (this.params.data.IsPerDiem) {
        this.info = `Forfait (<i>${this.info}</i>)`
      } else {
        this.info =
          this.params.context.parent.report &&
            this.params.data.AmountToBeReimbursed.toFixed(2) !==
            this.params.data.GlobalAmount.Ttc.toFixed(2)
            ? `${this.params.data.AmountToBeReimbursed.toFixed(2)} ${this.symbol
            } (<i>Total: ${this.info}</i>)`
            : this.info;
      }
    }
    // else if for reports
    // tslint:disable-next-line: one-line
    else if (this.params.for === 'reports') {
      const report = this.params.data as Report;
      this.info =
        report.AmountToBeReimbursed.toFixed(2) !== report.TotalInReport.toFixed(2) ?
          `${report.AmountToBeReimbursed.toFixed(2)} ${this.symbol
          } (<i>Total: ${report.TotalInReport.toFixed(2)} ${this.symbol}</i>)`
          : report.TotalInReport.toFixed(2) + ' ' + this.symbol; // See for currency symbol
    }
  }
}
