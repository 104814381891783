import { ExpenseModuleWithoutRouting } from './../expense/expense.module';
import { ReportRoutingModule } from './report-routing.module';
import { EditReportPageComponent } from './my-report-page/edit-report-page/edit-report-page.component';
import { MyReportsPageComponent } from './my-report-page/my-reports-page.component';
import { AgGridModule } from 'ag-grid-angular';
import { NgChartsModule } from 'ng2-charts';
import { ReportValidationGridcellComponent } from './_shared/grid-cell/report-validation-gridcell/report-validation-gridcell.component';
import { ExportGridcellComponent } from './_shared/grid-cell/export-gridcell/export-gridcell.component';
import { NgModule } from '@angular/core';
import { ReportListComponent } from './_shared/report-list/report-list.component';
import { SharedModule } from '../shared/shared.module';
import { EditReportFormComponent } from './my-report-page/edit-report-form/edit-report-form.component';
import { NbSpinnerModule } from '@nebular/theme';
import { SendValidationComponent } from './_shared/report-list/send-validation/send-validation.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    ReportListComponent,
    ExportGridcellComponent,
    ReportValidationGridcellComponent,
    EditReportFormComponent,
    EditReportPageComponent,
    MyReportsPageComponent,
    SendValidationComponent
  ],
  imports: [
    SharedModule,
    NbSpinnerModule,
    ExpenseModuleWithoutRouting,
    NgChartsModule,
    AgGridModule,
    NgbNavModule
  ],
  exports: [
    ReportListComponent,
    ExportGridcellComponent,
    ReportValidationGridcellComponent
  ]
})
export class ReportModuleWithoutRouting { }

@NgModule({
  imports: [
    ReportModuleWithoutRouting,
    ReportRoutingModule],
  exports: [
    ReportModuleWithoutRouting
  ]
})
export class ReportModule { }
