import { TranslateService } from '@ngx-translate/core';
import { CheckboxGridcellComponent } from './../../../../shared/grid-cell/checkbox-gridcell/checkbox-gridcell.component';

import { EmployeeCategory } from '../../../../shared/_models/EmployeeCategory';
import { AgGridAngular } from 'ag-grid-angular';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { DeleteRowGridcellComponent } from 'projects/onexpense/src/app/shared/grid-cell/delete-row-gridcell/delete-row-gridcell.component';
import { CompanyState } from 'projects/onexpense/src/app/shared/stores/company/company.state';
import { Observable, Subject } from 'rxjs';
import { Company } from 'projects/onexpense/src/app/shared/_models/Company';
import { Select, Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { CompanyAction } from 'projects/onexpense/src/app/shared/stores/company/company.action';

@Component({
  selector: 'on-employee-categories-table',
  templateUrl: './employee-categories-table.component.html',
  styleUrls: ['./employee-categories-table.component.scss'],
})
export class EmployeeCategoriesTableComponent implements OnInit, OnDestroy {
  @Input() companyId: number;
  @ViewChild('agGridEmployeeCategories', { static: true })
  gridEmployeeCategories: AgGridAngular;

  isLoading = false;

  employeeCategoryToAdd: EmployeeCategory;
  @Select(CompanyState.employeeCategories) employeeCategories$: Observable<EmployeeCategory[]>;
  destroy$ = new Subject<void>();

  private columnDefsEmployeeCategories = [
    {
      headerName: this.translateService.instant(
        'company.category.table.category',
      ),
      field: 'Name',
      editable: true,
      singleClickEdit: true,
      sortable: true,
      filter: 'agTextColumnFilter',
      resizable: true,
      cellRenderer: function (params) {
        return `<span class="col-12 editable-cell">
          <span class="col-10 editable-value">${params.value}</span>
          <span class="col-2 editable-icon"><nb-icon icon="fa-pen" class="icon-primary"></nb-icon></span>
        </span>`;
      },
      minWidth: 300,
    },
    {
      headerName: this.translateService.instant(
        'company.category.table.default',
      ),
      field: 'IsDefault',
      resizable: true,
      suppressMenu: true,
      cellRenderer: CheckboxGridcellComponent,
      minWidth: 100,
    },
    {
      headerName: this.translateService.instant(
        'company.category.table.delete',
      ),
      resizable: true,
      minWidth: 100,
      cellRenderer: DeleteRowGridcellComponent,
    },
  ];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const timeout = setTimeout((_) => {
      this.gridEmployeeCategories.api.sizeColumnsToFit();
      clearTimeout(timeout);
    }, 200);
  }

  constructor(
    private translateService: TranslateService,
    private store: Store
  ) { }

  ngOnInit() {
    this.isLoading = true;

    this.store.dispatch(new CompanyAction.LoadEmployeeCategoriesIfNeeded());

    this.initEmployeeCategoryToAdd();
    this.initAgGrid();

    const self = this;
    // Grid Hierarchy Options
    this.gridEmployeeCategories.gridOptions = {
      columnDefs: this.columnDefsEmployeeCategories,

      rowSelection: 'multiple',
      // floatingFilter: true,
      domLayout: 'autoHeight',

      pagination: false,
      // paginationPageSize: this.pageSize, // Nb items displayed per page - default

      rowHeight: 38,
      enableBrowserTooltips: true,
      suppressCellFocus: true,      
      suppressRowClickSelection: true,

      onGridReady: function (params) {
        self.getEmployeesCategories();
      },

      onCellEditingStopped: function (event) {
        self.updateEmployeeCategory(event.data);
      },

      // For get 'this' in child component
      context: { parent: this },

      // Translate
      localeText: {
        noRowsToShow: this.translateService.instant('table.no-employee'),
        // Pagination
        to: this.translateService.instant('table.to'),
        of: this.translateService.instant('table.of'),
        // Filters
        filterOoo: this.translateService.instant('table.filterOoo'),
        contains: this.translateService.instant('table.contains'),
        equals: this.translateService.instant('table.equals'),
        notEqual: this.translateService.instant('table.notEqual'),
        lessThan: this.translateService.instant('table.lessThan'),
        lessThanOrEqual: this.translateService.instant('table.lessThanOrEqual'),
        greaterThan: this.translateService.instant('table.greaterThan'),
        greaterThanOrEqual: this.translateService.instant(
          'table.greaterThanOrEqual',
        ),
        inRange: this.translateService.instant('table.inRange'),
        startsWith: this.translateService.instant('table.startsWith'),
        endsWith: this.translateService.instant('table.endsWith'),
        notContains: this.translateService.instant('table.notContains'),

        andCondition: this.translateService.instant('table.andCondition'),
        orCondition: this.translateService.instant('table.orCondition'),
      },
    };

    this.gridEmployeeCategories.defaultColDef = {
      cellClass: 'd-flex align-items-center',
      editable: false,
    };
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initAgGrid() { }

  checkChanged(event) {
    this.updateEmployeeCategory(event.data);
  }

  deleteRow(event) {
    this.deleteEmployeeCategory(event.data);
  }

  setEmployeeCategoriesTable(returedCategories: EmployeeCategory[]): void {
    this.gridEmployeeCategories.api.setRowData(returedCategories);
    this.gridEmployeeCategories.api.sizeColumnsToFit();
    this.isLoading = false;
  }

  getEmployeesCategories(): void {
    this.isLoading = true;

    this.employeeCategories$.pipe(takeUntil(this.destroy$)).subscribe(categories => {
      if (categories) {
        this.setEmployeeCategoriesTable(categories.map((c) => new EmployeeCategory(c)));

        this.isLoading = false;
      }
    });
  }

  updateEmployeeCategory(updatedCategory: EmployeeCategory): void {
    this.isLoading = true;
    this.store.dispatch(new CompanyAction.UpdateEmployeeCategory(updatedCategory));
  }

  deleteEmployeeCategory(categoryToDelete: EmployeeCategory): void {
    this.isLoading = true;
    this.store.dispatch(new CompanyAction.DeleteEmployeeCategory(categoryToDelete));
  }

  addEmployeeCategory(): void {
    this.store.dispatch(new CompanyAction.AddEmployeeCategory(this.employeeCategoryToAdd));
    this.initEmployeeCategoryToAdd();
  }

  initEmployeeCategoryToAdd(): void {
    this.employeeCategoryToAdd = {
      Id: 0,
      CompanyId: this.companyId,
      Name: '',
      IsDefault: false,
    };
  }

  reload() {
    this.isLoading = true;
    this.store.dispatch(new CompanyAction.LoadEmployeeCategories());
  }
}
