import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ManagerInfos } from 'projects/onexpense/src/app/shared/_models/ManagerInfos';
import { Report } from 'projects/onexpense/src/app/shared/_models/Report';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportsActions } from 'projects/onexpense/src/app/shared/stores/reports/reports.actions';
import { Store } from '@ngxs/store';
import { CompanyService } from 'projects/onexpense/src/app/shared/stores/company/company.service';

@Component({
  selector: 'on-send-validation',
  templateUrl: './send-validation.component.html',
  styleUrls: ['./send-validation.component.scss'],
})
export class SendValidationComponent implements OnInit {
  @ViewChild('ngSelect') ngSelect: NgSelectComponent;

  @Input() report: Report;

  isLoading: boolean;

  allManagerInfos: ManagerInfos[];
  selectedManagerIndex = 0;

  hasManager: boolean;

  constructor(
    private companyService: CompanyService,
    private activeModal: NgbActiveModal,
    private store: Store,
  ) {}

  ngOnInit() {
    this.loadManagers();
  }

  loadManagers() {
    this.isLoading = true;
    this.companyService.GetManagersInfos().subscribe((response) => {
      if (response.IsSuccess) {
        this.allManagerInfos = response.Result;

        this.isLoading = false;
      }
    });
  }

  sendToValidation() {
    this.isLoading = true;
    const selectedManager = this.allManagerInfos[this.selectedManagerIndex];

    this.store
      .dispatch(
        new ReportsActions.SendToValidation(
          this.report.Id,
          selectedManager.CompanyId,
          selectedManager.Id,
        ),
      )
      .subscribe(() => {
        this.isLoading = false;
        this.cancel(true);
      });
  }

  cancel(reloadReport: boolean = false) {
    this.activeModal.close({ reload: reloadReport });
  }
}
