import { CreateExpensePageComponent } from './create-expense-page/create-expense-page.component';
import { SendReceiptPageComponent } from './send-receipt-page/send-receipt-page.component';
import { MyExpensesPageComponent } from './my-expenses-page/my-expenses-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', component: MyExpensesPageComponent },
  { path: 'send', component: SendReceiptPageComponent },
  { path: 'create', component: CreateExpensePageComponent },
  {
    path: 'edit/:expenseId',
    component: CreateExpensePageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExpenseRoutingModule { }
