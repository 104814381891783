import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'on-password-recover-form',
  templateUrl: './password-recover-form.component.html',
  styleUrls: ['./password-recover-form.component.scss']
})
export class PasswordRecoverFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
