import { ExpenseType } from './ExpenseType';

export class CustomField {
  Id: number;
  ExpenseType: ExpenseType;
  CompanyId?: number;
  UserId?: number;
  Type: string;
  Name: string;
  Description: string;
  IsRequired: boolean;
  PossibleValues: string[];

  // Use for validation
  HasRequiredError?: boolean;

  constructor(model?: CustomField) {
    if (model) {
      this.Id = model.Id;
      this.ExpenseType = model.ExpenseType?.Id ? new ExpenseType(model.ExpenseType) : null;
      this.CompanyId = model.CompanyId;
      this.UserId = model.UserId;
      this.Type = model.Type;
      this.Name = model.Name;
      this.Description = model.Description;
      this.IsRequired = model.IsRequired;
      this.PossibleValues = model.PossibleValues;
      this.HasRequiredError = model.HasRequiredError;
    } else {
      this.Id = null;
      this.ExpenseType = null;
      this.CompanyId = null;
      this.UserId = null;
      this.Type = null;
      this.Name = null;
      this.Description = null;
      this.IsRequired = null;
      this.PossibleValues = null;
      this.HasRequiredError = null;
    }
  }
}
