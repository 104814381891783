import { Expense } from './Expense';
import { Report } from './Report';

export class QuantityPrice {
  Quantity: number;
  Price: number;

  constructor(model?: QuantityPrice) {
    if (model) {
      this.Quantity = model.Quantity;
      this.Price = model.Price;
    } else {
      this.Quantity = 0;
      this.Price = 0;
    }
  }
}

export class KeyValuePair {
  Key: any;
  Value: any;

  constructor(model?: KeyValuePair) {
    if (model) {
      this.Key = model.Key;
      this.Value = model.Value;
    } else {
      this.Key = null;
      this.Value = null;
    }
  }
}

export class DashboardModel {
  ExpenseTypeRepartition: KeyValuePair[];
  LastExpenses: Expense[];
  LastReports: Report[];
  NeedVerification: QuantityPrice;
  TotalInReportPerMonth: KeyValuePair[];
  Verified: QuantityPrice;

  constructor(model?: DashboardModel) {
    if (model) {
      this.ExpenseTypeRepartition = (model.ExpenseTypeRepartition || []).map(
        (c) => new KeyValuePair(c),
      );
      this.LastExpenses = (model.LastExpenses || []).map((c) => new Expense(c));
      this.LastReports = (model.LastReports || []).map((c) => new Report(c));
      this.NeedVerification = new QuantityPrice(model.NeedVerification);
      this.TotalInReportPerMonth = (model.TotalInReportPerMonth || []).map(
        (c) => new KeyValuePair(c),
      );
      this.Verified = new QuantityPrice(model.Verified);
    } else {
      this.ExpenseTypeRepartition = [];
      this.LastExpenses = [];
      this.LastReports = [];
      this.NeedVerification = new QuantityPrice();
      this.TotalInReportPerMonth = [];
      this.Verified = new QuantityPrice();
    }
  }
}
