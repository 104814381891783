import { EmployeeRole } from "on-shared/_models/EmployeeRole";

export class ExpertedCompany {
    Id: number
    Name: string
    NbOfActiveLicenses: number
    NbOfActivableLicenses?: number
    NbOfReportsValidated: number;
    NbOfAccountantExports: number;
    IsVP: boolean;
    Employees: EmployeeRole[];

    constructor(company: ExpertedCompany) {
        if (company) {
            this.Id = company.Id;
            this.Name = company.Name;
            this.NbOfActiveLicenses = company.NbOfActiveLicenses;
            this.NbOfActivableLicenses = company.NbOfActivableLicenses;
            this.NbOfReportsValidated = company.NbOfReportsValidated;
            this.NbOfAccountantExports = company.NbOfAccountantExports;
            this.Employees = company.Employees?.map(emp => new EmployeeRole(emp));
            this.IsVP = company.IsVP;
        } else {
            this.Id = null;
            this.Name = null;
            this.NbOfActiveLicenses = 0;
            this.NbOfActivableLicenses = 0;
            this.NbOfReportsValidated = 0;
            this.NbOfAccountantExports = 0;
            this.IsVP = false;
        }
    }
}
