import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Vehicle } from 'on-shared/_models/Vehicle';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { VehiclesService } from 'on-shared/stores/vehicles/vehicles.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { OnUser } from 'on-shared/_models/OnUser';
import { ToastService } from 'on-common/_services/toast.service';


marker('vehicle.types.Auto'); // Automobile
marker('vehicle.types.Moto'); // Moto (+ 50cm³)
marker('vehicle.types.Scooter'); // < 50cm³
marker('vehicle.types.Bicycle'); // Vélo

marker('vehicle.energies.Diesel');
marker('vehicle.energies.Essence');
marker('vehicle.energies.Hybrid');
marker('vehicle.energies.Electric');
marker('vehicle.energies.Hydrogen');

marker('vehicle.categories.Tourism');
marker('vehicle.categories.Commercial');


@Component({
  selector: 'on-create-vehicles',
  templateUrl: './create-vehicles.component.html',
  styleUrls: ['./create-vehicles.component.scss']
})
export class CreateVehiclesComponent implements OnInit {

  @Input() user: OnUser;
  @Input() vehicle: Vehicle;
  vehicleForm: UntypedFormGroup;
  formInvalid = false;

  @Input() canEdit = false;
  @Input() isNew = false;

  vehicleTypes = Vehicle.Type;
  vehicleTypesOptions: any;
  vehicleEnergies = Vehicle.Energy;
  vehicleEnergiesOptions: any;
  vehicleCategory = Vehicle.Category;
  vehicleCategoryOptions: any;

  isBicycle = false;
  isScooter = false;


  constructor(
    private fb: UntypedFormBuilder,
    private vehiclesService: VehiclesService,
    public modal: NgbActiveModal,
    private translateService: TranslateService,
    private toastService: ToastService
  ) {

    this.vehicleTypesOptions = this.convertEnumToArray(this.vehicleTypes);
    this.vehicleEnergiesOptions = this.convertEnumToArray(this.vehicleEnergies);
    this.vehicleCategoryOptions = this.convertEnumToArray(this.vehicleCategory);
  }

  ngOnInit(): void {
    // If creation
    if (!this.vehicle) {
      this.vehicle = new Vehicle();
      this.vehicle.UserId = this.user.Id;

      this.canEdit = true;
      this.isNew = true;
    }

    this.initForm(this.vehicle);
  }

  initForm(vehicle: Vehicle) {
    this.vehicleForm = this.fb.group({
      Name: [vehicle.Name, Validators.required],
      Type: [vehicle.Type, Validators.required],
      Energy: [vehicle.Energy, Validators.required],
      Category: [vehicle.Category, Validators.required],
      FiscalHorsePower: [vehicle.FiscalHorsePower, Validators.required]
    });

    this.isBicycle = this.vehicle.Type === Vehicle.Type.Bicycle ? true : false;
    this.isScooter = this.vehicle.Type === Vehicle.Type.Scooter ? true : false;

    // If the vehicle is not new
    if (!this.isNew) {
      this.vehicleForm.disable();
    }
  }

  Save() {
    if (this.vehicleForm.invalid) {
      this.formInvalid = true;
      return;
    }

    this.formInvalid = false;

    // Get Vehicle from form
    Object.assign(this.vehicle, this.vehicleForm.value);

    if (this.isNew) {
      this.Create();
    } else {
      this.Update();
    }
  }

  Create() {
    this.vehiclesService.Create(this.vehicle).subscribe(response => {
      if (response.IsSuccess) {
        this.toastService.success(
          this.translateService.instant('vehicle.create-success'),
          this.translateService.instant('vehicle.create-success-title'));

        this.modal.close();
      } else {
        this.toastService.error(
          this.translateService.instant('vehicle.create-failed'),
          this.translateService.instant('vehicle.create-failed-title'));
      }
    }, error => {
      this.toastService.error(
        this.translateService.instant('vehicle.create-failed'),
        this.translateService.instant('vehicle.create-failed-title'));
    });
  }

  Update() {
    this.vehiclesService.Update(this.vehicle).subscribe(response => {
      if (response.IsSuccess) {
        this.modal.close();
      } else {
        this.toastService.error(
          this.translateService.instant('vehicle.updated-failed'),
          this.translateService.instant('vehicle.updated-failed-title'));
      }
    }, error => {
      this.toastService.error(
        this.translateService.instant('vehicle.updated-failed'),
        this.translateService.instant('vehicle.updated-failed-title'));
    });
  }

  Activate() {
    this.vehicle.disabled = false;
    this.Update();
  }

  onChangeType(event) {
    this.isBicycle = event.target.value == Vehicle.Type.Bicycle;
    this.isScooter = event.target.value == Vehicle.Type.Scooter;
  }

  private convertEnumToArray(enumeration: any): any[] {
    const array = [];
    const objectEnum = Object.keys(enumeration);
    const values = objectEnum.slice(0, objectEnum.length / 2);
    const keys = objectEnum.slice(objectEnum.length / 2);

    for (let i = 0; i < objectEnum.length / 2; i++) {
      array.push({ key: keys[i], value: Number(values[i]) });
    }
    return array;
  }

}
