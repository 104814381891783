<div class="row col-12 d-flex p-3">

  <div class="col-12 d-flex">
    <!-- <on-rule-type-selector class="col" [selectedRuleType]="ruleToCreate.Type" (ruleTypeEmitter)="selectedRuleTypeChanged($event)"></on-rule-type-selector> -->
    <h4>
      {{'expense-rule.limit' | translate}}
    </h4>
  </div>
  <div class="col-12 d-flex  p-0">
    <on-limit-rule-form *ngIf="ruleToCreate.Type === 'limit'" class="col" [rule]="ruleToCreate"
      [expenseTypes]="expenseTypes" [employeeCategories]="employeeCategories" [new]="true"
      (closeModalEmitter)="ruleIsCreated($event)"></on-limit-rule-form>
  </div>

</div>