import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SubscriptionStateModel } from './subscription.model';
import { Injectable } from '@angular/core';
import { SubscriptionActions } from './subscription.actions';
import { Subscription } from '../../_models/Subscription';
import { CompanyService } from '../company/company.service';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'on-common/_services/toast.service';

@State<SubscriptionStateModel>({
    name: 'subscription',
    defaults: new SubscriptionStateModel()
})
@Injectable()
export class SubscriptionState {

    constructor(
        private companyService: CompanyService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {

    }

    @Selector()
    static companySubscriptions(state: SubscriptionStateModel): Subscription[] {
        return state.companySubscriptions;
    }

    @Action(SubscriptionActions.LoadSubscriptionsIfNeeded)
    public loadSubscriptionsIfNeeded(
        ctx: StateContext<SubscriptionStateModel>,
        { companyId }: SubscriptionActions.LoadSubscriptionsIfNeeded
    ) {
        if (ctx.getState().shouldReloadLicenses) {
            return this.loadSubscriptions(ctx, new SubscriptionActions.LoadSubscriptions(companyId));
        }
    }

    @Action(SubscriptionActions.LoadSubscriptions)
    public loadSubscriptions(ctx: StateContext<SubscriptionStateModel>, { companyId }: SubscriptionActions.LoadSubscriptions) {
        return this.companyService.GetSubscriptions(companyId).pipe(
            tap(response => {
                if (response.IsSuccess) {
                    ctx.patchState({
                        companySubscriptions: response.Result,
                        shouldReloadLicenses: false
                    });
                } else {
                    this.toastService.error(this.translateService.instant('company.subscription.load-subscription-error'));
                }
            }, error => {
                this.toastService.error(this.translateService.instant('company.subscription.load-subscription-error'));
            }));
    }

}
