<span class="col-12 editable-cell"
  [nbTooltip]="'company.manager-grid-cell-tooltip' | translate"
  nbTooltipStatus="info"
>
  <span class="col-10 editable-value">{{params.data.ManagerInfos}}</span>
  <span class="col-2 editable-icon"><nb-icon icon="fa-pen" class="icon-primary"></nb-icon></span>
</span>



