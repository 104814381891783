import { DomainName } from './../../_models/DomainName';
import { Company } from '../../_models/Company';
import { EmployeeCategory } from 'on-shared/_models/EmployeeCategory';

export class CompanyStateModel {
  current: Company;
  domainNames: DomainName[];
  employeeCategories: EmployeeCategory[];
  isLoadingDomainNames: boolean;
  companies: Company[];
  shouldReloadCompanies: boolean;
  shouldReloadEmployees: boolean;
  shouldReloadHierarchy: boolean;
  shouldReloadCustomFields: boolean;
  shouldReloadEmployeesCategories: boolean;

  constructor(model?: CompanyStateModel) {
    if (model) {
      this.current = model.current;
      this.companies = model.companies;
      this.shouldReloadCompanies = model.shouldReloadCompanies;
      this.shouldReloadEmployees = model.shouldReloadEmployees;
      this.shouldReloadHierarchy = model.shouldReloadHierarchy;
      this.shouldReloadCustomFields = model.shouldReloadCustomFields;
      this.domainNames = model.domainNames;
      this.employeeCategories = model.employeeCategories;
      this.isLoadingDomainNames = model.isLoadingDomainNames;
      this.shouldReloadEmployeesCategories = model.shouldReloadEmployeesCategories;
    } else {
      this.current = null;
      this.companies = null;
      this.shouldReloadCompanies = true;
      this.shouldReloadEmployees = true;
      this.shouldReloadHierarchy = true;
      this.shouldReloadCustomFields = true;
      this.domainNames = null;
      this.employeeCategories = null;
      this.isLoadingDomainNames = false;
      this.shouldReloadEmployeesCategories = true;
    }
  }
}
