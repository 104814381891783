import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BadgesData } from '../../_models/BadgesData';
import { API_V2_URL } from 'on-common/constants';
import { IApiResult } from 'on-common/_models/ApiResult';

const BADGES_URL = API_V2_URL + '/badges';

@Injectable({
  providedIn: 'root'
})
export class BadgesService {

  constructor(private http: HttpClient) { }

  GetBadgesData(): Observable<IApiResult<BadgesData>> {
    return this.http.get<IApiResult<BadgesData>>(BADGES_URL);
  }
}
