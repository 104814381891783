import { TranslateService } from '@ngx-translate/core';
import { Company } from './../../../shared/_models/Company';
import { Component, OnInit, OnChanges, SimpleChanges, Input, OnDestroy } from '@angular/core';
import swal from 'sweetalert2';
import { CompanyState } from '../../../shared/stores/company/company.state';
import { Observable, Subject } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { CompanyAction } from '../../../shared/stores/company/company.action';

@Component({
  selector: 'on-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit, OnChanges, OnDestroy {

  isLoading: boolean;

  @Input() companyId: number;
  company: Company;

  @Select(CompanyState.current) company$: Observable<Company>;
  destroy$ = new Subject<void>();

  constructor(private translateService: TranslateService,
              private store: Store) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadCompany();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadCompany() {
    this.isLoading = true;

    this.company$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.company = new Company(response);
      this.isLoading = false;
    });
  }

  updateCompany() {
    this.store.dispatch(new CompanyAction.SaveAuthorizationsChanges(this.company));
  }

  deleteCompany() {
    swal.fire({
      title: this.translateService.instant('company.administration.swarl-title'),
      text: this.translateService.instant('company.administration.swarl-text'),
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: this.translateService.instant('company.administration.swarl-confirm-btn'),
      cancelButtonText: this.translateService.instant('company.administration.swarl-cancel-btn')
    }).then(result => {
      if (result.value) {
        this.store.dispatch(new CompanyAction.Delete());
      }
    });
  }

  reload() {
    this.isLoading = true;
    this.store.dispatch(new CompanyAction.LoadCompanies());
  }

}
