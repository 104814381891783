import { Injectable } from '@angular/core';
import { Selector, Action, StateContext, State, Store } from '@ngxs/store';
import { tap, map } from 'rxjs/operators';
import { ReportsActions } from './reports.actions';
import { ReportsService } from './reports.service';
import { Report } from '../../_models/Report';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { ReportsModel } from './reports.model';
import { Expense } from '../../_models/Expense';
import {
  patch,
  insertItem,
  removeItem,
  updateItem,
  compose,
} from '@ngxs/store/operators';
import { ExpenseAction } from '../expense/expense.actions';
import { BadgesAction } from '../badges/badges.action';
import { ToastService } from 'on-common/_services/toast.service';
import { DialogService } from 'on-common/_services/dialog.service';
import { AccountService } from 'on-auth/_services/account.service';
import { IApiResult } from 'on-common/_models/ApiResult';

@State<ReportsModel>({
  name: `reports`,
  defaults: new ReportsModel(),
})
@Injectable()
export class ReportsState {
  constructor(
    private reportService: ReportsService,
    private toastService: ToastService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private store: Store,
    private accountService: AccountService,
  ) { }

  @Selector()
  public static getCurrentUserReportList(reportsModelState: ReportsModel) {
    return reportsModelState.list;
  }

  @Selector()
  public static getCurrentReport(reportsModelState: ReportsModel) {
    return reportsModelState.current;
  }

  @Selector()
  public static getToSignReportList(reportsModelState: ReportsModel) {
    return reportsModelState.toSignList;
  }

  @Selector()
  public static getSignedReportList(reportsModelState: ReportsModel) {
    return reportsModelState.signedList;
  }

  @Selector()
  public static getReportsForAccountant(reportsModelState: ReportsModel) {
    return reportsModelState.forAccountantList;
  }

  @Selector()
  public static isLoadingReports(reportsModelState: ReportsModel) {
    return reportsModelState.isLoadingReports;
  }

  private _getUserReports() {
    return this.store.dispatch(new ReportsActions.GetCurrentUserReports());
  }

  private _creationPending() {
    this.dialogService.ShowLoading(
      this.translateService.instant('report.pending-creation'),
    );
  }

  @Action(ReportsActions.GetCurrentUserReports)
  public getCurrentUserReports(ctx: StateContext<ReportsModel>) {
    ctx.patchState({
      isLoadingReports: true
    });

    return this.reportService.GetReportsOfCurrentUser().pipe(
      tap((res) =>
        ctx.setState(
          new ReportsModel({
            ...ctx.getState(),
            list: res.Result,
            isSuccess: res.IsSuccess,
            shouldReloadList: false,
            isLoadingReports: false
          }),
        ),
      ),
    );
  }

  @Action(ReportsActions.IfCurrentUserReportListNeeded)
  public ifCurrentUserReportListNeeded(ctx: StateContext<ReportsModel>) {
    const state = ctx.getState();
    // if (!state.list?.length || state.shouldReloadList) {
    // Always reload data for now
    ctx.dispatch(new ReportsActions.GetCurrentUserReports());
    // }
  }

  @Action(ReportsActions.Create)
  public createReport(
    ctx: StateContext<ReportsModel>,
    { report }: ReportsActions.Create,
  ) {
    return this._create(ctx, report);
  }

  @Action(ReportsActions.CreateEmpty)
  public createEmptyReport(ctx: StateContext<ReportsModel>) {
    return swal
      .fire({
        title: this.translateService.instant('report.create-swarl-title'),
        text: this.translateService.instant('report.create-swarl-text'),
        icon: 'question',
        input: 'text',
      })
      .then((result) => {
        if (result.value) {
          this._create(ctx, {
            Name: result.value,
            Comments: '',
          }).subscribe();
        }
      });
  }

  @Action(ReportsActions.CreateWithSelected)
  public createWithSelected(
    ctx: StateContext<ReportsModel>,
    { expenseIds }: ReportsActions.CreateWithSelected,
  ) {
    return swal
      .fire({
        title: this.translateService.instant('expense.create-report-title'),
        text: this.translateService.instant('expense.create-report-text'),
        icon: 'question',
        input: 'text',
      })
      .then((result) => {
        if (result.value) {
          this._create(ctx, {
            Name: result.value,
            Comments: '',
            Expenses: expenseIds.reduce(
              (expenseArray: Expense[], id: number, index: number) => {
                expenseArray.push({
                  Id: id,
                });
                return expenseArray;
              },
              [],
            ),
          }).subscribe((res) => {
            if (res.IsSuccess) {
              ctx.dispatch(ExpenseAction.LoadExpenses);
            }
          });
        }
      });
  }

  private _create(ctx: StateContext<ReportsModel>, reportToCreate: Report) {
    if (!reportToCreate.Comments) {
      reportToCreate.Comments = '';
    }
    this._creationPending();
    return this.reportService.Create(reportToCreate).pipe(
      tap(
        (res) =>
          ctx.setState(
            patch({
              list: insertItem(res.Result),
              isSuccess: res.IsSuccess,
            }),
          ),
      ),
      tap(
        (res) =>
          res.IsSuccess
            ? this.toastService.success(
              this.translateService.instant(
                'report.create-empty-swarl-success',
                { reportName: reportToCreate.Name },
              ),
            )
            : this.toastService.error(
              this.translateService.instant('report.create-empty-swarl-error'),
            ),
        () => this.dialogService.CloseLoading(),
        () => this.dialogService.CloseLoading(),
      ),
    );
  }

  @Action(ReportsActions.CreateFromLast)
  public createFromLast(ctx: StateContext<ReportsModel>) {
    return swal
      .fire({
        title: this.translateService.instant('report.create-swarl-title'),
        text: this.translateService.instant('report.create-swarl-text'),
        icon: 'question',
        input: 'text',
      })
      .then((result) => {
        if (result.value) {
          this._createFromLast(ctx, {
            Name: result.value,
            Comments: '',
          }).subscribe();
        }
      });
  }

  private _createFromLast(ctx: StateContext<ReportsModel>, report: Report) {
    this._creationPending();
    return this.reportService.CreateFromLast(report).pipe(
      tap(
        {
          next: (res) => {
            if (res.IsSuccess) {
              this.toastService.success(
                this.translateService.instant(
                  'expense.create-report-success-message',
                  { reportName: report.Name },
                ),
              )
              return this._getUserReports();
            }
            else {
              this.toastService.error(
                this.translateService.instant('expense.create-report-error'),
              )
            }
          },
          error: () => {
            this.dialogService.CloseLoading();
          },
          complete: () => this.dialogService.CloseLoading()
        }
      ),
    );
  }

  @Action(ReportsActions.Delete)
  public delete(
    ctx: StateContext<ReportsModel>,
    { ids }: ReportsActions.Delete,
  ) {
    swal
      .fire({
        title: 'Etes-vous sûr ?',
        showCancelButton: true,
        // tslint:disable-next-line:max-line-length
        text: this.translateService.instant('report.delete-swarl-text', {
          number: ids.length,
        }),
        showLoaderOnConfirm: true,
        icon: 'warning',
      })
      .then((accepted) => {
        if (accepted && accepted.value) {
          ctx.patchState({
            isLoadingReports: true
          });
          this.dialogService.ShowLoading(
            this.translateService.instant('report.pending-deletion'),
          );
          this.reportService
            .RemoveReports(ids)
            .pipe(
              map((res) => {
                const currentState = ctx.getState();
                const filteredReports = [...currentState.list].filter(
                  (r) => ids.indexOf(r.Id) === -1,
                );
                ctx.patchState({
                  list: filteredReports,
                  isSuccess: res.IsSuccess,
                  isLoadingReports: false
                });
                return res;
              }),
              tap(
                (response) => {
                  if (!response) {
                    // tslint:disable-next-line: deprecation
                    swal.close();
                    this.toastService.error(
                      this.translateService.instant(
                        'report.cannot-delete-reports',
                      ),
                    );
                  }
                },
                (error) => {
                  // tslint:disable-next-line: deprecation
                  swal.close();
                  this.toastService.error(
                    this.translateService.instant(
                      'report.cannot-delete-reports',
                    ),
                  );
                },
                () => {
                  this.dialogService.CloseLoading();
                },
              ),
            )
            .subscribe();
        }
      });
  }

  @Action(ReportsActions.IfCurrentReportNeeded)
  public ifCurrentReportNeeded(
    ctx: StateContext<ReportsModel>,
    { id }: ReportsActions.GetReport,
  ) {
    const state = ctx.getState();
    // if (!state.current || state.current.Id !== id) {
    // Always reload data for now
    ctx.dispatch(new ReportsActions.GetReport(id));
    // }
  }

  @Action(ReportsActions.GetReport)
  public getReport(
    ctx: StateContext<ReportsModel>,
    { id }: ReportsActions.GetReport,
  ) {
    return this.reportService.GetReport(id).pipe(
      map((res) => {
        if (res.IsSuccess) {
          const currentState = ctx.getState();
          const report = new Report(res.Result);
          // If the "My reports" list was already loaded
          if (currentState.list) {
            const reports = currentState.list.map((r) => new Report(r));
            reports.filter((r) => r.Id === id)[0] = report;
            ctx.setState(
              patch({
                list: updateItem<Report>(
                  (r) => r.Id === res.Result.Id,
                  new Report(res.Result),
                ),
              }),
            );
          }
          ctx.patchState({
            current: report,
            isSuccess: res.IsSuccess,
            shouldReloadCurrent: false,
          });
        } else {
          swal.fire(this.translateService.instant('report.load-report-error'));
        }
        return;
      }),
    );
  }

  @Action(ReportsActions.UpdateReport)
  public updateReport(
    ctx: StateContext<ReportsModel>,
    { report }: ReportsActions.UpdateReport,
  ) {
    return this.reportService.UpdateReport(report).pipe(
      map((res) => {
        const currentState = ctx.getState();
        if (res.IsSuccess) {
          this.toastService.success(
            this.translateService.instant('report.saved'),
          );
          const newData = new Report(res.Result);
          ctx.setState(
            patch({
              current: newData,
              isSuccess: true as boolean
            })
          );

          this.store.dispatch(new ExpenseAction.MustReloadExpenses());
        } else {
          ctx.patchState({
            isSuccess: res.IsSuccess,
          });
          this.toastService.error(this.translateService.instant('report.save-error'));
        }
        return res;
      }),
    );
  }

  @Action(ReportsActions.Valid)
  public valid(
    ctx: StateContext<ReportsModel>,
    { reportId, callback }: ReportsActions.Valid,
  ) {
    this.dialogService.ShowLoading(
      this.translateService.instant('report.pending-validation'),
    );
    return this.reportService.Validate(reportId).pipe(
      map((res) => {
        const currentState = ctx.getState();
        if (res.IsSuccess) {
          ctx.patchState({
            current: new Report(res.Result),
          });
          ctx.setState(
            patch({
              list: updateItem<Report>(
                (r) => r.Id === res.Result.Id,
                new Report(res.Result),
              ),
              toSignList: removeItem<Report>((r) => r.Id === res.Result.Id),
              signedList: insertItem<Report>(new Report(res.Result), 0),
            }),
          );
        }
        ctx.setState(patch({ isSuccess: res.IsSuccess }));

        this.toastService.success(this.translateService.instant('report.validated-success'));

        callback();
        // Reload badges
        this.store.dispatch(new BadgesAction.LoadBadges());

        return res;
      }),
      tap(null, null, () => {
        this.dialogService.CloseLoading();
      }),
    );
  }

  @Action(ReportsActions.Refuse)
  public refuse(
    ctx: StateContext<ReportsModel>,
    { reportId, comment, callback }: ReportsActions.Refuse,
  ) {
    this.dialogService.ShowLoading(
      this.translateService.instant('report.pending-reject'),
    );
    return this.reportService.Refuse(reportId, comment).pipe(
      map((res) => {
        const currentState = ctx.getState();
        if (res.IsSuccess) {
          ctx.patchState({
            current: new Report(res.Result),
          });
          ctx.setState(
            patch({
              list: updateItem<Report>(
                (r) => r.Id === res.Result.Id,
                res.Result,
              ),
              toSignList: removeItem<Report>((r) => r.Id === res.Result.Id),
              signedList: removeItem<Report>((r) => r.Id === res.Result.Id),
            }),
          );
        }
        ctx.patchState({
          isSuccess: res.IsSuccess,
        });

        this.toastService.success(this.translateService.instant('report.refused-success'));

        callback();
        // Reload badges
        this.store.dispatch(new BadgesAction.LoadBadges());

        return res;
      }),
      tap(
        null,
        null,
        () => this.dialogService.CloseLoading(),
      ),
    );
  }

  @Action(ReportsActions.SendByMail)
  public sendByMail(
    ctx: StateContext<ReportsModel>,
    { reportId }: ReportsActions.SendByMail,
  ) {
    this.dialogService.ShowLoading(
      this.translateService.instant('report.pending-mail'),
    );
    return this.reportService.SendByMail(reportId).pipe(
      tap(
        (res) => {
          if (res.IsSuccess) {
            this.toastService.success(
              this.translateService.instant(
                'report.export-mail-success-message',
              ),
            );
          } else {
            this.toastService.error(
              this.translateService.instant('report.export-mail-error-message'),
            );
          }
        },
        () =>
          this.toastService.error(
            this.translateService.instant('report.export-mail-error-message'),
          ),
        () => this.dialogService.CloseLoading(),
      ),
    );
  }

  private _openExportPending() {
    return this.dialogService.ShowLoading(
      this.translateService.instant('report.pending-export'),
    );
  }

  private _showDownloadableLink(linkToDownload) {
    return swal.fire({
      title: this.translateService.instant('report.download-title'),
      // tslint:disable-next-line:max-line-length
      html:
        this.translateService.instant('report.download-message') +
        `, <a target="_blank" href="${linkToDownload}?token=${this.accountService.getToken()}">${this.translateService.instant('report.clic-to-download')}</a>`,
      showCloseButton: true,
    });
  }

  @Action(ReportsActions.ExportCSV)
  public exportCSV(
    ctx: StateContext<ReportsModel>,
    { reportId }: ReportsActions.ExportCSV,
  ) {
    this._openExportPending();
    return this.reportService.ExportCSV(reportId).pipe(
      tap(
        (response) => {
          if (response.IsSuccess) {
            this._showDownloadableLink(response.Result);
          } else {
            this.toastService.error(
              this.translateService.instant('report.export-csv-error-message'),
            );
          }
        },
        () =>
          this.toastService.error(
            this.translateService.instant('report.export-csv-error-message'),
          ),
        () => {
          this.dialogService.CloseLoading();
        },
      ),
    );
  }

  @Action(ReportsActions.ExportExcel)
  public exportExcel(
    ctx: StateContext<ReportsModel>,
    { reportId }: ReportsActions.ExportExcel,
  ) {
    this._openExportPending();
    return this.reportService.ExportExcel(reportId).pipe(
      tap(
        (response) => {
          if (response.IsSuccess) {
            this._showDownloadableLink(response.Result);
          } else {
            this.toastService.error(
              this.translateService.instant(
                'report.export-excel-error-message',
              ),
            );
          }
        },
        () =>
          this.toastService.error(
            this.translateService.instant('report.export-csv-excel-message'),
          ),
        () => {
          this.dialogService.CloseLoading();
        },
      ),
    );
  }

  @Action(ReportsActions.ExportZip)
  public exportZip(
    ctx: StateContext<ReportsModel>,
    { reportId }: ReportsActions.ExportZip,
  ) {
    this._openExportPending();
    return this.reportService.ExportZip(reportId).pipe(
      tap(
        (response) => {
          if (response.IsSuccess) {
            this._showDownloadableLink(response.Result);
          } else {
            this.toastService.error(
              this.translateService.instant('report.export-zip-error-message'),
            );
          }
        },
        () =>
          this.toastService.error(
            this.translateService.instant('report.export-zip-error-message'),
          ),
        () => {
          this.dialogService.CloseLoading();
        },
      ),
    );
  }

  @Action(ReportsActions.ExportPDF)
  public exportPDF(
    ctx: StateContext<ReportsModel>,
    { reportId }: ReportsActions.ExportPDF,
  ) {
    this._openExportPending();
    return this.reportService.ExportPDF(reportId).pipe(
      tap(
        (response) => {
          if (response.IsSuccess) {
            this._showDownloadableLink(response.Result);
          } else {
            this.toastService.error(
              this.translateService.instant('report.export-pdf-error-message'),
            );
          }
        },
        () =>
          this.toastService.error(
            this.translateService.instant('report.export-pdf-error-message'),
          ),
        () => {
          this.dialogService.CloseLoading();
        },
      ),
    );
  }

  @Action(ReportsActions.SendToValidation)
  public sendTovalidation(
    ctx: StateContext<ReportsModel>,
    { reportId, companyId, managerId }: ReportsActions.SendToValidation,
  ) {
    this.dialogService.ShowLoading(
      this.translateService.instant('report.pending-sent-validation'),
    );
    this.reportService
      .SendToValidation(reportId, companyId, managerId)
      .subscribe((res) => {
        const currentState = ctx.getState();
        if (res.IsSuccess) {
          this.toastService.success(
            this.translateService.instant('report.send-success-message'),
            this.translateService.instant(
              'report.send-success-message-complement',
            ),
          );
          const toPatchComplement =
            res.Result.ManagerId === res.Result.UserId &&
              ctx.getState().toSignList?.length
              ? {
                toSignList: insertItem(res.Result),
              }
              : res.Result.ManagerId === res.Result.UserId
                ? { shouldReloadToSignList: true }
                : {};
          ctx.setState(
            patch({
              current: res.Result,
              list: updateItem<Report>(
                (r) => r.Id === res.Result.Id,
                res.Result,
              ),
            }),
          );
        }
        ctx.patchState({ isSuccess: res.IsSuccess });

        // Reload badges
        this.store.dispatch(new BadgesAction.LoadBadges());
        this.dialogService.CloseLoading();

        return res;
      }, error => {
        this.dialogService.CloseLoading();
      }, () => {
        this.dialogService.CloseLoading();
      });
  }

  @Action(ReportsActions.IfToSignReportListNeeded)
  public ifToSignReportListNeeded(ctx: StateContext<ReportsModel>) {
    const state = ctx.getState();
    // if (!state.toSignList?.length || state.shouldReloadToSignList) {
    // Always reload data for now
    ctx.dispatch(new ReportsActions.GetToSignReports());
    // }
  }

  @Action(ReportsActions.GetToSignReports)
  public getToSignReport(ctx: StateContext<ReportsModel>) {
    return this.reportService.GetToSign().pipe(
      tap(
        (res: IApiResult<Report[]>) =>
          ctx.patchState({
            toSignList: res.Result || [],
            isSuccess: res.IsSuccess,
            shouldReloadToSignList: false,
          }),
        () =>
          this.toastService.error(
            this.translateService.instant(
              'company.signature.cannot-load-to-sign',
            ),
          ),
      ),
    );
  }

  @Action(ReportsActions.IfSignedReportListNeeded)
  public ifSignedReportListNeeded(ctx: StateContext<ReportsModel>) {
    const state = ctx.getState();
    // if (!state.signedList?.length || state.shouldReloadSignedList) {
    // Always reload data for now
    ctx.dispatch(new ReportsActions.GetSignedReports());
    // }
  }

  @Action(ReportsActions.GetSignedReports)
  public getSignedReport(ctx: StateContext<ReportsModel>) {
    return this.reportService.GetSigned().pipe(
      tap(
        (res: IApiResult<Report[]>) =>
          ctx.patchState({
            signedList: res.Result || [],
            isSuccess: res.IsSuccess,
            shouldReloadSignedList: false,
          }),
        () =>
          this.toastService.error(
            this.translateService.instant(
              'company.signature.cannot-load-signed',
            ),
          ),
      ),
    );
  }

  @Action(ReportsActions.IfValidatedReportsForAccountantNeeded)
  public ifValidatedReportsForAccountantNeeded(
    ctx: StateContext<ReportsModel>,
  ) {
    const state = ctx.getState();
    // if (
    //   !state.forAccountantList?.length ||
    //   state.shouldReloadForAccountantList
    // ) {
    // Always reload data for now
    ctx.dispatch(new ReportsActions.GetValidatedReportsForAccountant());
    // }
  }

  @Action(ReportsActions.GetValidatedReportsForAccountant)
  public getValidatedReportsForAccountant(ctx: StateContext<ReportsModel>) {
    return this.reportService.GetValidatedReportsForAccountant().pipe(
      tap(
        (res: IApiResult<Report[]>) =>
          ctx.patchState({
            forAccountantList: res.Result || [],
            isSuccess: res.IsSuccess,
            shouldReloadForAccountantList: false,
          }),
        () => {
          this.toastService.error(
            this.translateService.instant(
              'company.accountant-export.error-load-validated',
            ),
            this.translateService.instant('company.accountant-export.error'),
          );
        },
      ),
    );
  }

  @Action(ReportsActions.RemoveExportedReport)
  public removeExportedReport(
    ctx: StateContext<ReportsModel>,
    { reportsToRemove }: ReportsActions.RemoveExportedReport,
  ) {
    const state = new ReportsModel(ctx.getState());
    const reportIdsToRemove: number[] = reportsToRemove.map((r) => r.Id);
    state.forAccountantList = state.forAccountantList.filter(
      (r) => reportIdsToRemove.indexOf(r.Id) === -1,
    );
    ctx.setState(state);
  }

  @Action(ReportsActions.MustReloadReports)
  public mustReloadReports(
    ctx: StateContext<ReportsModel>
  ) {
    ctx.patchState({
      shouldReloadList: true
    });
  }

  @Action(ReportsActions.MustReloadReportsToSign)
  public mustReloadReportsToSign(
    ctx: StateContext<ReportsModel>
  ) {
    ctx.patchState({
      shouldReloadToSignList: true
    });
  }

  @Action(ReportsActions.MustReloadReportsSigned)
  public mustReloadReportsSigned(
    ctx: StateContext<ReportsModel>
  ) {
    ctx.patchState({
      shouldReloadSignedList: true
    });
  }

  @Action(ReportsActions.MustReloadValidatedReportsForAccountant)
  public mustReloadValidatedReportsForAccountant(
    ctx: StateContext<ReportsModel>
  ) {
    ctx.patchState({
      shouldReloadForAccountantList: true
    });
  }
}
