import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { ReportsSignedComponent } from './reports-signed/reports-signed.component';
import { ReportsToSignComponent } from './reports-to-sign/reports-to-sign.component';

@Component({
  selector: 'on-my-signatures-page',
  templateUrl: './my-signatures-page.component.html',
  styleUrls: ['./my-signatures-page.component.scss'],
})
export class MySignaturesPageComponent implements OnInit {

  @ViewChild(NgbNav, {static: false}) ngbNav: NgbNav;
  @ViewChild('ReportsToSignPage', {static: false}) reportsToSignPage: ReportsToSignComponent;
  @ViewChild('ReportsSignedPage', {static: false}) reportsSignedPage: ReportsSignedComponent;

  constructor() {}

  ngOnInit() {
  }

  reload() {
    const tabId = this.ngbNav.activeId;

    if (tabId === "toSign") {
      this.reportsToSignPage.reload();
    } else if (tabId === "signed") {
      this.reportsSignedPage.reload();
    }
  }
}
