<on-two-columns-display [title]="'accounting-plan.title' | translate">
  <div description [innerHTML]="'accounting-plan.description' | translate">
  </div>

  <div content class="col align-items-center justify-content-center" *ngIf="userAccountingPlans">
    <div *ngIf="!(isLoading$ | async)">
      <div class="row" *ngIf="userAccountingPlans.length">
        <span class="col">Code exemple : {{ exampleCode }}</span>
        <button (click)="savePlans()" class="btn btn-primary btn mb-1 col">
          <nb-icon icon="fa-save"></nb-icon> {{'accounting-plan.save-plans' | translate}}
        </button>
      </div>
      <div class="row" *ngIf="showFieldsAreRequired">
        <div class="col text-danger">
          {{'accounting-plan.fields-required' | translate}}
        </div>
      </div>
      <div *ngFor="let plan of userAccountingPlans" class="card mb-2">
        <div class="card-header">
          <div class="d-flex align-items-center justify-content-between">
            <div class="btn-group-vertical">
              <button class="btn btn-sm btn-outline-secondary" type="button" (click)="goUp(plan)">
                <nb-icon icon="fa-arrow-up"></nb-icon>
              </button>
              <button class="btn btn-sm btn-outline-secondary" type="button" (click)="goDown(plan)">
                <nb-icon icon="fa-arrow-down"></nb-icon>
              </button>
            </div>
            <div>
              <span>{{'accounting-plan.name' | translate}} : </span>
              <input required [(ngModel)]="plan.Name" class="form-control plan-name">
            </div>
            <button (click)="removePlan(plan)" class="btn btn-danger btn-sm">
              <nb-icon icon="fa-trash"></nb-icon>
            </button>
          </div>
        </div>
        <div class="panel-body p-2">
          <table class="table table-bordered">
            <tr>
              <th>{{'accounting-plan.value-name' | translate}}</th>
              <th>{{'accounting-plan.value-analytics' | translate}}</th>
              <th></th>
            </tr>
            <tr *ngFor="let val of plan.PossibleValues">
              <td>
                <div class="input-group">
                  <input required class="form-control form-control-sm" [(ngModel)]="val.Name">
                </div>
              </td>
              <td>
                <div class="input-group">
                  <input required class="form-control form-control-sm" [(ngModel)]="val.AnalyticsCode"
                    (change)="calculateExample()">
                </div>
              </td>
              <td class="text-center">
                <div>
                  <button (click)="removeValue(plan, val)" class="btn btn-danger btn-sm">
                    <nb-icon icon="fa-trash"></nb-icon>
                  </button>
                </div>
              </td>
            </tr>
          </table>
          <div class="text-center">
            <button (click)="addValue(plan)" class="btn btn-outline-secondary btn-sm">
              <nb-icon icon="fa-plus"></nb-icon> {{'accounting-plan.add-value' | translate}}
            </button>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button type="button" class="btn btn-secondary mt-3" (click)="addPlan()">
          <nb-icon icon="fa-plus"></nb-icon> {{'accounting-plan.add-plan' | translate}}
        </button>
      </div>
    </div>
    <on-loading *ngIf="isLoading$ | async"></on-loading>
  </div>
</on-two-columns-display>