import { EditReportPageComponent } from './my-report-page/edit-report-page/edit-report-page.component';
import { MyReportsPageComponent } from './my-report-page/my-reports-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', component: MyReportsPageComponent },
  {
    path: 'edit/:reportId',
    component: EditReportPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportRoutingModule {}
