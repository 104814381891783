<on-page-title [title]="'expense.my-receipts' | translate" icon="fa-receipt" [showReloadButton]="true" (reload)="reload()"></on-page-title>

<fieldset class="expenses">
  <on-loading *ngIf="isLoading"></on-loading>
  <div class="col">
    <on-expense-list #expensesList *ngIf="!isLoading && expensesToShow" [leftActions]="addActions" [centerActions]="reportActions"
      [rightActions]="deleteActions" [expenses]="expensesToShow" [canSelect]="true" [canEdit]="true" [pageSize]="pageSize"
      [canAddColumns]="true" (changesDone)="loadMyExpenses()" [showDuplicates]="true" gridStateKey="my-expenses-grid-state"></on-expense-list>
  </div>

  <p class="info-text text-center" *ngIf="freeAccount">{{'list.limited-free-account' | translate}}</p>
</fieldset>
