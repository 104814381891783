<div *ngIf="!isEditing">
    <p class="text-center">
        <span *ngIf="initialValue != null">{{initialValue}}</span>
        <span *ngIf="initialValue == null">
            <nb-icon icon="fa-infinity"></nb-icon>
        </span>
        <button class="btn" (click)="isEditing = true">
            <nb-icon icon="fa-pen"></nb-icon>
        </button>
    </p>
</div>
<div *ngIf="isEditing">
    <div class="input-group">
        <button class="btn btn-sm btn-secondary" (click)="currentValue = null">
            <nb-icon icon="fa-infinity"></nb-icon>
        </button>
        <input class="form-control form-control-sm" [placeholder]="'expert-accountant.unlimited-licenses' | translate"
            [(ngModel)]="currentValue">
        <button class="btn btn-sm btn-success" (click)="update()">
            <nb-icon icon="fa-check"></nb-icon>
        </button>
        <button class="btn btn-danger btn-sm" (click)="cancelEdit()">
            <nb-icon icon="fa-times"></nb-icon>
        </button>
    </div>
</div>