import { Component, OnInit, ViewChild, HostListener, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions, ColDef } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { AccountantExport } from 'onexpense/company/_models/AccountantExport';
import { ExportFormatGridcellComponent } from '../gridcell/export-format-gridcell/export-format-gridcell.component';
import { ExportArchivedGridcellComponent } from '../gridcell/export-archived-gridcell/export-archived-gridcell.component';
import { DeleteRowGridcellComponent } from 'on-shared/grid-cell/delete-row-gridcell/delete-row-gridcell.component';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AccountantExportAction } from 'on-shared/stores/accountant-export/accountant-export.actions';


@Component({
  selector: 'on-accountant-exports-grid',
  templateUrl: './accountant-exports-grid.component.html',
  styleUrls: ['./accountant-exports-grid.component.scss']
})
export class AccountantExportsGridComponent implements OnInit, OnChanges {

  @Input() exports: AccountantExport[];
  @Input() showLockColumn = false;
  @Input() showDeleteButton = false;

  gridOptions: GridOptions;
  defaultColDef: ColDef;

  // Grid Exports
  @ViewChild('agGridExports', { static: false }) gridExports: AgGridAngular;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.gridExports) {
      const timeout = setTimeout(_ => {
        this.gridExports.api.sizeColumnsToFit();
        clearTimeout(timeout);
      }, 200);
    }
  }

  constructor(
    private translateService: TranslateService,
    public router: Router,
    private store: Store,
  ) { }

  ngOnInit() {
    this.initGrid();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.exports && this.exports) {
      this.exports.map(exp => {
        if (exp.NbOfReports > 0) {
          exp.disabled = true;
          exp.tooltipMessage = this.translateService.instant('company.accountant-export.table.cannot-delete-message');
        }
      });
    }

    if (simpleChanges.showLockColumn) {
      if (this.gridExports) {
        this.gridExports.columnApi.setColumnVisible('VPState', this.showLockColumn);
        this.gridExports.api.sizeColumnsToFit();
      } else if (this.gridOptions) {
        const col = this.gridOptions.columnDefs.filter(filterCol => (filterCol as ColDef).field === 'VPState')[0] as ColDef;
        col.hide = !this.showLockColumn;
      }
    }

    if (simpleChanges.showDeleteButton) {
      if (this.gridExports) {
        this.gridExports.columnApi.setColumnVisible('deleteColumn', this.showDeleteButton);
        this.gridExports.api.sizeColumnsToFit();
      } else if (this.gridOptions) {
        const col = this.gridOptions.columnDefs.filter(filterCol => (filterCol as ColDef).colId === 'deleteColumn')[0] as ColDef;
        col.hide = !this.showDeleteButton;
      }
    }
  }

  initGrid() {
    // Grid Hierarchy Options
    this.gridOptions = {
      columnDefs: [
        {
          headerName: this.translateService.instant('company.accountant-export.table.name'),
          field: 'Name',
          sortable: true,
          filter: 'agTextColumnFilter',
          resizable: true,
          minWidth: 150,
          tooltipField: 'Name'
        },
        {
          headerName: this.translateService.instant('company.accountant-export.table.comments'),
          field: 'Comment',
          sortable: true,
          filter: 'agTextColumnFilter',
          resizable: true,
          minWidth: 150,
          tooltipField: 'Comment'
        },
        {
          headerName: this.translateService.instant('company.accountant-export.table.reports'),
          field: 'NbOfReports',
          sortable: true,
          filter: 'agTextColumnFilter',
          cellRenderer: function (params) {
            return `
              <i class="fas fa-2x fa-archive" style="font-size: 1.5em; margin-right: 5px"></i> ${params.value}
            `;
          },
          resizable: true,
          minWidth: 70
        },
        {
          headerName: this.translateService.instant('company.accountant-export.table.period'),
          sortable: true,
          filter: 'agTextColumnFilter',
          resizable: true,
          cellRenderer: function (params) {
            const start = new Date(params.data.PeriodBeginDate);
            const end = new Date(params.data.PeriodEndDate);
            return `
              <i class="fas fa-calendar-alt" style="font-size: 1.5em; margin-right: 5px"></i>
                ${start.getDate() < 10 ? 0 : ''}${start.getDate()}/${start.getMonth() + 1 < 10 ? 0 : ''
              }${start.getMonth() + 1}/${start.getFullYear()}
              <i class="fas fa-arrow-right"></i>
                ${end.getDate() < 10 ? 0 : ''}${end.getDate()}/${end.getMonth() + 1 < 10 ? 0 : ''
              }${end.getMonth() + 1}/${end.getFullYear()}
            `;
          },
          minWidth: 200
        },
        {
          headerName: this.translateService.instant('company.accountant-export.table.generation'),
          resizable: false,
          minWidth: 180,
          maxWidth: 180,
          cellRenderer: ExportFormatGridcellComponent
        },
        {
          headerName: this.translateService.instant('company.accountant-export.table.archived'),
          field: 'VPState',
          resizable: false,
          hide: !this.showLockColumn,
          minWidth: 100,
          maxWidth: 100,
          cellRenderer: ExportArchivedGridcellComponent,
          cellClass: 'd-flex align-items-center justify-content-center text-center',
        },
        {
          colId: 'deleteColumn',
          headerName: this.translateService.instant('company.accountant-export.table.delete'),
          resizable: false,
          minWidth: 130,
          maxWidth: 130,
          cellRenderer: DeleteRowGridcellComponent,
          cellClass: 'd-flex align-items-center justify-content-center text-center',
          tooltipValueGetter: function (params) {
            if (params.data.NbOfReports !== 0) {
              return params.context.parent.translateService.instant('company.accountant-export.table.cannot-delete-message');
            }
          }
        }
      ],

      rowSelection: 'multiple',
      // floatingFilter: true,
      domLayout: 'autoHeight',

      pagination: false,
      // paginationPageSize: this.pageSize, // Nb items displayed per page - default

      rowHeight: 38,
      enableBrowserTooltips: true,
      suppressCellFocus: true,
      suppressRowClickSelection: true,

      // For get 'this' in child component
      context: { parent: this },

      onGridReady: function (params) {
        params.api.sizeColumnsToFit();
      },

      onCellClicked: this.onCellClicked,

      // Translate
      localeText: {
        noRowsToShow: this.translateService.instant('table.no-export'),
        // Pagination
        to: this.translateService.instant('table.to'),
        of: this.translateService.instant('table.of'),
        // Filters
        filterOoo: this.translateService.instant('table.filterOoo'),
        contains: this.translateService.instant('table.contains'),
        equals: this.translateService.instant('table.equals'),
        notEqual: this.translateService.instant('table.notEqual'),
        lessThan: this.translateService.instant('table.lessThan'),
        lessThanOrEqual: this.translateService.instant('table.lessThanOrEqual'),
        greaterThan: this.translateService.instant('table.greaterThan'),
        greaterThanOrEqual: this.translateService.instant('table.greaterThanOrEqual'),
        inRange: this.translateService.instant('table.inRange'),
        startsWith: this.translateService.instant('table.startsWith'),
        endsWith: this.translateService.instant('table.endsWith'),
        notContains: this.translateService.instant('table.notContains'),

        andCondition: this.translateService.instant('table.andCondition'),
        orCondition: this.translateService.instant('table.orCondition')
      }
    };

    this.defaultColDef = {
      cellClass: 'd-flex align-items-center',
      editable: false
    };
  }

  deleteRow(event) {
    this.deleteAccountantExport(event.data);
  }

  deleteAccountantExport(accountantExport: AccountantExport) {
    this.store.dispatch(new AccountantExportAction.DeleteAccountantExport(accountantExport.Id));
  }

  onCellClicked(event) {
    // Block for exports cell
    if (event.colDef.colId !== 'exports') {
      event.context.parent.router.navigate(['/company', 'exports', event.data.Id]);
    }
  }

}
